import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgDot = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={4}
    height={4}
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0.25 2C0.25 2.46413 0.434374 2.90925 0.762563 3.23744C1.09075 3.56563 1.53587 3.75 2 3.75C2.46413 3.75 2.90925 3.56563 3.23744 3.23744C3.56563 2.90925 3.75 2.46413 3.75 2C3.75 1.53587 3.56563 1.09075 3.23744 0.762563C2.90925 0.434374 2.46413 0.25 2 0.25C1.53587 0.25 1.09075 0.434374 0.762563 0.762563C0.434374 1.09075 0.25 1.53587 0.25 2V2Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgDot);
export default ForwardRef;
