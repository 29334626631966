import React from "react";

import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import { Group, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import useMintState from "../../../hooks/useMintState";

import { AuctionCardButton } from "./AuctionCardButton";
import { AuctionCardStats } from "./AuctionCardStats";
import { getDisabledCardText } from "./utils";

export const AuctionCardSmall = () => {
  const theme = useMantineTheme();
  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const { auction, auctionStatus, auctionDateTo } = useMintState();

  const disabledCardText = getDisabledCardText(
    auctionStatus,
    auctionDateTo,
    auction?.chainId,
    auction?.stats
  );
  const label = isSmall
    ? `${auction?.price} ${getCurrencySymbol(auction?.chainId || "")}`
    : undefined;

  return (
    <Group position="right">
      <Group spacing={32} position="right">
        {!isSmall && <AuctionCardStats smallSize />}
        {disabledCardText && (
          <Text weight={500} size={isSmall ? 14 : 18}>
            {disabledCardText.title}
          </Text>
        )}
        <AuctionCardButton
          auctionLabel={label}
          showErrorIcon={false}
          size={isSmall ? "xs" : "md"}
        />
      </Group>
    </Group>
  );
};
