import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPinned = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.99988 9.24006C6.09634 9.24079 6.19194 9.222 6.28095 9.18482C6.36995 9.14763 6.45051 9.09282 6.51778 9.02369C6.58504 8.95455 6.63763 8.87252 6.67236 8.78254C6.70709 8.69255 6.72326 8.59646 6.71988 8.50006V6.36006C6.71988 6.33301 6.72865 6.3067 6.74488 6.28506C6.76111 6.26342 6.78392 6.24763 6.80988 6.24006C7.47485 6.04517 8.04725 5.61707 8.4221 5.03427C8.79695 4.45148 8.94911 3.75307 8.85067 3.06716C8.75223 2.38125 8.40978 1.75383 7.88615 1.29999C7.36252 0.846149 6.69282 0.596313 5.99988 0.596313C5.30694 0.596313 4.63724 0.846149 4.11361 1.29999C3.58998 1.75383 3.24753 2.38125 3.14909 3.06716C3.05065 3.75307 3.20281 4.45148 3.57766 5.03427C3.95251 5.61707 4.52491 6.04517 5.18988 6.24006C5.21584 6.24763 5.23865 6.26342 5.25488 6.28506C5.27111 6.3067 5.27988 6.33301 5.27988 6.36006V8.50006C5.2765 8.59646 5.29267 8.69255 5.3274 8.78254C5.36213 8.87252 5.41472 8.95455 5.48198 9.02369C5.54925 9.09282 5.62981 9.14763 5.71881 9.18482C5.80782 9.222 5.90342 9.24079 5.99988 9.24006ZM5.49988 2.74006C5.50383 2.88409 5.46447 3.02599 5.3869 3.14741C5.30933 3.26883 5.19712 3.36419 5.06477 3.42115C4.93242 3.4781 4.78603 3.49404 4.64453 3.4669C4.50302 3.43976 4.37292 3.37079 4.27103 3.26891C4.16915 3.16702 4.10018 3.03692 4.07304 2.89541C4.0459 2.75391 4.06184 2.60752 4.1188 2.47517C4.17575 2.34282 4.27111 2.23061 4.39253 2.15304C4.51395 2.07547 4.65585 2.03611 4.79988 2.04006C4.98777 2.044 5.16655 2.12175 5.29755 2.25649C5.42855 2.39124 5.50124 2.57214 5.49988 2.76006V2.74006Z"
      fill="currentColor"
    />
    <path
      d="M0.72 11.4001H11.28C11.471 11.4001 11.6541 11.3242 11.7891 11.1892C11.9241 11.0542 12 10.871 12 10.6801C12 10.4891 11.9241 10.306 11.7891 10.171C11.6541 10.0359 11.471 9.96008 11.28 9.96008H0.72C0.529044 9.96008 0.345909 10.0359 0.210883 10.171C0.0758569 10.306 0 10.4891 0 10.6801C0 10.871 0.0758569 11.0542 0.210883 11.1892C0.345909 11.3242 0.529044 11.4001 0.72 11.4001Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPinned);
export default ForwardRef;
