import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgBrandHighlightNoLogo = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={84}
    height={20}
    viewBox="0 0 84 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2.74725 15.3846H0V0H2.74725V6.30769H9.95605V0H12.7033V15.3846H9.95605V8.83517H2.74725V15.3846Z"
      fill="black"
    />
    <path
      d="M17.467 15.3846H14.9835V4.79121H17.467V15.3846ZM16.2362 2.96703C15.8406 2.96703 15.4963 2.82784 15.2032 2.54945C14.9102 2.27106 14.7637 1.92674 14.7637 1.51648C14.7637 1.10623 14.9102 0.761905 15.2032 0.483517C15.4963 0.205129 15.8406 0.0659345 16.2362 0.0659345C16.6172 0.0659345 16.9541 0.205129 17.2472 0.483517C17.5402 0.761905 17.6868 1.10623 17.6868 1.51648C17.6868 1.92674 17.5402 2.27106 17.2472 2.54945C16.9541 2.82784 16.6172 2.96703 16.2362 2.96703Z"
      fill="black"
    />
    <path
      d="M24.0557 20C23.0301 20 22.085 19.7729 21.2206 19.3187C20.3561 18.8645 19.7334 18.359 19.3524 17.8022L21.0228 16.2198C21.3012 16.5861 21.7114 16.9231 22.2535 17.2308C22.8103 17.5385 23.3964 17.6923 24.0118 17.6923C24.9495 17.6923 25.6968 17.3993 26.2535 16.8132C26.8103 16.2271 27.0887 15.4359 27.0887 14.4396V13.4945H27.0667C26.7883 13.9048 26.3488 14.2784 25.748 14.6154C25.1473 14.9524 24.466 15.1209 23.7041 15.1209C22.8689 15.1209 22.0924 14.8938 21.3744 14.4396C20.6565 13.9853 20.085 13.3626 19.6601 12.5714C19.2352 11.7656 19.0228 10.8571 19.0228 9.84615C19.0228 8.83517 19.2352 7.93407 19.6601 7.14286C20.085 6.337 20.6565 5.70696 21.3744 5.25275C22.0924 4.79854 22.8689 4.57143 23.7041 4.57143C24.466 4.57143 25.1473 4.73993 25.748 5.07692C26.3488 5.41392 26.7883 5.78755 27.0667 6.1978H27.0887V4.79121H29.5722V14.4396C29.5722 15.4945 29.3378 16.4396 28.8689 17.2747C28.4001 18.1245 27.748 18.7912 26.9129 19.2747C26.0777 19.7582 25.1253 20 24.0557 20ZM24.3195 12.8132C25.096 12.8132 25.7481 12.5348 26.2755 11.978C26.8176 11.4212 27.0887 10.7106 27.0887 9.84615C27.0887 8.99634 26.8176 8.29304 26.2755 7.73626C25.7481 7.16484 25.096 6.87912 24.3195 6.87912C23.5283 6.87912 22.8616 7.16484 22.3195 7.73626C21.792 8.29304 21.5283 8.99634 21.5283 9.84615C21.5283 10.7106 21.792 11.4212 22.3195 11.978C22.8616 12.5348 23.5283 12.8132 24.3195 12.8132Z"
      fill="black"
    />
    <path
      d="M31.8121 15.3846V0H34.2956V6.28571H34.3396C34.618 5.84615 35.0429 5.45055 35.6143 5.0989C36.2004 4.74725 36.8524 4.57143 37.5703 4.57143C38.3615 4.57143 39.0722 4.76191 39.7022 5.14286C40.3322 5.50916 40.8231 6.03663 41.1747 6.72528C41.5264 7.41392 41.7022 8.22711 41.7022 9.16484V15.3846H39.1967V9.31868C39.1967 8.58608 38.9843 8.00733 38.5593 7.58242C38.1491 7.14286 37.6143 6.92308 36.955 6.92308C36.1931 6.92308 35.5557 7.18681 35.0429 7.71429C34.5447 8.22711 34.2956 8.89377 34.2956 9.71429V15.3846H31.8121Z"
      fill="black"
    />
    <path d="M46.2942 15.3846H43.8107V0H46.2942V15.3846Z" fill="black" />
    <path
      d="M51.1028 15.3846H48.6193V4.79121H51.1028V15.3846ZM49.872 2.96703C49.4764 2.96703 49.1321 2.82784 48.8391 2.54945C48.546 2.27106 48.3995 1.92674 48.3995 1.51648C48.3995 1.10623 48.546 0.761905 48.8391 0.483517C49.1321 0.205129 49.4764 0.0659345 49.872 0.0659345C50.253 0.0659345 50.59 0.205129 50.883 0.483517C51.176 0.761905 51.3226 1.10623 51.3226 1.51648C51.3226 1.92674 51.176 2.27106 50.883 2.54945C50.59 2.82784 50.253 2.96703 49.872 2.96703Z"
      fill="black"
    />
    <path
      d="M57.6916 20C56.6659 20 55.7209 19.7729 54.8564 19.3187C53.9919 18.8645 53.3692 18.359 52.9883 17.8022L54.6586 16.2198C54.937 16.5861 55.3472 16.9231 55.8894 17.2308C56.4461 17.5385 57.0322 17.6923 57.6476 17.6923C58.5853 17.6923 59.3326 17.3993 59.8894 16.8132C60.4461 16.2271 60.7245 15.4359 60.7245 14.4396V13.4945H60.7025C60.4242 13.9048 59.9846 14.2784 59.3839 14.6154C58.7831 14.9524 58.1018 15.1209 57.3399 15.1209C56.5047 15.1209 55.7282 14.8938 55.0102 14.4396C54.2923 13.9853 53.7209 13.3626 53.296 12.5714C52.871 11.7656 52.6586 10.8571 52.6586 9.84615C52.6586 8.83517 52.871 7.93407 53.296 7.14286C53.7209 6.337 54.2923 5.70696 55.0102 5.25275C55.7282 4.79854 56.5047 4.57143 57.3399 4.57143C58.1018 4.57143 58.7831 4.73993 59.3839 5.07692C59.9846 5.41392 60.4242 5.78755 60.7025 6.1978H60.7245V4.79121H63.208V14.4396C63.208 15.4945 62.9736 16.4396 62.5047 17.2747C62.0359 18.1245 61.3839 18.7912 60.5487 19.2747C59.7135 19.7582 58.7612 20 57.6916 20ZM57.9553 12.8132C58.7319 12.8132 59.3839 12.5348 59.9113 11.978C60.4535 11.4212 60.7245 10.7106 60.7245 9.84615C60.7245 8.99634 60.4535 8.29304 59.9113 7.73626C59.3839 7.16484 58.7319 6.87912 57.9553 6.87912C57.1641 6.87912 56.4974 7.16484 55.9553 7.73626C55.4278 8.29304 55.1641 8.99634 55.1641 9.84615C55.1641 10.7106 55.4278 11.4212 55.9553 11.978C56.4974 12.5348 57.1641 12.8132 57.9553 12.8132Z"
      fill="black"
    />
    <path
      d="M65.4479 15.3846V0H67.9314V6.28571H67.9754C68.2538 5.84615 68.6787 5.45055 69.2501 5.0989C69.8362 4.74725 70.4882 4.57143 71.2062 4.57143C71.9974 4.57143 72.708 4.76191 73.338 5.14286C73.9681 5.50916 74.4589 6.03663 74.8105 6.72528C75.1622 7.41392 75.338 8.22711 75.338 9.16484V15.3846H72.8325V9.31868C72.8325 8.58608 72.6201 8.00733 72.1952 7.58242C71.7849 7.14286 71.2501 6.92308 70.5908 6.92308C69.8289 6.92308 69.1915 7.18681 68.6787 7.71429C68.1805 8.22711 67.9314 8.89377 67.9314 9.71429V15.3846H65.4479Z"
      fill="black"
    />
    <path
      d="M81.4737 15.4945C80.4041 15.4945 79.5689 15.1795 78.9682 14.5495C78.3674 13.9194 78.0671 13.0183 78.0671 11.8462V7.12088H76.177V4.79121H77.2759C77.9792 4.79121 78.3308 4.38095 78.3308 3.56044V1.31868H80.5506V4.79121H83.0121V7.12088H80.5506V11.5604C80.5506 12.6154 81.0488 13.1429 82.0451 13.1429C82.3821 13.1429 82.7777 13.0403 83.2319 12.8352V15.1648C83.0414 15.2527 82.785 15.326 82.4627 15.3846C82.1403 15.4579 81.8107 15.4945 81.4737 15.4945Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgBrandHighlightNoLogo);
export default ForwardRef;
