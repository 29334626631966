import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPostEditorList = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.28571 2.99999C1.99579 2.99999 2.57143 2.42435 2.57143 1.71427C2.57143 1.00419 1.99579 0.428558 1.28571 0.428558C0.575634 0.428558 0 1.00419 0 1.71427C0 2.42435 0.575634 2.99999 1.28571 2.99999Z"
      fill="#0D0D0D"
    />
    <path
      d="M11.1426 2.5714H4.71408C4.48675 2.5714 4.26873 2.4811 4.10799 2.32035C3.94724 2.15961 3.85693 1.94159 3.85693 1.71426C3.85693 1.48693 3.94724 1.26891 4.10799 1.10817C4.26873 0.947423 4.48675 0.857117 4.71408 0.857117H11.1426C11.37 0.857117 11.588 0.947423 11.7487 1.10817C11.9095 1.26891 11.9998 1.48693 11.9998 1.71426C11.9998 1.94159 11.9095 2.15961 11.7487 2.32035C11.588 2.4811 11.37 2.5714 11.1426 2.5714Z"
      fill="#0D0D0D"
    />
    <path
      d="M1.28571 7.28569C1.99579 7.28569 2.57143 6.71006 2.57143 5.99998C2.57143 5.2899 1.99579 4.71426 1.28571 4.71426C0.575634 4.71426 0 5.2899 0 5.99998C0 6.71006 0.575634 7.28569 1.28571 7.28569Z"
      fill="#0D0D0D"
    />
    <path
      d="M11.1426 6.85714H4.71408C4.48675 6.85714 4.26873 6.76683 4.10799 6.60609C3.94724 6.44534 3.85693 6.22732 3.85693 6C3.85693 5.77267 3.94724 5.55465 4.10799 5.3939C4.26873 5.23316 4.48675 5.14285 4.71408 5.14285H11.1426C11.37 5.14285 11.588 5.23316 11.7487 5.3939C11.9095 5.55465 11.9998 5.77267 11.9998 6C11.9998 6.22732 11.9095 6.44534 11.7487 6.60609C11.588 6.76683 11.37 6.85714 11.1426 6.85714Z"
      fill="#0D0D0D"
    />
    <path
      d="M1.28571 11.5714C1.99579 11.5714 2.57143 10.9958 2.57143 10.2857C2.57143 9.57563 1.99579 9 1.28571 9C0.575634 9 0 9.57563 0 10.2857C0 10.9958 0.575634 11.5714 1.28571 11.5714Z"
      fill="#0D0D0D"
    />
    <path
      d="M11.1426 11.1428H4.71408C4.48675 11.1428 4.26873 11.0525 4.10799 10.8918C3.94724 10.731 3.85693 10.513 3.85693 10.2857C3.85693 10.0584 3.94724 9.84036 4.10799 9.67961C4.26873 9.51886 4.48675 9.42856 4.71408 9.42856H11.1426C11.37 9.42856 11.588 9.51886 11.7487 9.67961C11.9095 9.84036 11.9998 10.0584 11.9998 10.2857C11.9998 10.513 11.9095 10.731 11.7487 10.8918C11.588 11.0525 11.37 11.1428 11.1426 11.1428Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPostEditorList);
export default ForwardRef;
