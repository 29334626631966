import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgTextBubble = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={32}
    height={30}
    viewBox="0 0 32 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.9998 0.786621C7.5465 0.786621 0.666504 6.45329 0.666504 13.4133C0.691566 15.0886 1.08431 16.7379 1.81697 18.2447C2.54963 19.7515 3.60433 21.079 4.9065 22.1333L1.83984 28.2533C1.77685 28.3764 1.75453 28.5163 1.77609 28.6528C1.79766 28.7894 1.86199 28.9156 1.95984 29.0133C2.05706 29.1116 2.18243 29.1772 2.31859 29.2011C2.45475 29.225 2.59497 29.2059 2.71984 29.1466L10.9198 25.3333C12.5699 25.8157 14.2807 26.0582 15.9998 26.0533C24.4532 26.0533 31.3332 20.3866 31.3332 13.4133C31.3332 6.43995 24.4532 0.786621 15.9998 0.786621Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgTextBubble);
export default ForwardRef;
