import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const AuctionFieldsFragmentDoc = gql `
  fragment AuctionFields on Auction {
    chainId
    collectionId
    currency
    end
    gateId
    paused
    id
    price
    start
    tokenId
    customBufferMinutes
  }
`;
export const FulfillAuctionDocument = gql `
  mutation FulfillAuction($auctionId: String!, $chainId: Float!) {
    fulfillAuction(auctionId: $auctionId, chainId: $chainId) {
      chainId
      address
      args
      method
      value
      bytecode
    }
  }
`;
/**
 * __useFulfillAuctionMutation__
 *
 * To run a mutation, you first call `useFulfillAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFulfillAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fulfillAuctionMutation, { data, loading, error }] = useFulfillAuctionMutation({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useFulfillAuctionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(FulfillAuctionDocument, options);
}
export const GetManageTokenAuctionsDocument = gql `
  query GetManageTokenAuctions($collectionId: String!) {
    getAuctionsByCollection(collectionId: $collectionId) {
      ...AuctionFields
      stats {
        currentBid
        highestBidder
        totalBids
        status
      }
    }
  }
  ${AuctionFieldsFragmentDoc}
`;
/**
 * __useGetManageTokenAuctionsQuery__
 *
 * To run a query within a React component, call `useGetManageTokenAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageTokenAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageTokenAuctionsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetManageTokenAuctionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetManageTokenAuctionsDocument, options);
}
export function useGetManageTokenAuctionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetManageTokenAuctionsDocument, options);
}
