import { PropsWithChildren } from "react";

import { Helmet } from "react-helmet-async";

import { GetSalePageCollectionQuery } from "./queries.graphql.generated";

export type WarpcastSEOProps = {
  collection: GetSalePageCollectionQuery["getPublicCollection"];
};

export const MintPageSEO = ({
  collection,
}: PropsWithChildren<WarpcastSEOProps>) => {
  return (
    <Helmet>
      <meta name="eth:nft:collection" content={collection.name} />
      <meta name="eth:nft:contract_address" content={collection.address} />
      <meta
        name="eth:nft:creator_address"
        content={collection.creatorAddresses?.[0].address}
      />
      <meta name="eth_nft:mint_status" content={collection.status} />
      {collection.size && (
        <meta name="eth:nft:mint_count" content={collection.size.toString()} />
      )}
      {collection.editions &&
        collection.editions.length &&
        collection.editions[0].size && (
          <meta
            name="eth:nft:mint_count"
            content={collection.editions[0].size.toString()}
          />
        )}
      <meta name="eth:nft:scheme" content={"ERC721"} />
    </Helmet>
  );
};
