import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgArrowDownBoldSmall = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={8}
    height={6}
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3.98767 5.49817C4.2204 5.49817 4.41989 5.39843 4.55288 5.23219L7.84436 1.50849C8.07709 1.24251 8.04385 0.843541 7.77787 0.644057C7.51189 0.444573 7.14617 0.444573 6.91344 0.710551L4.05417 3.96879C4.02092 4.00204 3.98767 4.00204 3.92118 3.96879L1.06191 0.710551C0.829179 0.444572 0.430211 0.444572 0.19748 0.677304C-0.0352517 0.910035 -0.0684991 1.27576 0.130985 1.54173L3.38922 5.26544C3.55546 5.39842 3.75494 5.49817 3.98767 5.49817Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgArrowDownBoldSmall);
export default ForwardRef;
