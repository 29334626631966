import * as React from "react";
import { forwardRef, Ref, useEffect, useState } from "react";

import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { UnlockGateResult } from "@hl/shared-features/lib/apollo/graphql.generated";
import { useAuth } from "@hl/shared-features/lib/features/auth";
import {
  TransactionStateType,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { GateConditionsModalProps } from "@hl/shared-features/lib/features/gate/GateConditionsModalBody";
import { GateConditionsResult } from "@hl/shared-features/lib/features/gate/GateConditionsResult";
import DescriptionMarkdown from "@hl/shared-features/lib/features/markdown/DescriptionMarkdown";
import HighlightedNotes from "@hl/shared-features/lib/features/markdown/HighlightedNotes";
import { formatNumber } from "@hl/shared-features/lib/utils/format";
import {
  Accordion,
  Box,
  Button,
  createStyles,
  Flex,
  ScrollArea,
  Space,
  Stack,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useParams } from "react-router-dom";

import { TOOLS_URL } from "~config";
import { MintCard } from "~features/MintPage/MintVector/MintCard";
import CollectionDetailsTitleSection from "~features/MintPage/apollo/CollectionDetailsTitleSection";
import CollectorsListApollo from "~features/MintPage/apollo/CollectorsListApollo";
import { DetailSectionApollo } from "~features/MintPage/apollo/DetailSectionApollo";
import MintRequirementsNotice from "~features/MintPage/apollo/MintRequirementsNotice";
import { AuctionCardApollo } from "~features/MintPage/apollo/auction/AuctionCardApollo";
import { AuctionHistoryApollo } from "~features/MintPage/apollo/auction/AuctionHistoryApollo";
import { AuctionRulesCardApollo } from "~features/MintPage/apollo/auction/AuctionRulesCardApollo";
import BridgeLinks from "~features/MintPage/components/BridgeLinks";
import { DetailSectionViewFields } from "~features/MintPage/components/DetailSection/DetailSectionViewFields";
import { MintDetails } from "~features/MintPage/components/DetailSection/MintDetails";
import {
  GetSalePageMintVectorsDocument,
  useGetAudienceOfCollectionQuery,
} from "~features/MintPage/queries.graphql.generated";
import { SponsorMintCard } from "~features/MintPage/sponsor-mints/SponsoredMintsCard";
import {
  ModalType,
  resetModalVar,
  updateModalVar,
} from "~features/layout/Modal/modal";

import { _CollectionType } from "../../../apollo/graphql.generated";
import useMintState from "../../../hooks/useMintState";

export const useSidebarStyles = createStyles(
  (
    theme,
    {
      isMarketplaceShown,
      increasedSidebarWidth,
    }: {
      isMarketplaceShown?: boolean;
      increasedSidebarWidth?: boolean;
    }
  ) => {
    return {
      sidebarScrollArea: {
        width: "100%",
        order: 2,
        [theme.fn.largerThan("md")]: {
          flex: 1,
          minWidth: increasedSidebarWidth ? 520 : 420,
          maxWidth: increasedSidebarWidth ? 520 : 420,
          height: "100%",
          paddingBottom: isMarketplaceShown ? 0 : 96,
        },
        [theme.fn.largerThan("lg")]: {
          flex: 1,
          minWidth: increasedSidebarWidth ? 600 : 420,
          maxWidth: increasedSidebarWidth ? 600 : 420,
        },
      },
      sidebarDetails: {
        [theme.fn.largerThan("md")]: {
          padding: "40px 40px 0 40px !important",
          height: "100%",
        },
      },
      scrollbar: {
        zIndex: 2,
      },
      stack: {
        [theme.fn.largerThan("md")]: {
          height: "100%",
        },
      },
      link: {
        color: "inherit",
        textDecoration: "none",
        fontSize: 14,
        alignItems: "center",
        display: "flex",
        gap: 8,
      },
    };
  }
);

const COLLECTORS_PAGE_SIZE = 6;

const CollectionDetailsSidebar = forwardRef(
  (_, ref: Ref<HTMLButtonElement>) => {
    const { collectionId } = useParams();
    const {
      collection,
      txnId,
      tokens,
      handleMint,
      isCollectorChoiceMint,
      seriesTokenSelected,
      creatorAddresses,
      auction,
      isOptimizedLoading,
      mintVector,
      mintVectorsLoading,
      auctionsLoading,
      endedOrSoldOut,
      nonTransferable,
      collectionType,
    } = useMintState();
    const isOneOfOne = collectionType === _CollectionType.OneOfOne;
    const isMarketplaceShown = !nonTransferable && !isOneOfOne;
    const transactionState = useTransactionState(txnId);
    const [activePage, setActivePage] = useState<number>(1);
    const userGateAccess =
      mintVector?.userGateAccess ?? auction?.userGateAccess;
    const { classes, theme } = useSidebarStyles({
      isMarketplaceShown,
      increasedSidebarWidth: collection?.flagVariations.increasedSidebarWidth,
    });
    const isTablet = !useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    const useTabbedDetails = collection?.flagVariations.apolloTabbedDetails;

    const audienceOfCollectionQuery = useGetAudienceOfCollectionQuery({
      variables: {
        collectionId: collectionId!,
        cursor: activePage - 1,
        limit: COLLECTORS_PAGE_SIZE,
        findEnsNames: true,
      },
      skip: useTabbedDetails,
    });

    const { data, refetch, previousData } = audienceOfCollectionQuery;
    const { walletAddress } = useAuth();

    useEffect(() => {
      if (
        transactionState &&
        transactionState.type === TransactionStateType.Done
      ) {
        refetch();
      }
    }, [transactionState, refetch]);

    const collectors = data?.getAudienceOfCollection ?? null;
    const previousCollectors = previousData?.getAudienceOfCollection ?? null;
    const totalCount = collectors?.totalCount || previousCollectors?.totalCount;

    const showAllGatePrereqs = (
      result: UnlockGateResult,
      creatorAddress?: string | null,
      creatorEns?: string | null
    ) => {
      return () => {
        updateModalVar<GateConditionsModalProps>({
          showModal: ModalType.GATED_ENTITY_ALL_PREREQS,
          data: {
            creatorAddress,
            creatorEns,
            refetchOnRequirementChange: [GetSalePageMintVectorsDocument],
            result,
            handleDone: () => resetModalVar(),
            entity: "mint",
            isPreview: false,
          },
        });
      };
    };

    const onMintClick = isCollectorChoiceMint
      ? (chainId: number) =>
          handleMint(
            chainId,
            seriesTokenSelected?.id,
            seriesTokenSelected?.imageUrl || ""
          )
      : handleMint;

    const showGatePrereqs = userGateAccess
      ? showAllGatePrereqs(
          userGateAccess,
          creatorAddresses ? creatorAddresses[0].address : undefined,
          collection?.creatorEns
        )
      : undefined;

    return (
      <ScrollArea
        className={classes.sidebarScrollArea + " no-child-table"}
        classNames={{ scrollbar: classes.scrollbar }}
        miw={isTablet ? 300 : 380}
      >
        <Stack justify="space-between" className={classes.stack}>
          {!mintVectorsLoading &&
            !auctionsLoading &&
            !endedOrSoldOut &&
            userGateAccess &&
            showGatePrereqs && (
              <Box mb={-16}>
                <GateConditionsResult
                  creatorAddress={
                    creatorAddresses ? creatorAddresses[0].address : undefined
                  }
                  creatorEns={collection?.creatorEns}
                  refetchOnRequirementChange={[GetSalePageMintVectorsDocument]}
                  isApollo
                  handleShowModal={showGatePrereqs}
                  unlockGateResult={userGateAccess}
                  showFirstN={4}
                  showConnectWallet={false}
                  entity={auction ? "auction" : "mint"}
                  multipleMintVectorEnableMintPage={
                    collection?.flagVariations.multipleMintVectorsEnableMintPage
                  }
                />
              </Box>
            )}
          {collection?.flagVariations.multipleMintVectorsEnableMintPage &&
            mintVector && (
              <Box mb={-16}>
                <MintRequirementsNotice
                  handleShowModal={showGatePrereqs}
                  unlockGateResult={userGateAccess}
                />
              </Box>
            )}
          <Stack className={classes.sidebarDetails} spacing={0}>
            {!isTablet && (
              <>
                <CollectionDetailsTitleSection />
                {collection?.creatorAddresses?.some(
                  (evmAddress) => evmAddress.address === walletAddress
                ) && (
                  <Flex mt="xs">
                    <Button
                      component="a"
                      color={SECONDARY_COLOR}
                      size="xs"
                      href={TOOLS_URL.collections.manage.replace(
                        ":collectionId",
                        collection.id
                      )}
                    >
                      Manage collection
                    </Button>
                  </Flex>
                )}
              </>
            )}
            <Box mt={32}>
              <HighlightedNotes editorial={collection?.editorial} />
            </Box>
            {((!isOptimizedLoading && mintVector) ||
              (isOptimizedLoading && (mintVectorsLoading || mintVector))) && (
              <Stack w="100%" mt={32} spacing={20}>
                <MintCard
                  isApollo
                  ref={ref}
                  onMintClick={onMintClick}
                  onTimesUp={refetch}
                />
                <SponsorMintCard />
              </Stack>
            )}
            {auction && (
              <>
                <Space h="xl" />
                <AuctionCardApollo />
                <Space h="xl" />
                <AuctionHistoryApollo />
                <Space h="xl" />
                <AuctionRulesCardApollo
                  customBufferMinutes={auction.customBufferMinutes ?? undefined}
                />
                <Space h={45} />
              </>
            )}
            <Space h={32} />
            <DescriptionMarkdown
              description={collection?.description || ""}
              hasExpand={!collection?.flagVariations.increasedSidebarWidth}
              numChars={200}
            />

            {useTabbedDetails && (
              <MintDetails isApollo collectionId={collectionId} mt={40} />
            )}

            {!useTabbedDetails && (
              <Accordion
                multiple
                defaultValue={["notes"]}
                styles={{
                  label: { fontSize: 16, fontWeight: WEIGHT_BOLD },
                  panel: { fontSize: 14 },
                  control: { padding: 0, height: 80 },
                  content: { padding: "0 0 25px 0" },
                }}
              >
                {/*<Accordion.Item value="description">*/}
                {/*  <Accordion.Control>Description</Accordion.Control>*/}
                {/*  <Accordion.Panel>*/}
                {/*    <DescriptionMarkdown description={collection?.description || ""}/>*/}
                {/*  </Accordion.Panel>*/}
                {/*</Accordion.Item>*/}

                <Accordion.Item value="details">
                  <Accordion.Control>Details</Accordion.Control>
                  <Accordion.Panel>
                    <DetailSectionApollo token={tokens[0]} />
                    <DetailSectionViewFields noBottomDivider isApollo />
                    <Box h={12} />
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="collectors">
                  <Accordion.Control>
                    Collectors{" "}
                    {totalCount ? ` · ${formatNumber(totalCount)}` : ""}
                  </Accordion.Control>
                  <Accordion.Panel>
                    <CollectorsListApollo
                      audienceOfCollectionQuery={audienceOfCollectionQuery}
                      activePage={activePage}
                      setActivePage={setActivePage}
                      itemsPerPage={COLLECTORS_PAGE_SIZE}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            )}
            {((!isOptimizedLoading && mintVector) ||
              (isOptimizedLoading && (mintVectorsLoading || mintVector))) && (
              <BridgeLinks
                mt={auction ? 0 : 40}
                chainId={collection?.chainId ?? 1}
                paymentCurrency={mintVector?.paymentCurrency}
                isApollo
              />
            )}
            <Space h="xl" />
          </Stack>
        </Stack>
      </ScrollArea>
    );
  }
);

export default CollectionDetailsSidebar;
