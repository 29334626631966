import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgInboxEmail = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    ref={ref}
    {...props}
  >
    <rect width={40} height={40} fill="url(#pattern0)" />
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_275:208" transform="scale(0.0125)" />
      </pattern>
      <image
        id="image0_275:208"
        width={80}
        height={80}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAEXElEQVR4Ae2auwoUMRSGfxGx1MpKUAuxtbQRBUsb38ALNjbqAyhaK6hoI1oLFhY+gRcELSwUCzvR1ka8X0BQftmzu2Z3Ti4nmezMJjBkdybJOf+Xk0wyM4At7QVwFcALAH8GdtBn+n7QhiC99pmBAdM6mFp6TddGBE/AUlMviSEvRseW9zKc344YILUVT2OLOldPUYDa8H0H4NFADvrqgpP/RYexBvBI0a7L2/ixVQRYtOfy8vu39pOIc/OiOrQILGq4AcxMwNhctUCoZtgIzK1eTUc1wy4B4/9qOqoZNgJzq1fTYTHMug8Djj429hYdbmdE/bcY/qisvdylBB+TlUwWHSa/Ug1r9Vx4/H/R5KW/suYPrxVLqYa3RkQfAZbe1aTqMIO1GGZULYs29xz306WTRYfJtxyG2UbXwUjtI+XQkeRnNcNJ3nZXqqajmuFuFklXqumoZjgJU3elajqqGe5mkXSlmo5qhpMwdVeqpiOHYe4yDhQ8urHNruTQMWst4pfFMB+j9/VGz7eTseiIwLVY1PIuQXuR4y6mc/xf9H52RgNIjUUSvyPRhNGprhS7ldPshF7b2eXMZCGvtUOt2RLnrJCPhzSAdOalpwM0QbHX+ORHS1oEii1qNj8VuhAh2geQEcHvT0q/O74fIDwEoIAkg+gUGnVihLkPYLQTBSvEAKS2qGiMibp1ASg6vU/L+chdCsfmY47AeRZk1JksE/26ACQjNZ0FEPP+QnpnHQD6FuhTsLxj8m4pcELyMQMki6QlDd9NhEajDyAX00cB8CZV8qANX6KvIUFB7RyRpkThId9E+wDGRnSIwK4y6kQfsBNhu1xPBr1iuAvgJoAtHswEpN1kNIA1tnKaeC0CuWf3vRUkKzK7Q2bSi+8BHPZA5OWut2tjAMiRpoGnfjIiK+E2/SEn7gHY5gG57CajAWRzfQ5h2tKSG4EcWT7/yYRshJPkCyd44QOAkMc6LCM3GZ8D7FmWZwSXPEL8ngcYsjRhm2Qi0ObzpSelwAMAO7SunIR8iNOeZnq9zM4kOO2RFx3aDoAMhMeyXL3ICl8BnAawoVeJdY1RKzVT+zJo8+e8BaTwcwC76+rqxTo1Uqvo9uXBBdnQLwDnAWzqRUq/RjYCOAfgZwQ8MokCKL3xOnVr0y+TYGvcpr1KZJEEkCB/A7gMYHOwm6tXkL5fmmiR4IjNkwGKoTcA9q8eG69H+wDQd9GRmpsbEMO3ARyaLEi5zlrVgz7eygBOdGcDKA2uW94AGqOxAWwA7TcCy7TTIrBFYIvAQY+CQTtvmbty1W0A2xzY5sBBj4JBO59rHrO00wC2ObDNgYMeBYN23jJ35arbALY5sM2Bgx4Fg3Y+1zxmaacBbHNg5Tnwk7EHLOE/9LpkhycNYPI09pgArzeAyQCvEOAeAD8axGiI/HZwl3xAcqoBjAZ4UuBJfgLAlwbSC/IzgOMCzc35PfQNAM8AfG8wpzC/AXg6uV/89834X7K22e+WR/PaAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgInboxEmail);
export default ForwardRef;
