import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import {
  createStyles,
  DefaultProps,
  Group,
  Selectors,
  Space,
  Stack,
  Text,
} from "@mantine/core";

import useMintState from "~hooks/useMintState";

import { SaleStatusAuction } from "./types";

type StatsStylesParams = {
  smallSize?: boolean;
};
const useStatsStyle = createStyles(
  (theme, { smallSize }: StatsStylesParams) => ({
    value: {
      fontSize: smallSize ? 18 : 32,
    },
    valueLabel: {
      fontSize: smallSize ? 12 : 13,
      letterSpacing: "0.05em",
    },
  })
);

type StatsStylesNames = Selectors<typeof useStatsStyle>;

export interface CardStatsProps
  extends DefaultProps<StatsStylesNames, StatsStylesParams> {
  smallSize?: boolean;
}

export const AuctionCardStats = ({
  smallSize = false,
  styles,
}: CardStatsProps) => {
  const { auction, auctionStatus } = useMintState();
  const price = auction
    ? auctionStatus === SaleStatusAuction.ACTIVE_WITH_BID
      ? auction.stats!.currentBid.toString()
      : auction.price
    : "N/A";
  const currency = getCurrencySymbol(auction?.chainId || "");

  const { classes } = useStatsStyle(
    { smallSize },
    { name: "CardStats", styles }
  );
  if (
    [
      SaleStatusAuction.ACTIVE,
      SaleStatusAuction.ACTIVE_WITH_BID,
      SaleStatusAuction.NOT_STARTED,
    ].includes(auctionStatus)
  )
    return (
      <>
        <Group
          spacing={32}
          position={smallSize ? "right" : "apart"}
          grow={!smallSize}
          align="baseline"
        >
          <Stack spacing={0}>
            <Text className={classes.valueLabel} color="black.5">
              {auctionStatus === SaleStatusAuction.ACTIVE_WITH_BID
                ? "TOP BID"
                : "RESERVE PRICE"}
            </Text>
            <Group spacing={4} align="baseline">
              <Text
                weight={500}
                style={{ display: "inline" }}
                className={classes.value}
              >
                {price}
              </Text>
              <Text
                weight={500}
                size={smallSize ? 14 : 16}
                style={{ display: "inline" }}
              >
                {currency}
              </Text>
            </Group>
          </Stack>
        </Group>
        {!smallSize && <Space h="sm" />}
      </>
    );
  return null;
};
