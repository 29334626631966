import { memo } from "react";

import { Skeleton, Paper } from "@mantine/core";

const SponsoredMintCardSkeleton = () => {
  return (
    <Paper shadow="md" radius="md" p="md" w={"100%"}>
      <Skeleton height={100} />
    </Paper>
  );
};

export default memo(SponsoredMintCardSkeleton);
