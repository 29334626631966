import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgMailBox = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={36}
    height={40}
    viewBox="0 0 36 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.7493 5.83333H15.4993C16.2729 5.83333 17.0148 5.52604 17.5617 4.97906C18.1087 4.43208 18.416 3.69021 18.416 2.91667C18.416 2.14312 18.1087 1.40125 17.5617 0.854272C17.0148 0.307291 16.2729 0 15.4993 0H9.66602C9.33449 0 9.01655 0.131696 8.78213 0.366117C8.54771 0.600537 8.41602 0.918479 8.41602 1.25V7.91667H11.7493V5.83333Z"
      fill="black"
    />
    <path
      d="M31.8671 10.417H12.5505C12.4734 10.4164 12.3977 10.4381 12.3327 10.4795C12.2676 10.5209 12.2159 10.5802 12.1838 10.6503C12.1441 10.7156 12.123 10.7906 12.123 10.867C12.123 10.9434 12.1441 11.0184 12.1838 11.0837C13.2468 12.4405 13.8327 14.1101 13.8505 15.8337V27.5003C13.8505 27.6108 13.8944 27.7168 13.9725 27.795C14.0507 27.8731 14.1566 27.917 14.2671 27.917H18.8338C18.9443 27.917 19.0503 27.9609 19.1284 28.039C19.2066 28.1172 19.2505 28.2232 19.2505 28.3337V38.3337C19.2505 38.7757 19.4261 39.1996 19.7386 39.5122C20.0512 39.8247 20.4751 40.0003 20.9171 40.0003C21.3592 40.0003 21.7831 39.8247 22.0957 39.5122C22.4082 39.1996 22.5838 38.7757 22.5838 38.3337V28.3337C22.5838 28.2232 22.6277 28.1172 22.7059 28.039C22.784 27.9609 22.89 27.917 23.0005 27.917H35.1171C35.1697 27.9149 35.2213 27.9024 35.269 27.8803C35.3167 27.8582 35.3596 27.8269 35.3952 27.7882C35.4308 27.7495 35.4584 27.7041 35.4765 27.6547C35.4946 27.6053 35.5027 27.5529 35.5005 27.5003V14.167C35.5139 13.1885 35.1391 12.2446 34.4581 11.5418C33.7772 10.839 32.8456 10.4345 31.8671 10.417Z"
      fill="black"
    />
    <path
      d="M0.5 15.8337V27.5003C0.5 27.6108 0.543899 27.7168 0.622039 27.795C0.700179 27.8731 0.80616 27.917 0.916667 27.917H10.9167C11.0272 27.917 11.1332 27.8731 11.2113 27.795C11.2894 27.7168 11.3333 27.6108 11.3333 27.5003V15.8337C11.3333 14.3971 10.7627 13.0193 9.74683 12.0035C8.73101 10.9877 7.35326 10.417 5.91667 10.417C4.48008 10.417 3.10233 10.9877 2.0865 12.0035C1.07068 13.0193 0.5 14.3971 0.5 15.8337ZM8.41667 23.3337C8.41667 23.5547 8.32887 23.7666 8.17259 23.9229C8.01631 24.0792 7.80435 24.167 7.58333 24.167H4.25C4.02899 24.167 3.81702 24.0792 3.66074 23.9229C3.50446 23.7666 3.41667 23.5547 3.41667 23.3337C3.41667 23.1126 3.50446 22.9007 3.66074 22.7444C3.81702 22.5881 4.02899 22.5003 4.25 22.5003H7.58333C7.80435 22.5003 8.01631 22.5881 8.17259 22.7444C8.32887 22.9007 8.41667 23.1126 8.41667 23.3337Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgMailBox);
export default ForwardRef;
