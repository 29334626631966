import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgHome = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M17.5659 14.3333V8.49996C17.5596 8.24942 17.5024 8.00277 17.3978 7.77501C17.2932 7.54724 17.1435 7.34309 16.9576 7.17496L10.0409 1.34163C9.74942 1.09601 9.38048 0.961304 8.99928 0.961304C8.61808 0.961304 8.24914 1.09601 7.95762 1.34163L1.04095 7.17496C0.855087 7.34309 0.705325 7.54724 0.600752 7.77501C0.49618 8.00277 0.438978 8.24942 0.432617 8.49996V14.3333C0.432617 14.7753 0.608212 15.1992 0.920772 15.5118C1.23333 15.8244 1.65726 16 2.09928 16H6.54928C6.7703 16 6.98226 15.9122 7.13854 15.7559C7.29482 15.5996 7.38262 15.3876 7.38262 15.1666V10.5833C7.38262 10.3623 7.47041 10.1503 7.62669 9.99404C7.78297 9.83776 7.99494 9.74996 8.21595 9.74996H9.88262C10.1036 9.74996 10.3156 9.83776 10.4719 9.99404C10.6282 10.1503 10.7159 10.3623 10.7159 10.5833V15.1666C10.7159 15.3876 10.8037 15.5996 10.96 15.7559C11.1163 15.9122 11.3283 16 11.5493 16H16.0326C16.4511 15.9664 16.8416 15.7761 17.1258 15.4671C17.4101 15.1581 17.5673 14.7532 17.5659 14.3333Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgHome);
export default ForwardRef;
