import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgImage = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g opacity={0.12}>
      <path
        d="M6.66668 9.16634C7.58715 9.16634 8.33334 8.42015 8.33334 7.49967C8.33334 6.5792 7.58715 5.83301 6.66668 5.83301C5.7462 5.83301 5.00001 6.5792 5.00001 7.49967C5.00001 8.42015 5.7462 9.16634 6.66668 9.16634Z"
        fill="currentColor"
      />
      <path
        d="M10 10.833L3.33334 17.4997H16.6667L10 10.833Z"
        fill="currentColor"
      />
      <path
        d="M18.3333 12.4997L15.5893 9.7556C15.2638 9.43016 14.7362 9.43016 14.4108 9.7556L11.6667 12.4997L16.5471 17.3801C16.702 17.3423 16.8399 17.2926 16.9683 17.2272C17.4387 16.9875 17.8212 16.6051 18.0609 16.1347C18.3333 15.5999 18.3333 14.8998 18.3333 13.4997V12.4997Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M3.56007 17.2733L9.05718 11.7761C9.3872 11.4461 9.5522 11.2811 9.74248 11.2193C9.90985 11.1649 10.0901 11.1649 10.2575 11.2193C10.4478 11.2811 10.6128 11.4461 10.9428 11.7761L16.4033 17.2366M11.6667 12.5L14.0572 10.1095C14.3872 9.77946 14.5522 9.61445 14.7425 9.55263C14.9098 9.49825 15.0901 9.49825 15.2575 9.55263C15.4478 9.61445 15.6128 9.77946 15.9428 10.1095L18.3333 12.5M8.33332 7.5C8.33332 8.42047 7.58713 9.16667 6.66666 9.16667C5.74618 9.16667 4.99999 8.42047 4.99999 7.5C4.99999 6.57953 5.74618 5.83333 6.66666 5.83333C7.58713 5.83333 8.33332 6.57953 8.33332 7.5ZM5.66666 17.5H14.3333C15.7335 17.5 16.4335 17.5 16.9683 17.2275C17.4387 16.9878 17.8212 16.6054 18.0608 16.135C18.3333 15.6002 18.3333 14.9001 18.3333 13.5V6.5C18.3333 5.09987 18.3333 4.3998 18.0608 3.86502C17.8212 3.39462 17.4387 3.01217 16.9683 2.77248C16.4335 2.5 15.7335 2.5 14.3333 2.5H5.66666C4.26653 2.5 3.56646 2.5 3.03168 2.77248C2.56128 3.01217 2.17882 3.39462 1.93914 3.86502C1.66666 4.3998 1.66666 5.09987 1.66666 6.5V13.5C1.66666 14.9001 1.66666 15.6002 1.93914 16.135C2.17882 16.6054 2.56128 16.9878 3.03168 17.2275C3.56646 17.5 4.26653 17.5 5.66666 17.5Z"
      stroke="currentColor"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgImage);
export default ForwardRef;
