import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgEmailConfirmed = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M28.3333 14.1666C29.2853 14.1731 30.2344 14.2736 31.1667 14.4666C31.2208 14.4944 31.2808 14.5089 31.3417 14.5089C31.4025 14.5089 31.4625 14.4944 31.5167 14.4666C31.5654 14.4261 31.6042 14.375 31.6302 14.3172C31.6562 14.2595 31.6686 14.1966 31.6667 14.1333V2.63325C31.669 2.58567 31.6555 2.53866 31.6284 2.49949C31.6013 2.46033 31.562 2.43119 31.5167 2.41659C31.4761 2.39318 31.4301 2.38086 31.3833 2.38086C31.3365 2.38086 31.2905 2.39318 31.25 2.41659L18.3333 11.4333C17.6603 12.0771 16.7648 12.4364 15.8333 12.4364C14.9019 12.4364 14.0064 12.0771 13.3333 11.4333L0.416667 2.46659C0.376128 2.44318 0.330143 2.43086 0.283333 2.43086C0.236524 2.43086 0.190538 2.44318 0.15 2.46659C0.113013 2.47954 0.0798834 2.50159 0.0536678 2.53072C0.0274523 2.55985 0.0089953 2.59511 5.19034e-10 2.63325L5.19034e-10 18.4666C-1.38429e-05 19.1665 0.276893 19.8381 0.770265 20.3346C1.26364 20.831 1.9334 21.1122 2.63333 21.1166H15.9667C16.0375 21.1172 16.1072 21.099 16.1687 21.0639C16.2302 21.0288 16.2813 20.9779 16.3167 20.9166C17.5782 18.8605 19.3444 17.161 21.4476 15.9796C23.5508 14.7982 25.9211 14.1741 28.3333 14.1666Z"
      fill="black"
    />
    <path
      d="M14.6658 10C14.9774 10.3054 15.3962 10.4764 15.8325 10.4764C16.2688 10.4764 16.6876 10.3054 16.9992 10L29.7825 1.15C29.8784 1.06734 29.9494 0.959682 29.9876 0.839026C30.0259 0.71837 30.0299 0.589459 29.9992 0.466667C29.9631 0.334432 29.8851 0.21746 29.777 0.133314C29.6688 0.0491677 29.5362 0.00238837 29.3992 0H29.0325H2.6325H2.24917C2.11502 0.00590322 1.98622 0.0542892 1.88137 0.138171C1.77652 0.222052 1.70104 0.337088 1.66583 0.466667C1.62694 0.583514 1.62035 0.708699 1.64675 0.828985C1.67316 0.949271 1.73157 1.06019 1.81583 1.15L14.6658 10Z"
      fill="black"
    />
    <path
      d="M28.3327 16.6665C26.0252 16.6665 23.7696 17.3507 21.851 18.6327C19.9325 19.9146 18.4371 21.7367 17.5541 23.8685C16.6711 26.0003 16.44 28.3461 16.8902 30.6092C17.3404 32.8723 18.4515 34.9511 20.0831 36.5827C21.7147 38.2144 23.7935 39.3255 26.0566 39.7757C28.3197 40.2258 30.6655 39.9948 32.7973 39.1118C34.9291 38.2287 36.7512 36.7334 38.0332 34.8148C39.3151 32.8962 39.9994 30.6406 39.9994 28.3332C39.9994 25.239 38.7702 22.2715 36.5823 20.0836C34.3943 17.8957 31.4269 16.6665 28.3327 16.6665ZM28.3327 36.6665C26.6845 36.6665 25.0733 36.1778 23.7029 35.2621C22.3325 34.3464 21.2644 33.0449 20.6337 31.5222C20.003 29.9995 19.8379 28.3239 20.1595 26.7074C20.481 25.0909 21.2747 23.6061 22.4401 22.4406C23.6056 21.2752 25.0904 20.4815 26.7069 20.16C28.3234 19.8384 29.999 20.0034 31.5217 20.6342C33.0444 21.2649 34.3459 22.333 35.2616 23.7034C36.1773 25.0738 36.666 26.685 36.666 28.3332C36.666 30.5433 35.788 32.6629 34.2252 34.2257C32.6624 35.7885 30.5428 36.6665 28.3327 36.6665Z"
      fill="black"
    />
    <path
      d="M30.5841 25.0835L27.1008 29.7168L25.6008 28.2168C25.2885 27.9064 24.8661 27.7322 24.4258 27.7322C23.9855 27.7322 23.5631 27.9064 23.2508 28.2168C23.0946 28.3718 22.9706 28.5561 22.886 28.7592C22.8014 28.9623 22.7578 29.1801 22.7578 29.4002C22.7578 29.6202 22.8014 29.838 22.886 30.0411C22.9706 30.2442 23.0946 30.4286 23.2508 30.5835L25.2841 32.6002C25.5529 32.8756 25.8783 33.0891 26.2379 33.226C26.5975 33.3629 26.9826 33.4198 27.3664 33.3929C27.7503 33.3659 28.1236 33.2557 28.4605 33.0699C28.7975 32.884 29.0899 32.6271 29.3175 32.3168L33.3341 27.0835C33.5994 26.7277 33.7124 26.2811 33.6483 25.8419C33.5842 25.4028 33.3483 25.0071 32.9925 24.7418C32.6366 24.4766 32.19 24.3636 31.7509 24.4277C31.3117 24.4918 30.916 24.7277 30.6508 25.0835H30.5841Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgEmailConfirmed);
export default ForwardRef;
