import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPostEditorEmbed = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00042 0H9.25482V6.0313C9.25482 6.42579 9.57461 6.74559 9.9691 6.74559H16.0004V14.2857C16.0004 14.7403 15.8198 15.1765 15.4984 15.4979C15.1769 15.8194 14.7408 16 14.2861 16H9.01813L10.7595 14.2586C11.6522 13.3659 11.6522 11.9187 10.7595 11.0261L8.4738 8.74041C8.04515 8.31176 7.46377 8.07094 6.85756 8.07094H3.42899C3.02428 8.07094 2.63065 8.17826 2.28613 8.37717V1.71429C2.28613 1.25963 2.46675 0.823594 2.78824 0.502103C3.10972 0.180611 3.54577 0 4.00042 0ZM10.6834 0.731986L15.2684 5.31702H10.6834V0.731986ZM4.03508 9.75056C4.36981 10.0853 4.36981 10.628 4.03508 10.9627L2.35546 12.6424L4.03508 14.3219C4.36981 14.6567 4.36981 15.1994 4.03508 15.5342C3.70035 15.8689 3.15763 15.8689 2.8229 15.5342L0.537184 13.2485C0.202449 12.9137 0.202449 12.371 0.537184 12.0362L2.8229 9.75056C3.15763 9.41583 3.70035 9.41583 4.03508 9.75056ZM9.74937 12.0362L7.46365 9.75056C7.12892 9.41583 6.5862 9.41583 6.25147 9.75056C5.91674 10.0853 5.91674 10.628 6.25147 10.9627L7.93109 12.6424L6.25147 14.3219C5.91674 14.6567 5.91674 15.1994 6.25147 15.5342C6.5862 15.8689 7.12892 15.8689 7.46365 15.5342L9.74937 13.2485C10.0841 12.9137 10.0841 12.371 9.74937 12.0362Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPostEditorEmbed);
export default ForwardRef;
