import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from "@emotion/react";
import PPEditorialOldItalic from "@hl/base-components/lib/assets/fonts/PPEditorialOld/PPEditorialOld-Italic.woff2";
import PPEditorialOldRegular from "@hl/base-components/lib/assets/fonts/PPEditorialOld/PPEditorialOld-Regular.woff2";
import { BrandJustLogo } from "@hl/base-components/lib/assets/icons.generated";
import { createFontFaces } from "@hl/base-components/lib/theme/fonts";
import { Flex, Paper, Stack, Text, useMantineTheme } from "@mantine/core";
import { FEATURE_FLAGS, useFeatureFlags } from "../auth/hooks";
import DescriptionMarkdown from "./DescriptionMarkdown";
const PPEditorialOldFont = createFontFaces({
    name: "PP Editorial Old",
    normal: {
        400: [PPEditorialOldRegular],
    },
    italic: {
        400: [PPEditorialOldItalic],
    },
});
/*
 * All fonts that can be loaded
 */
const HighlightedNotes = ({ editorial }) => {
    const theme = useMantineTheme();
    const enableHighlightedNotes = useFeatureFlags(FEATURE_FLAGS.ENABLE_HIGHLIGHTED_NOTES);
    if (!enableHighlightedNotes || !editorial) {
        return null;
    }
    return (_jsx(Paper, { p: 16, sx: {
            border: `1px solid ${theme.colors.divider[0]}`,
            background: theme.colors.tertiaryBackground[0],
            boxShadow: "none",
        }, children: _jsxs(Stack, { spacing: 12, children: [_jsxs(Flex, { align: "center", children: [_jsx(BrandJustLogo, { width: 16, height: 16 }), _jsx(Text, { ml: 6, size: "sm", children: "HIGHLIGHT" }), _jsx(Text, { size: "sm", mt: -2, css: css `
              ${PPEditorialOldFont}
            `, ff: "PP Editorial Old", italic: true, children: "ed." })] }), _jsx(DescriptionMarkdown, { size: 12, description: editorial, numChars: 256, hasExpand: true })] }) }));
};
export default HighlightedNotes;
