import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgWarning = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.8468 13.7134L9.18014 1.04671C9.06713 0.83156 8.89748 0.651388 8.6895 0.525665C8.48153 0.399942 8.24315 0.33345 8.00014 0.333374C7.75712 0.33345 7.51874 0.399942 7.31077 0.525665C7.10279 0.651388 6.93314 0.83156 6.82013 1.04671L0.153466 13.7134C0.042463 13.9196 -0.0122815 14.1513 -0.00525966 14.3854C0.00176218 14.6195 0.0703026 14.8475 0.193466 15.0467C0.314955 15.2386 0.483442 15.3962 0.682949 15.5048C0.882457 15.6133 1.10637 15.669 1.33347 15.6667H14.6668C14.8963 15.6666 15.1219 15.6073 15.3218 15.4945C15.5216 15.3817 15.689 15.2192 15.8076 15.0228C15.9263 14.8264 15.9923 14.6026 15.9991 14.3732C16.006 14.1438 15.9535 13.9165 15.8468 13.7134ZM7.33347 5.66671C7.33347 5.4899 7.40371 5.32033 7.52873 5.1953C7.65376 5.07028 7.82332 5.00004 8.00014 5.00004C8.17695 5.00004 8.34652 5.07028 8.47154 5.1953C8.59656 5.32033 8.6668 5.4899 8.6668 5.66671V9.66671C8.6668 9.84352 8.59656 10.0131 8.47154 10.1381C8.34652 10.2631 8.17695 10.3334 8.00014 10.3334C7.82332 10.3334 7.65376 10.2631 7.52873 10.1381C7.40371 10.0131 7.33347 9.84352 7.33347 9.66671V5.66671ZM8.03347 13.3334C7.77089 13.3319 7.51901 13.2291 7.33026 13.0466C7.14151 12.8641 7.03043 12.6158 7.02013 12.3534C7.01568 12.2216 7.03768 12.0902 7.08485 11.9671C7.13202 11.844 7.20339 11.7315 7.29475 11.6364C7.38611 11.5413 7.4956 11.4655 7.61676 11.4135C7.73791 11.3614 7.86827 11.3342 8.00014 11.3334C8.26271 11.3349 8.51459 11.4376 8.70334 11.6201C8.89209 11.8027 9.00318 12.051 9.01347 12.3134C9.01793 12.4452 8.99592 12.5765 8.94875 12.6996C8.90159 12.8228 8.83022 12.9352 8.73886 13.0303C8.6475 13.1254 8.538 13.2012 8.41685 13.2533C8.29569 13.3053 8.16533 13.3326 8.03347 13.3334Z"
      fill="white"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgWarning);
export default ForwardRef;
