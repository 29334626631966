import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgForwardArrow = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={24}
    height={22}
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.38193 10.5239C1.27038 10.5349 1.16583 10.5849 1.08712 10.6657C1.00047 10.7546 0.951986 10.8739 0.951986 10.9981C0.951986 11.1223 1.00047 11.2416 1.08712 11.3305C1.17184 11.4175 1.28651 11.4688 1.40762 11.4741H18.9516H18.9596V11.4741C19.1737 11.4762 19.3822 11.5429 19.5577 11.6656C19.7331 11.7882 19.8674 11.9611 19.9429 12.1614L19.9429 12.1615C20.0184 12.3618 20.0315 12.5803 19.9806 12.7882C19.9297 12.9962 19.817 13.1839 19.6575 13.3267L19.6522 13.3314L19.6522 13.3314L11.8125 20.2299C11.7292 20.3139 11.6801 20.4261 11.675 20.5445C11.6697 20.6673 11.712 20.7874 11.7932 20.8797C11.8743 20.972 11.988 21.0294 12.1105 21.0399C12.2286 21.0499 12.346 21.0156 12.4399 20.944L22.5908 12.0117L1.38193 10.5239ZM1.38193 10.5239H1.39391H18.9498V10.524L18.9578 10.5239C19.1719 10.5218 19.3804 10.4551 19.5558 10.3324C19.7313 10.2098 19.8656 10.0369 19.9411 9.83657L19.9411 9.83655C20.0166 9.63621 20.0297 9.41772 19.9788 9.20977C19.9278 9.00181 19.8152 8.81412 19.6557 8.67132L19.6557 8.6713L19.6504 8.66659L11.8079 1.76571C11.7227 1.68171 11.6722 1.56862 11.6666 1.44888C11.6608 1.32554 11.7031 1.20477 11.7846 1.11203C11.8661 1.01928 11.9804 0.961796 12.1035 0.951694C12.2228 0.941897 12.3414 0.977401 12.4355 1.05089L22.5908 9.98725C22.5909 9.98739 22.5911 9.98753 22.5913 9.98767C22.7347 10.1142 22.8495 10.2699 22.9282 10.4442L23.6877 10.1013L22.9282 10.4442C23.007 10.6187 23.0477 10.808 23.0477 10.9995C23.0477 11.1909 23.007 11.3802 22.9282 11.5547L23.6877 11.8976L22.9282 11.5548C22.8495 11.729 22.7348 11.8845 22.5915 12.0111L1.38193 10.5239Z"
      fill="#0D0D0D"
      stroke="#0D0D0D"
      strokeWidth={1.66667}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgForwardArrow);
export default ForwardRef;
