import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgCrossSmall = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={10}
    height={10}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6.14958 5.11921C6.11694 5.08397 6.09881 5.03776 6.09881 4.98978C6.09881 4.9418 6.11694 4.89559 6.14958 4.86034L9.56452 1.4542C9.63391 1.3869 9.68907 1.30641 9.72674 1.21748C9.76441 1.12856 9.78381 1.033 9.78381 0.936462C9.78381 0.839925 9.76441 0.744367 9.72674 0.655441C9.68907 0.566514 9.63391 0.486023 9.56452 0.418727C9.49705 0.349512 9.41635 0.294493 9.32719 0.256923C9.23804 0.219354 9.14223 0.199997 9.04545 0.199997C8.94866 0.199997 8.85286 0.219354 8.7637 0.256923C8.67454 0.294493 8.59384 0.349512 8.52637 0.418727L5.11144 3.82488C5.0761 3.85743 5.02977 3.87551 4.98167 3.87551C4.93357 3.87551 4.88723 3.85743 4.8519 3.82488L1.43696 0.418727C1.29877 0.287872 1.11443 0.216631 0.923925 0.220457C0.733417 0.224283 0.552103 0.302867 0.419307 0.439164C0.349913 0.50646 0.294752 0.586951 0.257086 0.675877C0.219419 0.764804 0.200012 0.860362 0.200012 0.956899C0.200012 1.05344 0.219419 1.14899 0.257086 1.23792C0.294752 1.32685 0.349913 1.40734 0.419307 1.47463L3.83425 4.88078C3.86689 4.91602 3.88501 4.96224 3.88501 5.01022C3.88501 5.05819 3.86689 5.10441 3.83425 5.13965L0.419307 8.5458C0.349913 8.61309 0.294752 8.69358 0.257086 8.78251C0.219419 8.87144 0.200012 8.96699 0.200012 9.06353C0.200012 9.16007 0.219419 9.25563 0.257086 9.34455C0.294752 9.43348 0.349913 9.51397 0.419307 9.58127C0.486776 9.65048 0.567475 9.7055 0.656631 9.74307C0.745788 9.78064 0.841592 9.8 0.938378 9.8C1.03516 9.8 1.13097 9.78064 1.22012 9.74307C1.30928 9.7055 1.38998 9.65048 1.45745 9.58127L4.87239 6.17512C4.90772 6.14256 4.95406 6.12448 5.00216 6.12448C5.05026 6.12448 5.09659 6.14256 5.13192 6.17512L8.54686 9.58127C8.68453 9.71858 8.87125 9.79572 9.06594 9.79572C9.26062 9.79572 9.44734 9.71858 9.58501 9.58127C9.72267 9.44395 9.80001 9.25772 9.80001 9.06353C9.80001 8.86934 9.72267 8.68311 9.58501 8.5458L6.14958 5.11921Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCrossSmall);
export default ForwardRef;
