import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgFaceSmile = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_3260_6924)">
      <path
        opacity={0.12}
        d="M7.99995 14.6663C11.6818 14.6663 14.6666 11.6816 14.6666 7.99967C14.6666 4.31778 11.6818 1.33301 7.99995 1.33301C4.31805 1.33301 1.33328 4.31778 1.33328 7.99967C1.33328 11.6816 4.31805 14.6663 7.99995 14.6663Z"
        fill="currentColor"
      />
      <path
        d="M5.33328 9.33301C5.33328 9.33301 6.33328 10.6663 7.99995 10.6663C9.66662 10.6663 10.6666 9.33301 10.6666 9.33301M9.99995 5.99967H10.0066M5.99995 5.99967H6.00662M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99995 14.6663C4.31805 14.6663 1.33328 11.6816 1.33328 7.99967C1.33328 4.31778 4.31805 1.33301 7.99995 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967ZM10.3333 5.99967C10.3333 6.18377 10.184 6.33301 9.99995 6.33301C9.81585 6.33301 9.66662 6.18377 9.66662 5.99967C9.66662 5.81558 9.81585 5.66634 9.99995 5.66634C10.184 5.66634 10.3333 5.81558 10.3333 5.99967ZM6.33328 5.99967C6.33328 6.18377 6.18404 6.33301 5.99995 6.33301C5.81585 6.33301 5.66662 6.18377 5.66662 5.99967C5.66662 5.81558 5.81585 5.66634 5.99995 5.66634C6.18404 5.66634 6.33328 5.81558 6.33328 5.99967Z"
        stroke="currentColor"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3260_6924">
        <rect width={16} height={16} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgFaceSmile);
export default ForwardRef;
