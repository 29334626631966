import React, { useCallback, useEffect, useState } from "react";

import {
  Expand as ExpandIcon,
  Refresh2 as RefreshIcon,
  Shuffle as ShuffleIcon,
  Screen as FullScreenIcon,
  Minimize as MinimizeIcon,
  Image as ImageIcon,
  QuestionMark as QuestionIcon,
} from "@hl/base-components/lib/assets/icons.generated";
import { LinkExternal01 } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/General";
import {
  GenArtIFrame,
  generateParams,
} from "@hl/shared-features/lib/features/gen-art/GenArtIFrame";
import { useGenParams } from "@hl/shared-features/lib/features/gen-art/gen-art-utils";
import {
  PreviewStatus,
  usePreviewImage,
} from "@hl/shared-features/lib/features/gen-art/preview";
import {
  ArtworkInteractionType,
  trackArtworkInteraction,
} from "@hl/shared-features/lib/features/gen-art/tracking";
import useGenArtAspectRatio from "@hl/shared-features/lib/hooks/useAspectRatio";
import {
  ActionIcon,
  Box,
  Flex,
  Group,
  Loader,
  Modal,
  Tooltip,
  createStyles,
  Text,
  Skeleton,
  useMantineTheme,
} from "@mantine/core";
import {
  useDebouncedValue,
  useDisclosure,
  useElementSize,
  useFullscreen,
  useMediaQuery,
  useViewportSize,
} from "@mantine/hooks";

import { SaleStatus } from "~features/MintPage/utils/types";
import { GenArtMintedView } from "~features/token-details/GenArtMinted";
import { useTokenDetailsNormalizedQuery } from "~features/token-details/queries/details.graphql.generated";
import useMintState from "~hooks/useMintState";

import {
  CapturePreviewTriggerType,
  GenerativeTokenParams,
} from "../../apollo/graphql.generated";

import { GetSalePageCollectionQuery } from "./queries.graphql.generated";

type StyleProps = {
  expanded: boolean;
  responsive: boolean;
  isApollo?: boolean;
  isVertical?: boolean;
};

const TOOLBAR_HEIGHT = 78;

const useStyles = createStyles(
  (theme, { expanded, responsive, isApollo, isVertical }: StyleProps) => {
    const heightPadding = expanded ? 30 : 300;
    return {
      containerResponsive: {
        width: "calc(100% - 120px)",
        minHeight: 200,
        maxHeight: 1200,
        display: "flex",
        justifyContent: "center",
        [theme.fn.largerThan("md")]: {
          width: "100%",
          maxWidth: "100%",
          maxHeight: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
        },
        [theme.fn.smallerThan(600)]: {
          width: "calc(100% - 32px)",
        },

        ...(expanded && {
          width: "100%",
          height: "100%",

          [theme.fn.smallerThan(600)]: {
            width: "100%",
            height: "100vh",
          },
        }),
      },
      embedContainer: {
        width: "100%",
      },
      genArtToolbar: {
        position: "absolute",
        bottom: -76,
      },
      container: {
        width: "100%",
        maxWidth: "min(100%, 500px)",
        display: "flex",
        justifyContent: "center",
        [theme.fn.largerThan("md")]: {
          width: isVertical ? "auto" : "100%",
          height: isVertical ? "100%" : "auto",
          maxWidth: "none",
          // 214px is the combined height of the header, 40px of container padding top and bottom, toolbar and gap between it and the artwork
          maxHeight: "min(calc(100vh - 214px), 100vw)",
          margin: "0 auto",
        },
      },
      containerPreviewImage: {
        width: "100%",
        maxWidth: "min(100%, 500px)",
        display: "flex",
        justifyContent: "center",
        [theme.fn.largerThan("md")]: {
          width: `calc(100vh - 220px)`,
          maxHeight: "min(calc(100vh - 214px), 100vw)",
          margin: "0 auto",
          flex: 1,
          minHeight: 720,
          ["@media (orientation: portrait)"]: {
            width: "100%",
            height: "auto",
            maxHeight: `calc(100vh - ${heightPadding}px)`,
          },
        },
      },
      absolutePosition: {
        position: "absolute",
        top: 0,
      },
      previewImage: {
        objectFit: "scale-down",
      },
      expanded: {
        height: responsive ? "100vh" : "calc(100vh - 30px)",
        overflow: "hidden",
      },
      closeBtn: {
        position: "absolute",
        top: 10,
        right: 10,
      },
      fullscreenButton: {
        [theme.fn.smallerThan("sm")]: {
          display: "none",
        },
      },
      toolbar: {
        boxShadow: isApollo ? "0" : theme.shadows.sm,
        borderRadius: 10,
        border: isApollo ? `1px solid ${theme.colors.divider[0]}` : "none",
        backgroundColor: theme.colors.primaryBackground[0],
        button: {
          height: 36,
          width: 36,
        },
        ...(expanded &&
          responsive && {
            display: "none",
          }),
      },
      iframeShadow: {
        boxShadow: theme.shadows.sm,
      },
      modal: {
        ...(responsive && {
          padding: `0px !important`,
        }),
      },
      modalBody: {
        maxHeight: "100%",
        ...(responsive && {
          padding: 0,
          margin: 0,
        }),
      },
    };
  }
);

type Props = {
  collection: GetSalePageCollectionQuery["getPublicCollection"];
  isEmbed?: boolean;
  isApollo?: boolean;
};

export const GenArtHeader = ({
  collection,
  isEmbed = false,
  isApollo,
}: Props) => {
  const {
    genSeriesIframeElementRef,
    isImported,
    isMarketplaceNoticeShown,
    saleStatus,
  } = useMintState();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const [opened, { open, close: closeModal }] = useDisclosure(false);
  const responsive = !!collection.generativeDetails?.isResponsive;
  const {
    ref,
    width: containerWidth,
    height: containerHeight,
  } = useElementSize();
  const isMintControls = collection.flagVariations.enableMintGenSeriesControls;
  const isMintControlsDesktop = isMintControls && !isMobile;
  const featuredTokenId = collection.generativeDetails?.featuredTokenId;
  const isMinting = saleStatus
    ? ![SaleStatus.ENDED, SaleStatus.SOLD_OUT].includes(saleStatus)
    : true;
  const { data: tokenDataByRevealId, loading: tokenLoadingByRevealId } =
    useTokenDetailsNormalizedQuery({
      variables: {
        collectionId: collection?.id,
        tokenId: featuredTokenId!,
        fromS3: true,
      },
      skip: !featuredTokenId || isMinting,
    });
  const token = tokenDataByRevealId?.getPublicCollection.seriesTokenNormalized;
  const showToolbar = (!isMintControls || isMintControlsDesktop) && !token;
  const containerRatio =
    containerWidth / (containerHeight + (showToolbar ? TOOLBAR_HEIGHT : 0));
  const viewPort = collection.generativeDetails?.captureSettings.viewPort;
  const { aspectRatio, ratio: genArtRatio } = useGenArtAspectRatio(viewPort);
  const boundVertically = containerRatio > genArtRatio;
  const { classes } = useStyles({
    expanded: opened,
    responsive,
    isApollo,
    isVertical: boundVertically,
  });
  const details = collection.generativeDetails;
  const defaultParams = collection.generativeDetails?.initialIterationParams;
  const { params, refreshIframe, randomizeAll } = useGenParams({
    allowedHashes: details?.allowedHashes,
    size: collection.size || undefined,
    fixEditionSize: true,
    fixedChainId: collection.chainId,
    fixedContractAddress: collection.address,
    isCurated: !!collection.generativeDetails?.curateOutput,
  });
  const { ref: fullscreenRef, toggle: toggleFullscreen } = useFullscreen();
  const [isImagePreview, setImagePreview] = useState(false);
  const [useDefaultParams, setUseDefaultParams] = useState(!!defaultParams);
  const iconSize = { width: 16, height: 16 };
  const showSortingControls = isMarketplaceNoticeShown || isImported;

  const showImage = useCallback(() => {
    setImagePreview(true);
    open();
  }, [open]);
  const close = useCallback(() => {
    closeModal();
    setImagePreview(false);
  }, [closeModal]);
  const { height, width } = useViewportSize();
  // re-render iframe when height or width changes
  const [debounced] = useDebouncedValue(height + width, 500);
  const [initialViewport, setInitialViewport] = useState(0);
  useEffect(() => {
    if (!debounced) {
      return;
    }
    if (debounced && initialViewport === 0) {
      setInitialViewport(debounced);
      return;
    }
    if (debounced !== initialViewport) {
      refreshIframe();
    }
  }, [debounced, initialViewport]);
  const [howItWorksOpened, { open: openHowItWorks, close: closeHowItWorks }] =
    useDisclosure(false);

  const mediaUrl = collection.baseUri ?? details?.generativeCodeUri ?? "";
  const url = generateParams({ ...params, mediaUrl });

  const trackUsage = useCallback(
    (action: ArtworkInteractionType) => {
      trackArtworkInteraction({
        collectionId: collection.id,
        interactionType: action,
      });
    },
    [collection]
  );

  const content = (
    <Flex
      direction="column"
      align="center"
      justify="center"
      w={"100%"}
      sx={{ flexGrow: 1 }}
      mah={showSortingControls ? "calc(100% - 64px)" : "100%"}
      h={opened ? "100vh" : "auto"}
      ref={ref}
    >
      <Box
        className={
          isEmbed && !opened
            ? classes.embedContainer
            : responsive
            ? classes.containerResponsive
            : classes.container
        }
        sx={{ aspectRatio, position: "relative", marginBottom: token ? 76 : 0 }}
      >
        {featuredTokenId && token ? (
          <GenArtMintedView
            animationUrl={token.animationUrl ?? ""}
            imageUrl={token.imageUrl || collection.collectionImage || ""}
            toolbarExtraClass={classes.genArtToolbar}
            isReveal
          />
        ) : (
          <GenArtIFrame
            enableRestrictiveFlags={
              collection.flagVariations.enableIframeRestrictiveFlags
            }
            allowIframeSameOrigin={
              collection.flagVariations.allowIframeSameOrigin
            }
            mediaUrl={mediaUrl}
            {...params}
            defaultParams={
              useDefaultParams && defaultParams ? defaultParams : undefined
            }
            bordered={false}
            aspectRatio={aspectRatio}
            iframeRef={fullscreenRef}
            iframeElementRef={genSeriesIframeElementRef}
          />
        )}
      </Box>
      {showToolbar && (
        <Box
          p={4}
          mt={{ base: 16, md: isEmbed ? 16 : 32 }}
          className={classes.toolbar}
        >
          <Group spacing={4}>
            {!isMintControls && (
              <Tooltip label="Reload">
                <ActionIcon
                  onClick={() => {
                    refreshIframe();
                    trackUsage(ArtworkInteractionType.Reload);
                  }}
                >
                  <RefreshIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}

            {!isMintControls && (
              <Tooltip label="Randomize">
                <ActionIcon
                  onClick={() => {
                    setUseDefaultParams(false);
                    randomizeAll();
                    trackUsage(ArtworkInteractionType.Randomize);
                  }}
                >
                  <ShuffleIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            {!isMintControls && !opened && (
              <Tooltip label="Expand">
                <ActionIcon
                  onClick={() => {
                    open();
                    trackUsage(ArtworkInteractionType.Expand);
                  }}
                >
                  <ExpandIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            <Tooltip label="Fullscreen" className={classes.fullscreenButton}>
              <ActionIcon
                onClick={() => {
                  toggleFullscreen();
                  trackUsage(ArtworkInteractionType.Fullscreen);
                }}
              >
                <FullScreenIcon {...iconSize} />
              </ActionIcon>
            </Tooltip>
            {!isMintControls && (
              <Tooltip label="Preview image">
                <ActionIcon
                  onClick={() => {
                    showImage();
                    trackUsage(ArtworkInteractionType.PreviewImage);
                  }}
                >
                  <ImageIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            {!isMintControls && (
              <Tooltip label="Open in a new window">
                <ActionIcon
                  onClick={() => {
                    window.open(url, "_blank", "noreferrer");
                    trackUsage(ArtworkInteractionType.OpenInNewWindow);
                  }}
                >
                  <LinkExternal01 {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            {!isMintControls && (
              <Tooltip label="How it works">
                <ActionIcon
                  onClick={() => {
                    openHowItWorks();
                    trackUsage(ArtworkInteractionType.HowItWorks);
                  }}
                >
                  <QuestionIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
          </Group>
        </Box>
      )}
      <HowItWorksModal opened={howItWorksOpened} close={closeHowItWorks} />
    </Flex>
  );

  if (featuredTokenId && tokenLoadingByRevealId && !token) {
    return (
      <Skeleton
        height={isMobile ? 420 : 540}
        width={isMobile ? 320 : 540}
        radius={0}
      />
    );
  }

  if (opened) {
    return (
      <Modal
        opened={opened}
        onClose={close}
        fullScreen
        withCloseButton={false}
        centered
        classNames={{
          modal: classes.modal,
          body: classes.modalBody,
        }}
      >
        <div className={classes.expanded}>
          {isImagePreview ? (
            <PreviewImage collection={collection} params={params} />
          ) : (
            content
          )}
        </div>
        <ActionIcon className={classes.closeBtn} onClick={close}>
          <MinimizeIcon />
        </ActionIcon>
      </Modal>
    );
  }

  return content;
};

type PreviewProps = {
  collection: GetSalePageCollectionQuery["getPublicCollection"];
  params: GenerativeTokenParams;
};

const PreviewImage = ({ collection, params }: PreviewProps) => {
  const captureSettings = collection?.generativeDetails?.captureSettings;
  const mediaUrl = collection?.generativeDetails?.generativeCodeUri ?? "";
  const responsive = !!collection.generativeDetails?.isResponsive;
  const { previewStatus, imageUrl } = usePreviewImage({
    mediaUrl,
    params,
    trigger: captureSettings?.trigger ?? CapturePreviewTriggerType.Delay,
    delay: captureSettings?.delay ?? 3,
    width: captureSettings?.viewPort?.width ?? 1000,
    height: captureSettings?.viewPort?.height ?? 1000,
    isGPURendering: collection.generativeDetails?.isGPURendering || undefined,
    selector: captureSettings?.selector || undefined,
    selectorType: captureSettings?.selectorType || undefined,
  });
  const { classes, cx } = useStyles({
    expanded: true,
    responsive,
    isApollo: true,
  });
  if (!captureSettings) {
    return null;
  }
  return (
    <Flex justify="center" align="center" pos="relative" h="100%">
      {previewStatus === PreviewStatus.Processing && <Loader size="md" />}
      {previewStatus === PreviewStatus.Done && (
        <img
          className={cx([classes.containerPreviewImage, classes.previewImage])}
          src={imageUrl}
        />
      )}
      {previewStatus === PreviewStatus.Failed && <div>Failed to render</div>}
    </Flex>
  );
};

export const HowItWorksModal = ({
  opened,
  close,
}: {
  opened: boolean;
  close: () => void;
}) => {
  return (
    <Modal opened={opened} onClose={close} title="How it works">
      <Text size="sm">
        When minting a token from this generative collection, data from the
        blockchain will be passed to the artist’s code, resulting in an original
        piece of art. Note that the outputs shown here are possible outputs, and
        the token you receive will look different.
      </Text>
      <Text size="sm" mt={16}>
        Press the “Randomize” button to see more possible outputs from this
        collection.
      </Text>
    </Modal>
  );
};
