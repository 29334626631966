import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useEffect, useMemo, useState, } from "react";
import { Settings04 } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/General";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { OUTLINE_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Button, createStyles, Flex, Group, Select, Text, TextInput, } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons";
import { ampli } from "../../../ampli";
import useDetectSticky from "../../../hooks/useDetectSticky";
// import { useAuth } from "../../auth";
import SegmentedControlMarketplace from "./SegmentedControlMarketplace";
import SwitchMarketplace from "./SwitchMarketplace";
const useStyles = createStyles({
    input: { height: "40px", marginTop: 0, fontWeight: WEIGHT_BOLD },
    root: { flexGrow: 1, width: "100%" },
});
const MarketplaceFilterSortBarApollo = ({ setShowFilters, searchString, hasFilterToggle = false, setSearchString, setSorting, sorting, gridType, setGridType, gridOptions, listingsEnabled, 
// ownedByMe,
// setOwnedByMe,
setListingsEnabled, sortingOptions, containerConfig = {
    top: "calc(var(--mantine-header-height, 0px) + 1px)",
    zIndex: 99,
}, attributesVar, customFilterElement, customViewElement, collectionName, }) => {
    // const { walletAddress } = useAuth();
    const { classes, theme } = useStyles();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
    const { isLarge } = useMantineMediaQueries();
    const [headerHeight, setHeaderHeight] = useState("0px");
    const { isSticky, ref } = useDetectSticky(undefined, {
        threshold: [1],
        rootMargin: `-${headerHeight} 0px 0px 0px`,
    });
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isStickyScroll, setIsStickyScroll] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.pageYOffset;
            setIsStickyScroll(currentScroll > lastScrollTop);
            setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [lastScrollTop]);
    const selectedSort = useMemo(() => sortingOptions.find((sortingOption) => sortingOption.value === sorting), [sorting]);
    useEffect(() => {
        const height = getComputedStyle(document.documentElement).getPropertyValue("--mantine-header-height");
        const adjustedHeight = parseInt(height.split("px")[0]) + 2;
        if (height) {
            setHeaderHeight(String(adjustedHeight) + "px");
        }
    }, []);
    const numberOfTraitsSelected = (attributesVar === null || attributesVar === void 0 ? void 0 : attributesVar.reduce((acc, item) => item.values.length + acc, 0)) ||
        0;
    const filterElement = hasFilterToggle &&
        (customFilterElement ? (customFilterElement) : (_jsx(Button, { size: "lg", color: OUTLINE_COLOR, onClick: () => setShowFilters === null || setShowFilters === void 0 ? void 0 : setShowFilters((prev) => !prev), leftIcon: _jsx(Settings04, {}), rightIcon: numberOfTraitsSelected ? (_jsx(Text, { px: 6, fw: WEIGHT_BOLD, bg: theme.colors.secondaryBackground[0], sx: { borderRadius: 999, display: "flex", alignItems: "center" }, h: 20, color: TEXT_COLOR.PLACEHOLDER, size: 12, children: numberOfTraitsSelected })) : undefined, children: "Traits" })));
    return (_jsxs(Flex, { py: 16, direction: isMobile ? "column" : "row", gap: "sm", wrap: "wrap", justify: "space-between", sx: (theme) => ({
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                paddingLeft: 20,
                paddingRight: 20,
                marginLeft: -20,
                marginRight: -16,
            },
            marginLeft: -40,
            marginRight: -40,
            paddingLeft: 40,
            paddingRight: 40,
            position: "sticky",
            background: theme.colors.baseBackground[0],
            borderBottom: `1px solid ${isSticky ? theme.colors.divider[0] : "transparent"}`,
            ...containerConfig,
            transition: "top 250ms ease 0s",
            top: isSticky
                ? isStickyScroll && !isLarge
                    ? -100
                    : "calc(var(--mantine-header-height, 0px) + 1px)"
                : undefined,
        }), ref: ref, children: [_jsxs(Group, { spacing: isMobile ? "sm" : "lg", children: [!isMobile && filterElement, _jsx(TextInput, { w: { base: "100%", sm: 260, md: 460 }, sx: { flexGrow: 1 }, placeholder: "Filter tokens by name or ID", value: searchString, onChange: (e) => {
                            setSearchString(e.target.value);
                            if (!ampli.isLoaded) {
                                return;
                            }
                            ampli.filterTokens({
                                projectName: collectionName,
                                filterType: "search",
                            });
                        }, classNames: { input: classes.input }, icon: _jsx(IconSearch, { width: 16, height: 16, color: theme.colors.primaryText[0] }) })] }), !isMobile && (_jsx(Group, { children: _jsx(SegmentedControlMarketplace, { transitionDuration: 500, transitionTimingFunction: "linear", value: sorting !== null && sorting !== void 0 ? sorting : undefined, onChange: (e) => {
                        setSorting(e);
                        if (!ampli.isLoaded) {
                            return;
                        }
                        ampli.filterTokens({
                            projectName: collectionName,
                            filterType: e,
                        });
                    }, data: sortingOptions.map((option) => ({
                        label: sorting === option.value ? (_jsxs(Group, { spacing: 8, noWrap: true, children: [adjustIconSize(option.icon), _jsx(Text, { fw: WEIGHT_BOLD, size: "sm", children: option.label })] })) : (adjustIconSize(option.icon)),
                        value: option.value,
                    })) }) })), _jsxs(Group, { noWrap: true, spacing: isMobile ? "sm" : "lg", children: [isMobile && filterElement, customViewElement, !isMobile && (_jsxs(Group, { children: [setListingsEnabled && (_jsx(SwitchMarketplace, { label: "Listed for sale", checked: listingsEnabled, onChange: (e) => {
                                    setListingsEnabled(e.currentTarget.checked);
                                    if (!ampli.isLoaded) {
                                        return;
                                    }
                                    ampli.filterTokens({
                                        projectName: collectionName,
                                        filterType: "listedForSale",
                                    });
                                } })), setGridType && gridOptions && (_jsx(SegmentedControlMarketplace, { value: gridType, onChange: (e) => {
                                    setGridType(e);
                                    if (!ampli.isLoaded) {
                                        return;
                                    }
                                    ampli.filterTokens({
                                        projectName: collectionName,
                                        filterType: e === "1"
                                            ? "setSingleGrid"
                                            : e === "2"
                                                ? "setDoubleGrid"
                                                : "setQuadGrid",
                                    });
                                }, data: gridOptions.map((option) => ({
                                    value: option.value,
                                    label: adjustIconSize(option.label),
                                })) }))] })), isMobile && (_jsx(Select, { icon: selectedSort ? adjustIconSize(selectedSort.icon) : null, value: sorting, onChange: setSorting, data: sortingOptions, itemComponent: (props) => (_jsx(SelectItem, { ...props, selected: sorting })), classNames: { input: classes.input, root: classes.root } }))] })] }));
};
export default MarketplaceFilterSortBarApollo;
export const adjustIconSize = (IconComponent) => {
    return _jsx(IconComponent, { width: 16, height: 16 });
};
const SelectItem = forwardRef(({ icon, label, value, ...others }, ref) => (_jsxs(Group, { noWrap: true, ref: ref, ...others, spacing: 8, children: [adjustIconSize(icon), _jsx(Text, { size: "sm", sx: {
                flexGrow: 1,
                fontWeight: others.selected === value ? WEIGHT_BOLD : undefined,
            }, children: label })] })));
