import { memo, useCallback, useState } from "react";

import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { ampli } from "@hl/shared-features/lib/ampli";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { formatNumber } from "@hl/shared-features/lib/utils/format";
import {
  Button,
  Divider,
  Group,
  Modal,
  Paper,
  PaperProps,
  Progress,
  Space,
  Text,
} from "@mantine/core";

import {
  SponsorList,
  TopSponsors,
} from "~features/MintPage/sponsor-mints/SponsorEvents";
import { SponsorMintModal } from "~features/MintPage/sponsor-mints/SponsorMintModal";
import SponsoredMintCardSkeleton from "~features/MintPage/sponsor-mints/SponsoredMintCardSkeleton";
import { useSponsoredEvents } from "~hooks/useSponsorEvents";

import { _CollectionType } from "../../../apollo/graphql.generated";
import useMintState from "../../../hooks/useMintState";
import {
  GetSalePageCollectionQuery,
  GetSalePageMintVectorsQuery,
} from "../queries.graphql.generated";

export type MintVector =
  GetSalePageMintVectorsQuery["getMintVectorsByCollection"][0];
export type Collection = GetSalePageCollectionQuery["getPublicCollection"];

export type SponsorMintModalData = {
  mintVectorId: string;
  collectionId: string;
  collectionType: _CollectionType;
};

export const SponsorMintCard = memo(() => {
  const {
    mintVectorsLoading,
    collectionType,
    collection,
    sponsorVector,
    isCollectionInactive,
    refetchMintVector,
    isCrossChainBurn,
  } = useMintState();
  const [opened, setOpened] = useState(false);
  const { pushModal } = useModalStack();
  const { isMobile } = useMantineMediaQueries();
  const [numberOfSponsors, setNumberOfSponsors] = useState<number | null>(null);
  const [amount, setAmount] = useState<string>("");

  const onDone = useCallback(
    (amount: number) => {
      refetchMintVector();
      setOpened(false);
      if (ampli.isLoaded) {
        ampli.sponsorMints({
          amountSponsored: amount,
          projectName: collection?.name || "",
        });
      }
    },
    [setOpened, refetchMintVector]
  );

  const sponsoredEventData = useSponsoredEvents(
    collection?.id || "",
    collectionType,
    +amount,
    sponsorVector?.id ?? "",
    onDone
  );

  const handleOnClickAllSponsors = useCallback(
    () =>
      pushModal(<SponsorList mintVector={sponsorVector} />, {
        size: !isMobile ? "sm" : "xs",
        title: (
          <Text size="md" fw={WEIGHT_BOLD}>
            Top sponsors
          </Text>
        ),
      }),
    [isMobile, sponsorVector]
  );

  if (!sponsorVector?.id || isCrossChainBurn) {
    return <></>;
  }
  if (mintVectorsLoading) {
    return <SponsoredMintCardSkeleton />;
  }

  const cardPaperProps: PaperProps = {
    shadow: "0",
    radius: 12,
    withBorder: true,
    p: "md",
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size={isMobile ? "xs" : "sm"}
        title={
          <Text size="md" fw={WEIGHT_BOLD}>
            Sponsor mints
          </Text>
        }
      >
        <SponsorMintModal
          sponsorVectorId={sponsorVector?.id ?? ""}
          sponsoredEventData={sponsoredEventData}
          amount={amount}
          setAmount={setAmount}
        />
      </Modal>
      <Paper {...cardPaperProps}>
        <TopSponsors
          mintVector={sponsorVector}
          onLoad={(numberOfSponsors: number) =>
            setNumberOfSponsors(numberOfSponsors)
          }
        />
        {numberOfSponsors != null && numberOfSponsors === 0 && (
          <Text size="sm">
            Sponsor mints to help the community, support your favourite artists,
            and appear as a sponsor on the mint page, forever.
          </Text>
        )}
        <Button
          fullWidth
          onClick={() => setOpened(true)}
          mt={8}
          disabled={isCollectionInactive}
        >
          Sponsor mints
        </Button>
        {numberOfSponsors != null && numberOfSponsors > 3 && (
          <Button
            mt={8}
            color={SECONDARY_COLOR}
            fullWidth
            onClick={handleOnClickAllSponsors}
          >
            See all {numberOfSponsors} sponsors
          </Button>
        )}
        <Space h="sm" />
        <Divider mx="-md" />
        <Space h="sm" />
        <Group position="apart">
          <Text size="xs">
            {formatNumber(sponsorVector.mintVectorStats?.sold ?? 0)} claimed
          </Text>
          <Text size="xs" color={TEXT_COLOR.PRIMARY}>
            {formatNumber(sponsorVector.maxPerVector)} sponsored
          </Text>
        </Group>
        <Progress
          styles={{
            bar: {
              height: 6,
            },
          }}
          color={TEXT_COLOR.PRIMARY}
          mt={8}
          value={
            (100 * (sponsorVector.mintVectorStats?.sold ?? 0)) /
            sponsorVector.maxPerVector
          }
        />
      </Paper>
    </>
  );
});
