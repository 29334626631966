import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgSmallEditIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    ref={ref}
    {...props}
  >
    <rect x={0.5} width={16} height={16} fill="url(#small-edit-icon)" />
    <defs>
      <pattern
        id="small-edit-icon"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_275:474" transform="scale(0.03125)" />
      </pattern>
      <image
        id="image0_275:474"
        width={32}
        height={32}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABTElEQVRYCe2XYU3EQBBGPwlIQAISkIAEHCAFHIAEHICDkwAOwAHkXfqRSTu92+3O8aubNNNru/NeJ9edVvrfcSfpS9KPpDdJV8az8yDpcTrByZ7tWdKNk63E+wkM3BsMXQcrn9gSn1bAHM7gZug1GPlgb/yWRHmzcQrOPH1OAvygGpXjFJybvAXmu32vJJ8pO0zkjuMSAufu/A+OQbVAF7xaoBteKbAJXiWwGV4hMAQfFRiGjwiUwLcKsIL58c0ics3DCXpWQq71vHnM4HRKOm3aL5ygVYB+4TnzmMGpxMs0h3eBxXCSVgHarufEuAYHGCs2LEDXjGCSp6UNpDIB//lo4VSitX2XCfDqduzh4e5adssEWmDZNbvAXoG9Ak0VOGTPT9Gx+O2xSBntPjq/CVu+H8nppRvWYrCO+4JLx9VVFAns5o2mSojcKfwXrW5nFNhkqf0AAAAASUVORK5CYII="
      />
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgSmallEditIcon);
export default ForwardRef;
