import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgToken = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0.773704 4.93336L7.20037 7.72669C7.4527 7.83682 7.72505 7.89366 8.00037 7.89366C8.27569 7.89366 8.54804 7.83682 8.80037 7.72669L15.2337 4.93336C15.4136 4.85298 15.5662 4.72188 15.6728 4.55611C15.7794 4.39034 15.8353 4.19709 15.8337 4.00003C15.8343 3.80485 15.7769 3.61391 15.6689 3.45133C15.5609 3.28876 15.4072 3.16186 15.227 3.08669L8.80037 0.300026C8.54804 0.1899 8.27569 0.133057 8.00037 0.133057C7.72505 0.133057 7.4527 0.1899 7.20037 0.300026L0.773704 3.10003C0.595673 3.17431 0.443323 3.29915 0.335499 3.45911C0.227674 3.61907 0.169106 3.80713 0.167037 4.00003C0.165163 4.19802 0.221718 4.39216 0.329626 4.55818C0.437533 4.72419 0.592002 4.85469 0.773704 4.93336Z"
      fill="#0D0D0D"
    />
    <path
      d="M15.2271 7.09998L14.4004 6.73998C14.3163 6.70327 14.2255 6.68433 14.1338 6.68433C14.042 6.68433 13.9512 6.70327 13.8671 6.73998L8.00042 9.28665L2.13375 6.73998C2.04964 6.70327 1.95886 6.68433 1.86709 6.68433C1.77531 6.68433 1.68453 6.70327 1.60042 6.73998L0.773753 7.09998C0.592885 7.17549 0.438395 7.30281 0.329731 7.46592C0.221067 7.62904 0.163086 7.82065 0.163086 8.01665C0.163086 8.21264 0.221067 8.40426 0.329731 8.56737C0.438395 8.73049 0.592885 8.85781 0.773753 8.93331L7.20042 11.7266C7.45275 11.8368 7.7251 11.8936 8.00042 11.8936C8.27574 11.8936 8.54809 11.8368 8.80042 11.7266L15.2271 8.93331C15.4088 8.85465 15.5633 8.72414 15.6712 8.55813C15.7791 8.39212 15.8356 8.19797 15.8338 7.99998C15.8317 7.80709 15.7731 7.61903 15.6653 7.45907C15.5575 7.29911 15.4051 7.17427 15.2271 7.09998Z"
      fill="#0D0D0D"
    />
    <path
      d="M15.227 11.0668L14.4004 10.7068C14.3163 10.6701 14.2255 10.6511 14.1337 10.6511C14.0419 10.6511 13.9512 10.6701 13.867 10.7068L8.00037 13.2601L2.1337 10.7068C2.04959 10.6701 1.95881 10.6511 1.86704 10.6511C1.77527 10.6511 1.68448 10.6701 1.60037 10.7068L0.773704 11.0668C0.592002 11.1454 0.437534 11.2759 0.329626 11.442C0.221718 11.608 0.165163 11.8021 0.167037 12.0001C0.166488 12.1953 0.223834 12.3862 0.331822 12.5488C0.43981 12.7114 0.593588 12.8383 0.773704 12.9134L7.20037 15.7134C7.45271 15.8236 7.72506 15.8804 8.00037 15.8804C8.27569 15.8804 8.54804 15.8236 8.80037 15.7134L15.227 12.9134C15.4072 12.8383 15.5609 12.7114 15.6689 12.5488C15.7769 12.3862 15.8343 12.1953 15.8337 12.0001C15.8356 11.8021 15.779 11.608 15.6711 11.442C15.5632 11.2759 15.4087 11.1454 15.227 11.0668Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgToken);
export default ForwardRef;
