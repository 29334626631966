import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPostEditorImage = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.14279 10C8.72074 10 9.99993 8.72082 9.99993 7.14286C9.99993 5.56491 8.72074 4.28572 7.14279 4.28572C5.56483 4.28572 4.28564 5.56491 4.28564 7.14286C4.28564 8.72082 5.56483 10 7.14279 10Z"
      fill="black"
    />
    <path
      d="M20 1.78571C20 1.31211 19.8119 0.85791 19.477 0.523024C19.1421 0.188137 18.6879 0 18.2143 0L1.78571 0C1.31211 0 0.85791 0.188137 0.523024 0.523024C0.188137 0.85791 0 1.31211 0 1.78571L0 18.2143C0 18.6879 0.188137 19.1421 0.523024 19.477C0.85791 19.8119 1.31211 20 1.78571 20H18.2143C18.6879 20 19.1421 19.8119 19.477 19.477C19.8119 19.1421 20 18.6879 20 18.2143V1.78571ZM17.5 2.14286C17.5947 2.14286 17.6856 2.18048 17.7525 2.24746C17.8195 2.31444 17.8571 2.40528 17.8571 2.5V11.8429C17.8574 11.9126 17.8372 11.9808 17.7991 12.0392C17.7611 12.0976 17.7067 12.1435 17.6429 12.1714C17.5853 12.2084 17.5184 12.228 17.45 12.228C17.3816 12.228 17.3147 12.2084 17.2571 12.1714L14.6571 9.7C14.3468 9.42904 13.9446 9.28698 13.533 9.30292C13.1213 9.31885 12.7313 9.49158 12.4429 9.78571L7.65714 14.8857C7.58534 14.9582 7.48775 14.9993 7.38571 15H2.5C2.40528 15 2.31444 14.9624 2.24746 14.8954C2.18048 14.8284 2.14286 14.7376 2.14286 14.6429V2.5C2.14286 2.40528 2.18048 2.31444 2.24746 2.24746C2.31444 2.18048 2.40528 2.14286 2.5 2.14286H17.5Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPostEditorImage);
export default ForwardRef;
