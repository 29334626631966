import React, { memo, useEffect, useState } from "react";

import { InfoCircleFill } from "@hl/base-components/lib/assets/icons.generated";
import CustomPaginationWrapper from "@hl/base-components/lib/table/CustomPaginationWrapper";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { User } from "@hl/shared-features/lib/features/auth/User";
import { Text, Group, Divider, Box, Tooltip, Stack } from "@mantine/core";

import { MintVector } from "~features/MintPage";
import SponsoredEventsSkeleton from "~features/MintPage/sponsor-mints/SponsorEventsSkeleton";
import { useGetRankedSponsorsByMintVectorQuery } from "~features/MintPage/sponsor-mints/queries.graphql.generated";

export const TopSponsors = memo(
  ({
    onLoad,
    mintVector,
  }: {
    onLoad: (numberOfSponsors: number) => void;
    mintVector: MintVector;
  }) => {
    return (
      <Box>
        <Group align="center" spacing={4}>
          <Text size="lg" fw={WEIGHT_BOLD}>
            Top sponsors
          </Text>
          <Tooltip
            multiline
            closeDelay={0}
            sx={{
              pointerEvents: "all",
            }}
            label={
              <Stack spacing="xs" p="xs">
                <Text size="xs" color={TEXT_COLOR.INVERTED_PRIMARY}>
                  Sponsored mints allow you to cover the full cost of mints
                  (cost, platform fee, and gas) for others to enjoy. If free
                  mints are available, you&apos;ll see a Claim button above.
                </Text>
              </Stack>
            }
          >
            <InfoCircleFill width={14} height={14} cursor="pointer" />
          </Tooltip>
        </Group>
        <SponsorEvents
          mintVector={mintVector}
          onLoad={onLoad}
          limit={3}
          hidden
        />
      </Box>
    );
  }
);

export const SponsorList = memo(
  ({ mintVector }: { mintVector: MintVector }) => (
    <SponsorEvents mintVector={mintVector} limit={5} hidden={false} />
  )
);

export const SponsorEvents = memo(
  ({
    limit,
    onLoad,
    hidden,
    mintVector,
  }: {
    limit: number;
    hidden: boolean;
    mintVector: MintVector;
    onLoad?: (numberOfSponsors: number) => void;
  }) => {
    const [activePage, setActivePage] = useState<number>(1);
    const { data, loading } = useGetRankedSponsorsByMintVectorQuery({
      variables: {
        mintVectorId: mintVector!.id!,
        limit,
        page: activePage,
      },
      skip: !mintVector?.id,
      notifyOnNetworkStatusChange: true,
    });
    const events = data?.getRankedSponsorsByMintVector?.sponsors || [];
    const totalCount =
      data?.getRankedSponsorsByMintVector.pageInfo.totalCount ?? 0;

    useEffect(() => {
      if (!loading) {
        onLoad?.(totalCount);
      }
    }, [onLoad, loading, totalCount]);

    if (loading)
      return (
        <Box my={4}>
          <SponsoredEventsSkeleton />
        </Box>
      );

    return (
      <CustomPaginationWrapper
        activePage={activePage}
        setPage={setActivePage}
        maxLength={totalCount}
        itemsPerPage={limit}
        border={false}
        hidden={hidden}
      >
        {events.map((event, index) => (
          <React.Fragment key={event.ranking}>
            <Group position="apart" mt="sm">
              <Group spacing={8}>
                <Text size="sm" fw={WEIGHT_BOLD}>
                  {event.ranking}.
                </Text>
                <User
                  textSize="sm"
                  avatarUrl={event.user?.avatarUrl}
                  displayName={event.user?.displayName}
                  walletAddresses={event.user?.ensNames}
                  enableLink
                />
              </Group>
              <Text size="sm">
                {event.totalNumSponsored} mint
                {event.totalNumSponsored > 1 ? "s" : ""}
              </Text>
            </Group>
            {index < events.length - 1 && <Divider mt="sm" />}
          </React.Fragment>
        ))}
      </CustomPaginationWrapper>
    );
  }
);
