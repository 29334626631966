import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgBenefitGeneric = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.7067 5.82002C15.6371 5.62604 15.5096 5.45809 15.3415 5.33887C15.1734 5.21965 14.9728 5.15489 14.7667 5.15335H10.7067C10.6382 5.15332 10.5714 5.13219 10.5153 5.09282C10.4592 5.05346 10.4167 4.99778 10.3934 4.93335L8.94004 0.80002C8.86431 0.612802 8.73436 0.452483 8.56687 0.339637C8.39938 0.226791 8.202 0.166565 8.00004 0.166687C7.79463 0.16767 7.59458 0.232308 7.42743 0.351701C7.26028 0.471093 7.13426 0.639368 7.06671 0.833354L5.60671 4.94669C5.58341 5.01111 5.54084 5.06679 5.48477 5.10616C5.42871 5.14552 5.36188 5.16666 5.29337 5.16669H1.23337C1.02706 5.16727 0.825972 5.23165 0.657684 5.351C0.489396 5.47035 0.362144 5.63884 0.293373 5.83335C0.227239 6.02564 0.220485 6.23337 0.273987 6.42955C0.32749 6.62572 0.438774 6.80127 0.593373 6.93335L4.04671 9.80002C4.09859 9.84236 4.13652 9.89936 4.15555 9.96357C4.17457 10.0278 4.17382 10.0962 4.15337 10.16L2.70004 14.5134C2.63159 14.7149 2.62925 14.9331 2.69336 15.1361C2.75747 15.339 2.88468 15.5163 3.05648 15.642C3.22828 15.7677 3.43571 15.8352 3.64858 15.8349C3.86145 15.8345 4.06865 15.7663 4.24004 15.64L7.80004 13.0267C7.8567 12.984 7.92574 12.9609 7.99671 12.9609C8.06767 12.9609 8.13671 12.984 8.19337 13.0267L11.76 15.64C11.9301 15.7815 12.1423 15.8626 12.3634 15.8706C12.5845 15.8787 12.802 15.8131 12.9818 15.6843C13.1617 15.5555 13.2938 15.3707 13.3574 15.1588C13.421 14.9469 13.4126 14.7199 13.3334 14.5134L11.88 10.16C11.8596 10.0962 11.8588 10.0278 11.8779 9.96357C11.8969 9.89936 11.9348 9.84236 11.9867 9.80002L15.4467 6.92669C15.5965 6.7899 15.7015 6.61104 15.7479 6.41357C15.7943 6.21609 15.7799 6.0092 15.7067 5.82002Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgBenefitGeneric);
export default ForwardRef;
