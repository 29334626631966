import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgBrandHighlightLight = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={214}
    height={20}
    viewBox="0 0 214 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.253906 0V19.6625H18.6875C24.1171 19.6625 28.5187 15.2609 28.5187 9.83125C28.5187 4.4016 24.1171 0 18.6875 0H0.253906ZM17.6429 2.15901C13.8611 2.66905 10.9454 5.90965 10.9454 9.83125C10.9454 13.7528 13.8611 16.9934 17.6429 17.5035V2.15901ZM19.7321 17.5035C23.5139 16.9934 26.4296 13.7528 26.4296 9.83125C26.4296 5.90965 23.5139 2.66905 19.7321 2.15901V17.5035ZM12.6277 17.5734C10.3316 15.7737 8.85625 12.9748 8.85625 9.83125C8.85625 6.68774 10.3316 3.88881 12.6277 2.08914H2.34305V17.5734H12.6277Z"
      fill="white"
    />
    <path
      d="M44.9625 19.8315V10.9551H53.867V19.8315H56.3108V0.168539H53.867V8.70787H44.9625V0.168539H42.5187V19.8315H44.9625Z"
      fill="white"
    />
    <path
      d="M74.2706 0.168539H63.2032V2.19101H67.529V17.809H63.2032V19.8315H74.2706V17.809H69.9728V2.19101H74.2706V0.168539Z"
      fill="white"
    />
    <path
      d="M87.4553 20C90.18 20 91.8935 18.8764 92.7923 17.1629L92.9609 19.8315H95.0676V9.18539H87.8485V11.4326H92.68V12.1067C92.68 15.5899 90.7418 17.7528 87.4834 17.7528C83.7474 17.7528 81.7811 14.9157 81.7811 10C81.7811 5.08427 83.6631 2.24719 87.4272 2.24719C90.1238 2.24719 91.7811 3.6236 92.371 6.57303H94.9834C94.2249 2.13483 91.5002 0 87.4834 0C82.1744 0 79.2811 3.79213 79.2811 10C79.2811 16.1236 82.3429 20 87.4553 20Z"
      fill="white"
    />
    <path
      d="M104.549 19.8315V10.9551H113.454V19.8315H115.898V0.168539H113.454V8.70787H104.549V0.168539H102.105V19.8315H104.549Z"
      fill="white"
    />
    <path
      d="M134.475 19.8315V17.5843H125.655V0.168539H123.211V19.8315H134.475Z"
      fill="white"
    />
    <path
      d="M151.013 0.168539H139.946V2.19101H144.271V17.809H139.946V19.8315H151.013V17.809H146.715V2.19101H151.013V0.168539Z"
      fill="white"
    />
    <path
      d="M164.198 20C166.922 20 168.636 18.8764 169.535 17.1629L169.703 19.8315H171.81V9.18539H164.591V11.4326H169.422V12.1067C169.422 15.5899 167.484 17.7528 164.226 17.7528C160.49 17.7528 158.524 14.9157 158.524 10C158.524 5.08427 160.406 2.24719 164.17 2.24719C166.866 2.24719 168.524 3.6236 169.113 6.57303H171.726C170.967 2.13483 168.243 0 164.226 0C158.917 0 156.024 3.79213 156.024 10C156.024 16.1236 159.085 20 164.198 20Z"
      fill="white"
    />
    <path
      d="M181.292 19.8315V10.9551H190.196V19.8315H192.64V0.168539H190.196V8.70787H181.292V0.168539H178.848V19.8315H181.292Z"
      fill="white"
    />
    <path
      d="M207.369 19.8315V2.41573H213.746V0.168539H198.549V2.41573H204.926V19.8315H207.369Z"
      fill="white"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgBrandHighlightLight);
export default ForwardRef;
