import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgBackArrow = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.0707 7.12707H3.36672C3.33603 7.12677 3.30614 7.1172 3.28099 7.09962C3.25583 7.08203 3.23658 7.05725 3.22576 7.02853C3.21494 6.9998 3.21305 6.96848 3.22036 6.93867C3.22766 6.90885 3.2438 6.88195 3.26667 6.86147L8.50394 2.25301C8.66859 2.09778 8.76654 1.88468 8.77716 1.65864C8.78777 1.4326 8.71021 1.21126 8.56083 1.04128C8.41145 0.8713 8.20191 0.765951 7.97638 0.747436C7.75084 0.728921 7.52693 0.798686 7.35183 0.942028L0.571934 6.90816C0.41708 7.04473 0.293059 7.21269 0.208108 7.40088C0.123158 7.58906 0.0792236 7.79317 0.0792236 7.99964C0.0792236 8.20611 0.123158 8.41022 0.208108 8.59841C0.293059 8.78659 0.41708 8.95455 0.571934 9.09112L7.35062 15.056C7.52586 15.1976 7.74902 15.2659 7.97347 15.2468C8.19792 15.2276 8.40629 15.1225 8.55504 14.9533C8.70378 14.7842 8.78139 14.564 8.77164 14.339C8.76189 14.1139 8.66554 13.9013 8.50273 13.7457L3.26546 9.1372C3.24259 9.11673 3.22644 9.08982 3.21914 9.06001C3.21184 9.0302 3.21372 8.99887 3.22454 8.97015C3.23537 8.94143 3.25462 8.91665 3.27977 8.89906C3.30493 8.88147 3.33482 8.87191 3.36551 8.87161H15.0707C15.2982 8.86561 15.5144 8.77102 15.6732 8.60798C15.832 8.44493 15.9208 8.22633 15.9208 7.99873C15.9208 7.77113 15.832 7.55253 15.6732 7.38949C15.5144 7.22645 15.2982 7.13185 15.0707 7.12585V7.12707Z"
      fill="#2B24FF"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgBackArrow);
export default ForwardRef;
