import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import { formatNumber } from "@hl/shared-features/lib/utils/format";
import { Box, Group, Stack, Text } from "@mantine/core";
import { useCollections } from "@reservoir0x/reservoir-kit-ui";

import MarketplaceFooterSkeleton from "~features/MintPage/apollo/skeleton/MarketplaceFooterSkeleton";
import useMintState from "~hooks/useMintState";

const MarketplaceStatsFooter = ({
  isScrollable,
}: {
  isScrollable?: boolean;
}) => {
  const {
    chainId,
    reservoirCollectionId,
    isImported,
    totalMintEarned,
    totalSoldCount,
  } = useMintState();

  const { data } = useCollections(
    {
      id: reservoirCollectionId,
    },
    undefined,
    chainId
  );

  const marketplaceCollection = data[0];

  const currency = getCurrencySymbol(chainId || "");

  const totalVolumeFormatted = formatNumber(
    marketplaceCollection?.volume?.allTime || 0
  );
  const floorAskFormatted = formatNumber(
    marketplaceCollection?.floorAsk?.price?.amount?.native || 0
  );
  const topBidFormatted = formatNumber(
    marketplaceCollection?.topBid?.price?.amount?.native || 0
  );
  const percentListed =
    +(marketplaceCollection?.tokenCount ?? 0) > 0
      ? `${
          Math.floor(
            (+(marketplaceCollection.onSaleCount ?? 0) /
              +(marketplaceCollection.tokenCount ?? 0)) *
              1000
          ) / 10
        }%`
      : 0;
  const ownerCount = marketplaceCollection?.ownerCount || 0;

  return marketplaceCollection ? (
    <Group
      spacing={32}
      position="center"
      px={isScrollable ? 16 : 0}
      noWrap={isScrollable}
      mx="auto"
      sx={{ flexShrink: isScrollable ? 0 : undefined }}
    >
      {!isImported && (
        <MarketplaceStat
          value={totalMintEarned}
          label="Primary sales"
          suffix={currency}
        />
      )}
      <MarketplaceStat
        value={
          isImported
            ? parseInt(marketplaceCollection.tokenCount ?? "0")
            : totalSoldCount
        }
        label="Collection size"
      />
      <MarketplaceStat
        value={totalVolumeFormatted}
        label="Secondary sales"
        suffix={currency}
      />
      <MarketplaceStat
        value={floorAskFormatted}
        suffix={currency}
        label="Floor Price"
      />
      <MarketplaceStat
        value={topBidFormatted}
        suffix={currency}
        label="Best offer"
      />
      <MarketplaceStat value={percentListed} label="Listed" />
      <MarketplaceStat value={ownerCount} label="Owners" />
    </Group>
  ) : (
    <MarketplaceFooterSkeleton isScrollable={isScrollable} />
  );
};

const MarketplaceStat = ({
  value,
  suffix,
  label,
}: {
  value: string | number;
  suffix?: string | number;
  label: string;
}) => {
  return (
    <Stack spacing={0} align="center">
      <Box>
        <Text component="span" fw={WEIGHT_BOLD} size="sm">
          {value}
        </Text>{" "}
        {suffix && (
          <Text component="span" fw={WEIGHT_BOLD} size="sm">
            {suffix}
          </Text>
        )}
      </Box>
      <Text color={TEXT_COLOR.SECONDARY} size="sm">
        {label}
      </Text>
    </Stack>
  );
};

export default MarketplaceStatsFooter;
