import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import { useAuth } from "@hl/shared-features/lib/features/auth";
import {
  CollectionTokensV2QueryVariables,
  useCollectionTokensV2LazyQuery,
} from "@hl/shared-features/lib/features/marketplace/queries/marketplace.graphql.generated";
import { gridOptions } from "@hl/shared-features/lib/utils/gridOptions";
import {
  sortingOptionsDefault,
  sortingOptionsWithMarketplace,
} from "@hl/shared-features/lib/utils/sortingOptions";
import { useDebouncedValue } from "@mantine/hooks";

import { useQuery } from "~features/MintPage/MintPageApollo";
import {
  MintStatus,
  mintStatusToBool,
} from "~features/marketplace/MarketplaceTokensApollo";
import { useMarketplaceBrowseCollectionQuery } from "~features/marketplace/queries/browse.graphql.generated";

import {
  MarketplaceCollectionAttributesFilter,
  NftContractStandard,
} from "../apollo/graphql.generated";

export type EligibleRebate = {
  vectorId: string;
  rebateAmount: string;
};

export type MintTokensState = ReturnType<typeof useMintPageTokens>;

export const useMintPageTokens = (
  collectionId: string | null | undefined,
  marketplaceId: string | null | undefined,
  editionId: string | number | null | undefined,
  isImportedCollection: boolean,
  isMarketplaceEnabledForCollectionChain: boolean,
  standard?: NftContractStandard | null
) => {
  const { walletAddress: address } = useAuth();
  const searchParams = useQuery();
  const [gridType, setGridType] = useState<string>(gridOptions[2].value);
  const [attributeFilters, setAttributeFilters] = useState<
    Record<string, string[]>
  >(
    searchParams.get("attributes")
      ? JSON.parse(searchParams.get("attributes") as string)
      : {}
  );
  const [mintStatus, setMintStatus] = useState<string>(MintStatus.Minted);
  const [searchString, setSearchString] = useState<string>(
    searchParams.get("tokenNameOrId") || ""
  );
  const [debouncedSearchString] = useDebouncedValue(searchString, 250);
  const [showFilters, setShowFilters] = useState(false);
  const [listingsEnabled, setListingsEnabled] = useState<boolean>(
    searchParams.get("withListings") === "true"
  );
  const [ownedByMe, setOwnedByMe] = useState<boolean>(
    searchParams.get("ownedByMe") === "true"
  );

  useEffect(() => {
    if (!address) {
      setOwnedByMe(false);
    }
  }, [address]);

  const handleClearFilters = useCallback(() => {
    // setMintStatus(mintedOnly ? MintStatus.Minted : MintStatus.All);
    setMintStatus(MintStatus.Minted);
    setAttributeFilters({});
    setShowFilters(false);
  }, [setAttributeFilters, setMintStatus, setShowFilters]);

  const sortingOptions = useMemo(
    () =>
      isMarketplaceEnabledForCollectionChain
        ? sortingOptionsWithMarketplace
        : sortingOptionsDefault,
    [isMarketplaceEnabledForCollectionChain]
  );

  const [sorting, setSorting] = useState<string | null>(
    searchParams.get("sorting") || sortingOptions[0].value
  );

  const { data } = useMarketplaceBrowseCollectionQuery({
    variables: {
      collectionId: collectionId!,
      // metadata hidden automatically returns only minted attributes
      minted: true,
    },
    fetchPolicy: "cache-first",
    skip: !collectionId,
  });
  const browseCollection = data?.getPublicCollection;

  const attributesVar = useMemo(
    () =>
      Object.entries(attributeFilters).map(([k, v]) => ({
        name: k,
        values: v,
      })) as MarketplaceCollectionAttributesFilter[],
    [attributeFilters]
  );

  const selectedSort = useMemo(
    () =>
      sortingOptions.find((sortingOption) => sortingOption.value === sorting),
    [sorting]
  );

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const paramsObject: Partial<
      Omit<CollectionTokensV2QueryVariables, "attributes">
    > & { attributes?: string; sorting?: string; ownedByMe?: boolean } = {};
    if (debouncedSearchString)
      paramsObject.tokenNameOrId = debouncedSearchString;
    if (attributesVar?.length)
      paramsObject.attributes = JSON.stringify(attributeFilters);
    if (listingsEnabled) paramsObject.withListings = listingsEnabled || null;
    if (ownedByMe && address) paramsObject.ownedByMe = true;
    if (selectedSort!.value !== "price_asc")
      paramsObject.sorting = selectedSort!.value;

    const params = new URLSearchParams(
      paramsObject as unknown as string
    ).toString();
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}${params ? "?" : ""}${params}`
    );
  }, [
    browseCollection,
    debouncedSearchString,
    selectedSort,
    mintStatus,
    attributesVar,
    marketplaceId,
    listingsEnabled,
    ownedByMe,
    address,
  ]);
  const ownerAddress =
    ownedByMe && address ? (address.toLowerCase() as string) : null;

  const forceUserBalanceAddress =
    standard === NftContractStandard.ERC1155 ? address ?? null : null;

  const [
    getCollectionTokensV2,
    { data: tokensResponse, loading, fetchMore, refetch },
  ] = useCollectionTokensV2LazyQuery({
    variables: {
      // @ts-ignore
      forceUserBalanceAddress,
      tokenAddress: browseCollection?.address ?? "",
      after: null,
      first: 12,
      tokenNameOrId: debouncedSearchString,
      sortBy: selectedSort!.sortBy,
      sortDirection: selectedSort!.sortDirection,
      minted: mintStatusToBool(mintStatus),
      attributes: attributesVar,
      chainId: browseCollection?.chainId ?? 1,
      collectionMarketplaceId: marketplaceId ?? "",
      editionId: (editionId ?? "0").toString(),
      withOffers: null,
      withListings: listingsEnabled || null,
      ownerAddress,
      isImportedCollection,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const tokensData = tokensResponse?.getCollectionTokensV2;

  useLayoutEffect(() => {
    if (!browseCollection?.address) {
      return;
    }
    async function fetchCollectionTokens() {
      await getCollectionTokensV2();
    }
    fetchCollectionTokens();
  }, [browseCollection?.address]);

  const pageInfo = tokensData?.pageInfo;
  const nextCursor = pageInfo?.endCursor;

  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        after: nextCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        fetchMoreResult.getCollectionTokensV2.edges = [
          ...(previousResult?.getCollectionTokensV2?.edges ?? []),
          ...(fetchMoreResult?.getCollectionTokensV2?.edges ?? []),
        ];
        return fetchMoreResult;
      },
    });
  }, [fetchMore, nextCursor]);

  const tokens = tokensData?.edges;
  const cols = parseInt(gridType);

  return {
    sorting,
    sortingOptions,
    setSorting,
    browseCollection,
    attributesVar,
    pageInfo,
    attributeFilters,
    handleClearFilters,
    setAttributeFilters,
    tokens,
    loading,
    nextCursor,
    refetch,
    loadMore,
    mintStatus,
    setSearchString,
    searchString,
    setShowFilters,
    showFilters,
    setMintStatus,
    debouncedSearchString,
    listingsEnabled,
    setListingsEnabled,
    selectedSort,
    ownedByMe,
    setOwnedByMe,
    gridType,
    setGridType,
    cols,
  };
};
