import { useState } from "react";

import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { chainIdToNetworkType } from "@hl/shared-features/lib/features/chain";
import { buildContractUrl } from "@hl/shared-features/lib/utils/blockExplorer";
import { maskAddress } from "@hl/shared-features/lib/utils/content";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import { formatDateAndTime } from "@hl/shared-features/lib/utils/format";
import {
  Paper,
  Box,
  Stack,
  Title,
  Text,
  Space,
  Grid,
  Divider,
  Button,
  Modal,
  ScrollArea,
  Anchor,
} from "@mantine/core";

import useMintState from "~hooks/useMintState";

export type EvmAddress = {
  address: string;
  name: string | null;
};

export const AuctionHistoryApollo = () => {
  const { auction } = useMintState();
  const [historyModalOpened, setHistoryModalOpened] = useState<boolean>(false);
  const bidHistory = auction?.history ?? [];
  const visibleHistory = bidHistory.slice(0, 2);

  return (
    <>
      <Paper shadow="0" radius="md" p="md" withBorder>
        <Text size="lg" weight={WEIGHT_BOLD}>
          History
        </Text>
        <Space h="sm" />
        <AuctionBidHistory showCount={2} />
        {bidHistory.length > 0 && visibleHistory.length === bidHistory.length && (
          <>
            <Space h="sm" />
            <Divider />
            <Space h="sm" />
          </>
        )}
        {visibleHistory.length === bidHistory.length && <AuctionListItem />}
        {visibleHistory.length < bidHistory.length && (
          <>
            <Space h="sm" />
            <Button
              fullWidth
              color={SECONDARY_COLOR}
              onClick={() => setHistoryModalOpened(true)}
            >
              See full history
            </Button>
          </>
        )}
      </Paper>
      <AuctionHistoryModal
        isOpened={historyModalOpened}
        handleClose={() => setHistoryModalOpened(false)}
      />
    </>
  );
};

export const AuctionListItem = () => {
  const { auction, creatorAddresses, collection } = useMintState();
  const creatorEns = collection?.creatorEns;
  const address = !!creatorEns
    ? creatorEns
    : creatorAddresses
    ? maskAddress(creatorAddresses[0].address, 4, 4)
    : "Creator";
  return (
    <>
      <Grid>
        <Grid.Col span="auto">
          <Stack spacing={0}>
            <Text size="sm" weight={500}>
              <Anchor
                href={buildContractUrl(
                  chainIdToNetworkType(auction?.chainId ?? 1),
                  creatorAddresses ? creatorAddresses[0].address : ""
                )}
                size="sm"
                color="accent"
                target="_blank"
                rel="noreferrer"
              >
                {address}
              </Anchor>{" "}
              listed their token
            </Text>
            <Text size="xs" color="dimmed">
              {auction ? formatDateAndTime(new Date(auction.createdAt)) : null}
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col span="content">
          <Text size="sm" weight={500}>{`${auction?.price} ${getCurrencySymbol(
            auction?.chainId ?? ""
          )}`}</Text>
        </Grid.Col>
      </Grid>
    </>
  );
};

export const AuctionBidHistory = ({ showCount }: { showCount?: number }) => {
  const { auction } = useMintState();
  const chainId = auction?.chainId ?? 1;
  const bidHistory = auction?.history ?? [];
  const visibleHistory = bidHistory.slice(0, showCount || bidHistory.length);
  return (
    <>
      {visibleHistory.map((h, index) => (
        <Box key={`bid-history-${h.time + h.bidder}`}>
          <Grid>
            <Grid.Col span="auto">
              <Stack spacing={0}>
                <Text size="sm" weight={500}>
                  <Anchor
                    href={buildContractUrl(
                      chainIdToNetworkType(chainId),
                      h.bidder
                    )}
                    size="sm"
                    color="accent"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {!!h.bidderEns ? h.bidderEns : maskAddress(h.bidder, 4, 4)}
                  </Anchor>{" "}
                  made a bid
                </Text>
                <Text size="xs" color="dimmed">
                  {formatDateAndTime(new Date(h.time))}
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span="content">
              <Text size="sm" weight={500}>{`${h.value} ${getCurrencySymbol(
                chainId
              )}`}</Text>
            </Grid.Col>
          </Grid>
          {index !== bidHistory.length - 1 && (
            <>
              <Space h="sm" />
              <Divider />
              <Space h="sm" />
            </>
          )}
        </Box>
      ))}
    </>
  );
};

type AuctionHistoryModalProps = {
  isOpened: boolean;
  handleClose: () => void;
};

export const AuctionHistoryModal = ({
  isOpened,
  handleClose,
}: AuctionHistoryModalProps) => {
  return (
    <Modal
      size="lg"
      opened={isOpened}
      onClose={handleClose}
      title={<Title order={5}>Auction history</Title>}
    >
      <ScrollArea type="never">
        <AuctionBidHistory />
        <Space h="sm" />
        <Divider />
        <Space h="sm" />
        <AuctionListItem />
      </ScrollArea>
    </Modal>
  );
};
