import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPostEditorHtml = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9724 3.30651C11.5412 2.8456 10.818 2.82155 10.3571 3.25278C9.89618 3.68402 9.87214 4.40723 10.3034 4.86813L13.2337 8.00001L10.3034 11.1319C9.87214 11.5928 9.89618 12.316 10.3571 12.7472C10.818 13.1784 11.5412 13.1544 11.9724 12.6935L15.6332 8.78082C16.0443 8.34143 16.0443 7.65857 15.6332 7.21919L11.9724 3.30651ZM5.69679 4.86817C6.12802 4.40726 6.10397 3.68405 5.64306 3.25282C5.18216 2.82158 4.45895 2.84564 4.02772 3.30654L0.366913 7.21923C-0.0441794 7.6586 -0.0441794 8.34147 0.366913 8.78085L4.02772 12.6935C4.45895 13.1544 5.18216 13.1785 5.64306 12.7472C6.10397 12.316 6.12802 11.5928 5.69679 11.1319L2.76654 8.00004L5.69679 4.86817Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPostEditorHtml);
export default ForwardRef;
