import { ampli } from "@hl/shared-features/lib/ampli";
import { User } from "@hl/shared-features/lib/features/auth/User";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import { Carousel } from "@mantine/carousel";
import {
  Anchor,
  Box,
  createStyles,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useElementSize, useMediaQuery } from "@mantine/hooks";

import { getMintPageUrl } from "~config";
import { useGetRelatedProjectsQuery } from "~features/MintPage/queries.graphql.generated";

const useCardStyles = createStyles((theme) => ({
  mediaItem: {
    border: `24px solid ${theme.colors.tableRowBackground[0]}`,
    background: theme.colors.tableRowBackground[0],
    minWidth: 100,
    minHeight: 100,
    width: 100,
    height: 100,
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
  },
}));

type RelatedProjectProps = {
  collectionId: string;
  collectionName: string;
};

const RelatedProjects = ({
  collectionId,
  collectionName,
}: RelatedProjectProps) => {
  const { classes, theme } = useCardStyles();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs - 1}px)`);
  const { ref, width } = useElementSize();
  const slideSize = isMobile
    ? "90%"
    : width > 2000
    ? "20%"
    : width > 1500
    ? "25%"
    : 400;

  const { data } = useGetRelatedProjectsQuery({
    variables: {
      collectionId,
      first: 9,
      after: null,
    },
  });

  const relatedProjects = data?.getRelatedCollections.collections.filter(
    (project) => project.id.toLowerCase() !== collectionId.toLowerCase()
  );

  if (!relatedProjects?.length) return <></>;

  return (
    <Stack
      spacing={24}
      pt={isMobile ? 20 : 40}
      pb={isMobile ? 30 : 60}
      pl={isMobile ? 20 : 80}
      ml={isMobile ? -20 : -40}
      mr={isMobile ? -20 : -40}
      bg={theme.colors.tertiaryBackground[0]}
      ref={ref}
    >
      <Title order={isMobile ? 6 : 5}>Related projects</Title>
      <Carousel
        slideSize={slideSize}
        height={100}
        slideGap={32}
        withControls={false}
        dragFree
        containScroll="trimSnaps"
      >
        {relatedProjects.map((project, index) => (
          <Carousel.Slide key={index}>
            <Group spacing={0} w="100%" noWrap>
              <Box className={classes.mediaItem}>
                <ResizedImage
                  src={project.collectionImage}
                  fit="contain"
                  aspectRatio="1"
                />
              </Box>
              <Stack
                p={20}
                justify="center"
                spacing={0}
                h={100}
                w="100%"
                bg={theme.colors.primaryBackground[0]}
              >
                {/*TODO: <Link /> is faster, but we have to fix the native browser scroll position restoration*/}
                <Anchor
                  className={classes.link}
                  onClick={() => {
                    if (!ampli.isLoaded) {
                      return;
                    }
                    ampli.relatedProjectClick({
                      projectName: collectionName,
                      relatedProjectName: project.name,
                    });
                  }}
                  href={getMintPageUrl(project, { withBaseUrl: true })}
                >
                  <Text size="lg" lineClamp={1}>
                    {project.name}
                  </Text>
                </Anchor>
                <User
                  walletAddresses={
                    project.creatorAccountSettings?.ensNames?.length
                      ? project.creatorAccountSettings?.ensNames
                      : project.creatorAccountSettings?.walletAddresses
                  }
                  displayName={project.creatorAccountSettings?.displayName}
                  verified={!!project.creatorAccountSettings?.verified}
                  avatarUrl={project.creatorAccountSettings?.displayAvatar}
                  imported={!!project.creatorAccountSettings?.imported}
                />
              </Stack>
            </Group>
          </Carousel.Slide>
        ))}
      </Carousel>
    </Stack>
  );
};

export default RelatedProjects;
