import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgBrandHighlightDark = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={150}
    height={14}
    viewBox="0 0 150 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.118125V13.8819H12.9035C16.7043 13.8819 19.7854 10.8008 19.7854 7C19.7854 3.19925 16.7043 0.118125 12.9035 0.118125H0ZM12.1723 1.62943C9.52503 1.98646 7.48404 4.25488 7.48404 7C7.48404 9.74512 9.52503 12.0135 12.1723 12.3706V1.62943ZM13.6347 12.3706C16.282 12.0135 18.323 9.74512 18.323 7C18.323 4.25488 16.282 1.98646 13.6347 1.62943V12.3706ZM8.66163 12.4195C7.05439 11.1597 6.02164 9.20046 6.02164 7C6.02164 4.79954 7.05439 2.84029 8.66163 1.58052H1.4624V12.4195H8.66163Z"
      fill="black"
    />
    <path
      d="M31.2445 13.882V7.66854H37.4776V13.882H39.1883V0.117977H37.4776V6.09551H31.2445V0.117977H29.5338V13.882H31.2445Z"
      fill="black"
    />
    <path
      d="M51.7601 0.117977H44.0129V1.53371H47.041V12.4663H44.0129V13.882H51.7601V12.4663H48.7517V1.53371H51.7601V0.117977Z"
      fill="black"
    />
    <path
      d="M60.9894 14C62.8967 14 64.0961 13.2135 64.7253 12.014L64.8433 13.882H66.318V6.42977H61.2647V8.00281H64.6467V8.47472C64.6467 10.9129 63.29 12.427 61.0091 12.427C58.3939 12.427 57.0175 10.441 57.0175 7C57.0175 3.55899 58.3349 1.57303 60.9697 1.57303C62.8574 1.57303 64.0175 2.53652 64.4304 4.60112H66.2591C65.7282 1.49438 63.8209 0 61.0091 0C57.2928 0 55.2675 2.65449 55.2675 7C55.2675 11.2865 57.4107 14 60.9894 14Z"
      fill="black"
    />
    <path
      d="M72.9552 13.882V7.66854H79.1883V13.882H80.899V0.117977H79.1883V6.09551H72.9552V0.117977H71.2445V13.882H72.9552Z"
      fill="black"
    />
    <path
      d="M93.9034 13.882V12.309H87.7293V0.117977H86.0186V13.882H93.9034Z"
      fill="black"
    />
    <path
      d="M105.48 0.117977H97.7326V1.53371H100.761V12.4663H97.7326V13.882H105.48V12.4663H102.471V1.53371H105.48V0.117977Z"
      fill="black"
    />
    <path
      d="M114.709 14C116.616 14 117.816 13.2135 118.445 12.014L118.563 13.882H120.038V6.42977H114.984V8.00281H118.366V8.47472C118.366 10.9129 117.01 12.427 114.729 12.427C112.114 12.427 110.737 10.441 110.737 7C110.737 3.55899 112.055 1.57303 114.689 1.57303C116.577 1.57303 117.737 2.53652 118.15 4.60112H119.979C119.448 1.49438 117.541 0 114.729 0C111.012 0 108.987 2.65449 108.987 7C108.987 11.2865 111.13 14 114.709 14Z"
      fill="black"
    />
    <path
      d="M126.675 13.882V7.66854H132.908V13.882H134.619V0.117977H132.908V6.09551H126.675V0.117977H124.964V13.882H126.675Z"
      fill="black"
    />
    <path
      d="M144.929 13.882V1.69101H149.393V0.117977H138.755V1.69101H143.219V13.882H144.929Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgBrandHighlightDark);
export default ForwardRef;
