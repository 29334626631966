import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgFileDropArea = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={396}
    height={396}
    viewBox="0 0 396 396"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={395}
      height={395}
      rx={19.3}
      stroke="#020202"
      strokeOpacity={0.1}
      strokeLinecap="square"
      strokeDasharray="8 8"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgFileDropArea);
export default ForwardRef;
