import * as React from "react";
import { useEffect, useState } from "react";

import { LoadingPage } from "@hl/base-components/lib/LoadingPage";
import CustomPaginationWrapper from "@hl/base-components/lib/table/CustomPaginationWrapper";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { User } from "@hl/shared-features/lib/features/auth/User";
import {
  TransactionStateType,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { maskAddress } from "@hl/shared-features/lib/utils/content";
import { Box, Divider, Group, SimpleGrid, Stack, Text } from "@mantine/core";

import { COLLECTORS_PAGE_SIZE } from "~features/MintPage/components/DetailSection/MintDetails";

import useMintState from "../../hooks/useMintState";

import { useGetAudienceOfCollectionQuery } from "./queries.graphql.generated";

const CollectorsList = ({
  collectionId,
  onLoad,
  isActive,
}: {
  collectionId: string;
  onLoad: (collectorsCount: number) => void;
  isActive: boolean;
}) => {
  const [activePage, setActivePage] = useState<number>(1);
  const { data, loading, error, refetch, previousData } =
    useGetAudienceOfCollectionQuery({
      variables: {
        collectionId: collectionId!,
        cursor: activePage - 1,
        limit: COLLECTORS_PAGE_SIZE,
        findEnsNames: true,
      },
      skip: !isActive,
    });

  const collectors = data?.getAudienceOfCollection ?? null;
  const previousCollectors = previousData?.getAudienceOfCollection ?? null;

  useEffect(() => {
    const totalCount = collectors?.totalCount || previousCollectors?.totalCount;
    if (totalCount || totalCount === 0) {
      onLoad(totalCount);
    }
  }, [previousCollectors, collectors, onLoad]);

  const { txnId } = useMintState();
  const transactionState = useTransactionState(txnId);
  useEffect(() => {
    if (
      transactionState &&
      transactionState.type === TransactionStateType.Done
    ) {
      refetch();
    }
  }, [transactionState, refetch]);

  if (loading) return <LoadingPage marginTop={100} marginBottom={514} />;
  if (error) {
    return (
      <Text size="sm" color={STATUS_COLOR.ERROR}>
        Error loading Collectors
      </Text>
    );
  }
  if (!collectors || collectors.totalCount === 0)
    return (
      <Box mih={350} mt={40} ta="center">
        <Text size="sm">No collectors yet</Text>
      </Box>
    );
  const members = collectors?.members;

  return (
    <>
      <CustomPaginationWrapper
        activePage={activePage}
        setPage={setActivePage}
        maxLength={collectors?.totalCount ?? 0}
        itemsPerPage={COLLECTORS_PAGE_SIZE}
        border={false}
        withControls
      >
        <SimpleGrid
          cols={members.length > COLLECTORS_PAGE_SIZE / 2 ? 2 : 1}
          w="100%"
          spacing={0}
        >
          {members.map((member, index) => (
            <Stack spacing={0} key={index} role="collectorsitem">
              <Group grow position="apart" py={14}>
                <User
                  displayName={
                    member.ensName
                      ? member.ensName
                      : maskAddress(member.walletAddress, 6, 4) ?? ""
                  }
                  walletAddresses={[member.walletAddress]}
                  enableLink
                  bold
                  numLines="1"
                  small
                  newTab={false}
                  sx={{ flex: 1, maxWidth: "unset" }}
                />
                <Text size="sm" align="right" mr="xs" sx={{ flexGrow: 0 }}>
                  {member.tokensCount}
                </Text>
              </Group>
              <Divider />
            </Stack>
          ))}
        </SimpleGrid>
      </CustomPaginationWrapper>
    </>
  );
};

export default CollectorsList;
