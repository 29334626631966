import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Anchor, Box, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { UnlockedGate as UnlockedGateIcon } from "../../assets/icons.generated";
import { uppercaseFirstLetter } from "../../utils/strings";
export const GateConditionsPassed = ({ handleShowModal, entity, }) => {
    return (_jsx(Box, { sx: (theme) => ({
            [`@media (min-width: ${theme.breakpoints.md}px)`]: {
                maxWidth: "360px",
            },
        }), children: _jsx(Paper, { shadow: "md", p: "xl", children: _jsxs(Stack, { children: [_jsxs(Group, { spacing: 6, children: [_jsx(UnlockedGateIcon, { width: 24, height: 24 }), _jsxs(Title, { size: "lg", children: [uppercaseFirstLetter(entity), " unlocked"] })] }), _jsx(Text, { size: "sm", children: "Congratulations! You meet the requirements to unlock this." }), _jsx(Anchor, { href: "", onClick: (event) => {
                            event.preventDefault();
                            handleShowModal();
                        }, fw: WEIGHT_BOLD, size: "sm", children: "View requirements" })] }) }) }));
};
