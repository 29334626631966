import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo, useEffect } from "react";
import { Text, Grid, Pagination, Space, useMantineTheme, Card, createStyles, } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
const CustomPagination = ({ activePage, setPage, setLowerBound, setUpperBound, maxLength, itemsPerPage = 20, withControls = false, hidden = false, }) => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
    const numPages = Math.ceil(maxLength / itemsPerPage);
    const lowerBound = (activePage - 1) * itemsPerPage + 1;
    const upperBound = Math.min(maxLength, activePage * itemsPerPage);
    const paginationText = `Showing ` +
        (lowerBound === upperBound
            ? `${lowerBound} of ${maxLength}`
            : `${lowerBound}-${upperBound} of ${maxLength}`);
    // avoids - React warning - "Cannot update a component while rendering a different component"
    useEffect(() => {
        setLowerBound === null || setLowerBound === void 0 ? void 0 : setLowerBound(lowerBound);
        setUpperBound === null || setUpperBound === void 0 ? void 0 : setUpperBound(upperBound);
    }, [activePage, itemsPerPage, maxLength]);
    if (numPages <= 1 || hidden)
        return _jsx(_Fragment, {});
    return (_jsxs(Grid, { justify: "space-between", align: "center", children: [_jsx(Grid.Col, { span: "auto", children: _jsx(Text, { size: "sm", children: paginationText }) }), _jsx(Grid.Col, { span: "content", children: _jsx(Pagination, { sx: { gap: 5 }, page: activePage, onChange: setPage, total: numPages, withControls: isMobile || withControls }) })] }));
};
const CustomPaginationWrapper = ({ activePage, setPage, setLowerBound, setUpperBound, maxLength, itemsPerPage = 20, padding = "0 16px", border = true, borderRadius = true, withControls = false, hidden = false, children, }) => {
    const useStyles = createStyles((theme) => {
        return {
            container: {
                border: border ? `1px solid ${theme.colors.divider[0]}` : "none",
                borderRadius: borderRadius ? "6px" : "none",
                overflow: "hidden",
                padding,
                "& > div:not(:last-of-type)": {
                    borderBottom: border
                        ? `1px solid ${theme.colors.divider[0]}`
                        : "none",
                },
            },
        };
    });
    const { classes } = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx(Card, { withBorder: true, p: 0, className: classes.container, children: children }), _jsx(Space, { h: "md" }), _jsx(CustomPagination, { activePage: activePage, setPage: setPage, setLowerBound: setLowerBound, setUpperBound: setUpperBound, maxLength: maxLength, itemsPerPage: itemsPerPage, withControls: withControls, hidden: hidden })] }));
};
export default memo(CustomPaginationWrapper);
