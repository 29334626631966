import {
  OUTLINE_COLOR,
  SECONDARY_COLOR,
} from "@hl/base-components/lib/theme/button";
import { Button, Flex, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { getMintPageUrl } from "~config";
import { InternalLink } from "~features/MintPage/components/InternalLink";
import { isActiveSale } from "~features/MintPage/utils/statuses";
import { SaleStatus } from "~features/MintPage/utils/types";
import { CreditCardPayButton } from "~features/fiat-payments/pay-button";
import ReferralButton from "~features/share/ReferralButton";

import { CollectionStatus } from "../../../apollo/graphql.generated";
import useMintState from "../../../hooks/useMintState";

import { MintCardProps } from "./MintCard";
import MintCardButton, { MintButtonPosition } from "./MintCardButton";
import CardStats from "./MintCardStats";

const MintCardSmall = ({
  onMintClick,
  seriesTokeMinted,
  showGateRequirementsModal,
  showWarningModals,
  isApollo,
  showCreditCardButton,
  showReferButton,
}: MintCardProps) => {
  const {
    disabledCardText,
    isMarketplaceNoticeShown,
    loadingClaimMint,
    collection,
    mintDataLoading,
    loadingUserCurrencyAllowance,
    saleStatus,
    collectionStatus,
    mintVector,
    numTokensToMint,
    handleCreditCardClaim,
    ccClaim,
  } = useMintState();
  const hideNumInput =
    SaleStatus.NOT_STARTED === saleStatus ||
    collectionStatus !== CollectionStatus.LIVE;

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const marketplaceLink = collection
    ? getMintPageUrl(collection, { marketplace: true })
    : "";

  const shouldShowCreditCardButton = !!(
    showCreditCardButton &&
    collection?.creditCardEnabled &&
    collection.crossmint &&
    mintVector?.isDirectMint &&
    saleStatus &&
    isActiveSale(saleStatus)
  );

  return (
    <Flex
      gap={isMobile || isApollo ? 16 : 32}
      direction={isMobile ? "column" : "row"}
      align={isMobile ? undefined : "center"}
      justify={isMobile ? undefined : "right"}
    >
      <CardStats smallSize hideNumInput={hideNumInput} />
      {disabledCardText ? (
        isMarketplaceNoticeShown ? (
          <Button
            component={InternalLink}
            to={marketplaceLink}
            color={SECONDARY_COLOR}
          >
            View on marketplace
          </Button>
        ) : (
          <Text weight={500} size={isMobile ? 14 : 18}>
            {disabledCardText.title}
          </Text>
        )
      ) : (
        <MintCardButton
          miw={120}
          fullWidth
          mintLabel="Mint"
          onMintClick={onMintClick}
          loading={
            loadingClaimMint || mintDataLoading || loadingUserCurrencyAllowance
          }
          showErrorIcon={false}
          disabledMint={seriesTokeMinted}
          size={isMobile ? "xl" : "lg"}
          enableCrossChainMint={
            collection?.flagVariations.enableCrossChainMinting
          }
          showGateRequirementsModal={showGateRequirementsModal}
          showWarningModals={showWarningModals}
          buttonPosition={MintButtonPosition.BottomBar}
          showLongCreditCardMintLabel={shouldShowCreditCardButton}
          isApollo={isApollo}
        />
      )}
      {shouldShowCreditCardButton && (
        <CreditCardPayButton
          crossmint={{
            ...collection.crossmint!,
            projectId: collection.crossmint!.projectId ?? "",
          }}
          handleCreditCardClaim={handleCreditCardClaim}
          claim={ccClaim}
          quantity={numTokensToMint}
          vectorId={mintVector.id!}
          onchainVectorId={mintVector.onchainMintVectorId?.split(":")[2] ?? ""}
          price={mintVector.price}
          mintFee={mintVector.chain?.mintFee}
          collectionId={collection.id}
          onchainId={collection.onchainId}
          editionId={0}
          collectionName={collection.name}
          artistName={
            collection.creatorAccountSettings?.displayName ??
            (collection.creatorAccountSettings?.walletAddresses ?? [])[0] ??
            ""
          }
          chainId={collection.chainId}
          buttonProps={{
            size: "lg",
          }}
        />
      )}
      {showReferButton && (
        <ReferralButton
          color={OUTLINE_COLOR}
          size="lg"
          iconSize={22}
          collectionName={collection?.name || ""}
        />
      )}
    </Flex>
  );
};

export default MintCardSmall;
