import React, { memo } from "react";

import { SkeletonList } from "@hl/base-components/lib/Skeleton";
import { Group, Stack, Skeleton } from "@mantine/core";

const MarketplaceFooterSkeleton = ({
  isScrollable,
}: {
  isScrollable?: boolean;
}) => {
  return (
    <Group
      spacing={32}
      position="center"
      px={isScrollable ? 16 : 0}
      noWrap={isScrollable}
      mx="auto"
      sx={{ flexShrink: isScrollable ? 0 : undefined }}
    >
      <SkeletonList
        count={6}
        skeleton={
          <Stack spacing="sm">
            <Skeleton height={14} width={50} />
            <Skeleton height={14} width={100} />
          </Stack>
        }
      />
    </Group>
  );
};

export default memo(MarketplaceFooterSkeleton);
