import React, { useCallback, useEffect, useRef } from "react";

import BoxContainer from "@hl/base-components/lib/BoxContainer";
import SimpleIntersectionObserver from "@hl/base-components/lib/SimpleIntersectionObserver";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { NoResults } from "@hl/shared-features/lib/features/collections/NoResults";
import MarketplaceFilterSortBarApollo from "@hl/shared-features/lib/features/marketplace/components/MarketplaceFilterSortBarApollo";
import { gridOptions } from "@hl/shared-features/lib/utils/gridOptions";
import {
  Box,
  createStyles,
  Flex,
  Group,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconX } from "@tabler/icons";
import { Property } from "csstype";

import { shloms404OnImageError } from "~features/MintPage/custom/Shloms404Carousel";
import MarketplaceTokenListSkeleton from "~features/marketplace/skeleton/MarketplaceTokenListSkeleton";
import useMintState from "~hooks/useMintState";
import useMintTokensState, {
  MintTokensStateRequired,
} from "~hooks/useMintTokensState";

import TokenCard from "./TokenCard";
import FilterSidebarSeries, {
  FilterSidebarProps,
} from "./filter/FilterSidebarSeries";

export enum MintStatus {
  All = "all",
  Minted = "minted",
  Available = "available",
}

const useStyles = createStyles((theme) => ({
  badge: {
    height: 36,
    gap: 8,
    alignItems: "center",
    display: "flex",
    paddingLeft: 16,
    paddingRight: 8,
    borderRadius: 999,
    background: theme.colors.tableRowBackground[0],
    flexShrink: 0,
  },
  badgePropText: {
    fontSize: 14,
    fontWeight: WEIGHT_BOLD,
    color: theme.colors.placeholderText[0],
  },
  badgeValueText: {
    fontSize: 14,
    fontWeight: WEIGHT_BOLD,
  },
}));

type MarketplaceTokensProps = {
  collectionId: string;
  scrollBodyOnFilterChange?: boolean;
  containerConfig?: {
    top: Property.Top;
    zIndex: Property.ZIndex;
    borderBottom?: Property.BorderBottom;
    paddingTop?: Property.PaddingTop;
    marginBottom?: Property.MarginBottom;
  };
  enableMint?: boolean;
  filterScrollContainerConfig?: FilterSidebarProps["scrollContainerConfig"];
  onComponentMount?(): void;
};

export const MarketplaceTokensApollo = ({
  scrollBodyOnFilterChange,
  containerConfig,
  filterScrollContainerConfig,
  enableMint,
  onComponentMount,
}: MarketplaceTokensProps) => {
  const { isCodeGenMint, collection, isAnySeriesMint } = useMintState();
  const {
    sorting,
    setSorting,
    sortingOptions,
    browseCollection,
    attributesVar,
    pageInfo,
    attributeFilters,
    setAttributeFilters,
    handleClearFilters,
    tokens,
    loading,
    nextCursor,
    refetch,
    loadMore,
    mintStatus,
    setSearchString,
    searchString,
    setShowFilters,
    showFilters,
    setMintStatus,
    debouncedSearchString,
    listingsEnabled,
    ownedByMe,
    // setOwnedByMe,
    setListingsEnabled,
    gridType,
    setGridType,
    cols,
  } = useMintTokensState() as MintTokensStateRequired;

  const isMetadataHidden =
    browseCollection?.reveal &&
    browseCollection?.seriesDetails?.showAllMetadata === false;

  const mintedOnly = !enableMint || isMetadataHidden || isCodeGenMint;
  const defaultMintStatus = mintedOnly ? MintStatus.Minted : MintStatus.All;

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
  const bodyRef = useRef(null);
  const { classes } = useStyles();

  useEffect(() => {
    const elem = scrollBodyOnFilterChange ? bodyRef.current : window;
    // Scroll to top when filters change
    elem?.scrollTo(0, 0);
  }, [attributesVar]);

  useEffect(() => {
    onComponentMount?.();
  }, []);

  const mintStatusText =
    mintStatus === MintStatus.All || defaultMintStatus !== MintStatus.All
      ? ""
      : mintStatus.toLowerCase();

  const isFiltering =
    mintStatus !== defaultMintStatus || attributesVar.length !== 0;

  const handleUnselect = useCallback(
    (traitType: string, value: string) => {
      setAttributeFilters((filters) => {
        const values = filters[traitType].filter((x) => x !== value);
        if (!values.length) {
          const copy = { ...filters };
          delete copy[traitType];
          return copy;
        } else {
          return {
            ...filters,
            [traitType]: values,
          };
        }
      });
    },
    [setAttributeFilters]
  );

  return (
    <Stack ref={bodyRef} spacing="xl">
      <MarketplaceFilterSortBarApollo
        setSorting={setSorting}
        sorting={sorting}
        hasFilterToggle={isAnySeriesMint}
        gridOptions={gridOptions}
        gridType={gridType}
        setGridType={setGridType}
        sortingOptions={sortingOptions}
        setSearchString={setSearchString}
        searchString={searchString}
        setShowFilters={setShowFilters}
        containerConfig={containerConfig}
        setListingsEnabled={setListingsEnabled}
        listingsEnabled={listingsEnabled}
        ownedByMe={ownedByMe}
        // TODO: uncomment, when BE is fixed
        // setOwnedByMe={setOwnedByMe}
        attributesVar={attributesVar}
        collectionName={collection?.name || ""}
      />
      {attributesVar.length > 0 && (
        <ScrollArea
          styles={{
            viewport: {
              display: "flex",
              ["div"]: {
                display: "flex !important",
                gap: 8,
              },
            },
          }}
          style={{ width: "100%" }}
        >
          {attributesVar?.map((attr) =>
            attr.values.map((value) => (
              <Flex className={classes.badge} key={attr.name + value}>
                <Text className={classes.badgePropText}>{attr.name}:</Text>{" "}
                <Text className={classes.badgeValueText}>{value}</Text>
                <IconX
                  style={{ cursor: "pointer" }}
                  width={16}
                  height={16}
                  onClick={() => handleUnselect(attr.name, value)}
                />
              </Flex>
            ))
          )}
        </ScrollArea>
      )}
      <Group spacing={40} noWrap align="flex-start">
        {/*TODO: Improve transitions*/}
        {showFilters && (
          <FilterSidebarSeries
            handleClearFilters={handleClearFilters}
            mintedOnly={mintedOnly}
            browseCollection={browseCollection}
            showFilters={showFilters}
            closeFilters={() => setShowFilters(false)}
            defaultMintStatus={defaultMintStatus}
            mintStatus={mintStatus}
            setMintStatus={setMintStatus}
            attributeFilters={attributeFilters}
            setAttributeFilters={setAttributeFilters}
            scrollContainerConfig={filterScrollContainerConfig}
          />
        )}
        <Stack w="100%">
          {pageInfo && isFiltering && !(isMobile && showFilters) && (
            <BoxContainer
              radius={10}
              p="12px 12px 12px 16px"
              mb={isMobile ? 20 : 40}
            >
              <Flex justify="space-between" align="center" gap={16}>
                <Text size="sm" color={TEXT_COLOR.SECONDARY}>
                  Showing{" "}
                  <Text component="span" size="sm" fw={WEIGHT_BOLD}>
                    {mintStatus === MintStatus.All && pageInfo.totalCount !== 0
                      ? "all "
                      : ""}
                    {pageInfo.totalCount} {mintStatusText} token
                    {pageInfo.totalCount !== 1 ? "s" : ""}
                  </Text>{" "}
                  {attributesVar.length !== 0 && <> with applied filters </>}
                </Text>

                {isFiltering && (
                  <UnstyledButton onClick={handleClearFilters}>
                    <Text weight={500} size={14}>
                      Clear filters
                    </Text>
                  </UnstyledButton>
                )}
              </Flex>
            </BoxContainer>
          )}
          {!tokens?.length ? (
            loading ? (
              <Loading cols={cols} />
            ) : (
              <NoResults
                description={
                  debouncedSearchString ||
                  isFiltering ||
                  listingsEnabled ||
                  ownedByMe
                    ? "Try adjusting your filter settings"
                    : "Once you do, they'll appear here."
                }
                title={
                  debouncedSearchString ||
                  isFiltering ||
                  listingsEnabled ||
                  ownedByMe
                    ? "No tokens found"
                    : "No tokens created yet"
                }
              />
            )
          ) : (
            <Box sx={{ display: isMobile && showFilters ? "none" : "block" }}>
              <SimpleGrid
                mb={isMobile ? 40 : 60}
                px={scrollBodyOnFilterChange && !isMobile ? 24 : 0}
                cols={cols}
                spacing={40}
                verticalSpacing={56}
                breakpoints={[
                  {
                    maxWidth: "xl",
                    cols: cols === 4 ? (showFilters ? 3 : 4) : cols,
                  },
                  {
                    maxWidth: "lg",
                    cols: cols === 4 ? (showFilters ? 2 : 3) : cols,
                  },
                  {
                    maxWidth: "md",
                    cols: cols > 1 ? (showFilters ? 1 : 2) : cols,
                  },
                  { maxWidth: "sm", cols: 1, verticalSpacing: 40 },
                ]}
              >
                {tokens?.map((token) => (
                  <TokenCard
                    key={token.id}
                    token={token}
                    onCompleted={() => {
                      setTimeout(refetch, 1000);
                    }}
                    onImageError={
                      collection?.flagVariations.shloms404UI
                        ? shloms404OnImageError
                        : undefined
                    }
                  />
                ))}
              </SimpleGrid>
              {!!nextCursor && (
                <SimpleIntersectionObserver
                  onIntersection={(e) => {
                    if (e.isIntersecting) {
                      loadMore();
                    }
                  }}
                />
              )}
              {loading && <Loading cols={cols} />}
            </Box>
          )}
        </Stack>
      </Group>
    </Stack>
  );
};

const Loading = ({ cols }: { cols?: number }) => (
  <Box
    sx={{
      flexGrow: 1,
      width: "100%",
    }}
  >
    <MarketplaceTokenListSkeleton cols={cols} />
  </Box>
);

export const mintStatusToBool = (status: MintStatus | string) => {
  if (status === MintStatus.All) {
    return null;
  }
  return status === MintStatus.Minted;
};
