import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgCheckWhiteSmall = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={12}
    height={10}
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.1075 1.88001L5.85749 9.00501C5.79771 9.08648 5.72208 9.15502 5.63515 9.20654C5.54821 9.25805 5.45177 9.29146 5.3516 9.30477C5.25143 9.31808 5.14961 9.31101 5.05224 9.28399C4.95487 9.25696 4.86397 9.21054 4.78499 9.14751L1.03499 6.14751C0.939153 6.09442 0.856113 6.02097 0.791709 5.93234C0.727305 5.84371 0.683101 5.74205 0.662205 5.6345C0.641309 5.52695 0.64423 5.41614 0.67076 5.30984C0.697291 5.20354 0.746788 5.10434 0.81577 5.01923C0.884752 4.93411 0.971544 4.86515 1.07004 4.81717C1.16854 4.7692 1.27635 4.74339 1.3859 4.74156C1.49544 4.73973 1.60405 4.76192 1.7041 4.80657C1.80415 4.85122 1.8932 4.91725 1.96499 5.00001L5.10749 7.51251L9.89249 1.01751C9.9506 0.93773 10.0239 0.870179 10.1081 0.818712C10.1923 0.767244 10.2858 0.732868 10.3833 0.717546C10.4808 0.702225 10.5804 0.706257 10.6763 0.729414C10.7723 0.752571 10.8627 0.794398 10.9425 0.852508C11.0223 0.910618 11.0898 0.983873 11.1413 1.06809C11.1928 1.15231 11.2271 1.24583 11.2424 1.34334C11.2578 1.44084 11.2537 1.5404 11.2306 1.63634C11.2074 1.73229 11.1656 1.82273 11.1075 1.90251V1.88001Z"
      fill="white"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCheckWhiteSmall);
export default ForwardRef;
