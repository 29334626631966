import * as React from "react";
import { Dispatch, SetStateAction } from "react";

import CustomPaginationWrapper from "@hl/base-components/lib/table/CustomPaginationWrapper";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { User } from "@hl/shared-features/lib/features/auth/User";
import { maskAddress } from "@hl/shared-features/lib/utils/content";
import {
  Box,
  Center,
  Divider,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";

import {
  AudienceOfCollectionQuery,
  COLLECTORS_PAGE_SIZE,
} from "~features/MintPage/components/DetailSection/MintDetails";

const CollectorsList = ({
  audienceOfCollectionQuery,
  activePage,
  setActivePage,
  itemsPerPage = COLLECTORS_PAGE_SIZE,
}: {
  audienceOfCollectionQuery: AudienceOfCollectionQuery;
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
  itemsPerPage?: number;
}) => {
  const { data, loading, error } = audienceOfCollectionQuery;
  if (loading)
    return (
      <Center h={374}>
        <Loader />
      </Center>
    );
  if (error) {
    return (
      <Text size="sm" color={STATUS_COLOR.ERROR}>
        Error loading Collectors
      </Text>
    );
  }
  const collectors = data?.getAudienceOfCollection ?? null;
  if (!collectors || collectors.totalCount === 0)
    return (
      <Box mih={350} mt={40} ta="center">
        <Text size="sm">No collectors yet</Text>
      </Box>
    );
  const members = collectors?.members;

  return (
    <>
      <CustomPaginationWrapper
        activePage={activePage}
        setPage={setActivePage}
        maxLength={collectors?.totalCount ?? 0}
        itemsPerPage={itemsPerPage}
        border={false}
        withControls
      >
        <SimpleGrid cols={1} w="100%" spacing={0}>
          {members.map((member, index) => (
            <Stack spacing={0} key={index} role="collectorsitem">
              <Group grow position="apart" py={14}>
                <User
                  displayName={
                    member.ensName
                      ? member.ensName
                      : maskAddress(member.walletAddress, 6, 4) ?? ""
                  }
                  walletAddresses={[member.walletAddress]}
                  enableLink
                  numLines="1"
                  small
                  newTab={false}
                  sx={{ flex: 1, maxWidth: "unset" }}
                />
                <Text size="sm" align="right" mr="xs" sx={{ flexGrow: 0 }}>
                  {member.tokensCount}
                </Text>
              </Group>
              {index !== members.length - 1 && <Divider />}
            </Stack>
          ))}
        </SimpleGrid>
      </CustomPaginationWrapper>
    </>
  );
};

export default CollectorsList;
