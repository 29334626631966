import React, { memo } from "react";

import {
  SKELETON_TEXT_HEIGHT,
  SKELETON_INPUT_HEIGHT,
} from "@hl/base-components/lib/Skeleton";
import {
  Group,
  Stack,
  Divider,
  useMantineTheme,
  Skeleton,
  Box,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import MarketplaceTokenListSkeleton from "~features/marketplace/skeleton/MarketplaceTokenListSkeleton";

const MarketplacePageSkeletonApollo = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);

  return (
    <Stack spacing={0}>
      <Group my={8}>
        <Skeleton height={SKELETON_TEXT_HEIGHT} width={50} />
        <Skeleton height={SKELETON_TEXT_HEIGHT} width={50} />
      </Group>
      <Divider />
      <Box my={24}>
        {!isMobile ? (
          <Group position="apart" py={16}>
            <Group spacing={"lg"}>
              <Skeleton height={SKELETON_INPUT_HEIGHT} width={100} />
              <Skeleton height={40} width={460} />
            </Group>
            <Skeleton height={SKELETON_INPUT_HEIGHT} width={208} />
          </Group>
        ) : (
          <Stack spacing={"sm"}>
            <Skeleton height={SKELETON_INPUT_HEIGHT} />
            <Group spacing={"sm"} grow noWrap>
              <Skeleton height={SKELETON_INPUT_HEIGHT} />
              <Skeleton height={SKELETON_INPUT_HEIGHT} />
            </Group>
          </Stack>
        )}
      </Box>
      <MarketplaceTokenListSkeleton />
    </Stack>
  );
};

export default memo(MarketplacePageSkeletonApollo);
