import { createStyles } from "@mantine/core";

export const useMediaStyles = createStyles(
  (theme, { hasControls }: { hasControls?: boolean }) => ({
    video: {
      objectFit: "contain",
      height: "100%",
      [theme.fn.largerThan("sm")]: {
        maxHeight: `calc(100vh - ${hasControls ? 300 : 220}px)`,
      },
      zIndex: 0,
      boxShadow: theme.shadows.sm,
      width: "100%",
    },
    videoLoading: {
      width: "300px",
      height: "150px",
      zIndex: 0,
      boxShadow: theme.shadows.sm,
      background: theme.colors.tableRowBackground[0],
    },
    audio: {
      marginTop: 100,
      objectFit: "cover",
      width: 900,
      zIndex: 0,
    },
    buttonIcon: {
      width: 30,
      height: 30,
    },
  })
);
