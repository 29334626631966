import * as React from "react";
import { memo } from "react";

import { STATUS_COLOR, TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { CheckGreen } from "@hl/shared-features/lib/assets/icons.generated";
import { Paper, Space, Stack, Text, Title } from "@mantine/core";

import { AuctionCardButton } from "~features/MintPage/Auction/AuctionCardButton";
import { AuctionCardDate } from "~features/MintPage/Auction/AuctionCardDate";
import { AuctionCardStats } from "~features/MintPage/Auction/AuctionCardStats";
import { AuctionCardProps } from "~features/MintPage/Auction/types";
import { getDisabledCardText } from "~features/MintPage/Auction/utils";
import useMintState from "~hooks/useMintState";

export const AuctionCardApollo = memo((props: AuctionCardProps) => (
  <Paper shadow="0" radius="md" p="md" withBorder>
    <AuctionCardDate showDivider />
    <AuctionCardInnerApollo {...props} />
  </Paper>
));

export const AuctionCardInnerApollo = ({
  hideBidButton,
  statsStyles,
}: AuctionCardProps) => {
  const { auction, walletAddress, auctionStatus, auctionDateTo } =
    useMintState();

  const highestBidByCurrentUser =
    walletAddress?.toLowerCase() ===
    auction?.stats?.highestBidder.toLowerCase();

  const disableCardText = getDisabledCardText(
    auctionStatus,
    auctionDateTo,
    auction?.chainId,
    auction?.stats
  );

  return (
    <>
      <AuctionCardStats styles={statsStyles} />

      {disableCardText && (
        <Stack spacing="xs" mb="sm">
          <Title size="h6">{disableCardText.title}</Title>
          <Text size="sm" color={TEXT_COLOR.SECONDARY}>
            {disableCardText.description}
          </Text>
        </Stack>
      )}
      {!hideBidButton && <AuctionCardButton fullWidth />}
      {!disableCardText && highestBidByCurrentUser && (
        <>
          <Space h="sm" />
          <Text
            size="xs"
            color={STATUS_COLOR.AFFIRMATIVE}
            sx={{ display: "flex", alignItems: "center", gap: "6px" }}
          >
            <CheckGreen height={14} width={14} />
            You&apos;re currently the top bidder
          </Text>
        </>
      )}
    </>
  );
};
