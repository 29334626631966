import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgUnlocked = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={14}
    height={16}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12 6.33331H11.5V4.49998C11.5025 3.41787 11.1151 2.37106 10.4086 1.55138C9.70217 0.731696 8.72399 0.194037 7.65334 0.0369225C6.58269 -0.120192 5.49128 0.113759 4.57909 0.695908C3.66691 1.27806 2.99505 2.16941 2.68663 3.20665C2.62297 3.41882 2.64621 3.64759 2.75124 3.84263C2.85626 4.03766 3.03445 4.18299 3.24663 4.24664C3.4588 4.3103 3.68757 4.28706 3.88261 4.18204C4.07764 4.07702 4.22297 3.89882 4.28663 3.68665C4.48237 3.03565 4.90564 2.47672 5.47922 2.11185C6.05279 1.74699 6.73843 1.6005 7.41103 1.69913C8.08363 1.79776 8.69835 2.13492 9.14302 2.64909C9.5877 3.16327 9.83269 3.82019 9.83329 4.49998V5.99998C9.83329 6.08838 9.79817 6.17317 9.73566 6.23568C9.67315 6.29819 9.58836 6.33331 9.49996 6.33331H1.99996C1.64634 6.33331 1.3072 6.47379 1.05715 6.72384C0.807102 6.97389 0.666626 7.31302 0.666626 7.66665V14.6666C0.666626 15.0203 0.807102 15.3594 1.05715 15.6095C1.3072 15.8595 1.64634 16 1.99996 16H12C12.3536 16 12.6927 15.8595 12.9428 15.6095C13.1928 15.3594 13.3333 15.0203 13.3333 14.6666V7.66665C13.3333 7.31302 13.1928 6.97389 12.9428 6.72384C12.6927 6.47379 12.3536 6.33331 12 6.33331ZM6.99996 12.3333C6.73625 12.3333 6.47847 12.2551 6.2592 12.1086C6.03993 11.9621 5.86904 11.7539 5.76812 11.5102C5.6672 11.2666 5.6408 10.9985 5.69225 10.7399C5.74369 10.4812 5.87068 10.2436 6.05715 10.0572C6.24362 9.8707 6.4812 9.74371 6.73984 9.69226C6.99848 9.64082 7.26657 9.66722 7.5102 9.76814C7.75384 9.86906 7.96208 10.04 8.10859 10.2592C8.25509 10.4785 8.33329 10.7363 8.33329 11C8.33329 11.3536 8.19282 11.6927 7.94277 11.9428C7.69272 12.1928 7.35358 12.3333 6.99996 12.3333Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgUnlocked);
export default ForwardRef;
