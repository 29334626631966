import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgMembershipKey = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M19.2584 4.9999L18.2084 3.90824L18.9417 3.1749C19.0899 3.02747 19.2074 2.85216 19.2875 2.65907C19.3676 2.46598 19.4087 2.25894 19.4084 2.0499C19.4108 1.73115 19.318 1.41893 19.1419 1.15327C18.9657 0.887614 18.7142 0.680627 18.4196 0.558855C18.1251 0.437083 17.8008 0.406075 17.4885 0.469809C17.1762 0.533543 16.89 0.689112 16.6667 0.916569L7.50002 10.0832C6.43763 9.55645 5.22259 9.42439 4.07184 9.71064C2.92108 9.99689 1.90952 10.6828 1.21776 11.646C0.525991 12.6091 0.199055 13.7868 0.295318 14.9687C0.391581 16.1506 0.904777 17.2598 1.74328 18.0983C2.58179 18.9368 3.69101 19.45 4.87292 19.5463C6.05483 19.6425 7.23249 19.3156 8.19563 18.6238C9.15877 17.9321 9.8447 16.9205 10.1309 15.7698C10.4172 14.619 10.2851 13.404 9.75835 12.3416L12.875 9.2249L13.5084 9.86657C13.7824 10.1395 14.1537 10.2924 14.5405 10.2916C14.9273 10.2909 15.2979 10.1365 15.5709 9.8624C15.8438 9.58835 15.9967 9.21708 15.9959 8.83029C15.9951 8.4435 15.8407 8.07285 15.5667 7.7999L15 7.1499L16.1834 5.96657L17.2334 7.0249C17.5108 7.28931 17.8793 7.43681 18.2625 7.43681C18.6458 7.43681 19.0143 7.28931 19.2917 7.0249C19.5476 6.74783 19.6869 6.38283 19.6807 6.00573C19.6744 5.62863 19.5232 5.26841 19.2584 4.9999ZM5.31669 12.0499C5.81114 12.0499 6.29449 12.1965 6.70561 12.4712C7.11673 12.7459 7.43717 13.1364 7.62639 13.5932C7.8156 14.05 7.86511 14.5527 7.76865 15.0376C7.67219 15.5226 7.43408 15.968 7.08445 16.3177C6.73482 16.6673 6.28936 16.9054 5.80441 17.0019C5.31946 17.0983 4.81679 17.0488 4.35998 16.8596C3.90316 16.6704 3.51272 16.35 3.23801 15.9388C2.96331 15.5277 2.81669 15.0444 2.81669 14.5499C2.81669 13.8869 3.08008 13.251 3.54892 12.7821C4.01776 12.3133 4.65365 12.0499 5.31669 12.0499Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgMembershipKey);
export default ForwardRef;
