import { Grid01 } from "@hl/base-components/lib/assets/icons.generated";
import { Columns01 } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/Layout";
import { Square } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/Shapes";
export const gridOptions = [
    {
        value: "1",
        label: Square,
    },
    {
        value: "2",
        label: Columns01,
    },
    {
        value: "4",
        label: Grid01,
    },
];
