import React, { memo } from "react";

import UserSkeleton from "@hl/shared-features/lib/features/auth/UserSkeleton";
import {
  Group,
  Stack,
  useMantineTheme,
  Skeleton,
  AppShell,
  Card,
  Box,
  Flex,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { usMintPageApolloStyles } from "~features/MintPage/MintPageApollo";
import MainCardSkeleton from "~features/MintPage/MintVector/MintCardSkeleton";
import { useSidebarStyles } from "~features/MintPage/apollo/CollectionDetailsSidebar";
import { AppHeader } from "~features/layout/Header";

export const GenArtworkSkeleton = () => {
  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md - 1}px)`);

  const { classes } = usMintPageApolloStyles();

  return (
    <Flex className={classes.mediaSectionContainer} justify="center">
      <Stack spacing="xl" className={classes.mediaSection}>
        {isTablet && (
          <Box mt={32}>
            <Skeleton height={20} width={180} />
          </Box>
        )}
        <Card shadow="sm" p={0} withBorder={false}>
          <Skeleton
            height={isTablet ? 420 : 540}
            width={isTablet ? 320 : 540}
            radius={0}
          />
        </Card>
      </Stack>
    </Flex>
  );
};

const MintPageApolloSkeleton = () => {
  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md - 1}px)`);

  const { classes } = usMintPageApolloStyles();
  const { classes: sidebarClasses } = useSidebarStyles({
    isMarketplaceShown: undefined,
  });

  return (
    <AppShell
      header={<AppHeader />}
      styles={{
        main: { paddingTop: "calc(var(--mantine-header-height, 0px))" },
      }}
    >
      <Group
        spacing={isTablet ? "xl" : 0}
        align="stretch"
        mx={isTablet ? 0 : -20}
        noWrap={!isTablet}
        style={{
          overflowX: "hidden",
          borderTop: `1px solid ${theme.colors.divider[0]}`,
        }}
        className={classes.container}
      >
        <GenArtworkSkeleton />
        <Stack
          className={sidebarClasses.sidebarScrollArea + " no-child-table"}
          classNames={{ scrollbar: sidebarClasses.scrollbar }}
          miw={isTablet ? 300 : 380}
        >
          <Stack justify="space-between" h="100%">
            <Stack className={sidebarClasses.sidebarDetails} spacing={8}>
              <Skeleton height={17} width={120} />
              <Skeleton height={40} width={180} />
              <Box h={2} />
              <UserSkeleton />
              <Box h={29} />
              <MainCardSkeleton isApollo />
              <Box h={20} />
              <Skeleton height={22} width={140} />
              <Box h={20} />
              <Skeleton height={120} width="100%" />
              <Box h={58} />
              <Skeleton height={22} width={160} />
              <Box h={58} />
              <Skeleton height={22} width={125} />
              <Box h={58} />
              <Skeleton height={22} width={150} />
              <Box h={58} />
              <Skeleton height={22} width={140} />
            </Stack>
          </Stack>
        </Stack>
      </Group>
    </AppShell>
  );
};

export default memo(MintPageApolloSkeleton);
