import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgArrowTiltedAccented = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12 8.13428V0.857143C12 0.629814 11.9097 0.411797 11.7489 0.251051C11.5882 0.0903059 11.3702 0 11.1429 0L3.86571 0C3.63838 0 3.42037 0.0903059 3.25962 0.251051C3.09887 0.411797 3.00857 0.629814 3.00857 0.857143C3.00857 1.08447 3.09887 1.30249 3.25962 1.46323C3.42037 1.62398 3.63838 1.71429 3.86571 1.71429H8.57143C8.61325 1.71414 8.6542 1.72624 8.68923 1.74908C8.72426 1.77193 8.75184 1.80453 8.76857 1.84286C8.78551 1.8827 8.79048 1.92662 8.78287 1.96924C8.77526 2.01186 8.7554 2.05134 8.72571 2.08286L0.248569 10.5343C0.0871658 10.6957 -0.00350952 10.9146 -0.00350952 11.1429C-0.00350952 11.3711 0.0871658 11.59 0.248569 11.7514C0.409972 11.9128 0.628882 12.0035 0.85714 12.0035C1.0854 12.0035 1.30431 11.9128 1.46571 11.7514L9.91714 3.29143C9.95298 3.26853 9.99461 3.25636 10.0371 3.25636C10.0797 3.25636 10.1213 3.26853 10.1571 3.29143C10.1876 3.30301 10.215 3.32138 10.2373 3.34515C10.2595 3.36891 10.2761 3.39744 10.2857 3.42857V8.13428C10.2857 8.36161 10.376 8.57963 10.5368 8.74038C10.6975 8.90112 10.9155 8.99143 11.1429 8.99143C11.3702 8.99143 11.5882 8.90112 11.7489 8.74038C11.9097 8.57963 12 8.36161 12 8.13428Z"
      fill="#4442CA"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgArrowTiltedAccented);
export default ForwardRef;
