import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgNoImage = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.57139 12C10.4649 12 12 10.4649 12 8.57139C12 6.67785 10.4649 5.14282 8.57139 5.14282C6.67785 5.14282 5.14282 6.67785 5.14282 8.57139C5.14282 10.4649 6.67785 12 8.57139 12Z"
      fill="#E0E0E0"
    />
    <path
      d="M24 2.14286C24 1.57454 23.7742 1.02949 23.3724 0.627628C22.9705 0.225765 22.4255 0 21.8571 0L2.14286 0C1.57454 0 1.02949 0.225765 0.627628 0.627628C0.225765 1.02949 0 1.57454 0 2.14286L0 21.8571C0 22.4255 0.225765 22.9705 0.627628 23.3724C1.02949 23.7742 1.57454 24 2.14286 24H21.8571C22.4255 24 22.9705 23.7742 23.3724 23.3724C23.7742 22.9705 24 22.4255 24 21.8571V2.14286ZM21 2.57143C21.1137 2.57143 21.2227 2.61658 21.303 2.69695C21.3834 2.77733 21.4286 2.88634 21.4286 3V14.2114C21.4289 14.2951 21.4047 14.377 21.359 14.447C21.3133 14.5171 21.2481 14.5723 21.1714 14.6057C21.1024 14.65 21.022 14.6736 20.94 14.6736C20.858 14.6736 20.7776 14.65 20.7086 14.6057L17.5886 11.64C17.2162 11.3148 16.7336 11.1444 16.2396 11.1635C15.7456 11.1826 15.2776 11.3899 14.9314 11.7429L9.18857 17.8629C9.10241 17.9499 8.98529 17.9992 8.86286 18H3C2.88634 18 2.77733 17.9548 2.69695 17.8745C2.61658 17.7941 2.57143 17.6851 2.57143 17.5714V3C2.57143 2.88634 2.61658 2.77733 2.69695 2.69695C2.77733 2.61658 2.88634 2.57143 3 2.57143H21Z"
      fill="#E0E0E0"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgNoImage);
export default ForwardRef;
