import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgArrowRightDefault = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={11}
    height={16}
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M10.9741 7.97535C10.9741 8.44081 10.7746 8.83978 10.4421 9.10576L2.9947 15.6887C2.46274 16.1542 1.6648 16.0877 1.26583 15.5557C0.866867 15.0238 0.866867 14.2923 1.39882 13.8269L7.9153 8.10834C7.98179 8.04184 7.98179 7.97535 7.9153 7.84236L1.39882 2.12382C0.866867 1.65836 0.866867 0.860422 1.33233 0.394959C1.79779 -0.0705034 2.52923 -0.136998 3.06119 0.26197L10.5086 6.77845C10.7746 7.11092 10.9741 7.50989 10.9741 7.97535Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgArrowRightDefault);
export default ForwardRef;
