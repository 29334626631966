import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { SegmentedControl, useMantineTheme } from "@mantine/core";
const SegmentedControlMarketplace = (props) => {
    var _a;
    const theme = useMantineTheme();
    return (_jsx(SegmentedControl, { value: (_a = props.value) !== null && _a !== void 0 ? _a : undefined, onChange: props.onChange, data: props.data, styles: {
            root: {
                height: 40,
                display: "flex",
                alignItems: "center",
                border: `1px solid ${theme.colors.divider[0]}`,
                flexShrink: 0,
            },
            control: {
                paddingTop: 0,
                paddingBottom: 0,
                display: "flex",
                alignItems: "center",
                border: "none !important",
            },
            controlActive: {
                backgroundColor: "unset",
            },
            active: {
                backgroundColor: theme.colors.secondaryBackground[0],
            },
            label: {
                height: 30,
                display: "flex",
                alignItems: "center",
            },
        } }));
};
export default SegmentedControlMarketplace;
