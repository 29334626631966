import * as React from "react";
import { useCallback, useState } from "react";

import { DownloadCircleFill } from "@hl/base-components/lib/assets/icons.generated";
import { handleClick } from "@hl/base-components/lib/localFileDownloadHandler";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { STATUS_COLOR, TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import {
  FEATURE_FLAGS,
  useFeatureFlags,
} from "@hl/shared-features/lib/features/auth/hooks";
import { logError } from "@hl/shared-features/lib/services/logger";
import {
  Box,
  BoxProps,
  Button,
  Group,
  Space,
  Stack,
  Tabs,
  Text,
} from "@mantine/core";
import { unparse } from "papaparse";
import { useParams } from "react-router-dom";
import { mantle } from "viem/chains";

import { DetailSectionViewFields } from "~features/MintPage/components/DetailSection/DetailSectionViewFields";
import { TagsCategoriesDetails } from "~features/MintPage/components/DetailSection/tags-categories/TagsCategoriesDetails";
import { StrabismusCopySection } from "~features/MintPage/custom/Strabismus";
import {
  GetAudienceOfCollectionQuery,
  useGetAudienceOfCollectionLazyQuery,
  useGetAudienceOfCollectionQuery,
} from "~features/MintPage/queries.graphql.generated";

import { SeriesToken } from "../../../../apollo/graphql.generated";
import useMintState from "../../../../hooks/useMintState";
import CollectorsList from "../../CollectorsList";
import { useEmbedFlags } from "../../embed/customize";

import { TokenTraitContent } from "./TokenTraitContent";

import { DetailSection, DetailSectionItem } from "./index";

type AudienceByCollection =
  GetAudienceOfCollectionQuery["getAudienceOfCollection"];
type AudienceMembersByCollection = AudienceByCollection["members"];
export type AudienceMemberByCollection = AudienceMembersByCollection[0];
export type AudienceOfCollectionQuery = ReturnType<
  typeof useGetAudienceOfCollectionQuery
>;
const EXPORT_BATCH_SIZE = 1000;
export const COLLECTORS_PAGE_SIZE = 14;
const COLLECTORS_LIST_UNSUPPORTED_CHAIN_IDS: number[] = [mantle.id];

export const MintDetails = ({
  collectionId,
  selectedSeriesToken,
  isApollo,
  ...boxProps
}: {
  collectionId?: string;
  selectedSeriesToken?: SeriesToken;
  isApollo?: boolean;
} & BoxProps) => {
  const { collectionId: paramsCollectionId } = useParams();
  collectionId = collectionId ?? paramsCollectionId;

  const { auction, tokens, collection, chainId } = useMintState();
  const showMintPageCollectors =
    useFeatureFlags(FEATURE_FLAGS.SHOW_MINT_PAGE_COLLECTORS) &&
    !COLLECTORS_LIST_UNSUPPORTED_CHAIN_IDS.includes(chainId);
  const showDetails =
    useFeatureFlags(FEATURE_FLAGS.ENABLE_TAGS_AND_CATEGORIES) &&
    collection?.hasDetails;

  const [exportingPercentage, setExportingPercentage] = useState<number | null>(
    null
  );
  const [exportError, setExportError] = useState<string>("");

  const [audienceOfCollectionLazyQuery] = useGetAudienceOfCollectionLazyQuery();
  const [collectorsCount, setCollectorsCount] = useState<number | null>(null);

  const handleDownloadClick = useCallback(async () => {
    setExportingPercentage(0);
    const collectors: AudienceMembersByCollection = [];
    let batchCount = 0;
    let cursor = 0;
    try {
      do {
        const collectorsBatchData = await audienceOfCollectionLazyQuery({
          variables: {
            collectionId: collectionId!,
            cursor: cursor,
            limit: EXPORT_BATCH_SIZE,
            findEnsNames: false,
          },
        });
        const collectionData =
          collectorsBatchData.data?.getAudienceOfCollection;
        const collectorsBatch = collectionData?.members;
        const totalCount = collectionData?.totalCount;
        if (!collectorsBatch)
          throw new Error("Error fetching collectors batch");
        collectors.push(...collectorsBatch);
        batchCount = collectorsBatch.length;
        setExportingPercentage(
          totalCount
            ? Math.floor(
                ((batchCount + cursor * EXPORT_BATCH_SIZE) * 100) / totalCount
              )
            : null
        );
        cursor++;
      } while (batchCount === EXPORT_BATCH_SIZE);

      handleClick(
        unparse(
          collectors.map((member) => ({
            Collector: member.walletAddress,
            Amount: member.tokensCount,
          })) || []
        ),
        "collectors.csv"
      );
      setExportError("");
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      setExportError("Error Exporting file");
      await logError(error, "export csv error");
    } finally {
      setExportingPercentage(null);
    }
  }, [
    audienceOfCollectionLazyQuery,
    collectionId,
    setExportingPercentage,
    setExportError,
  ]);

  const initialTab = showDetails ? "details" : "specs";

  const flags = useEmbedFlags();
  const [tab, setTab] = useState<string | null>(
    flags.strabismusTemplate ? "artistStatement" : initialTab
  );

  return (
    <Box {...boxProps}>
      <Tabs
        color="dark"
        value={tab}
        onTabChange={setTab}
        defaultValue={
          collection?.flagVariations.shloms404UI ? "traits" : initialTab
        }
        styles={(theme) => ({
          tabsList: {
            gap: 8,
          },
          tab: {
            color: theme.fn.themeColor(TEXT_COLOR.PLACEHOLDER),
            fontWeight: WEIGHT_BOLD,
            fontSize: theme.fontSizes.sm,
            padding: "14px 0",
          },
          panel: {
            marginTop: theme.spacing.xs,
          },
        })}
      >
        <Tabs.List display="flex" sx={{ justifyContent: "space-between" }}>
          <Group spacing={32}>
            {!!selectedSeriesToken && (
              <Tabs.Tab value="traits">Traits</Tabs.Tab>
            )}
            {flags.strabismusTemplate && (
              <Tabs.Tab value="artistStatement">
                Artist&apos;s statement
              </Tabs.Tab>
            )}
            {showDetails && <Tabs.Tab value="details">Details</Tabs.Tab>}
            <Tabs.Tab value="specs">Specs</Tabs.Tab>
            {!auction &&
              !flags.strabismusTemplate &&
              showMintPageCollectors && (
                <Tabs.Tab value="collectors">
                  Collectors{" "}
                  {collectorsCount ? ` · ${formatNumber(collectorsCount)}` : ""}
                </Tabs.Tab>
              )}
            <Tabs.Tab value="links">Links</Tabs.Tab>
          </Group>
          <Stack justify="center" py={6}>
            {(collectorsCount ?? 0) !== 0 && !isApollo && (
              <Button
                size="xs"
                color={SECONDARY_COLOR}
                leftIcon={<DownloadCircleFill />}
                onClick={handleDownloadClick}
                loading={exportingPercentage != null}
              >
                {exportingPercentage != null
                  ? `Downloading${
                      exportingPercentage > 0 ? ` ${exportingPercentage}%` : ""
                    }`
                  : "Download .CSV"}
              </Button>
            )}
            {exportError && (
              <Text size="sm" color={STATUS_COLOR.ERROR}>
                Error exporting Collectors
              </Text>
            )}
          </Stack>
        </Tabs.List>

        {!!selectedSeriesToken && (
          <Tabs.Panel value="traits">
            {selectedSeriesToken.attributes.map((attribute) => {
              return (
                <>
                  <DetailSectionItem
                    title={attribute.name}
                    content={
                      <TokenTraitContent
                        attributeValue={attribute.value}
                        showDfsResolutionFailure={
                          collection?.flagVariations.shloms404UI
                        }
                      />
                    }
                  />
                </>
              );
            })}
          </Tabs.Panel>
        )}
        {flags.strabismusTemplate && (
          <Tabs.Panel value="artistStatement">
            <StrabismusCopySection />
          </Tabs.Panel>
        )}
        {showDetails && (
          <Tabs.Panel value="details">
            <TagsCategoriesDetails collectionId={collectionId!} />
          </Tabs.Panel>
        )}
        <Tabs.Panel value="specs">
          <DetailSection token={tokens[0]} />
        </Tabs.Panel>
        {!auction && showMintPageCollectors && (
          <Tabs.Panel value="collectors" mt={0}>
            <CollectorsList
              collectionId={collectionId!}
              onLoad={setCollectorsCount}
              isActive={tab === "collectors"}
            />
          </Tabs.Panel>
        )}
        <Tabs.Panel value="links" sx={{ marginTop: 14 }}>
          <DetailSectionViewFields isApollo={isApollo} />
        </Tabs.Panel>
      </Tabs>
      <Space h={20} />
    </Box>
  );
};

const formatNumber = (number?: number) => {
  if (!number) return "";
  const formatter = Intl.NumberFormat("en");
  return formatter.format(number);
};
