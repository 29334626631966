import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgMetamask = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M27.5303 3.56567L17.5366 10.9881L19.3847 6.60899L27.5303 3.56567Z"
      fill="#E2761B"
      stroke="#E2761B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.46045 3.56567L14.3738 11.0585L12.6161 6.60899L4.46045 3.56567Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.9356 20.771L21.2739 24.8488L26.9688 26.4157L28.606 20.8614L23.9356 20.771Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.40576 20.8614L5.03288 26.4157L10.7278 24.8488L8.06615 20.771L3.40576 20.8614Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4068 13.8808L8.81982 16.2813L14.4746 16.5324L14.2737 10.4558L10.4068 13.8808Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5853 13.8808L17.6682 10.3855L17.5376 16.5324L23.1823 16.2813L21.5853 13.8808Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7275 24.8489L14.1224 23.1916L11.1896 20.9016L10.7275 24.8489Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8691 23.1916L21.274 24.8489L20.802 20.9016L17.8691 23.1916Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2735 24.8487L17.8687 23.1914L18.1398 25.4111L18.1097 26.3452L21.2735 24.8487Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7271 24.8487L13.8909 26.3452L13.8708 25.4111L14.1219 23.1914L10.7271 24.8487Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9408 19.4349L11.1084 18.6013L13.1071 17.6873L13.9408 19.4349Z"
      fill="#233447"
      stroke="#233447"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0493 19.4349L18.883 17.6873L20.8918 18.6013L18.0493 19.4349Z"
      fill="#233447"
      stroke="#233447"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7266 24.8488L11.2087 20.771L8.06494 20.8614L10.7266 24.8488Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7905 20.771L21.2726 24.8488L23.9343 20.8614L20.7905 20.771Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.1808 16.2812L17.5361 16.5323L18.0584 19.435L18.8921 17.6874L20.9009 18.6014L23.1808 16.2812Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1079 18.6014L13.1167 17.6874L13.9403 19.435L14.4726 16.5323L8.81787 16.2812L11.1079 18.6014Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.81885 16.2812L11.1892 20.9015L11.1089 18.6014L8.81885 16.2812Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9012 18.6014L20.8008 20.9015L23.1812 16.2812L20.9012 18.6014Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4737 16.5325L13.9414 19.4352L14.6043 22.8602L14.755 18.3504L14.4737 16.5325Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5368 16.5325L17.2656 18.3404L17.3862 22.8602L18.0591 19.4352L17.5368 16.5325Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0587 19.435L17.3857 22.86L17.8679 23.1914L20.8007 20.9014L20.9011 18.6013L18.0587 19.435Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1084 18.6013L11.1888 20.9014L14.1216 23.1914L14.6037 22.86L13.9408 19.435L11.1084 18.6013Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1089 26.3452L18.139 25.4111L17.8879 25.1901H14.1013L13.8703 25.4111L13.8904 26.3452L10.7266 24.8486L11.8314 25.7526L14.0712 27.3094H17.918L20.1679 25.7526L21.2727 24.8486L18.1089 26.3452Z"
      fill="#C0AD9E"
      stroke="#C0AD9E"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8676 23.1913L17.3855 22.8599H14.6033L14.1212 23.1913L13.8701 25.411L14.1011 25.1901H17.8877L18.1388 25.411L17.8676 23.1913Z"
      fill="#161616"
      stroke="#161616"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.9533 11.4703L28.807 7.37233L27.5314 3.56567L17.8691 10.737L21.5854 13.8808L26.8384 15.4175L28.0035 14.0616L27.5013 13.7L28.3048 12.9668L27.6821 12.4847L28.4856 11.872L27.9533 11.4703Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.19482 7.37233L4.04856 11.4703L3.50619 11.872L4.3097 12.4847L3.69702 12.9668L4.50054 13.7L3.99834 14.0616L5.15339 15.4175L10.4064 13.8808L14.1226 10.737L4.46036 3.56567L3.19482 7.37233Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8372 15.4173L21.5842 13.8806L23.1812 16.2811L20.8008 20.9013L23.9345 20.8612H28.6049L26.8372 15.4173Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4054 13.8806L5.15243 15.4173L3.40479 20.8612H8.06517L11.1888 20.9013L8.81847 16.2811L10.4054 13.8806Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5363 16.5323L17.8677 10.7369L19.3944 6.60889H12.6147L14.1213 10.7369L14.4729 16.5323L14.5934 18.3603L14.6034 22.86H17.3856L17.4057 18.3603L17.5363 16.5323Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth={0.100439}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgMetamask);
export default ForwardRef;
