import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgAccountSignout = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.25 14.5833C11.029 14.5833 10.817 14.6711 10.6607 14.8274C10.5045 14.9837 10.4167 15.1956 10.4167 15.4167V17.5C10.4167 17.6105 10.3728 17.7165 10.2946 17.7946C10.2165 17.8728 10.1105 17.9167 10 17.9167H2.08333C1.97283 17.9167 1.86685 17.8728 1.78871 17.7946C1.71057 17.7165 1.66667 17.6105 1.66667 17.5V2.49999C1.66667 2.38948 1.71057 2.2835 1.78871 2.20536C1.86685 2.12722 1.97283 2.08332 2.08333 2.08332H10C10.1105 2.08332 10.2165 2.12722 10.2946 2.20536C10.3728 2.2835 10.4167 2.38948 10.4167 2.49999V4.58332C10.4167 4.80434 10.5045 5.0163 10.6607 5.17258C10.817 5.32886 11.029 5.41666 11.25 5.41666C11.471 5.41666 11.683 5.32886 11.8393 5.17258C11.9955 5.0163 12.0833 4.80434 12.0833 4.58332V2.08332C12.0833 1.6413 11.9077 1.21737 11.5952 0.904812C11.2826 0.592251 10.8587 0.416656 10.4167 0.416656H1.66667C1.22464 0.416656 0.800716 0.592251 0.488155 0.904812C0.175595 1.21737 0 1.6413 0 2.08332L0 17.9167C0 18.3587 0.175595 18.7826 0.488155 19.0952C0.800716 19.4077 1.22464 19.5833 1.66667 19.5833H10.4167C10.8587 19.5833 11.2826 19.4077 11.5952 19.0952C11.9077 18.7826 12.0833 18.3587 12.0833 17.9167V15.4167C12.0833 15.1956 11.9955 14.9837 11.8393 14.8274C11.683 14.6711 11.471 14.5833 11.25 14.5833Z"
      fill="black"
    />
    <path
      d="M19.6249 9.72498L14.6249 6.39164C14.4996 6.3091 14.3543 6.26194 14.2044 6.25513C14.0545 6.24832 13.9055 6.28213 13.7732 6.35297C13.6409 6.42381 13.5302 6.52907 13.4527 6.65762C13.3753 6.78617 13.334 6.93324 13.3333 7.08331V9.16664H5.83325C5.50173 9.16664 5.18379 9.29834 4.94937 9.53276C4.71495 9.76718 4.58325 10.0851 4.58325 10.4166C4.58325 10.7482 4.71495 11.0661 4.94937 11.3005C5.18379 11.5349 5.50173 11.6666 5.83325 11.6666H13.3333V13.75C13.3333 13.971 13.421 14.183 13.5773 14.3392C13.7336 14.4955 13.9456 14.5833 14.1666 14.5833C14.3299 14.582 14.4893 14.5328 14.6249 14.4416L19.6249 11.1083C19.7395 11.0328 19.8338 10.9302 19.8992 10.8095C19.9646 10.6889 19.9992 10.5539 19.9999 10.4166C19.9992 10.2794 19.9646 10.1444 19.8992 10.0238C19.8338 9.9031 19.7395 9.80047 19.6249 9.72498Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgAccountSignout);
export default ForwardRef;
