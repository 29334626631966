import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgWhiteFacebook = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2329 23.8776C13.6671 23.9595 13.0885 24.0018 12.5 24.0018C12.2689 24.0018 12.0394 23.9953 11.8115 23.9824C12.0394 23.9952 12.269 24.0016 12.5002 24.0016C13.0887 24.0016 13.6671 23.9585 14.2329 23.8776ZM14.375 23.8562V15.4712H17.1712L17.7028 12.0017H14.375V9.75054C14.375 8.80215 14.8391 7.87571 16.3308 7.87571H17.8445V4.92255C17.8445 4.92255 16.4709 4.68799 15.158 4.68799C12.4175 4.68799 10.6253 6.3485 10.6253 9.35734V12.0017H7.57764V15.4712H10.6253V23.8563C4.88835 22.9562 0.5 17.9915 0.5 12.0019C0.5 5.37451 5.87258 0.00195312 12.5 0.00195312C19.1274 0.00195312 24.5 5.37451 24.5 12.0019C24.5 17.9914 20.1118 22.9561 14.375 23.8562Z"
      fill="white"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgWhiteFacebook);
export default ForwardRef;
