import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgArrowDownBold = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={10}
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.97535 9.99627C8.44081 9.99627 8.83978 9.79679 9.10576 9.46432L15.6887 2.01691C16.1542 1.48496 16.0877 0.68702 15.5557 0.288052C15.0238 -0.110916 14.2923 -0.110916 13.8269 0.421041L8.10834 6.93752C8.04184 7.00401 7.97535 7.00401 7.84236 6.93752L2.12382 0.421041C1.65836 -0.110916 0.860422 -0.110916 0.394959 0.354546C-0.0705034 0.820009 -0.136998 1.55145 0.26197 2.08341L6.77844 9.53081C7.11092 9.79679 7.50989 9.99627 7.97535 9.99627Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgArrowDownBold);
export default ForwardRef;
