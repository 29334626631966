import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgArrowDown = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={10}
    height={8}
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.99968 7.12484C4.86349 7.12402 4.72899 7.09467 4.60483 7.0387C4.48068 6.98272 4.36963 6.90135 4.27884 6.79984L0.191342 2.15401C0.116271 2.07967 0.0572823 1.99069 0.0180394 1.8926C-0.0212035 1.79451 -0.0398633 1.6894 -0.0367813 1.5838C-0.0336993 1.47819 -0.00894138 1.37435 0.0359553 1.27871C0.080852 1.18308 0.144929 1.09769 0.224208 1.02786C0.303486 0.958022 0.396274 0.905231 0.49681 0.87276C0.597347 0.840288 0.703485 0.828831 0.808634 0.839098C0.913784 0.849365 1.0157 0.881138 1.10806 0.932444C1.20041 0.983749 1.28124 1.05349 1.34551 1.13734L4.92051 5.19984C4.93029 5.21128 4.94243 5.22046 4.95609 5.22676C4.96976 5.23305 4.98463 5.23631 4.99968 5.23631C5.01472 5.23631 5.02959 5.23305 5.04326 5.22676C5.05692 5.22046 5.06906 5.21128 5.07884 5.19984L8.65384 1.13734C8.71812 1.05349 8.79894 0.983749 8.89129 0.932444C8.98365 0.881138 9.08557 0.849365 9.19072 0.839098C9.29587 0.828831 9.40201 0.840288 9.50254 0.87276C9.60308 0.905231 9.69587 0.958022 9.77514 1.02786C9.85442 1.09769 9.9185 1.18308 9.9634 1.27871C10.0083 1.37435 10.0331 1.47819 10.0361 1.5838C10.0392 1.6894 10.0206 1.79451 9.98131 1.8926C9.94207 1.99069 9.88308 2.07967 9.80801 2.15401L5.72051 6.79984C5.62972 6.90135 5.51867 6.98272 5.39452 7.0387C5.27036 7.09467 5.13586 7.12402 4.99968 7.12484Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgArrowDown);
export default ForwardRef;
