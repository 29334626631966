import * as React from "react";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import MultilineText from "@hl/base-components/lib/MultilineText";
import {
  Minimize as MinimizeIcon,
  Pause as PauseIcon,
  Play as PlayIcon,
} from "@hl/base-components/lib/assets/icons.generated";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Video } from "@hl/base-components/lib/video";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import useGenArtAspectRatio from "@hl/shared-features/lib/hooks/useAspectRatio";
import {
  ActionIcon,
  Box,
  Card,
  Center,
  createStyles,
  Flex,
  Grid,
  Loader,
  MediaQuery,
  Modal,
  Skeleton,
  Stack,
} from "@mantine/core";
import { useDisclosure, useMediaQuery, useViewportSize } from "@mantine/hooks";
import { IconArrowNarrowLeft } from "@tabler/icons";
import WaveSurfer from "wavesurfer.js";

import { getDetailPageUrl } from "~config";
import {
  InternalLink,
  useLinkStyles,
} from "~features/MintPage/components/InternalLink";
import { useMediaStyles } from "~features/MintPage/styles";
import TokenDetailsCardSection from "~features/token-details/TokenDetailsCardSection";
import useMintState from "~hooks/useMintState";
import useMintTokensState from "~hooks/useMintTokensState";
import { getContentTypeInfo } from "~utils/contentType";

import { PageSupportedMediaType } from "../../../apollo/graphql.generated";

type StyleProps = {
  expanded: boolean;
  responsive: boolean;
  isVertical: boolean;
};

const useStyles = createStyles(
  (theme, { expanded, responsive, isVertical }: StyleProps) => {
    const heightPadding = expanded ? 30 : 60;
    return {
      fullHeight: {
        height: "100% !important",
      },
      containerResponsive: {
        width: "calc(100% - 120px)",
        height: "75vh",
        minHeight: 200,
        maxHeight: 1200,

        [theme.fn.smallerThan(600)]: {
          width: "calc(100% - 32px)",
          height: "66.67vh",
        },

        ...(expanded && {
          width: "100%",
          height: "100%",

          [theme.fn.smallerThan(600)]: {
            width: "100%",
            height: "100vh",
          },
        }),
      },
      embedContainer: {
        width: "100%",
      },
      container: {
        width: "100%",
        boxShadow: theme.shadows.sm,
        maxWidth: 600,
        [theme.fn.largerThan("md")]: {
          maxWidth: "100%",
          width: isVertical ? "100%" : "auto",
          height: isVertical ? "auto" : "100%",
          ["@media (orientation: portrait)"]: {
            width: "100%",
            height: "auto",
            maxHeight: `calc(100vh - ${heightPadding}px)`,
          },
        },
      },
      absolutePosition: {
        position: "absolute",
        top: 0,
      },
      previewImage: {
        objectFit: "scale-down",
      },
      expanded: {
        height: responsive ? "100vh" : "calc(100vh - 30px)",
        overflow: "hidden",
      },
      closeBtn: {
        position: "absolute",
        top: 10,
        right: 10,
      },
      fullscreenButton: {
        [theme.fn.smallerThan("md")]: {
          display: "none",
        },
      },
      toolbar: {
        boxShadow: theme.shadows.sm,
        borderRadius: 10,
        backgroundColor: theme.colors.primaryBackground[0],
        ...(expanded && {
          position: "absolute",
          bottom: 20,
        }),
        button: {
          height: 36,
          width: 36,
        },
        ...(expanded &&
          responsive && {
            display: "none",
          }),
      },
      modal: {
        ...(responsive && {
          padding: `0px !important`,
        }),
      },
      modalBody: {
        maxHeight: "100%",
        ...(responsive && {
          padding: 0,
          margin: 0,
        }),
      },
    };
  }
);

type Props = {
  isEmbed?: boolean;
  selectedTokenIndex: number;
  setSelectedTokenIndex: Dispatch<SetStateAction<number>>;
  containerWidth?: number;
  containerHeight?: number;
};

export const HeaderImportedApollo = ({
  isEmbed = false,
  selectedTokenIndex,
  setSelectedTokenIndex,
  containerWidth,
  containerHeight,
}: Props) => {
  const { contentTypeInfo, setContentTypeInfo, collection } = useMintState();
  const {
    tokens,
    loadMore,
    sorting,
    refetch,
    attributesVar,
    loading,
    debouncedSearchString,
  } = useMintTokensState();
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
  const [opened, { open, close: closeModal }] = useDisclosure(false);

  const token = tokens?.[selectedTokenIndex];
  const isFiltering =
    attributesVar.length !== 0 || !!debouncedSearchString?.length;
  const animationMimeType = token?.metadata?.animationMimeType;
  const isHtml = animationMimeType?.includes("html");
  const isImage =
    animationMimeType?.includes("image") ||
    (!animationMimeType && !contentTypeInfo?.type);
  const animationUrl = token?.animationUrl;
  const imageUrl =
    (isFiltering && !token
      ? collection?.seriesImages?.coverImageUrl
      : token?.imageUrl) ?? "";

  const { aspectRatio, isLoaded } = useGenArtAspectRatio(null, imageUrl);
  const { width: viewportWidth, height: viewportHeight } = useViewportSize();
  // approximate containerWidth via viewport: 516px is sidebar width + artwork container padding
  const viewportWidthNormalized = viewportWidth - 516;
  // approximate containerWidth via viewport: 136px is navbar height + artwork container padding + token info section height
  const viewportHeightNormalized = viewportHeight - 136;
  const width = containerWidth ? containerWidth : viewportWidthNormalized;
  // height of the token info section is 90px
  const height = containerHeight ? containerHeight : viewportHeightNormalized;

  const viewportAspectRatio = width / height;
  const [artworkAspectRatio, setArtworkAspectRatio] = useState(1);
  const isVertical = viewportAspectRatio <= artworkAspectRatio;

  const { classes, theme } = useStyles({
    expanded: opened,
    responsive: false,
    isVertical,
  });
  const { classes: classesLink } = useLinkStyles();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const { isLarge } = useMantineMediaQueries();
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const waveformRef = useRef<any>(null);
  const { classes: classesMedia } = useMediaStyles({ hasControls: true });

  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

  const moveToToken = useCallback(
    (moveFor: number) => {
      const newIndex = selectedTokenIndex + moveFor;
      if (newIndex < 0 || !tokens) {
        return;
      }
      if (newIndex >= tokens.length) {
        // fetch more
      }
      setSelectedTokenIndex((index) => index + moveFor);
    },
    [selectedTokenIndex, tokens]
  );

  useEffect(() => {
    if (selectedTokenIndex === (tokens?.length ?? 0) - 2) {
      loadMore();
    }
  }, [selectedTokenIndex]);

  useEffect(() => {
    setSelectedTokenIndex(0);
  }, [sorting]);

  useEffect(() => {
    async function fetchContentType() {
      if (token?.animationUrl) {
        setContentTypeInfo(await getContentTypeInfo(token.animationUrl));
      }
    }

    fetchContentType();
  }, [token?.animationUrl]);

  const onAudioButtonClick = () => {
    if (audioPlaying) {
      waveformRef.current.pause();
    } else {
      waveformRef.current.play();
    }

    setAudioPlaying(!audioPlaying);
  };

  const setWaveformRef = useCallback(() => {
    if (
      animationUrl &&
      contentTypeInfo?.type === PageSupportedMediaType.AUDIO &&
      !waveformRef.current
    ) {
      waveformRef.current = WaveSurfer.create({
        container: "#waveform",
        height: 280,
        fillParent: true,
        barWidth: 3,
        hideScrollbar: true,
      });

      waveformRef.current.load(animationUrl);
    }
  }, [animationUrl, contentTypeInfo]);

  if ((!tokens?.length && !isFiltering) || (loading && !tokens?.length)) {
    return (
      <Stack h="100%" align="center" spacing={0} w="100%">
        <Center h="100%" w="100%">
          <Card shadow="sm" p={0} withBorder={false} h="100%" w="100%">
            <Skeleton
              height={isMobile ? 420 : "100%"}
              width={isMobile ? undefined : "100%"}
              radius={0}
            >
              <Loader />
            </Skeleton>
          </Card>
        </Center>
        {!isLarge && (
          <>
            <Skeleton
              h={48}
              w="100%"
              mt={12}
              maw={440}
              sx={{ display: "flex", flexShrink: 0 }}
            ></Skeleton>
            <Skeleton
              h={32}
              w="100%"
              mt={12}
              maw={440}
              sx={{ display: "flex", flexShrink: 0 }}
            ></Skeleton>
          </>
        )}
      </Stack>
    );
  }

  const sourceType = !!contentTypeInfo?.mime
    ? `${contentTypeInfo.type?.toLowerCase()}/${contentTypeInfo.mime?.toLowerCase()}`
    : "video/mp4";

  const content = (
    <Flex
      direction="column"
      align="center"
      justify="center"
      w={isVertical ? "100%" : "auto"}
      mah={"100%"}
      h={opened ? "100vh" : isVertical ? "auto" : "100%"}
    >
      <Box
        className={
          isEmbed && !opened ? classes.embedContainer : classes.container
        }
        sx={{
          aspectRatio: isLoaded ? aspectRatio : "auto",
          position: "relative",
        }}
      >
        {animationUrl &&
        !isHtml &&
        !isImage &&
        contentTypeInfo?.type !== PageSupportedMediaType.IMAGE ? (
          <>
            {contentTypeInfo?.type === PageSupportedMediaType.VIDEO && (
              <Video
                className={classesMedia.video}
                style={{ maxHeight: "unset" }}
                autoPlay
                loop
                muted
                playsInline
                onLoadedMetadata={(event) => {
                  const target = event.target as HTMLVideoElement;
                  const ar = target.videoWidth / target.videoHeight;
                  setArtworkAspectRatio(ar);
                }}
              >
                <source src={animationUrl} type={sourceType} />
              </Video>
            )}
            {contentTypeInfo?.type === PageSupportedMediaType.AUDIO && (
              <div className={classesMedia.audio}>
                <Grid>
                  <Grid.Col span={3} style={{ position: "relative" }}>
                    <ResizedImage
                      src={imageUrl}
                      fit="scale-down"
                      height={280}
                      width={280}
                      style={{
                        position: "absolute",
                        filter: "blur(0)",
                        zIndex: 1,
                        marginRight: 100,
                      }}
                      onLoad={(event) => {
                        const target = event.target as HTMLImageElement;
                        const ar = target.naturalWidth / target.naturalHeight;
                        setArtworkAspectRatio(ar);
                      }}
                    />
                    <button
                      onClick={onAudioButtonClick}
                      color="white"
                      style={{
                        zIndex: 10,
                        borderRadius: "100%",
                        border: "none",
                        width: 70,
                        height: 70,
                        position: "absolute",
                        top: 105,
                        left: 105,
                      }}
                    >
                      {audioPlaying ? (
                        <PauseIcon className={classesMedia.buttonIcon} />
                      ) : (
                        <PlayIcon className={classesMedia.buttonIcon} />
                      )}
                    </button>
                  </Grid.Col>
                  <Grid.Col span={8} offset={1}>
                    <div id="waveform" ref={setWaveformRef}></div>
                  </Grid.Col>
                </Grid>
              </div>
            )}
            {/*{contentTypeInfo?.isHtml && (*/}
            {/*  <div className={classesMedia.video}>*/}
            {/*    <GenArtUrlIFrame url={animationUrl} iframeRef={resourceRef} />*/}
            {/*  </div>*/}
            {/*)}*/}
          </>
        ) : (
          <>
            <MediaQuery smallerThan="md" styles={{ display: "none" }}>
              <ResizedImage
                src={imageUrl}
                fit="contain"
                classNames={{
                  root: classes.fullHeight,
                  imageWrapper: classes.fullHeight,
                  image: classes.fullHeight,
                  figure: classes.fullHeight,
                }}
                styles={{
                  image: {
                    maxWidth: "100%",
                  },
                }}
                onClick={open}
                height={500}
                width="auto"
                style={{ filter: "blur(0)", zIndex: 1, cursor: "pointer" }}
                onLoad={(event) => {
                  const target = event.target as HTMLImageElement;
                  const ar = target.naturalWidth / target.naturalHeight;
                  setArtworkAspectRatio(ar);
                }}
              />
            </MediaQuery>
            <MediaQuery largerThan="md" styles={{ display: "none" }}>
              <ResizedImage
                src={imageUrl}
                fit="scale-down"
                onClick={open}
                fitSize={500}
                classNames={{
                  root: classes.fullHeight,
                  imageWrapper: classes.fullHeight,
                  image: classes.fullHeight,
                  figure: classes.fullHeight,
                }}
                style={{ filter: "blur(0)", zIndex: 1, cursor: "pointer" }}
                onLoad={(event) => {
                  const target = event.target as HTMLImageElement;
                  const ar = target.naturalWidth / target.naturalHeight;
                  setArtworkAspectRatio(ar);
                }}
              />
            </MediaQuery>
          </>
        )}
      </Box>
      {!opened && token && !isLarge && (
        <Stack spacing="sm" pt={12}>
          <Flex
            w={isTablet ? 440 : "100%"}
            justify="space-between"
            align="center"
          >
            <ActionIcon
              onClick={() => moveToToken(-1)}
              size={48}
              disabled={selectedTokenIndex === 0}
            >
              <IconArrowNarrowLeft />
            </ActionIcon>
            <InternalLink
              className={classesLink.grow}
              to={collection ? getDetailPageUrl(collection, token.tokenId) : ""}
            >
              <MultilineText size="xl" weight={WEIGHT_BOLD} numLines="2">
                {token?.name}
              </MultilineText>
            </InternalLink>
            <ActionIcon
              onClick={() => moveToToken(1)}
              size={48}
              disabled={selectedTokenIndex === (tokens?.length ?? 1) - 1}
            >
              <IconArrowNarrowLeft style={{ transform: "rotate(180deg)" }} />
            </ActionIcon>
          </Flex>
          {token && (
            <Center>
              <TokenDetailsCardSection
                token={token}
                refetchToken={() => refetch?.()}
              />
            </Center>
          )}
        </Stack>
      )}
    </Flex>
  );

  if (opened) {
    return (
      <Modal
        opened={opened}
        onClose={closeModal}
        fullScreen
        withCloseButton={false}
        centered
        classNames={{
          modal: classes.modal,
          body: classes.modalBody,
        }}
      >
        <div className={classes.expanded}>{content}</div>
        <ActionIcon className={classes.closeBtn} onClick={closeModal}>
          <MinimizeIcon />
        </ActionIcon>
      </Modal>
    );
  }

  return content;
};
