import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgTheme = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.26597 7.7836C9.4759 7.8433 9.70088 7.81826 9.89255 7.71386C10.0842 7.60945 10.2273 7.43402 10.291 7.22527L11.391 3.52527C11.4838 3.20777 11.4479 2.86648 11.291 2.57527C11.2146 2.42919 11.1096 2.30002 10.9822 2.19549C10.8547 2.09096 10.7075 2.01322 10.5493 1.96693L4.11597 0.0502676C3.79883 -0.0434711 3.45753 -0.00859352 3.1659 0.147356C2.87428 0.303306 2.65577 0.567791 2.55764 0.883601L1.43264 4.65027C1.37267 4.86297 1.39965 5.09079 1.50764 5.2836C1.5572 5.38085 1.62654 5.46667 1.71121 5.53555C1.79587 5.60443 1.894 5.65486 1.9993 5.6836L9.26597 7.7836Z"
      fill="black"
    />
    <path
      d="M14.3662 7.91665C13.2391 7.90379 12.1162 8.05538 11.0329 8.36665C9.74954 8.79998 8.99121 9.47498 8.99121 10.0333V17.5333C8.99121 18.6833 11.4162 19.6166 14.4079 19.6166C17.3995 19.6166 19.8245 18.6833 19.8245 17.5333V9.99998C19.7829 8.84998 17.3579 7.91665 14.3662 7.91665ZM18.3329 14.95C18.3407 15.1165 18.315 15.2829 18.2571 15.4392C18.1993 15.5955 18.1106 15.7386 17.9963 15.8599C17.8819 15.9813 17.7444 16.0783 17.5917 16.1453C17.4391 16.2123 17.2746 16.2479 17.1079 16.25C16.7674 16.2434 16.4432 16.1036 16.2048 15.8605C15.9663 15.6174 15.8328 15.2905 15.8329 14.95C15.8329 14.7201 15.7416 14.4997 15.579 14.3372C15.4165 14.1746 15.1961 14.0833 14.9662 14.0833C14.7364 14.0833 14.5159 14.1746 14.3534 14.3372C14.1909 14.4997 14.0995 14.7201 14.0995 14.95C14.0995 15.1798 14.0082 15.4003 13.8457 15.5628C13.6832 15.7253 13.4627 15.8166 13.2329 15.8166C13.003 15.8166 12.7826 15.7253 12.6201 15.5628C12.4575 15.4003 12.3662 15.1798 12.3662 14.95V12.1666C12.3667 12.0635 12.3337 11.963 12.272 11.8803C12.2104 11.7976 12.1235 11.7373 12.0245 11.7083C10.8995 11.4083 10.3079 10.925 10.3079 10.675C10.3079 10.3 11.6745 9.38332 14.2079 9.38332C16.3745 9.38332 18.3745 10.0833 18.3745 11.3416L18.3329 14.95Z"
      fill="black"
    />
    <path
      d="M7.59969 8.60829L0.833028 6.66663L0.649695 7.29163C0.409013 8.10593 0.397575 8.97084 0.616639 9.79123C0.835703 10.6116 1.27678 11.3557 1.89136 11.9416L0.299695 17.3083C0.221722 17.572 0.196525 17.8485 0.225545 18.122C0.254565 18.3955 0.337234 18.6606 0.468819 18.9021C0.600405 19.1436 0.778323 19.3568 0.992392 19.5294C1.20646 19.7021 1.45248 19.8308 1.71636 19.9083C1.90705 19.9718 2.10706 20.0028 2.30803 20C2.76056 20.0031 3.20172 19.8583 3.56429 19.5875C3.92687 19.3167 4.19097 18.9348 4.31636 18.5L5.91636 13.125C6.58918 12.9861 7.22346 12.7017 7.77469 12.2916C7.87429 12.2192 7.95659 12.1255 8.01567 12.0175C8.07475 11.9094 8.10914 11.7896 8.11636 11.6666V9.99996C8.11533 9.86989 8.13502 9.7405 8.17469 9.61663C8.22877 9.40678 8.19897 9.18413 8.09162 8.99588C7.98428 8.80764 7.80783 8.66861 7.59969 8.60829ZM2.29969 18.4333C2.13393 18.4333 1.97496 18.3674 1.85775 18.2502C1.74054 18.133 1.67469 17.9741 1.67469 17.8083C1.67469 17.6425 1.74054 17.4836 1.85775 17.3664C1.97496 17.2491 2.13393 17.1833 2.29969 17.1833C2.46546 17.1833 2.62443 17.2491 2.74164 17.3664C2.85885 17.4836 2.92469 17.6425 2.92469 17.8083C2.92469 17.9741 2.85885 18.133 2.74164 18.2502C2.62443 18.3674 2.46546 18.4333 2.29969 18.4333Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgTheme);
export default ForwardRef;
