import { FC, useCallback, useEffect, useState } from "react";

import { STATUS_COLOR, TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { AuctionStatus } from "@hl/shared-features/lib/apollo/graphql.generated";
import { FulfillAuction } from "@hl/shared-features/lib/features/auction-manager";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { SignInButton } from "@hl/shared-features/lib/features/auth/SignInButton";
import { LIFECYCLE_ERROR_CODES } from "@hl/shared-features/lib/features/evm-tx";
import {
  TransactionStateType,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import {
  Button,
  ButtonProps,
  Group,
  NumberInput,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons";

import { ReactComponent as OpenSeaIcon } from "~assets/icons/opensea.svg";

import { CollectionStatus } from "../../../apollo/graphql.generated";
import useMintState, { MintStateRequired } from "../../../hooks/useMintState";
import useCardAuctionData from "../hooks/useCardAuctionData";
import useLifecycleWarningsButtonHandler from "../useLifecycleWarningsButtonHandler";

import { SaleStatusAuction } from "./types";
import { parseBidError } from "./utils";

const SAFE_FLOAT_ROUNDING_PRECISION = 6;

const stateLabels: { [key: string]: string } = {
  [TransactionStateType.WaitingSignedTx]: "Waiting for user signature...",
};

type CardButtonProps = {
  auctionLabel?: string;
  showErrorIcon?: boolean;
  showWarningModals?: boolean;
} & Partial<ButtonProps>;

const BidInputExtraProps = (chainId: number | string) => {
  // fudge a number to multiply * the number of characters in the `rightSection`
  // and everything lines up properly /shrug
  const LETTER_WIDTH = 12;

  const currencySymbol = getCurrencySymbol(chainId);

  // TODO: Load currency configuration dynamically
  // TODO: Precision could be up to 18 for wei based currencies, but it causes
  // float rounding issues, so we'll use something safer.
  return {
    rightSection: (
      <Text size="sm" color={TEXT_COLOR.SECONDARY}>
        {currencySymbol}
      </Text>
    ),
    rightSectionWidth: currencySymbol.length * LETTER_WIDTH,
    precision: SAFE_FLOAT_ROUNDING_PRECISION,
  };
};

export const AuctionCardButton: FC<CardButtonProps> = ({
  auctionLabel = "Make bid",
  showErrorIcon = true,
  showWarningModals = false,
  ...rest
}) => {
  const {
    collectionStatus,
    collection,
    bidError,
    bid,
    auction,
    loadingAuctionBid: loading,
    txnId,
    openSeaCollectionUrl,
    refetchAuction,
    auctionStatus,
    handleBid,
    isAuctionLocked,
    isOpenSeaSupportedForCollectionChain,
  } = useMintState() as MintStateRequired;
  rest.loading = loading;
  const { hasEnoughMoney, nextPrice } = useCardAuctionData({ auction });
  const auctionStatusGraphQL =
    auction?.stats?.status ?? AuctionStatus.InProgress;
  const collectionId = collection?.id ?? "";
  const auctionId = auction?.id ?? "";
  const bidId = bid?.id;
  const chainId = auction.chainId ?? 1;
  const transactionState = useTransactionState(txnId);
  const { authenticated } = useAuth();
  const [bidAmount, setBidAmount] = useState<number | undefined>();
  const [error, setError] = useState<string | null>(null);
  const handleChange = useCallback(
    (amount?: number) => {
      setBidAmount(amount);
      if (error) {
        setError(null);
      }
    },
    [setBidAmount, error, setError]
  );

  const { onClickHandler } = useLifecycleWarningsButtonHandler({
    txnId,
    hasEnoughMoney,
    onClick: async () => {
      // First bid can be at reserve price
      if ((bidAmount ?? 0) < +nextPrice) {
        setError("You must make a bid higher than the current top bid.");
        return;
      }
      await handleBid(bidAmount!.toString());
    },
    showWarningModals,
  });

  useEffect(() => {
    handleChange(undefined);
  }, [bidId]);

  useEffect(() => {
    if (bidError && bidAmount !== undefined) {
      setError(parseBidError(bidError));
    }
  }, [bidError]);

  const bidInput = (
    <>
      <NumberInput
        placeholder={"0.05"}
        onInput={(event) => {
          try {
            const target = event.target as HTMLInputElement;
            target.value = target.value.replaceAll(",", ".");
          } catch (e) {
            console.error("Error parsing input", e);
          }
        }}
        onChange={handleChange}
        {...BidInputExtraProps(chainId)}
        min={0}
        step={0.01}
        removeTrailingZeros={true}
        value={bidAmount}
        error={error}
      />
    </>
  );
  if (
    SaleStatusAuction.ENDED_WITH_WIN === auctionStatus &&
    AuctionStatus.Fulfilled != auctionStatusGraphQL
  ) {
    return (
      <FulfillAuction
        size={"lg"}
        fullWidth={rest.fullWidth}
        collectionId={collectionId}
        auctionId={auctionId}
        chainId={chainId}
        refetchData={refetchAuction}
        fulfillerIsOwner={false}
      />
    );
  }
  if (
    SaleStatusAuction.ENDED === auctionStatus ||
    SaleStatusAuction.ENDED_WITH_WIN === auctionStatus
  ) {
    return <></>;
  }

  if (SaleStatusAuction.ENDED_WITH_LOSS === auctionStatus) {
    if (isOpenSeaSupportedForCollectionChain) {
      return (
        <Button
          component="a"
          href={openSeaCollectionUrl}
          target="_blank"
          leftIcon={<OpenSeaIcon />}
          {...rest}
        >
          View on OpenSea
        </Button>
      );
    } else {
      return <></>;
    }
  }

  if (
    SaleStatusAuction.NOT_STARTED === auctionStatus ||
    collectionStatus !== CollectionStatus.LIVE
  ) {
    return (
      <Button {...rest} disabled>
        Auction not open
      </Button>
    );
  }

  if (!authenticated) {
    return <SignInButton {...rest} />;
  }

  if (isAuctionLocked) {
    return (
      <Button {...rest} disabled>
        Auction locked
      </Button>
    );
  }
  if (
    transactionState?.error &&
    transactionState.error.cause &&
    transactionState.error.cause.code !== LIFECYCLE_ERROR_CODES.USER_DENIED_TX
  ) {
    return (
      <Stack spacing="xs">
        {bidInput}
        <Button {...rest} disabled>
          {auctionLabel}
        </Button>
        <Group noWrap spacing="xs">
          {showErrorIcon && <ErrorIcon />}
          <Text color={STATUS_COLOR.ERROR} size={showErrorIcon ? "md" : "sm"}>
            There was an error, please try again later
          </Text>
        </Group>
      </Stack>
    );
  }

  if (transactionState && !!transactionState.args) {
    if (transactionState.type === TransactionStateType.SignTxRejected) {
      return (
        <Stack spacing="xs">
          {bidInput}
          <Button {...rest} onClick={() => onClickHandler(chainId)}>
            {auctionLabel}
          </Button>
          <Group noWrap spacing="xs">
            {showErrorIcon && <ErrorIcon />}
            <Text color={STATUS_COLOR.ERROR} size={showErrorIcon ? "md" : "sm"}>
              Please confirm in your wallet
            </Text>
          </Group>
        </Stack>
      );
    } else if (
      // Transaction is completed when both state type are Done and updatedEvmTx is true.
      transactionState.type !== TransactionStateType.Done ||
      !transactionState.updatedEvmTx
    ) {
      return (
        <Button {...rest} disabled>
          {stateLabels[transactionState.type] ?? "Placing your bid..."}
        </Button>
      );
    }
  }

  return (
    <Stack spacing="xs">
      {bidInput}
      <Button
        {...rest}
        disabled={bidAmount === undefined}
        onClick={() => onClickHandler(chainId)}
      >
        {auctionLabel}
      </Button>
      {!hasEnoughMoney && (
        <Group noWrap spacing="xs">
          {showErrorIcon && <ErrorIcon />}
          <Text color={STATUS_COLOR.ERROR} size={showErrorIcon ? "md" : "sm"}>
            Insufficient funds
          </Text>
        </Group>
      )}
    </Stack>
  );
};

const ErrorIcon = () => {
  const theme = useMantineTheme();
  return (
    <IconAlertTriangle
      color={theme.colors.errorStatus[0]}
      size={20}
      fillOpacity={0.12}
      strokeWidth={1.16667}
      fill="#DB000D"
      stroke="#DB000D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};
