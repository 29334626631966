import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgHyperlinkTilted = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={19}
    height={18}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.84166 11.4248C9.6375 11.508 9.47463 11.6688 9.38873 11.8718C9.30282 12.0749 9.30088 12.3037 9.38333 12.5081C9.44715 12.6599 9.46458 12.8272 9.43344 12.9888C9.40229 13.1505 9.32396 13.2993 9.20833 13.4165L6.85 15.7748C6.69521 15.9298 6.51139 16.0527 6.30906 16.1366C6.10673 16.2205 5.88986 16.2636 5.67083 16.2636C5.45181 16.2636 5.23493 16.2205 5.0326 16.1366C4.83027 16.0527 4.64645 15.9298 4.49166 15.7748L2.725 13.9998C2.57004 13.845 2.44711 13.6612 2.36323 13.4589C2.27936 13.2565 2.23619 13.0397 2.23619 12.8206C2.23619 12.6016 2.27936 12.3847 2.36323 12.1824C2.44711 11.9801 2.57004 11.7963 2.725 11.6415L5.08333 9.29148C5.20052 9.17585 5.34933 9.09752 5.51099 9.06637C5.67264 9.03523 5.83991 9.05266 5.99166 9.11648C6.1961 9.20046 6.42553 9.1998 6.62948 9.11462C6.83342 9.02945 6.99518 8.86675 7.07916 8.66231C7.16315 8.45787 7.16248 8.22845 7.07731 8.0245C6.99214 7.82055 6.82944 7.6588 6.625 7.57481C6.16814 7.38545 5.66538 7.33583 5.18032 7.43225C4.69527 7.52867 4.2497 7.76679 3.9 8.11648L1.54166 10.4748C0.918289 11.0997 0.568207 11.9463 0.568207 12.829C0.568207 13.7116 0.918289 14.5583 1.54166 15.1831L3.31666 16.9581C3.94155 17.5815 4.78817 17.9316 5.67083 17.9316C6.55349 17.9316 7.40011 17.5815 8.025 16.9581L10.3833 14.5998C10.733 14.2501 10.9711 13.8045 11.0676 13.3195C11.164 12.8344 11.1144 12.3317 10.925 11.8748C10.8402 11.6722 10.6788 11.5112 10.4759 11.4269C10.273 11.3426 10.0451 11.3419 9.84166 11.4248Z"
      fill="#0D0D0D"
    />
    <path
      d="M5.96667 12.5333C6.1228 12.6885 6.33401 12.7756 6.55417 12.7756C6.77432 12.7756 6.98553 12.6885 7.14167 12.5333L13.6667 6.04994C13.8032 5.89052 13.8745 5.68546 13.8664 5.47573C13.8583 5.266 13.7714 5.06705 13.623 4.91864C13.4746 4.77023 13.2756 4.68328 13.0659 4.67518C12.8561 4.66708 12.6511 4.73842 12.4917 4.87494L5.96667 11.3583C5.81146 11.5144 5.72434 11.7256 5.72434 11.9458C5.72434 12.1659 5.81146 12.3771 5.96667 12.5333Z"
      fill="#0D0D0D"
    />
    <path
      d="M17.4583 2.80824L15.6833 1.04157C15.0584 0.418197 14.2118 0.0681152 13.3292 0.0681152C12.4465 0.0681152 11.5999 0.418197 10.975 1.04157L8.61666 3.39991C8.14769 3.8683 7.88391 4.50375 7.88333 5.16657C7.88355 5.49552 7.94868 5.82118 8.075 6.12491C8.15898 6.32934 8.32074 6.49205 8.52469 6.57722C8.72863 6.66239 8.95806 6.66306 9.1625 6.57907C9.36694 6.49509 9.52964 6.33333 9.61481 6.12938C9.69998 5.92544 9.70065 5.69601 9.61666 5.49157C9.57288 5.3888 9.55021 5.27828 9.55 5.16657C9.55092 4.94796 9.63771 4.73846 9.79166 4.58324L12.15 2.22491C12.3048 2.06995 12.4886 1.94701 12.6909 1.86314C12.8933 1.77927 13.1101 1.7361 13.3292 1.7361C13.5482 1.7361 13.7651 1.77927 13.9674 1.86314C14.1697 1.94701 14.3535 2.06995 14.5083 2.22491L16.275 3.99991C16.43 4.15469 16.5529 4.33851 16.6368 4.54084C16.7206 4.74317 16.7638 4.96005 16.7638 5.17907C16.7638 5.3981 16.7206 5.61498 16.6368 5.81731C16.5529 6.01964 16.43 6.20345 16.275 6.35824L13.9167 8.69991C13.8004 8.81694 13.652 8.89683 13.4903 8.92947C13.3286 8.9621 13.1609 8.94602 13.0083 8.88324C12.8049 8.80031 12.577 8.80106 12.3741 8.88533C12.1712 8.9696 12.0098 9.13058 11.925 9.33324C11.8826 9.43444 11.8607 9.54301 11.8604 9.65271C11.8601 9.76242 11.8815 9.8711 11.9233 9.97252C11.9651 10.0739 12.0265 10.1661 12.104 10.2438C12.1816 10.3214 12.2736 10.3829 12.375 10.4249C12.8319 10.6143 13.3346 10.6639 13.8197 10.5675C14.3047 10.471 14.7503 10.2329 15.1 9.88324L17.4583 7.52491C17.7683 7.21533 18.0141 6.8477 18.1819 6.44304C18.3496 6.03838 18.436 5.60462 18.436 5.16657C18.436 4.72852 18.3496 4.29477 18.1819 3.89011C18.0141 3.48544 17.7683 3.11782 17.4583 2.80824Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgHyperlinkTilted);
export default ForwardRef;
