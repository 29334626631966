import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPostEditorBold = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={10}
    height={12}
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.2828 5.65714C8.24354 5.61709 8.22155 5.56323 8.22155 5.50714C8.22155 5.45105 8.24354 5.3972 8.2828 5.35714C8.8275 4.77586 9.1335 4.01087 9.13995 3.21428C9.14004 2.78635 9.05469 2.3627 8.88889 1.96818C8.72308 1.57367 8.48017 1.21624 8.17439 0.91685C7.86862 0.617462 7.50614 0.382147 7.10821 0.224706C6.71029 0.0672658 6.28493 -0.00913022 5.85709 -8.57399e-07H2.23138C1.77672 -8.57399e-07 1.34068 0.180611 1.01919 0.502102C0.697702 0.823593 0.51709 1.25963 0.51709 1.71428V10.2857C0.51709 10.7404 0.697702 11.1764 1.01919 11.4979C1.34068 11.8194 1.77672 12 2.23138 12H5.85709C6.5927 11.9995 7.31091 11.7762 7.91722 11.3597C8.52353 10.9432 8.98954 10.3528 9.25391 9.66639C9.51829 8.97994 9.56866 8.22952 9.39838 7.5139C9.2281 6.79827 8.84516 6.15096 8.29995 5.65714H8.2828ZM2.23138 1.92857C2.2312 1.87459 2.25141 1.82253 2.28796 1.7828C2.32451 1.74307 2.37471 1.7186 2.42852 1.71428H5.85709C6.25492 1.71428 6.63645 1.87232 6.91775 2.15362C7.19906 2.43493 7.35709 2.81646 7.35709 3.21428C7.35709 3.61211 7.19906 3.99364 6.91775 4.27494C6.63645 4.55625 6.25492 4.71428 5.85709 4.71428H2.42852C2.37169 4.71428 2.31718 4.69171 2.277 4.65152C2.23681 4.61134 2.21423 4.55683 2.21423 4.5L2.23138 1.92857ZM5.85709 10.2857H2.42852C2.37169 10.2857 2.31718 10.2631 2.277 10.2229C2.23681 10.1828 2.21423 10.1283 2.21423 10.0714V6.64286C2.21423 6.58602 2.23681 6.53152 2.277 6.49133C2.31718 6.45115 2.37169 6.42857 2.42852 6.42857H5.85709C6.36858 6.42857 6.85912 6.63176 7.2208 6.99344C7.58247 7.35511 7.78566 7.84565 7.78566 8.35714C7.78566 8.86863 7.58247 9.35917 7.2208 9.72085C6.85912 10.0825 6.36858 10.2857 5.85709 10.2857Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPostEditorBold);
export default ForwardRef;
