import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgLocked = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={14}
    height={16}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12 6.33333H11.5V4.5C11.5 3.30653 11.0259 2.16193 10.1819 1.31802C9.33803 0.474106 8.19343 0 6.99996 0C5.80648 0 4.66189 0.474106 3.81798 1.31802C2.97407 2.16193 2.49996 3.30653 2.49996 4.5V6.33333H1.99996C1.64634 6.33333 1.3072 6.47381 1.05715 6.72386C0.807102 6.97391 0.666626 7.31305 0.666626 7.66667V14.6667C0.666626 15.0203 0.807102 15.3594 1.05715 15.6095C1.3072 15.8595 1.64634 16 1.99996 16H12C12.3536 16 12.6927 15.8595 12.9428 15.6095C13.1928 15.3594 13.3333 15.0203 13.3333 14.6667V7.66667C13.3333 7.31305 13.1928 6.97391 12.9428 6.72386C12.6927 6.47381 12.3536 6.33333 12 6.33333ZM6.99996 12.3333C6.73625 12.3333 6.47846 12.2551 6.2592 12.1086C6.03993 11.9621 5.86904 11.7539 5.76812 11.5102C5.6672 11.2666 5.6408 10.9985 5.69225 10.7399C5.74369 10.4812 5.87068 10.2437 6.05715 10.0572C6.24362 9.87072 6.4812 9.74373 6.73984 9.69229C6.99848 9.64084 7.26657 9.66724 7.5102 9.76816C7.75384 9.86908 7.96208 10.04 8.10858 10.2592C8.25509 10.4785 8.33329 10.7363 8.33329 11C8.33329 11.3536 8.19282 11.6928 7.94277 11.9428C7.69272 12.1929 7.35358 12.3333 6.99996 12.3333ZM9.83329 6C9.83329 6.08841 9.79817 6.17319 9.73566 6.2357C9.67315 6.29821 9.58836 6.33333 9.49996 6.33333H4.49996C4.41155 6.33333 4.32677 6.29821 4.26426 6.2357C4.20174 6.17319 4.16663 6.08841 4.16663 6V4.5C4.16663 3.74855 4.46514 3.02788 4.99649 2.49653C5.52784 1.96518 6.24851 1.66667 6.99996 1.66667C7.75141 1.66667 8.47208 1.96518 9.00343 2.49653C9.53478 3.02788 9.83329 3.74855 9.83329 4.5V6Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgLocked);
export default ForwardRef;
