import { ArrowUpRight } from "@hl/base-components/lib/assets/icons.generated";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { networkLookup } from "@hl/shared-features/lib/utils/blockExplorer";
import {
  getCurrencySymbol,
  getERC20Links,
  getNativeCurrencyIcon,
} from "@hl/shared-features/lib/utils/currency";
import {
  Anchor,
  Box,
  BoxProps,
  Divider,
  Group,
  Stack,
  Text,
} from "@mantine/core";

import { Currency, CurrencyType } from "../../../apollo/graphql.generated";

const links: {
  chainId: number;
  addToWalletLink: string;
  bridgeLink: string;
}[] = [
  {
    chainId: 8453,
    addToWalletLink: "https://docs.base.org/using-base/",
    bridgeLink: "https://bridge.base.org",
  },
  {
    chainId: 84532,
    addToWalletLink: "https://docs.base.org/using-base/",
    bridgeLink: "https://sepolia-bridge.base.org",
  },
  {
    chainId: 10,
    addToWalletLink:
      "https://help.optimism.io/hc/en-us/articles/6223777057179-How-do-I-use-OP-Mainnet-with-MetaMask-",
    bridgeLink: "https://app.optimism.io/bridge/deposit",
  },
  {
    chainId: 42161,
    addToWalletLink: "https://docs.arbitrum.io/getting-started-users",
    bridgeLink: "https://bridge.arbitrum.io/?l2ChainId=42161",
  },
  {
    chainId: 137,
    addToWalletLink:
      "https://wiki.polygon.technology/docs/tools/wallets/metamask/config-polygon-on-metamask/",
    bridgeLink: "https://wallet.polygon.technology/",
  },
  {
    chainId: 7777777,
    addToWalletLink:
      "https://support.zora.co/en/articles/8033751-adding-zora-network-to-your-wallet",
    bridgeLink: "https://bridge.zora.energy/",
  },
  {
    chainId: 984122,
    addToWalletLink: "https://chainlist.org/chain/984122",
    bridgeLink: "https://bridge.forma.art/",
  },
  {
    chainId: 5000,
    addToWalletLink: "https://docs-v2.mantle.xyz/users/how-to/wallet",
    bridgeLink: "https://bridge.mantle.xyz/",
  },
  {
    chainId: 534352,
    addToWalletLink: "https://chainlist.org/chain/534352",
    bridgeLink: "https://scroll.io/bridge/",
  },
  {
    chainId: 324,
    addToWalletLink: "https://docs.zksync.io/build/connect-to-zksync",
    bridgeLink: "https://portal.zksync.io/bridge/",
  },
  {
    chainId: 360,
    addToWalletLink: "https://chainlist.org/chain/360",
    bridgeLink: "https://shape.network/bridge",
  },
];

const BridgeLinks = ({
  chainId,
  paymentCurrency,
  isApollo,
  ...rest
}: {
  chainId: number;
  paymentCurrency?: Currency | null;
  isApollo?: boolean;
} & BoxProps) => {
  const network = networkLookup(chainId);
  if (paymentCurrency?.type === CurrencyType.ERC20) {
    return (
      <Erc20BridgeLinks
        chainId={chainId}
        isApollo={isApollo}
        paymentCurrency={paymentCurrency}
        {...rest}
      />
    );
  }

  const networkLinks = links.find((link) => link.chainId === chainId);
  if (!networkLinks) return <></>;

  return (
    <Stack
      spacing={0}
      sx={(theme) => ({
        boxShadow: isApollo ? undefined : theme.shadows.sm,
        borderRadius: 6,
        backgroundColor: theme.colors.primaryBackground[0],
        border: isApollo ? `1px solid ${theme.colors.divider[0]}` : undefined,
      })}
      px="sm"
      pt="sm"
      pb={0}
      {...rest}
    >
      <Group spacing="xs">
        {getNativeCurrencyIcon(network.type, 20)}
        <Text size="sm" fw={WEIGHT_BOLD}>
          New to {network.displayName}?
        </Text>
      </Group>
      <LinkItem
        link={networkLinks.addToWalletLink}
        text={`Add ${network.displayName} to your wallet`}
      />
      <Divider />
      <LinkItem
        link={networkLinks.bridgeLink}
        text={`Bridge your ${network.currencySymbol} to ${network.displayName} using the official ${network.displayName} Bridge`}
      />
    </Stack>
  );
};

const LinkItem = ({ text, link }: { text: string; link: string }) => (
  <Anchor href={link} target="_blank" rel="noreferrer" p="sm" underline={false}>
    <Group position="apart" noWrap>
      <Text size="sm">{text}</Text>
      <Box w={16} h={16}>
        <ArrowUpRight width={16} height={16} />
      </Box>
    </Group>
  </Anchor>
);

const Erc20BridgeLinks = ({
  chainId,
  paymentCurrency,
  isApollo,
  ...rest
}: {
  chainId: number;
  paymentCurrency: Currency;
  isApollo?: boolean;
} & BoxProps) => {
  const symbol = getCurrencySymbol(chainId, paymentCurrency.symbol);
  const links = getERC20Links(paymentCurrency, chainId);
  if (!links) return <></>;
  return (
    <Stack
      spacing={0}
      sx={(theme) => ({
        boxShadow: isApollo ? undefined : theme.shadows.sm,
        borderRadius: 6,
        backgroundColor: theme.colors.primaryBackground[0],
        border: isApollo ? `1px solid ${theme.colors.divider[0]}` : undefined,
      })}
      px="sm"
      pt="sm"
      pb={0}
      {...rest}
    >
      <Text size="sm" fw={WEIGHT_BOLD} ml="xs">
        New to {symbol}?
      </Text>
      <LinkItem link={links.currencyUrl} text={`Learn more about ${symbol}`} />
      <Divider />
      <LinkItem
        link={links.bridgeUrl}
        text={`See ${symbol} price and where to buy or swap`}
      />
    </Stack>
  );
};

export default BridgeLinks;
