import { Dispatch, memo, SetStateAction } from "react";

import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { useAuth } from "@hl/shared-features/lib/features/auth";
import { SignInButton } from "@hl/shared-features/lib/features/auth/SignInButton";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import {
  Box,
  Button,
  Divider,
  Group,
  NumberInput,
  Space,
  Text,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";

import ErrorBox from "~features/MintPage/MintVector/ErrorBox";
import { useGetSponsoRankingByAmountQuery } from "~features/MintPage/sponsor-mints/queries.graphql.generated";
import { useSponsoredEvents } from "~hooks/useSponsorEvents";

import { _CollectionType } from "../../../apollo/graphql.generated";
import {
  GetSalePageCollectionQuery,
  GetSalePageMintVectorsQuery,
} from "../queries.graphql.generated";

export type MintVector =
  GetSalePageMintVectorsQuery["getMintVectorsByCollection"][0];
export type Collection = GetSalePageCollectionQuery["getPublicCollection"];

export type SponsorMintModalData = {
  sponsorVectorId: string;
  collectionId: string;
  collectionName: string;
  collectionType: _CollectionType;
  refetchMintVector: () => void;
};

export const SponsorMintModal = memo(
  ({
    sponsorVectorId,
    amount,
    setAmount,
    sponsoredEventData,
  }: {
    sponsorVectorId: string;
    amount: string;
    setAmount: Dispatch<SetStateAction<string>>;
    sponsoredEventData: ReturnType<typeof useSponsoredEvents>;
  }) => {
    const {
      error: errorTrx,
      buttonLoading,
      buttonLabel,
      sponsorMint,
    } = sponsoredEventData;
    const { popModal } = useModalStack();
    const { authenticated } = useAuth();
    const [debouncedAmount] = useDebouncedValue(amount, 250);

    const invalidNumber = !amount || isNaN(+amount) || +amount <= 0;

    const { data: sponsorDetailsData, loading: sponsorDetailsLoading } =
      useGetSponsoRankingByAmountQuery({
        variables: {
          mintVectorId: sponsorVectorId,
          amount: +debouncedAmount,
        },
        skip: invalidNumber || !authenticated,
      });

    const sponsorRanking = sponsorDetailsData?.getSponsoRankingByAmount;

    const invalidAmount =
      invalidNumber || (!!sponsorRanking && sponsorRanking === 0);

    return (
      <Box mx="auto">
        <form>
          <NumberInput
            label="Amount to sponsor"
            placeholder="30"
            max={10000}
            onKeyDown={(event) => {
              if (event.key === "Enter") event.preventDefault();
            }}
            onChange={(amount) => setAmount(amount?.toString() || "")}
          />
          <Text size="xs" color={TEXT_COLOR.SECONDARY} mt={8}>
            {sponsorDetailsLoading
              ? "Loading..."
              : sponsorRanking && sponsorRanking > 0
              ? `Sponsoring this many will put you in the number ${sponsorRanking} spot on the leaderboard`
              : ""}
          </Text>
        </form>

        <Divider my={16} mx={-16} />
        <Group grow>
          <Button
            color={SECONDARY_COLOR}
            fullWidth
            size="lg"
            onClick={popModal}
          >
            Cancel
          </Button>

          {!authenticated ? (
            <SignInButton fullWidth size="lg" />
          ) : (
            <Button
              fullWidth
              size="lg"
              loading={buttonLoading}
              disabled={invalidAmount}
              onClick={sponsorMint}
            >
              {buttonLabel || "Sponsor mints"}
            </Button>
          )}
        </Group>
        {errorTrx && (
          <>
            <Space h={4} />
            <ErrorBox message={errorTrx} />
          </>
        )}
      </Box>
    );
  }
);
