import { useEffect, useState } from "react";
import axios from "axios";
import debounce from "lodash/debounce";
import { useCodeGenImagePreviewMutation } from "./queries/codegen.graphql.generated";
export var PreviewStatus;
(function (PreviewStatus) {
    PreviewStatus["Done"] = "done";
    PreviewStatus["Failed"] = "failed";
    PreviewStatus["Processing"] = "processing";
})(PreviewStatus || (PreviewStatus = {}));
export const fetchPreviewJson = async (url) => {
    try {
        const res = await axios.get(url);
        return res === null || res === void 0 ? void 0 : res.data;
    }
    catch (err) {
        return {
            status: PreviewStatus.Processing,
        };
    }
};
export const usePreviewImage = ({ mediaUrl, params, width, trigger, height, delay, selector, selectorType, skipImageCapture = false, isGPURendering = false, }) => {
    const [previewStatus, setPreviewStatus] = useState(null);
    const [previewImage] = useCodeGenImagePreviewMutation();
    const [metadata, setMetadata] = useState(null);
    useEffect(() => {
        const abortController = new AbortController();
        let interval;
        const run = debounce(async () => {
            var _a, _b;
            if (!mediaUrl) {
                return;
            }
            const res = await previewImage({
                context: {
                    fetchOptions: {
                        signal: abortController.signal,
                    },
                },
                variables: {
                    skipImageCapture,
                    isGPURendering,
                    codeURI: mediaUrl,
                    settings: {
                        trigger,
                        delay,
                        viewPort: {
                            width,
                            height,
                        },
                        selector,
                        selectorType,
                    },
                    params: {
                        txHash: params.txHash,
                        timestamp: params.timestamp,
                        walletAddress: params.walletAddress,
                        tokenId: params.tokenId,
                        editionSize: params.editionSize,
                        address: params.address,
                        blockHash: params.blockHash,
                        blockNumber: params.blockNumber,
                        chainId: params.chainId,
                        gasPrice: params.gasPrice,
                        gasUsed: params.gasUsed,
                        mintSize: params.mintSize,
                        mintIteration: params.mintIteration,
                        isCurated: params.isCurated,
                    },
                },
            });
            const statusUrl = (_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.genSeriesCapturePreview) === null || _b === void 0 ? void 0 : _b.statusUrl;
            if (!statusUrl) {
                setPreviewStatus(PreviewStatus.Failed);
                return;
            }
            interval = setInterval(async () => {
                var _a, _b;
                const res = await fetchPreviewJson(statusUrl);
                setPreviewStatus(res.status);
                if (res.status === PreviewStatus.Done) {
                    setMetadata((_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.metadata) !== null && _b !== void 0 ? _b : null);
                    clearInterval(interval);
                }
                else if (res.status === PreviewStatus.Failed) {
                    clearInterval(interval);
                }
            }, 5000);
        }, 300);
        setPreviewStatus(PreviewStatus.Processing);
        run();
        return () => {
            abortController.abort();
            clearInterval(interval);
        };
    }, [
        mediaUrl,
        params,
        width,
        height,
        trigger,
        delay,
        isGPURendering,
        selector,
        selectorType,
    ]);
    return {
        previewStatus,
        loading: previewStatus === PreviewStatus.Processing,
        imageUrl: metadata === null || metadata === void 0 ? void 0 : metadata.image,
        metadata,
    };
};
