import * as React from "react";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useState,
} from "react";

import MultilineText from "@hl/base-components/lib/MultilineText";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { adjustIconSize } from "@hl/shared-features/lib/features/marketplace/components/MarketplaceFilterSortBarApollo";
import SegmentedControlMarketplace from "@hl/shared-features/lib/features/marketplace/components/SegmentedControlMarketplace";
import { SortingOption } from "@hl/shared-features/lib/utils/sortingOptions";
import {
  ActionIcon,
  Box,
  Center,
  createStyles,
  Flex,
  Group,
  Select,
  Skeleton,
  Stack,
  Text,
} from "@mantine/core";
import { useElementSize, useMediaQuery } from "@mantine/hooks";
import { IconArrowNarrowLeft } from "@tabler/icons";

import { getDetailPageUrl } from "~config";
import { GenArtHeader } from "~features/MintPage/GenArtHeader";
import CollectionDetailsSidebar from "~features/MintPage/apollo/CollectionDetailsSidebar";
import CollectionDetailsTitleSection from "~features/MintPage/apollo/CollectionDetailsTitleSection";
import { EditionHeaderApollo } from "~features/MintPage/apollo/EditionHeaderApollo";
import { GenArtMintedViewApollo } from "~features/MintPage/apollo/GenArtMintedApollo";
import { HeaderImportedApollo } from "~features/MintPage/apollo/HeaderImportedApollo";
import { GenSeriesControls } from "~features/MintPage/components/GenSeriesControls";
import {
  InternalLink,
  useLinkStyles,
} from "~features/MintPage/components/InternalLink";
import MintSeriesHeader from "~features/MintPage/header/MintSeriesHeader";
import TokenDetailsCardSection from "~features/token-details/TokenDetailsCardSection";
import useMintTokensState from "~hooks/useMintTokensState";

import useMintState from "../../../hooks/useMintState";

const useStyles = createStyles(
  (theme, { showTokenNavigation }: { showTokenNavigation?: boolean }) => {
    return {
      header: {
        backgroundColor: theme.colors.baseBackground[0],
        borderBottom: `1px solid ${theme.colors.divider[0]}`,
      },
      mediaSectionContainer: {
        width: "100%",
        order: 1,
        [theme.fn.largerThan("md")]: {
          flex: "2 !important",
          order: 2,
          borderRight: `1px solid ${theme.colors.divider[0]}`,
          height: "100%",
        },
      },
      mediaSection: {
        justifyContent: "center",
        overflowX: "hidden",
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        [theme.fn.largerThan("md")]: {
          padding: 40,
          height: `calc(-56px + 100vh)`,
          position: "sticky",
          top: 56,
        },
        [theme.fn.largerThan("lg")]: {
          padding: 40,
          paddingTop: showTokenNavigation ? 109 : 40,
          height: `calc(-56px + 100vh)`,
          position: "sticky",
          top: 56,
        },
      },
      seriesMedia: {
        alignSelf: "center",
        maxWidth: "75vh",
        [theme.fn.largerThan("md")]: {
          height: "100%",
          padding: 40,
        },
      },
      genArtToolbar: {
        marginTop: 24,
        [theme.fn.largerThan("md")]: {
          marginTop: 32,
        },
      },
      fullHeight: {
        [theme.fn.largerThan("md")]: {
          maxHeight: "100%",
          height: "100%",
        },
      },
      video: {
        display: "block",
        objectFit: "contain",
        width: "100%",
      },
      mobileFooter: {
        padding: 20,
      },
      tokenNavigation: {
        borderBottom: `1px solid ${theme.colors.divider[0]}`,
        padding: "10px 40px",
        alignItems: "center",
        justifyContent: "space-between",
        position: "absolute",
        top: 0,
        width: "100%",
      },
    };
  }
);

const useSelectStyles = createStyles({
  input: { height: "40px", marginTop: 0, fontWeight: WEIGHT_BOLD },
  root: { flexGrow: 1, width: "100%" },
});

const MintView = forwardRef((_, ref: Ref<HTMLButtonElement>) => {
  const {
    isCodeGenMint,
    collection,
    isImported,
    isMarketplaceNoticeShown,
    isSeriesMint,
    endedOrSoldOut,
    mintVectorsLoading,
    isCollectorChoiceMint,
    isAnySeriesMint,
  } = useMintState();
  const { tokens, loading } = useMintTokensState();
  const {
    sorting,
    selectedSort,
    setSorting,
    sortingOptions,
    loadMore,
    refetch: refetchToken,
  } = useMintTokensState();
  const [selectedTokenIndex, setSelectedTokenIndex] = useState(0);
  const token = tokens?.[selectedTokenIndex];
  const moveToToken = useCallback(
    (moveFor: number) => {
      const newIndex = selectedTokenIndex + moveFor;
      if (newIndex < 0 || !tokens) {
        return;
      }
      if (newIndex >= tokens.length) {
        loadMore();
      }
      setSelectedTokenIndex((index) => index + moveFor);
    },
    [selectedTokenIndex, tokens]
  );

  useEffect(() => {
    if ((tokens?.length ?? 0) <= selectedTokenIndex + 1) {
      setSelectedTokenIndex(0);
    }
  }, [tokens, selectedTokenIndex]);
  const { isLarge, isExtraLarge } = useMantineMediaQueries();
  const showTokenInfo =
    ((endedOrSoldOut && isCodeGenMint) || isImported) &&
    isLarge &&
    !!(tokens?.length || (!tokens?.length && loading));
  const { classes, theme } = useStyles({ showTokenNavigation: showTokenInfo });
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md - 1}px)`);
  const { classes: classesLink } = useLinkStyles();
  const showSortingControls =
    (isMarketplaceNoticeShown && isCodeGenMint) || isImported;
  const hasMarketplaceButtons =
    (isCollectorChoiceMint || isAnySeriesMint) && !!token;
  const { classes: classesSelect } = useSelectStyles();
  const { ref: refContainer, width, height } = useElementSize();

  return (
    <Group
      spacing={isTablet ? "xl" : 0}
      align="stretch"
      mx={isTablet ? 0 : -40}
      pb={0}
      noWrap={!isTablet}
    >
      <Box className={classes.mediaSectionContainer}>
        <Stack spacing="xl" className={classes.mediaSection} ref={refContainer}>
          {showTokenInfo && (
            <Flex gap="xl" className={classes.tokenNavigation}>
              <Flex
                w={isTablet ? 440 : "33.3333%"}
                justify="space-between"
                align="center"
              >
                <ActionIcon
                  onClick={() => moveToToken(-1)}
                  size={48}
                  disabled={selectedTokenIndex === 0}
                >
                  <IconArrowNarrowLeft />
                </ActionIcon>
                <InternalLink
                  className={classesLink.grow}
                  to={
                    collection && token
                      ? getDetailPageUrl(collection, token.tokenId)
                      : ""
                  }
                >
                  <MultilineText
                    size="xl"
                    weight={WEIGHT_BOLD}
                    numLines="1"
                    display="flex"
                    align="center"
                  >
                    {token ? (
                      token?.name || `#${token?.id}`
                    ) : (
                      <Skeleton height={28} width={150} />
                    )}
                  </MultilineText>
                </InternalLink>
                <ActionIcon
                  onClick={() => moveToToken(1)}
                  size={48}
                  disabled={selectedTokenIndex === (tokens?.length ?? 1) - 1}
                >
                  <IconArrowNarrowLeft
                    style={{ transform: "rotate(180deg)" }}
                  />
                </ActionIcon>
              </Flex>
              {hasMarketplaceButtons && (
                <Center w="33.3333%">
                  <TokenDetailsCardSection
                    token={token}
                    refetchToken={() => refetchToken?.()}
                  />
                </Center>
              )}
              <Flex w="33.3333%" justify="end">
                {isExtraLarge ? (
                  <SegmentedControlMarketplace
                    transitionDuration={500}
                    w="33.3333%"
                    transitionTimingFunction="linear"
                    value={sorting ?? undefined}
                    onChange={setSorting}
                    data={sortingOptions.map((option) => ({
                      label:
                        sorting === option.value ? (
                          <Group spacing={8} noWrap>
                            {adjustIconSize(option.icon)}
                            <Text fw={WEIGHT_BOLD} size="sm">
                              {option.label}
                            </Text>
                          </Group>
                        ) : (
                          adjustIconSize(option.icon)
                        ),
                      value: option.value,
                    }))}
                  />
                ) : (
                  <Select
                    icon={
                      selectedSort ? adjustIconSize(selectedSort.icon) : null
                    }
                    value={sorting}
                    onChange={setSorting}
                    data={sortingOptions}
                    itemComponent={(props) => (
                      <SelectItem {...props} selected={sorting} />
                    )}
                    classNames={{
                      input: classesSelect.input,
                      root: classesSelect.root,
                    }}
                  />
                )}
              </Flex>
            </Flex>
          )}
          {isTablet && (
            <Box mt={32}>
              <CollectionDetailsTitleSection />
            </Box>
          )}
          {showSortingControls && !isLarge && (
            <Box mx="auto">
              <SegmentedControlMarketplace
                transitionDuration={500}
                transitionTimingFunction="linear"
                value={sorting ?? undefined}
                onChange={setSorting}
                data={sortingOptions.map((option) => ({
                  label:
                    sorting === option.value ? (
                      <Group spacing={8} noWrap>
                        {adjustIconSize(option.icon)}
                        <Text fw={WEIGHT_BOLD} size="sm">
                          {option.label}
                        </Text>
                      </Group>
                    ) : (
                      adjustIconSize(option.icon)
                    ),
                  value: option.value,
                }))}
              />
            </Box>
          )}
          {collection &&
            !mintVectorsLoading &&
            (isImported ? (
              <HeaderImportedApollo
                selectedTokenIndex={selectedTokenIndex}
                setSelectedTokenIndex={setSelectedTokenIndex}
                containerWidth={width}
                containerHeight={height}
              />
            ) : isCodeGenMint ? (
              <>
                {collection.flagVariations.enableMintGenSeriesControls && (
                  <GenSeriesControls />
                )}
                {endedOrSoldOut ? (
                  <GenArtMintedViewApollo
                    toolbarExtraClass={classes.genArtToolbar}
                    selectedTokenIndex={selectedTokenIndex}
                    setSelectedTokenIndex={setSelectedTokenIndex}
                  />
                ) : (
                  <GenArtHeader collection={collection} isApollo />
                )}
              </>
            ) : isSeriesMint ? (
              <MintSeriesHeader
                collectionId={collection.id}
                isApollo
                containerWidth={width}
                containerHeight={height}
              />
            ) : (
              <EditionHeaderApollo />
            ))}
        </Stack>
      </Box>
      <CollectionDetailsSidebar ref={ref} />
    </Group>
  );
});

type ItemProps = SortingOption &
  ComponentPropsWithoutRef<"div"> & { selected: string };

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ icon, label, value, ...others }: ItemProps, ref) => (
    <Group noWrap ref={ref} {...others} spacing={8}>
      {adjustIconSize(icon)}
      <Text
        size="sm"
        sx={{
          flexGrow: 1,
          fontWeight: others.selected === value ? WEIGHT_BOLD : undefined,
        }}
      >
        {label}
      </Text>
    </Group>
  )
);

export default MintView;
