import { ampli } from "../../ampli";
export var ArtworkInteractionType;
(function (ArtworkInteractionType) {
    ArtworkInteractionType["Reload"] = "reload";
    ArtworkInteractionType["Randomize"] = "randomize";
    ArtworkInteractionType["Expand"] = "expand";
    ArtworkInteractionType["Fullscreen"] = "fullscreen";
    ArtworkInteractionType["PreviewImage"] = "previewImage";
    ArtworkInteractionType["HowItWorks"] = "howItWorks";
    ArtworkInteractionType["OpenInNewWindow"] = "openInNewWindow";
})(ArtworkInteractionType || (ArtworkInteractionType = {}));
export const trackArtworkInteraction = ({ interactionType, collectionId, }) => {
    if (!ampli.isLoaded) {
        return;
    }
    ampli.artworkInteraction({
        collectionId,
        artworkInteractionType: interactionType,
        pagePath: window.location.pathname,
    });
};
