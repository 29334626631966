import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgCreateCommunity = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_421_608)">
      <path
        d="M7.91667 14.5834C7.91969 13.3037 8.29091 12.052 8.98602 10.9777C9.68112 9.90331 10.6707 9.05169 11.8367 8.5245C13.0027 7.99731 14.2957 7.81682 15.5615 8.00458C16.8273 8.19233 18.0123 8.74041 18.975 9.58336H19.5833C19.6659 9.58221 19.7463 9.55656 19.8142 9.50965C19.8822 9.46275 19.9347 9.39671 19.965 9.31992C19.9954 9.24313 20.0023 9.15905 19.9847 9.07835C19.9672 8.99766 19.9261 8.92399 19.8667 8.86669L10.8333 0.34169C10.605 0.134979 10.308 0.0205078 10 0.0205078C9.692 0.0205078 9.395 0.134979 9.16667 0.34169L0.133336 8.86669C0.073874 8.92399 0.0327776 8.99766 0.0152634 9.07835C-0.00225081 9.15905 0.00460699 9.24313 0.0349663 9.31992C0.0653255 9.39671 0.117817 9.46275 0.185778 9.50965C0.253738 9.55656 0.334102 9.58221 0.416669 9.58336H2.08334C2.19384 9.58336 2.29982 9.62726 2.37796 9.7054C2.4561 9.78354 2.5 9.88952 2.5 10V18.3334C2.5 18.5544 2.5878 18.7663 2.74408 18.9226C2.90036 19.0789 3.11232 19.1667 3.33334 19.1667H8.86667C8.94405 19.1667 9.0199 19.1451 9.08572 19.1045C9.15155 19.0638 9.20474 19.0056 9.23935 18.9364C9.27395 18.8671 9.2886 18.7897 9.28165 18.7126C9.2747 18.6355 9.24643 18.5619 9.2 18.5C8.36404 17.3655 7.91421 15.9926 7.91667 14.5834Z"
        fill="black"
      />
      <path
        d="M14.5833 9.16675C13.512 9.16675 12.4648 9.48443 11.574 10.0796C10.6832 10.6748 9.98896 11.5208 9.57899 12.5105C9.16901 13.5003 9.06174 14.5894 9.27075 15.6402C9.47975 16.6909 9.99564 17.656 10.7532 18.4136C11.5107 19.1711 12.4759 19.687 13.5266 19.896C14.5773 20.105 15.6664 19.9977 16.6562 19.5878C17.646 19.1778 18.4919 18.4835 19.0871 17.5928C19.6823 16.702 20 15.6547 20 14.5834C19.9978 13.1475 19.4264 11.771 18.4111 10.7557C17.3957 9.74034 16.0192 9.16895 14.5833 9.16675ZM16.6667 15.4167H15.8333C15.7228 15.4167 15.6168 15.4606 15.5387 15.5388C15.4606 15.6169 15.4167 15.7229 15.4167 15.8334V16.6667C15.4167 16.8878 15.3289 17.0997 15.1726 17.256C15.0163 17.4123 14.8043 17.5001 14.5833 17.5001C14.3623 17.5001 14.1504 17.4123 13.9941 17.256C13.8378 17.0997 13.75 16.8878 13.75 16.6667V15.8334C13.75 15.7229 13.7061 15.6169 13.628 15.5388C13.5498 15.4606 13.4438 15.4167 13.3333 15.4167H12.5C12.279 15.4167 12.067 15.329 11.9107 15.1727C11.7545 15.0164 11.6667 14.8044 11.6667 14.5834C11.6667 14.3624 11.7545 14.1504 11.9107 13.9942C12.067 13.8379 12.279 13.7501 12.5 13.7501H13.3333C13.4438 13.7501 13.5498 13.7062 13.628 13.628C13.7061 13.5499 13.75 13.4439 13.75 13.3334V12.5001C13.75 12.2791 13.8378 12.0671 13.9941 11.9108C14.1504 11.7545 14.3623 11.6667 14.5833 11.6667C14.8043 11.6667 15.0163 11.7545 15.1726 11.9108C15.3289 12.0671 15.4167 12.2791 15.4167 12.5001V13.3334C15.4167 13.4439 15.4606 13.5499 15.5387 13.628C15.6168 13.7062 15.7228 13.7501 15.8333 13.7501H16.6667C16.8877 13.7501 17.0996 13.8379 17.2559 13.9942C17.4122 14.1504 17.5 14.3624 17.5 14.5834C17.5 14.8044 17.4122 15.0164 17.2559 15.1727C17.0996 15.329 16.8877 15.4167 16.6667 15.4167Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_421_608">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgCreateCommunity);
export default ForwardRef;
