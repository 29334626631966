import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import BoxContainer from "@hl/base-components/lib/BoxContainer";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Box, Button, Divider, Group, Paper, Space, Stack, Text, Title, useMantineTheme, } from "@mantine/core";
import { ConditionType, } from "../../apollo/graphql.generated";
import { LockedGate as LockedGateIcon } from "../../assets/icons.generated";
import { useAuth } from "../auth/AuthContext";
import { SignInButton } from "../auth/SignInButton";
import { GateConditionsList } from "./GateConditionsList";
const SHOW_FIRST_N_GATE_CONDITIONS = 3;
const ApolloEntityLockedHeader = ({ entity }) => (_jsxs(Group, { spacing: 6, align: "center", children: [_jsx(LockedGateIcon, { width: 16, height: 16 }), _jsxs(Text, { size: "sm", fw: WEIGHT_BOLD, children: ["This ", entity, " is locked"] })] }));
const SmallEntityLockedHeader = ({ entity }) => (_jsxs(Group, { spacing: 6, align: "flex-start", children: [_jsx(LockedGateIcon, { width: 24, height: 24 }), _jsxs(Text, { size: "lg", fw: WEIGHT_BOLD, children: ["This ", entity, " is locked"] })] }));
const LargeEntityLockedHeader = ({ entity }) => (_jsxs(Stack, { spacing: "sm", align: "center", children: [_jsx(BoxContainer, { bg: "gray.0", border: false, radius: 999, lh: 0, children: _jsx(LockedGateIcon, { width: 24, height: 24 }) }), _jsxs(Title, { order: 5, ml: "xs", children: ["This ", entity, " is locked"] })] }));
export const GateConditionsFailed = ({ unlockGateResult, handleShowModal, isPreview = false, showFirstN = SHOW_FIRST_N_GATE_CONDITIONS, showConnectWallet = true, entity, shadow, headerSize = "sm", creatorAddress, creatorEns, refetchOnRequirementChange, isApollo, }) => {
    var _a, _b, _c, _d, _e;
    const theme = useMantineTheme();
    const { authenticated } = useAuth();
    const hiddenConditionCount = showFirstN
        ? ((_b = (_a = unlockGateResult.conditionResults) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) - showFirstN
        : 0;
    const results = (_c = unlockGateResult.conditionResults) !== null && _c !== void 0 ? _c : [];
    const marketingAccessConditionIdx = results.findIndex((value) => value.type === ConditionType.MARKETING_ACCESS);
    const hasMarketingAccessCondition = marketingAccessConditionIdx > -1;
    const web3Conditions = [...results];
    if (hasMarketingAccessCondition) {
        web3Conditions.splice(marketingAccessConditionIdx, 1);
    }
    const maxWidth = !isApollo ? (isPreview ? "320px" : "380px") : undefined;
    const paperProps = {
        shadow: isApollo ? "0" : shadow,
        bg: isApollo ? theme.colors.tableRowBackground[0] : undefined,
        px: isApollo ? "lg" : "md",
        pt: isApollo ? 14 : "lg",
        pb: isApollo ? 14 : "md",
        radius: isApollo ? 0 : undefined,
    };
    return (_jsx(Box, { sx: (theme) => ({
            [`@media (min-width: ${theme.breakpoints.md}px)`]: {
                maxWidth,
            },
        }), children: _jsxs(Paper, { ...paperProps, style: {
                borderBottom: isApollo
                    ? `1px solid ${theme.colors.divider[0]}`
                    : undefined,
            }, children: [isApollo && entity && (_jsxs(Group, { position: "apart", children: [_jsx(ApolloEntityLockedHeader, { entity: entity }), _jsx(Button, { onClick: handleShowModal, size: "sm", children: "View requirements" })] })), !isApollo && (_jsxs(Stack, { spacing: 0, children: [entity &&
                            (headerSize === "sm" ? (_jsx(SmallEntityLockedHeader, { entity: entity })) : (_jsx(LargeEntityLockedHeader, { entity: entity }))), _jsx(Space, { h: 8 }), hasMarketingAccessCondition && (_jsxs(Stack, { spacing: 12, children: [_jsx(Text, { size: "sm", align: headerSize === "lg" ? "center" : undefined, children: "To unlock it, you must meet the following requirement:" }), _jsx(GateConditionsList, { conditionResults: [results[marketingAccessConditionIdx]], creatorAddress: creatorAddress, creatorEns: creatorEns, creatorId: (_d = unlockGateResult.gateOwnerId) !== null && _d !== void 0 ? _d : "", refetchOnRequirementChange: refetchOnRequirementChange, isPreview: isPreview })] })), hasMarketingAccessCondition && web3Conditions.length >= 1 && (_jsxs(_Fragment, { children: [_jsx(Space, { h: 8 }), _jsx(Divider, {}), _jsx(Space, { h: 16 })] })), !!web3Conditions.length && (_jsxs(_Fragment, { children: [_jsxs(Text, { size: "sm", align: headerSize === "lg" ? "center" : undefined, children: [hasMarketingAccessCondition
                                            ? "In addition to "
                                            : "To unlock it, you must meet ", _jsx(Text, { span: true, size: "sm", weight: WEIGHT_BOLD, children: !unlockGateResult.type
                                                ? "all"
                                                : unlockGateResult.type === "all"
                                                    ? "all"
                                                    : "any" }), " ", "of the following requirements:"] }), _jsx(Space, { h: "sm" }), _jsx(GateConditionsList, { conditionResults: web3Conditions, showFirstN: hasMarketingAccessCondition ? showFirstN - 1 : showFirstN, creatorAddress: creatorAddress, creatorEns: creatorEns, creatorId: (_e = unlockGateResult.gateOwnerId) !== null && _e !== void 0 ? _e : "", refetchOnRequirementChange: refetchOnRequirementChange, isPreview: isPreview })] })), hiddenConditionCount > 0 && (_jsxs(_Fragment, { children: [_jsx(Space, { h: "lg" }), _jsxs(Button, { size: "sm", onClick: handleShowModal, color: SECONDARY_COLOR, children: ["+", hiddenConditionCount, " more requirements"] })] })), showConnectWallet && isPreview && (_jsxs(_Fragment, { children: [_jsx(Space, { h: "md" }), _jsx(Button, { children: "Connect Wallet" })] })), showConnectWallet && !isPreview && !authenticated && (_jsxs(_Fragment, { children: [_jsx(Space, { h: "md" }), _jsx(SignInButton, {})] }))] }))] }) }));
};
