import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgYoutube = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      opacity={0.12}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4531 5.04528C17.7112 5.31131 17.8944 5.64071 17.9844 6.00023C18.2247 7.33251 18.3414 8.68415 18.3329 10.0379C18.3377 11.3714 18.221 12.7026 17.9844 14.015C17.8944 14.3745 17.7112 14.7039 17.4531 14.9699C17.1951 15.2359 16.8714 15.4291 16.5148 15.53C15.2118 15.8785 9.99999 15.8785 9.99999 15.8785C9.99999 15.8785 4.78814 15.8785 3.48518 15.53C3.13582 15.4344 2.81702 15.2504 2.55958 14.9956C2.30214 14.7408 2.11476 14.4239 2.01556 14.0756C1.77524 12.7433 1.65859 11.3917 1.66709 10.0379C1.66045 8.69426 1.77709 7.35285 2.01556 6.03053C2.10554 5.67101 2.28881 5.34161 2.54685 5.07559C2.80489 4.80957 3.12856 4.61635 3.48518 4.51546C4.78814 4.16699 9.99999 4.16699 9.99999 4.16699C9.99999 4.16699 15.2118 4.16699 16.5148 4.48516C16.8714 4.58605 17.1951 4.77926 17.4531 5.04528ZM8.20811 7.1805C8.12499 7.29152 8.12499 7.49039 8.12499 7.88813V12.1126C8.12499 12.5103 8.12499 12.7092 8.20811 12.8202C8.28054 12.917 8.39142 12.9775 8.51197 12.9861C8.65031 12.996 8.81759 12.8884 9.15216 12.6734L12.4378 10.5611C12.7282 10.3745 12.8733 10.2812 12.9235 10.1625C12.9673 10.0588 12.9673 9.94185 12.9235 9.83817C12.8733 9.71952 12.7282 9.6262 12.4378 9.43956L9.15216 7.32734C8.81759 7.11226 8.65031 7.00472 8.51197 7.0146C8.39142 7.02321 8.28054 7.08374 8.20811 7.1805Z"
      fill="currentColor"
      stroke="black"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9844 6.00023C17.8944 5.64071 17.7112 5.31131 17.4531 5.04528C17.1951 4.77926 16.8714 4.58605 16.5148 4.48516C15.2118 4.16699 9.99999 4.16699 9.99999 4.16699C9.99999 4.16699 4.78814 4.16699 3.48518 4.51546C3.12856 4.61635 2.80489 4.80957 2.54685 5.07559C2.28881 5.34161 2.10554 5.67101 2.01556 6.03053C1.77709 7.35285 1.66045 8.69426 1.66709 10.0379C1.65859 11.3917 1.77524 12.7433 2.01556 14.0756C2.11476 14.4239 2.30214 14.7408 2.55958 14.9956C2.81702 15.2504 3.13582 15.4344 3.48518 15.53C4.78814 15.8785 9.99999 15.8785 9.99999 15.8785C9.99999 15.8785 15.2118 15.8785 16.5148 15.53C16.8714 15.4291 17.1951 15.2359 17.4531 14.9699C17.7112 14.7039 17.8944 14.3745 17.9844 14.015C18.221 12.7026 18.3377 11.3714 18.3329 10.0379C18.3414 8.68415 18.2247 7.33251 17.9844 6.00023Z"
      stroke="currentColor"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.12499 7.8881C8.12499 7.49037 8.12499 7.2915 8.20811 7.18047C8.28054 7.08372 8.39142 7.02319 8.51197 7.01458C8.65031 7.0047 8.81759 7.11224 9.15216 7.32732L12.4378 9.43954C12.7282 9.62618 12.8733 9.71949 12.9235 9.83815C12.9673 9.94183 12.9673 10.0588 12.9235 10.1625C12.8733 10.2812 12.7282 10.3745 12.4378 10.5611L9.15216 12.6733C8.81759 12.8884 8.65031 12.996 8.51197 12.9861C8.39142 12.9775 8.28054 12.9169 8.20811 12.8202C8.12499 12.7092 8.12499 12.5103 8.12499 12.1125V7.8881Z"
      stroke="currentColor"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgYoutube);
export default ForwardRef;
