import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgThreeDots = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.75 8C11.75 8.46413 11.9344 8.90925 12.2626 9.23744C12.5908 9.56563 13.0359 9.75 13.5 9.75C13.9641 9.75 14.4092 9.56563 14.7374 9.23744C15.0656 8.90925 15.25 8.46413 15.25 8C15.25 7.53587 15.0656 7.09075 14.7374 6.76256C14.4092 6.43437 13.9641 6.25 13.5 6.25C13.0359 6.25 12.5908 6.43437 12.2626 6.76256C11.9344 7.09075 11.75 7.53587 11.75 8Z"
      fill="black"
    />
    <path
      d="M6.25 8C6.25 8.46413 6.43437 8.90925 6.76256 9.23744C7.09075 9.56563 7.53587 9.75 8 9.75C8.46413 9.75 8.90925 9.56563 9.23744 9.23744C9.56563 8.90925 9.75 8.46413 9.75 8C9.75 7.53587 9.56563 7.09075 9.23744 6.76256C8.90925 6.43437 8.46413 6.25 8 6.25C7.53587 6.25 7.09075 6.43437 6.76256 6.76256C6.43437 7.09075 6.25 7.53587 6.25 8Z"
      fill="black"
    />
    <path
      d="M0.75 8C0.75 8.46413 0.934374 8.90925 1.26256 9.23744C1.59075 9.56563 2.03587 9.75 2.5 9.75C2.96413 9.75 3.40925 9.56563 3.73744 9.23744C4.06563 8.90925 4.25 8.46413 4.25 8C4.25 7.53587 4.06563 7.09075 3.73744 6.76256C3.40925 6.43437 2.96413 6.25 2.5 6.25C2.03587 6.25 1.59075 6.43437 1.26256 6.76256C0.934374 7.09075 0.75 7.53587 0.75 8H0.75Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgThreeDots);
export default ForwardRef;
