import { useCallback } from "react";

import { TransactionType } from "@hl/shared-features/lib/apollo/graphql.generated";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import {
  TransactionState,
  TransactionStateType,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { useOnChainTransaction } from "@hl/shared-features/lib/hooks/useOnChainTransaction";

import {
  GetRankedSponsorsByMintVectorDocument,
  useGetSponsorMintTxArgsLazyQuery,
} from "~features/MintPage/sponsor-mints/queries.graphql.generated";

import {
  _CollectionType,
  EvmSponsoredMintMetadata,
} from "../apollo/graphql.generated";

export const useSponsoredEvents = (
  collectionId: string,
  collectionType: _CollectionType,
  numToMint: number,
  sponsorVectorId: string,
  onDone: (amount: number) => void
) => {
  const { walletAddress } = useAuth();
  const [generateTxArgs, { loading: loadingArgs, error: errorArgs }] =
    useGetSponsorMintTxArgsLazyQuery();

  const { error, txnId, startTransaction, buttonLabel, buttonLoading } =
    useOnChainTransaction({
      collectionType,
      entityId: collectionId,
      onFinish: (state: TransactionState) => {
        if (state.type === TransactionStateType.Done) {
          setTimeout(() => onDone(numToMint), 500);
        }
      },
      refetchQueries: [GetRankedSponsorsByMintVectorDocument],
      transactionType: TransactionType.EVM_SPONSOR_MINT,
    });

  const sponsorMint = useCallback(async () => {
    const metadata: EvmSponsoredMintMetadata = {
      collectionId,
      sponsoredVectorId: sponsorVectorId,
    };
    const result = await generateTxArgs({
      variables: {
        numToMint,
        sponsorVectorId,
      },
    });
    const args = result?.data?.sponsorMintTxArgs;
    return startTransaction({ args, metadata });
  }, [generateTxArgs, collectionId, numToMint, walletAddress, sponsorVectorId]);

  return {
    txnId,
    error: error || (errorArgs ? "Error preparing transaction" : ""),
    sponsorMint,
    buttonLabel: buttonLabel,
    buttonLoading: buttonLoading || loadingArgs,
  };
};
