import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgArrowWithinCircleTilted = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3.42893 17.0711C4.82746 18.4696 6.60929 19.422 8.5491 19.8079C10.4889 20.1937 12.4996 19.9957 14.3268 19.2388C16.1541 18.4819 17.7159 17.2002 18.8147 15.5557C19.9135 13.9112 20.5 11.9778 20.5 10C20.5 8.02219 19.9135 6.08879 18.8147 4.4443C17.7159 2.79981 16.1541 1.51809 14.3268 0.76121C12.4996 0.0043335 10.4889 -0.193701 8.5491 0.192151C6.60929 0.578004 4.82746 1.53041 3.42893 2.92894C1.55357 4.8043 0.5 7.34784 0.5 10C0.5 12.6522 1.55357 15.1957 3.42893 17.0711ZM8.4376 5.92235L13.7409 5.92236C13.8506 5.9228 13.9591 5.94489 14.0602 5.98736C14.1613 6.02983 14.2531 6.09184 14.3302 6.16984C14.4841 6.32508 14.5709 6.53459 14.5718 6.75321L14.5718 12.0565C14.5725 12.2776 14.4854 12.49 14.3296 12.647C14.1738 12.8039 13.962 12.8925 13.7409 12.8932C13.5198 12.894 13.3074 12.8069 13.1505 12.6511C12.9935 12.4953 12.9049 12.2835 12.9042 12.0624V9.26933C12.9044 9.22841 12.8922 9.18839 12.869 9.15467C12.8458 9.12096 12.8128 9.09516 12.7745 9.08076C12.7367 9.06591 12.6953 9.06246 12.6555 9.07084C12.6157 9.07922 12.5793 9.09905 12.5506 9.12791L7.55372 14.1248C7.39744 14.2811 7.18548 14.3689 6.96447 14.3689C6.74345 14.3689 6.53149 14.2811 6.37521 14.1248C6.21893 13.9685 6.13113 13.7566 6.13113 13.5355C6.13113 13.3145 6.21893 13.1026 6.37521 12.9463L11.3721 7.94939C11.3988 7.91909 11.4165 7.88192 11.4233 7.84207C11.43 7.80223 11.4254 7.7613 11.4101 7.72392C11.3948 7.68653 11.3693 7.65417 11.3365 7.6305C11.3038 7.60683 11.2651 7.59278 11.2248 7.58995H8.43171C8.21156 7.58929 8.00061 7.50155 7.84494 7.34587C7.68926 7.1902 7.60152 6.97925 7.60086 6.7591C7.60041 6.64909 7.62175 6.54008 7.66364 6.43836C7.70553 6.33664 7.76714 6.24421 7.84493 6.16643C7.92272 6.08864 8.01514 6.02702 8.11686 5.98513C8.21859 5.94324 8.3276 5.9219 8.4376 5.92235Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgArrowWithinCircleTilted);
export default ForwardRef;
