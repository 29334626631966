import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPostEditorToken = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0.886069 6.72863L9.01464 10.5001C9.32402 10.6414 9.6602 10.7146 10.0004 10.7146C10.3405 10.7146 10.6767 10.6414 10.9861 10.5001L19.1146 6.72863C19.3869 6.60462 19.6163 6.40276 19.7739 6.14851C19.9315 5.89427 20.0103 5.59903 20.0004 5.30006C20.0123 5.00071 19.9343 4.70467 19.7764 4.45005C19.6185 4.19544 19.3881 3.99393 19.1146 3.87148L10.9861 0.214341C10.6767 0.0729499 10.3405 -0.000228882 10.0004 -0.000228882C9.6602 -0.000228882 9.32402 0.0729499 9.01464 0.214341L0.886069 3.98577C0.613837 4.10978 0.384434 4.31164 0.226802 4.56588C0.0691707 4.82013 -0.00962565 5.11536 0.000355048 5.41434C0.0105526 5.69426 0.0989238 5.96573 0.255461 6.19801C0.411998 6.43029 0.630447 6.6141 0.886069 6.72863Z"
      fill="black"
    />
    <path
      d="M0.600362 10.9571L8.94322 15.1143C9.27284 15.2737 9.63423 15.3564 10.0004 15.3564C10.3665 15.3564 10.7279 15.2737 11.0575 15.1143L19.4004 10.9571C19.527 10.8963 19.6402 10.8107 19.7333 10.7056C19.8265 10.6004 19.8978 10.4778 19.943 10.3448C19.9882 10.2118 20.0065 10.0711 19.9967 9.93095C19.9869 9.79081 19.9493 9.65402 19.8861 9.52857C19.8267 9.40062 19.742 9.28603 19.6371 9.19174C19.5322 9.09745 19.4093 9.02543 19.2757 8.98002C19.1422 8.93462 19.0008 8.91678 18.8602 8.92758C18.7195 8.93838 18.5826 8.9776 18.4575 9.04286L10.1004 13.1857C10.07 13.2033 10.0355 13.2125 10.0004 13.2125C9.96525 13.2125 9.93077 13.2033 9.90036 13.1857L1.54322 9.04286C1.41817 8.9776 1.28119 8.93838 1.14055 8.92758C0.999911 8.91678 0.858543 8.93462 0.724998 8.98002C0.591453 9.02543 0.468507 9.09745 0.363598 9.19174C0.258689 9.28603 0.173998 9.40062 0.114648 9.52857C0.0514046 9.65402 0.0137978 9.79081 0.00402565 9.93095C-0.00574653 10.0711 0.0125118 10.2118 0.0577337 10.3448C0.102956 10.4778 0.174234 10.6004 0.267403 10.7056C0.360572 10.8107 0.473764 10.8963 0.600362 10.9571Z"
      fill="black"
    />
    <path
      d="M18.4575 13.6857L10.1004 17.8286C10.07 17.8461 10.0355 17.8554 10.0004 17.8554C9.96525 17.8554 9.93077 17.8461 9.90036 17.8286L1.54322 13.6857C1.41817 13.6204 1.28119 13.5812 1.14055 13.5704C0.999911 13.5596 0.858543 13.5775 0.724998 13.6229C0.591453 13.6683 0.468507 13.7403 0.363598 13.8346C0.258689 13.9289 0.173998 14.0435 0.114648 14.1714C0.0514046 14.2969 0.0137978 14.4337 0.00402565 14.5738C-0.00574653 14.7139 0.0125118 14.8546 0.0577337 14.9876C0.102956 15.1206 0.174234 15.2433 0.267403 15.3484C0.360572 15.4536 0.473764 15.5391 0.600362 15.6L8.94322 19.7571C9.27284 19.9165 9.63423 19.9993 10.0004 19.9993C10.3665 19.9993 10.7279 19.9165 11.0575 19.7571L19.4004 15.6C19.527 15.5391 19.6402 15.4536 19.7333 15.3484C19.8265 15.2433 19.8978 15.1206 19.943 14.9876C19.9882 14.8546 20.0065 14.7139 19.9967 14.5738C19.9869 14.4337 19.9493 14.2969 19.8861 14.1714C19.8267 14.0435 19.742 13.9289 19.6371 13.8346C19.5322 13.7403 19.4093 13.6683 19.2757 13.6229C19.1422 13.5775 19.0008 13.5596 18.8602 13.5704C18.7195 13.5812 18.5826 13.6204 18.4575 13.6857Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPostEditorToken);
export default ForwardRef;
