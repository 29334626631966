import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgView = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={20}
    height={14}
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M19.525 5.76663C17.325 3.34163 13.6917 0.766632 10 0.833299C6.30835 0.899965 2.67501 3.34163 0.475012 5.76663C0.162301 6.10782 -0.0111694 6.55382 -0.0111694 7.01663C-0.0111694 7.47944 0.162301 7.92545 0.475012 8.26663C1.94168 9.84997 5.61668 13.1666 10 13.1666C14.3833 13.1666 18.05 9.8333 19.525 8.22497C19.8261 7.88631 19.9924 7.44893 19.9924 6.9958C19.9924 6.54267 19.8261 6.10529 19.525 5.76663ZM6.16668 6.99997C6.16668 6.2418 6.3915 5.50067 6.81271 4.87028C7.23392 4.23989 7.83261 3.74856 8.53306 3.45843C9.23351 3.16829 10.0043 3.09238 10.7479 3.24029C11.4915 3.3882 12.1745 3.75329 12.7106 4.28939C13.2467 4.82549 13.6118 5.50853 13.7597 6.25212C13.9076 6.99571 13.8317 7.76647 13.5415 8.46692C13.2514 9.16737 12.7601 9.76605 12.1297 10.1873C11.4993 10.6085 10.7582 10.8333 10 10.8333C8.98335 10.8333 8.00833 10.4294 7.28944 9.71054C6.57055 8.99165 6.16668 8.01663 6.16668 6.99997Z"
      fill="#0D0D0D"
    />
    <path
      d="M10 8.66659C10.9205 8.66659 11.6667 7.92039 11.6667 6.99992C11.6667 6.07944 10.9205 5.33325 10 5.33325C9.07954 5.33325 8.33334 6.07944 8.33334 6.99992C8.33334 7.92039 9.07954 8.66659 10 8.66659Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgView);
export default ForwardRef;
