import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPostEditorItalic = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.5716 0.857143C11.5716 0.629814 11.4813 0.411797 11.3205 0.251051C11.1598 0.0903059 10.9418 0 10.7144 0L4.28585 0C4.05853 0 3.84051 0.0903059 3.67976 0.251051C3.51902 0.411797 3.42871 0.629814 3.42871 0.857143C3.42871 1.08447 3.51902 1.30249 3.67976 1.46323C3.84051 1.62398 4.05853 1.71429 4.28585 1.71429H6.23157C6.26472 1.71492 6.29731 1.72294 6.32697 1.73777C6.35662 1.7526 6.3826 1.77386 6.403 1.8C6.4195 1.83023 6.42815 1.86413 6.42815 1.89857C6.42815 1.93302 6.4195 1.96691 6.403 1.99714L3.69442 10.14C3.68065 10.1785 3.65658 10.2124 3.62484 10.2381C3.59309 10.2638 3.5549 10.2803 3.51443 10.2857H1.28585C1.05853 10.2857 0.840508 10.376 0.679762 10.5368C0.519017 10.6975 0.428711 10.9155 0.428711 11.1429C0.428711 11.3702 0.519017 11.5882 0.679762 11.7489C0.840508 11.9097 1.05853 12 1.28585 12H7.71442C7.94175 12 8.15977 11.9097 8.32052 11.7489C8.48126 11.5882 8.57157 11.3702 8.57157 11.1429C8.57157 10.9155 8.48126 10.6975 8.32052 10.5368C8.15977 10.376 7.94175 10.2857 7.71442 10.2857H5.76871C5.73556 10.2851 5.70297 10.2771 5.67331 10.2622C5.64365 10.2474 5.61768 10.2261 5.59728 10.2C5.58078 10.1698 5.57213 10.1359 5.57213 10.1014C5.57213 10.067 5.58078 10.0331 5.59728 10.0029L8.30585 1.86C8.31962 1.82155 8.3437 1.78762 8.37544 1.76193C8.40718 1.73623 8.44538 1.71975 8.48585 1.71429H10.7144C10.9418 1.71429 11.1598 1.62398 11.3205 1.46323C11.4813 1.30249 11.5716 1.08447 11.5716 0.857143Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPostEditorItalic);
export default ForwardRef;
