import { memo } from "react";

import { Group, Skeleton } from "@mantine/core";

const SponsoredEventsSkeleton = () => {
  return (
    <Group spacing={8}>
      <Skeleton height={30} />
      <Skeleton height={30} />
      <Skeleton height={30} />
    </Group>
  );
};

export default memo(SponsoredEventsSkeleton);
