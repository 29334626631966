import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { logError } from "../../services/logger";
import { GateConditionsFailed } from "./GateConditionsFailed";
import { GateConditionsPassed } from "./GateConditionsPassed";
export const GateConditionsResult = ({ handleShowModal, // need this so long as modals aren't in shared-features
unlockGateResult, showFirstN = undefined, showConnectWallet = true, entity, headerSize, creatorAddress, creatorEns, refetchOnRequirementChange, multipleMintVectorEnableMintPage, isApollo, }) => {
    if (unlockGateResult) {
        if (unlockGateResult.passed) {
            return multipleMintVectorEnableMintPage ? null : (_jsx(GateConditionsPassed, { entity: entity, handleShowModal: handleShowModal }));
        }
        else
            return (_jsx(GateConditionsFailed, { entity: entity, unlockGateResult: unlockGateResult, handleShowModal: handleShowModal, showFirstN: showFirstN, showConnectWallet: showConnectWallet, shadow: "md", isApollo: isApollo, headerSize: headerSize, creatorAddress: creatorAddress, creatorEns: creatorEns, refetchOnRequirementChange: refetchOnRequirementChange }));
    }
    else {
        logError("could not render the checked gate conditions component");
        return null;
    }
};
