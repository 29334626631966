import { useCallback } from "react";

import { useTheme } from "@emotion/react";
import {
  BrandJustLogo,
  CheckCircle,
  CoinsStacked,
  HexagonFill,
  SpeechBubbleAnchor,
} from "@hl/base-components/lib/assets/icons.generated";
import { Sale02 } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/Finance & eCommerce";
import { OUTLINE_COLOR } from "@hl/base-components/lib/theme/button";
import {
  TEXT_COLOR,
  UTILITY_COLOR,
} from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { UnlockGateResult } from "@hl/shared-features/lib/apollo/graphql.generated";
import { User } from "@hl/shared-features/lib/features/auth/User";
import UserSkeleton from "@hl/shared-features/lib/features/auth/UserSkeleton";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import {
  Box,
  Card,
  Group,
  Paper,
  Stack,
  Text,
  Button,
  UnstyledButton,
} from "@mantine/core";
import { commify } from "ethers/lib/utils";

import { Collection } from "~features/MintPage";
import useMintState from "~hooks/useMintState";

const MintRequirementsNotice = ({
  handleShowModal,
  unlockGateResult,
}: {
  handleShowModal?: () => void;
  unlockGateResult?: UnlockGateResult | null;
}) => {
  const { collection, mintVector } = useMintState();
  const theme = useTheme();
  const { pushModal, popModal } = useModalStack();

  const collectorMessage = mintVector?.collectorMessage?.trim();
  const showDetails =
    !!mintVector &&
    (!!collectorMessage ||
      mintVector.maxPerUser > 0 ||
      mintVector.maxPerVector > 0);

  const saleName = mintVector?.name || "Default sale";

  const openGateDetailsModalHandler = useCallback(() => {
    pushModal(
      <GateDetailsModal
        collection={collection}
        collectorMessage={collectorMessage}
        maxPerVector={mintVector?.maxPerVector}
        maxPerUser={mintVector?.maxPerUser}
        handleShowModal={handleShowModal}
      />,
      {
        size: "sm",
        title: (
          <Group spacing={8}>
            <Sale02 />
            <Text fw={WEIGHT_BOLD} size="sm">
              {saleName}
            </Text>
          </Group>
        ),
        onClose: popModal,
      }
    );
  }, [saleName, collection, collectorMessage, mintVector]);

  //TODO lucass use isPublic field instead
  if (mintVector == null || !!mintVector.onchainMintVectorId) return <></>;
  if (!unlockGateResult?.passed) return <></>;
  if (!mintVector.gateId) return <></>;

  return (
    <Paper
      shadow="0"
      radius={0}
      p="0"
      sx={{ borderBottom: `1px solid ${theme.colors.divider[0]}` }}
    >
      <Stack spacing={0}>
        <Box
          sx={{
            padding: "14px 20px",
          }}
          bg={UTILITY_COLOR.TABLE_ROW_BACKGROUND}
        >
          <Group position="apart">
            <Group spacing={8}>
              {showDetails ? (
                <Sale02 width={16} height={16} />
              ) : (
                <CheckCircle width={16} height={16} />
              )}
              <Text size="sm" fw={WEIGHT_BOLD}>
                {saleName}
              </Text>
            </Group>
            <Button
              color={OUTLINE_COLOR}
              size="sm"
              onClick={
                showDetails ? openGateDetailsModalHandler : handleShowModal
              }
            >
              View {showDetails ? "details" : "requirements"}
            </Button>
          </Group>
        </Box>
      </Stack>
    </Paper>
  );
};

const AllocatedTokensText = ({ amount }: { amount: number }) => (
  <Group spacing={"sm"} align="center">
    <CoinsStacked height={16} width={16} />
    <Text
      size={"xs"}
      color={TEXT_COLOR.SECONDARY}
      sx={{ flexGrow: 1, flexShrink: 1, flex: 1 }}
    >
      {commify(amount)} tokens have been allocated for this sale
    </Text>
  </Group>
);

const LimitPerWalletText = ({ amount }: { amount: number }) => (
  <Group spacing={"sm"} align="center">
    <HexagonFill height={16} width={16} />
    <Text
      size={"xs"}
      color={TEXT_COLOR.SECONDARY}
      sx={{ flexGrow: 1, flexShrink: 1, flex: 1 }}
    >
      Each collector who qualifies for this sale can mint up to {amount} tokens
    </Text>
  </Group>
);

const SaleMessage = ({
  message,
  collection,
}: {
  message?: string;
  collection: Collection;
}) => {
  const theme = useTheme();

  return (
    <Stack spacing="xs">
      <Box sx={{ position: "relative", overflow: "visible" }}>
        <Card
          radius={10}
          shadow="0"
          withBorder={false}
          bg={UTILITY_COLOR.TABLE_ROW_BACKGROUND}
        >
          <Text size={"sm"}>{message}</Text>
        </Card>
        <SpeechBubbleAnchor
          color={theme.colors.tableRowBackground[0]}
          style={{
            position: "absolute",
            right: 32,
            bottom: -6,
          }}
        />
      </Box>
      <Group position="right">
        {/* TODO jj: check if creator reserves! */}
        {1234 ? (
          <UserProfile avatarSize={16} collection={collection} />
        ) : (
          <Group position="right" spacing={"xs"} align="center">
            <BrandJustLogo height={12} width={18} />
            <Text size={"sm"}>Highlight</Text>
          </Group>
        )}
      </Group>
    </Stack>
  );
};

const GateDetailsModal = ({
  collection,
  collectorMessage,
  maxPerVector,
  maxPerUser,
  handleShowModal,
}: {
  collection: Collection;
  collectorMessage?: string;
  maxPerVector?: number;
  maxPerUser?: number;
  handleShowModal?: () => void;
}) => (
  <>
    {collectorMessage && (
      <SaleMessage message={collectorMessage} collection={collection} />
    )}
    <Stack spacing={6} mt="sm">
      {!!maxPerVector && <AllocatedTokensText amount={maxPerVector} />}
      {!!maxPerUser && <LimitPerWalletText amount={maxPerUser} />}
      {handleShowModal && (
        <Group spacing={4}>
          <Group spacing={8}>
            <CheckCircle width={16} height={16} />
            <Text size="xs" component="span" color={TEXT_COLOR.SECONDARY}>
              Sale unlocked!
            </Text>
          </Group>{" "}
          <UnstyledButton
            onClick={handleShowModal}
            fw={WEIGHT_BOLD}
            color={TEXT_COLOR.SECONDARY}
          >
            <Text size="xs" fw={WEIGHT_BOLD} color={TEXT_COLOR.SECONDARY}>
              View requirements
            </Text>
          </UnstyledButton>
        </Group>
      )}
    </Stack>
  </>
);

const UserProfile = ({
  collection,
  avatarSize,
}: {
  collection: Collection;
  avatarSize?: number;
}) => {
  if (
    !collection ||
    !collection.creatorAddresses ||
    !collection.creatorAccountSettings
  )
    return <UserSkeleton />;

  return (
    <>
      <User
        avatarSize={avatarSize}
        avatarUrl={collection.creatorAccountSettings.displayAvatar}
        displayName={collection.creatorAccountSettings.displayName}
        walletAddresses={collection.creatorAccountSettings.walletAddresses}
        verified={!!collection.creatorAccountSettings.verified}
        imported={!!collection.creatorAccountSettings.imported}
        enableLink
      />
    </>
  );
};

export default MintRequirementsNotice;
