import * as React from "react";

import { useTheme } from "@emotion/react";
import { CoinsHand } from "@hl/base-components/lib/assets/icons.generated";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { ampli } from "@hl/shared-features/lib/ampli";
import CollectionActivity from "@hl/shared-features/lib/features/marketplace/activity/CollectionActivity";
import BidModalHighlight from "@hl/shared-features/lib/features/marketplace/modals/BidModal";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { Button, Group, Tabs, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { shloms404OnImageError } from "~features/MintPage/custom/Shloms404Carousel";
import { MarketplaceTokensApollo } from "~features/marketplace/MarketplaceTokensApollo";
import MarketplacePageSkeletonApollo from "~features/marketplace/skeleton/MarketplacePageSkeletonApollo";
import useMintState from "~hooks/useMintState";
import useMintTokensState from "~hooks/useMintTokensState";

import { _CollectionType } from "../../../apollo/graphql.generated";

const MintMarketplace = ({
  onTokensComponentMount,
}: {
  onTokensComponentMount: () => void;
}) => {
  const {
    collection,
    isMarketplaceEnabledForCollectionChain,
    isAnySeriesMint,
    isImported,
    collectionType,
  } = useMintState();
  const enableCollectionOffer =
    collectionType === _CollectionType.OpenEdition ||
    isImported ||
    isAnySeriesMint;
  const { browseCollection } = useMintTokensState();
  const collectionId = collection?.id;
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const { pushModal } = useModalStack();

  if (!browseCollection || !collectionId) {
    return <MarketplacePageSkeletonApollo />;
  }

  return (
    <Tabs defaultValue="artwork" keepMounted={false} mt={40}>
      <Tabs.List mb={isMobile ? 0 : 24}>
        <Tabs.Tab value="artwork" py="md">
          Artwork
        </Tabs.Tab>
        {isMarketplaceEnabledForCollectionChain && (
          <Tabs.Tab
            value="activity"
            py="md"
            onClick={() => {
              if (!ampli.isLoaded) {
                return;
              }
              ampli.viewActivity({
                projectName: collection.name,
              });
            }}
          >
            Activity
          </Tabs.Tab>
        )}
        {isMarketplaceEnabledForCollectionChain && enableCollectionOffer && (
          <Button
            px="md"
            ml="auto"
            my="xs"
            color={SECONDARY_COLOR}
            size={isMobile ? "xs" : "sm"}
            onClick={() =>
              pushModal(
                <BidModalHighlight
                  src={collection?.collectionImage || ""}
                  collectionData={collection}
                />,
                {
                  size: "lg",
                  title: (
                    <Text size="md" fw={WEIGHT_BOLD}>
                      Make an offer
                    </Text>
                  ),
                }
              )
            }
          >
            <Group spacing={8}>
              {!isMobile && <CoinsHand />}
              Make collection offer
            </Group>
          </Button>
        )}
      </Tabs.List>
      <Tabs.Panel value="artwork">
        <MarketplaceTokensApollo
          collectionId={collectionId}
          scrollBodyOnFilterChange
          onComponentMount={onTokensComponentMount}
        />
      </Tabs.Panel>
      <Tabs.Panel value="activity" pt="md">
        <CollectionActivity
          collection={collection}
          onImageError={
            collection.flagVariations.shloms404UI
              ? shloms404OnImageError
              : undefined
          }
        />
      </Tabs.Panel>
    </Tabs>
  );
};

export default MintMarketplace;
