import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgImageLandscape = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M16 2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0L2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V2ZM14.6667 14C14.6667 14.1768 14.5964 14.3464 14.4714 14.4714C14.3464 14.5964 14.1768 14.6667 14 14.6667H2C1.82319 14.6667 1.65362 14.5964 1.5286 14.4714C1.40357 14.3464 1.33333 14.1768 1.33333 14V2C1.33333 1.82319 1.40357 1.65362 1.5286 1.5286C1.65362 1.40357 1.82319 1.33333 2 1.33333H14C14.1768 1.33333 14.3464 1.40357 14.4714 1.5286C14.5964 1.65362 14.6667 1.82319 14.6667 2V14Z"
      fill="#0D0D0D"
    />
    <path
      d="M2.66699 5.33341C2.66699 5.68704 2.80747 6.02617 3.05752 6.27622C3.30756 6.52627 3.6467 6.66675 4.00033 6.66675H6.00033C6.53076 6.66675 7.03947 6.45603 7.41454 6.08096C7.78961 5.70589 8.00033 5.19718 8.00033 4.66675C8.00033 4.13631 7.78961 3.62761 7.41454 3.25253C7.03947 2.87746 6.53076 2.66675 6.00033 2.66675C5.60699 2.66762 5.22264 2.78446 4.89537 3.00264C4.56809 3.22083 4.31241 3.53067 4.16033 3.89341C4.14794 3.92554 4.12591 3.95305 4.09726 3.97215C4.06861 3.99125 4.03475 4.00101 4.00033 4.00008C3.73098 3.99709 3.46749 4.0787 3.24699 4.23341C3.06821 4.35584 2.92196 4.51999 2.8209 4.71166C2.71984 4.90332 2.66701 5.11673 2.66699 5.33341Z"
      fill="#0D0D0D"
    />
    <path
      d="M2.84645 13.48C2.78396 13.4797 2.72281 13.4619 2.66998 13.4285C2.61715 13.3951 2.57476 13.3476 2.54765 13.2913C2.52054 13.235 2.5098 13.1722 2.51666 13.1101C2.52352 13.048 2.54771 12.989 2.58645 12.94L4.44645 10.52C4.55277 10.3928 4.68353 10.2882 4.83099 10.2124C4.97845 10.1366 5.13961 10.0911 5.30495 10.0787C5.47028 10.0663 5.63643 10.0871 5.79357 10.14C5.9507 10.1929 6.09563 10.2768 6.21978 10.3867L7.45312 11.9C7.48655 11.9395 7.52854 11.9708 7.57591 11.9916C7.62328 12.0124 7.67476 12.0221 7.72645 12.02C7.77832 12.018 7.82897 12.0036 7.87418 11.9781C7.9194 11.9526 7.95788 11.9167 7.98645 11.8734L9.91312 9.04669C10.0068 8.88507 10.1426 8.75188 10.306 8.66131C10.4694 8.57073 10.6543 8.52614 10.8411 8.5323C11.0278 8.53846 11.2094 8.59512 11.3664 8.69626C11.5235 8.79741 11.6502 8.93925 11.7331 9.10669L13.6798 13C13.7079 13.0487 13.7227 13.1038 13.7227 13.16C13.7227 13.2162 13.7079 13.2714 13.6798 13.32C13.6509 13.3686 13.61 13.4089 13.5609 13.437C13.5118 13.465 13.4563 13.4799 13.3998 13.48H2.84645Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgImageLandscape);
export default ForwardRef;
