import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgArrowLeftDefault = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={11}
    height={16}
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.72994e-05 8.00011C9.73402e-05 7.53345 0.200097 7.13345 0.53343 6.86678L8.0001 0.266779C8.53343 -0.199888 9.33343 -0.13322 9.73343 0.400113C10.1334 0.933446 10.1334 1.66678 9.6001 2.13345L3.06676 7.86678C3.0001 7.93345 3.0001 8.00011 3.06676 8.13345L9.6001 13.8668C10.1334 14.3334 10.1334 15.1334 9.66676 15.6001C9.2001 16.0668 8.46676 16.1334 7.93343 15.7334L0.466764 9.20011C0.200098 8.86678 9.72586e-05 8.46678 9.72994e-05 8.00011Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgArrowLeftDefault);
export default ForwardRef;
