import React, { useEffect, useCallback } from "react";

import { SkeletonList } from "@hl/base-components/lib/Skeleton";
import {
  ArrowDown,
  ArrowUp,
} from "@hl/base-components/lib/assets/icons.generated";
import {
  OUTLINE_COLOR,
  SECONDARY_COLOR,
} from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import {
  Button,
  Stack,
  Text,
  Collapse,
  Group,
  useMantineTheme,
  Skeleton,
} from "@mantine/core";
import { useMediaQuery, useDisclosure } from "@mantine/hooks";

import useMintState from "~hooks/useMintState";

type ControlButton = {
  key: string;
  changesMintData: boolean;
  mobileLabel?: React.ReactNode;
  label: React.ReactNode;
};

type Control = {
  buttons: ControlButton[];
  isAdditional: boolean;
  description: string;
};

export const GenSeriesControls = () => {
  const theme = useMantineTheme();
  const {
    collection,
    genSeriesIframeElementRef,
    setMintData,
    setMintDataLoading,
    enableBurnAndRedeem,
    mintVector,
  } = useMintState();
  const [showAdditionalControl, { toggle: toggleAdditionalControls }] =
    useDisclosure(false);

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const iframeUrl =
    collection?.baseUri ??
    collection?.generativeDetails?.generativeCodeUri ??
    "";
  const iframeContent = genSeriesIframeElementRef?.current;
  const iframeWindow = iframeContent?.contentWindow;

  const controlsBatz: Control[] = [
    {
      description: "Space",
      isAdditional: false,
      buttons: [
        {
          label: "Randomize",
          mobileLabel: "RND",
          key: " ",
          changesMintData: true,
        },
      ],
    },
    {
      description: "/",
      isAdditional: false,
      buttons: [
        {
          label: "Min. size",
          mobileLabel: "MSZ",
          key: "/",
          changesMintData: true,
        },
      ],
    },
    {
      description: "c",
      isAdditional: false,
      buttons: [
        {
          label: "Color Palette",
          mobileLabel: "COLOR",
          key: "c",
          changesMintData: true,
        },
      ],
    },
    {
      description: "0-4 (Line Width)",
      isAdditional: false,
      buttons: [
        {
          label: "0",
          mobileLabel: "LW0",
          key: "0",
          changesMintData: true,
        },
        {
          label: "1",
          mobileLabel: "LW1",
          key: "1",
          changesMintData: true,
        },
        {
          label: "2",
          mobileLabel: "LW2",
          key: "2",
          changesMintData: true,
        },
        {
          label: "3",
          mobileLabel: "LW3",
          key: "3",
          changesMintData: true,
        },
        {
          label: "4",
          mobileLabel: "LW4",
          key: "4",
          changesMintData: true,
        },
      ],
    },
    {
      description: "Up",
      isAdditional: false,
      buttons: [
        {
          label: "Turbulence +",
          mobileLabel: "TRB+",
          key: "ArrowUp",
          changesMintData: true,
        },
      ],
    },
    {
      description: "Down",
      isAdditional: false,
      buttons: [
        {
          label: "Turbulence -",
          mobileLabel: "TRB-",
          key: "ArrowDown",
          changesMintData: true,
        },
      ],
    },
    {
      description: "o",
      isAdditional: false,
      buttons: [
        {
          label: "Enable fill",
          mobileLabel: "FILL",
          key: "o",
          changesMintData: true,
        },
      ],
    },
    {
      description: "q",
      isAdditional: false,
      buttons: [
        {
          label: "Geometric",
          mobileLabel: "GEO",
          key: "q",
          changesMintData: true,
        },
      ],
    },
    {
      description: "t",
      isAdditional: true,
      buttons: [
        {
          label: "Texture",
          mobileLabel: "TXTR",
          key: "t",
          changesMintData: true,
        },
      ],
    },
    {
      description: "l",
      isAdditional: true,
      buttons: [
        {
          label: "Stroke color",
          mobileLabel: "STRK",
          key: "l",
          changesMintData: true,
        },
      ],
    },
    {
      description: "d",
      isAdditional: true,
      buttons: [
        {
          label: "Duplicate count",
          mobileLabel: "DUPC",
          key: "d",
          changesMintData: true,
        },
      ],
    },
    {
      description: "b",
      isAdditional: true,
      buttons: [
        {
          label: "Background Color",
          mobileLabel: "BGC",
          key: "b",
          changesMintData: true,
        },
      ],
    },
    {
      description: "f",
      isAdditional: true,
      buttons: [
        {
          label: "Show frame",
          mobileLabel: "FRM",
          key: "f",
          changesMintData: true,
        },
      ],
    },
    {
      description: "Right",
      isAdditional: true,
      buttons: [
        {
          label: "Skew factor +",
          mobileLabel: "SKW+",
          key: "ArrowRight",
          changesMintData: true,
        },
      ],
    },
    {
      description: "Left",
      isAdditional: true,
      buttons: [
        {
          label: "Skew factor -",
          mobileLabel: "SKW-",
          key: "ArrowLeft",
          changesMintData: true,
        },
      ],
    },
    {
      description: "n",
      isAdditional: true,
      buttons: [
        {
          label: "Blur +",
          mobileLabel: "BLR+",
          key: "n",
          changesMintData: true,
        },
      ],
    },
    {
      description: "v",
      isAdditional: true,
      buttons: [
        {
          label: "Blur -",
          mobileLabel: "BLR-",
          key: "v",
          changesMintData: true,
        },
      ],
    },
    {
      description: "s",
      isAdditional: true,
      buttons: [
        {
          label: "Save",
          mobileLabel: "SAVE",
          key: "s",
          changesMintData: true,
        },
      ],
    },
  ];

  const controlsPiter: Control[] = [
    {
      description: "Space",
      isAdditional: false,
      buttons: [
        {
          label: "Randomize",
          mobileLabel: "RANDOM",
          key: " ",
          changesMintData: true,
        },
      ],
    },
    {
      description: "z",
      isAdditional: false,
      buttons: [
        {
          label: "4D perspective",
          mobileLabel: "4D",
          key: "z",
          changesMintData: true,
        },
      ],
    },
    {
      description: "x",
      isAdditional: false,
      buttons: [
        {
          label: "Omni-wobble",
          mobileLabel: "OMNI",
          key: "x",
          changesMintData: true,
        },
      ],
    },
    {
      description: "c",
      isAdditional: false,
      buttons: [
        {
          label: "Color palette",
          mobileLabel: "COLOR",
          key: "c",
          changesMintData: true,
        },
      ],
    },
    {
      description: "v",
      isAdditional: false,
      buttons: [
        {
          label: "Blur wobble",
          mobileLabel: "BLOR",
          key: "v",
          changesMintData: true,
        },
      ],
    },
    {
      description: "b",
      isAdditional: false,
      buttons: [
        {
          label: "Blur style",
          mobileLabel: "BLUR",
          key: "b",
          changesMintData: true,
        },
      ],
    },
    {
      description: "Left",
      isAdditional: false,
      buttons: [
        {
          label: "Undo",
          mobileLabel: "UNDO",
          key: "ArrowLeft",
          changesMintData: true,
        },
      ],
    },
    {
      description: "Right",
      isAdditional: false,
      buttons: [
        {
          label: "Redo",
          mobileLabel: "REDO",
          key: "ArrowRight",
          changesMintData: true,
        },
      ],
    },
    {
      description: "Enter",
      isAdditional: true,
      buttons: [
        {
          label: "Favorite",
          key: "Enter",
          changesMintData: false,
        },
      ],
    },
    {
      description: "Browse favorites",
      isAdditional: true,
      buttons: [
        {
          label: <ArrowUp width={16} height={16} />,
          key: "ArrowUp",
          changesMintData: true,
        },
        {
          label: <ArrowDown width={16} height={16} />,
          key: "ArrowDown",
          changesMintData: true,
        },
      ],
    },
    {
      description: "r",
      isAdditional: true,
      buttons: [
        {
          label: "Normal resolution",
          mobileLabel: "RES",
          key: "r",
          changesMintData: true,
        },
      ],
    },
    {
      description: "R",
      isAdditional: true,
      buttons: [
        {
          label: "Higher resolution",
          key: "R",
          changesMintData: true,
        },
      ],
    },
    {
      description: "a",
      isAdditional: true,
      buttons: [
        {
          label: "Aspect ratio",
          mobileLabel: "ASPECT",
          key: "a",
          changesMintData: true,
        },
      ],
    },
    {
      description: "h",
      isAdditional: true,
      buttons: [{ label: "Max iterations", key: "h", changesMintData: true }],
    },
    {
      description: "s",
      isAdditional: true,
      buttons: [{ label: "Save image", key: "s", changesMintData: false }],
    },
    {
      description: "j",
      isAdditional: true,
      buttons: [{ label: "More info", key: "j", changesMintData: false }],
    },
    {
      description: "i",
      isAdditional: true,
      buttons: [{ label: "Less info", key: "i", changesMintData: false }],
    },
  ];

  const controls = enableBurnAndRedeem ? controlsBatz : controlsPiter;
  const sendMessage = useCallback(
    ({ key, changesMintData }: ControlButton) => {
      setMintDataLoading(changesMintData);
      iframeWindow?.postMessage({ key }, iframeUrl);
    },
    [iframeWindow, iframeUrl]
  );

  useEffect(() => {
    const handleOnKeyDownMessage = (e: KeyboardEvent) => {
      const button = controls
        .flatMap((control) => control.buttons)
        .find((button) => button.key === e.key);
      if (
        !button ||
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      ) {
        return;
      }
      e.preventDefault();
      //iframeContent?.scrollIntoView({ behavior: "smooth", block: "center" });
      //genSeriesIframeRef?.current?.focus();
      sendMessage(button);
    };

    const handleMessageReceived = (e: MessageEvent<unknown>) => {
      console.log("Message received", e);
      if (
        !iframeUrl ||
        e.origin !== new URL(iframeUrl).origin ||
        e.source !== iframeWindow ||
        typeof e.data !== "string"
      ) {
        console.log("Message discarded", e);
        return;
      }
      console.log("Message processed", e);
      const seed = e.data.split(":")?.slice(1).join(":");
      setMintData(seed);
      setMintDataLoading(!seed);
    };

    window.addEventListener("message", handleMessageReceived);
    document.addEventListener("keydown", handleOnKeyDownMessage);

    return () => {
      window.removeEventListener("message", handleMessageReceived);
      document.removeEventListener("keydown", handleOnKeyDownMessage);
    };
  }, [iframeWindow, iframeUrl, sendMessage]);

  if (!collection) {
    return null;
  }
  const handleOnClickControl = (button: ControlButton) => () =>
    sendMessage(button);

  const basicControls = controls.filter(
    (control) =>
      (!isMobile && !control.isAdditional) ||
      (isMobile && control.buttons.some((button) => !!button.mobileLabel))
  );
  const additionalControls = controls.filter((control) => control.isAdditional);

  if (!mintVector) {
    return (
      <Stack
        spacing={8}
        align="center"
        mb={isMobile ? -24 : -48}
        mt={isMobile ? -12 : -24}
      >
        <Skeleton height={16.8} width={160} />
        <Skeleton height={16.8} width={500} />
        <Group px="md" py="sm">
          <SkeletonList
            count={10}
            skeleton={
              <Stack spacing={8}>
                <Skeleton height={28} width={100} />
                <Skeleton height={16.8} width={40} />
              </Stack>
            }
          />
        </Group>
      </Stack>
    );
  }

  return (
    <Stack
      spacing={8}
      align="center"
      mb={isMobile ? -24 : -48}
      mt={isMobile ? -12 : -24}
    >
      <Text size="xs" color={TEXT_COLOR.SECONDARY}>
        Collector generated outputs
      </Text>
      <Text size="xs" align="center">
        Choose to mint this iteration as is, or use the keyboard controls to
        adjust the parameters.
      </Text>

      <Stack
        spacing={20}
        px={isMobile ? 0 : 16}
        pt={12}
        pb={isMobile ? 0 : 12}
        align="center"
      >
        <Group spacing={16} align="center" position="center">
          <Text size="xs" fw={WEIGHT_BOLD}>
            PLAY →
          </Text>
          {basicControls.map((control) => (
            <Control
              key={control.description}
              {...control}
              onClick={handleOnClickControl}
            />
          ))}
          {!isMobile && (
            <Button
              mb={24}
              size="xs"
              color={OUTLINE_COLOR}
              onClick={toggleAdditionalControls}
            >
              {showAdditionalControl ? "Hide" : "View"} additional controls
            </Button>
          )}
        </Group>
        {isMobile ? (
          <Text size="xs" color={TEXT_COLOR.SECONDARY}>
            View on desktop to access additional controls
          </Text>
        ) : (
          <Collapse in={showAdditionalControl}>
            <Group spacing={16}>
              {additionalControls.map((control) => (
                <Control
                  key={control.description}
                  {...control}
                  onClick={handleOnClickControl}
                />
              ))}
            </Group>
          </Collapse>
        )}
      </Stack>
    </Stack>
  );
};

const Control = ({
  buttons,
  description,
  onClick,
}: Control & {
  onClick: (button: ControlButton) => () => void;
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  return (
    <Stack spacing={8} align="center">
      <Group spacing={8}>
        {buttons.map((button) => (
          <Button
            key={`${button.key}`}
            size="xs"
            color={SECONDARY_COLOR}
            onClick={onClick(button)}
          >
            {isMobile ? button.mobileLabel : button.label}
          </Button>
        ))}
      </Group>
      {!isMobile && (
        <Text size="xs" color={TEXT_COLOR.SECONDARY}>
          {description}
        </Text>
      )}
    </Stack>
  );
};
