import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { List, Paper, Space, Text } from "@mantine/core";

export const AuctionRulesCardApollo = ({
  customBufferMinutes,
}: {
  customBufferMinutes?: number;
}) => {
  const bufferPeriod = customBufferMinutes?.toString() ?? "5";

  return (
    <>
      <Paper shadow="0" radius="md" p="md" withBorder>
        <Text weight={WEIGHT_BOLD}>Details</Text>
        <Space h="sm" />
        <List spacing="sm" mr={15}>
          <List.Item>
            <Text size="sm">
              In the last {bufferPeriod} minutes of each auction, a new top bid
              will extend the auction time by {bufferPeriod} minutes.
            </Text>
          </List.Item>
          <List.Item>
            <Text size="sm">
              All bids must be at least 5% higher than the current highest bid.
            </Text>
          </List.Item>
        </List>
      </Paper>
    </>
  );
};
