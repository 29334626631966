import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgLink = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_3260_15680)">
      <path
        d="M8.33329 10.833C8.69116 11.3114 9.14775 11.7073 9.67208 11.9938C10.1964 12.2802 10.7762 12.4506 11.3722 12.4933C11.9681 12.5359 12.5663 12.45 13.1261 12.2411C13.6859 12.0323 14.1942 11.7055 14.6166 11.283L17.1166 8.78298C17.8756 7.99714 18.2956 6.94463 18.2861 5.85214C18.2766 4.75965 17.8384 3.7146 17.0659 2.94207C16.2933 2.16953 15.2483 1.73133 14.1558 1.72184C13.0633 1.71234 12.0108 2.13232 11.225 2.89131L9.79162 4.31631M11.6666 9.16631C11.3087 8.68787 10.8522 8.29199 10.3278 8.00553C9.8035 7.71907 9.2237 7.54872 8.62774 7.50603C8.03179 7.46335 7.43363 7.54934 6.87383 7.75816C6.31403 7.96698 5.80569 8.29375 5.38329 8.71631L2.88329 11.2163C2.12429 12.0022 1.70432 13.0547 1.71381 14.1472C1.7233 15.2396 2.16151 16.2847 2.93404 17.0572C3.70658 17.8298 4.75163 18.268 5.84411 18.2775C6.9366 18.2869 7.98911 17.867 8.77495 17.108L10.2 15.683"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3260_15680">
        <rect width={20} height={20} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgLink);
export default ForwardRef;
