import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgCode = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M14.1667 14.1667L18.3333 10L14.1667 5.83333M5.83332 5.83333L1.66666 10L5.83332 14.1667M11.6667 2.5L8.33332 17.5"
      stroke="currentColor"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCode);
export default ForwardRef;
