import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgMusicNote = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g opacity={0.12}>
      <path
        d="M5 17.4997C6.38071 17.4997 7.5 16.3804 7.5 14.9997C7.5 13.619 6.38071 12.4997 5 12.4997C3.61929 12.4997 2.5 13.619 2.5 14.9997C2.5 16.3804 3.61929 17.4997 5 17.4997Z"
        fill="currentColor"
      />
      <path
        d="M15 15.833C16.3807 15.833 17.5 14.7137 17.5 13.333C17.5 11.9523 16.3807 10.833 15 10.833C13.6193 10.833 12.5 11.9523 12.5 13.333C12.5 14.7137 13.6193 15.833 15 15.833Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M7.5 15.0003V5.29646C7.5 4.89518 7.5 4.69454 7.573 4.53181C7.63735 4.38836 7.74097 4.26605 7.87189 4.17899C8.0204 4.08023 8.21831 4.04724 8.61413 3.98127L15.9475 2.75905C16.4816 2.67003 16.7487 2.62551 16.9568 2.70282C17.1395 2.77067 17.2926 2.90037 17.3895 3.06938C17.5 3.26198 17.5 3.53273 17.5 4.07424V13.3336M7.5 15.0003C7.5 16.381 6.38071 17.5003 5 17.5003C3.61929 17.5003 2.5 16.381 2.5 15.0003C2.5 13.6196 3.61929 12.5003 5 12.5003C6.38071 12.5003 7.5 13.6196 7.5 15.0003ZM17.5 13.3336C17.5 14.7143 16.3807 15.8336 15 15.8336C13.6193 15.8336 12.5 14.7143 12.5 13.3336C12.5 11.9529 13.6193 10.8336 15 10.8336C16.3807 10.8336 17.5 11.9529 17.5 13.3336Z"
      stroke="currentColor"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgMusicNote);
export default ForwardRef;
