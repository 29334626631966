import * as React from "react";

import { reactTimeAgoStyle } from "@hl/shared-features/lib/utils/format";
import { Center, Divider, Group, Space, Text } from "@mantine/core";
import ReactTimeAgo from "react-time-ago";

import { ReactComponent as AnalogClock } from "~assets/icons/analog-clock.svg";
import useMintState, { MintStateRequired } from "~hooks/useMintState";

import { SaleStatusAuction } from "./types";

type AuctionCardDateProps = {
  showDivider?: boolean;
  smallSize?: boolean;
};

export const AuctionCardDate: React.FC<AuctionCardDateProps> = ({
  showDivider,
  smallSize,
}) => {
  const { auction, auctionStatus, refetchAuction } =
    useMintState() as MintStateRequired;

  if (
    [
      SaleStatusAuction.ACTIVE,
      SaleStatusAuction.ACTIVE_WITH_BID,
      SaleStatusAuction.NOT_STARTED,
    ].includes(auctionStatus)
  )
    return (
      <>
        <Group spacing={smallSize ? 5 : 8}>
          <Center>
            <AnalogClock
              height={smallSize ? 12 : 16}
              width={smallSize ? 12 : 16}
            />
          </Center>
          <Text size={smallSize ? 12 : 14}>
            Auction{" "}
            {SaleStatusAuction.NOT_STARTED === auctionStatus ? (
              <>
                starts{" "}
                {new Date().toLocaleDateString() ==
                new Date(Date.parse(auction.start)).toLocaleDateString()
                  ? ""
                  : "in"}{" "}
                <ReactTimeAgo
                  date={Date.parse(auction.start)}
                  timeStyle={reactTimeAgoStyle(refetchAuction)}
                  future={true}
                  locale="en-US"
                />
              </>
            ) : (
              <>
                ends in{" "}
                <ReactTimeAgo
                  date={Date.parse(auction.end!)}
                  future={true}
                  timeStyle={reactTimeAgoStyle(refetchAuction)}
                  locale="en-US"
                />
              </>
            )}
          </Text>
        </Group>

        {showDivider && (
          <>
            <Space h="sm" />
            <Divider mx="-md" />
            <Space h="sm" />
          </>
        )}
      </>
    );
  return null;
};
