import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgInformationIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={13}
    height={12}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6.5 0C5.31331 0 4.15327 0.351894 3.16658 1.01118C2.17988 1.67047 1.41085 2.60754 0.956722 3.7039C0.502596 4.80026 0.383776 6.00666 0.615287 7.17054C0.846798 8.33443 1.41824 9.40353 2.25736 10.2426C3.09647 11.0818 4.16557 11.6532 5.32946 11.8847C6.49334 12.1162 7.69974 11.9974 8.7961 11.5433C9.89245 11.0892 10.8295 10.3201 11.4888 9.33342C12.1481 8.34673 12.5 7.18669 12.5 6C12.5 4.4087 11.8679 2.88258 10.7426 1.75736C9.61742 0.632141 8.0913 0 6.5 0V0ZM6.625 2.5C6.77333 2.5 6.91834 2.54399 7.04168 2.6264C7.16501 2.70881 7.26114 2.82594 7.31791 2.96299C7.37467 3.10003 7.38953 3.25083 7.36059 3.39632C7.33165 3.5418 7.26022 3.67544 7.15533 3.78033C7.05044 3.88522 6.9168 3.95665 6.77132 3.98559C6.62583 4.01453 6.47503 3.99968 6.33799 3.94291C6.20094 3.88614 6.08381 3.79001 6.0014 3.66668C5.91898 3.54334 5.875 3.39834 5.875 3.25C5.875 3.05109 5.95402 2.86032 6.09467 2.71967C6.23532 2.57902 6.42609 2.5 6.625 2.5ZM7.75 9.25H5.75C5.61739 9.25 5.49021 9.19732 5.39644 9.10355C5.30268 9.00979 5.25 8.88261 5.25 8.75C5.25 8.61739 5.30268 8.49021 5.39644 8.39645C5.49021 8.30268 5.61739 8.25 5.75 8.25H6.125C6.15815 8.25 6.18994 8.23683 6.21339 8.21339C6.23683 8.18995 6.25 8.15815 6.25 8.125V5.875C6.25 5.84185 6.23683 5.81005 6.21339 5.78661C6.18994 5.76317 6.15815 5.75 6.125 5.75H5.75C5.61739 5.75 5.49021 5.69732 5.39644 5.60355C5.30268 5.50979 5.25 5.38261 5.25 5.25C5.25 5.11739 5.30268 4.99021 5.39644 4.89645C5.49021 4.80268 5.61739 4.75 5.75 4.75H6.25C6.51521 4.75 6.76957 4.85536 6.9571 5.04289C7.14464 5.23043 7.25 5.48478 7.25 5.75V8.125C7.25 8.15815 7.26317 8.18995 7.28661 8.21339C7.31005 8.23683 7.34185 8.25 7.375 8.25H7.75C7.88261 8.25 8.00978 8.30268 8.10355 8.39645C8.19732 8.49021 8.25 8.61739 8.25 8.75C8.25 8.88261 8.19732 9.00979 8.10355 9.10355C8.00978 9.19732 7.88261 9.25 7.75 9.25Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgInformationIcon);
export default ForwardRef;
