import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgExpandArrows = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0 15.3333C0 15.5101 0.0702379 15.6797 0.195262 15.8047C0.320286 15.9298 0.489856 16 0.666667 16H4C4.13162 15.9993 4.26009 15.9597 4.36924 15.8862C4.47839 15.8126 4.56332 15.7084 4.61333 15.5867C4.66439 15.4653 4.67833 15.3315 4.65342 15.2021C4.6285 15.0728 4.56584 14.9537 4.47333 14.86L3.51333 13.9C3.49739 13.885 3.48468 13.867 3.476 13.8469C3.46731 13.8268 3.46283 13.8052 3.46283 13.7833C3.46283 13.7615 3.46731 13.7398 3.476 13.7198C3.48468 13.6997 3.49739 13.6816 3.51333 13.6667L5.92 11.2533C6.07559 11.0977 6.16301 10.8867 6.16301 10.6667C6.16301 10.4466 6.07559 10.2356 5.92 10.08C5.76441 9.92441 5.55338 9.837 5.33333 9.837C5.11329 9.837 4.90226 9.92441 4.74667 10.08L2.33333 12.4867C2.31837 12.5026 2.3003 12.5153 2.28023 12.524C2.26017 12.5327 2.23853 12.5372 2.21667 12.5372C2.1948 12.5372 2.17317 12.5327 2.1531 12.524C2.13304 12.5153 2.11496 12.5026 2.1 12.4867L1.14 11.5267C1.04625 11.4342 0.927202 11.3715 0.797876 11.3466C0.668549 11.3217 0.534741 11.3356 0.413333 11.3867C0.291587 11.4367 0.18737 11.5216 0.113814 11.6308C0.0402586 11.7399 0.000656988 11.8684 0 12V15.3333Z"
      fill="black"
    />
    <path
      d="M16 0.666667C16 0.489856 15.9298 0.320286 15.8047 0.195262C15.6797 0.0702379 15.5101 0 15.3333 0H12C11.8684 0.000656988 11.7399 0.0402586 11.6308 0.113814C11.5216 0.18737 11.4367 0.291587 11.3867 0.413333C11.3356 0.534741 11.3217 0.668549 11.3466 0.797876C11.3715 0.927202 11.4342 1.04625 11.5267 1.14L12.4867 2.1C12.5026 2.11496 12.5153 2.13304 12.524 2.1531C12.5327 2.17317 12.5372 2.1948 12.5372 2.21667C12.5372 2.23853 12.5327 2.26017 12.524 2.28023C12.5153 2.3003 12.5026 2.31837 12.4867 2.33333L10.08 4.74667C10.0026 4.82351 9.94117 4.9149 9.89925 5.01559C9.85732 5.11628 9.83574 5.22427 9.83574 5.33333C9.83574 5.4424 9.85732 5.55039 9.89925 5.65108C9.94117 5.75176 10.0026 5.84316 10.08 5.92C10.1564 5.99821 10.2476 6.06036 10.3484 6.1028C10.4491 6.14523 10.5573 6.16709 10.6667 6.16709C10.776 6.16709 10.8842 6.14523 10.985 6.1028C11.0857 6.06036 11.177 5.99821 11.2533 5.92L13.6667 3.51333C13.6816 3.49739 13.6997 3.48468 13.7198 3.476C13.7398 3.46731 13.7615 3.46283 13.7833 3.46283C13.8052 3.46283 13.8268 3.46731 13.8469 3.476C13.867 3.48468 13.885 3.49739 13.9 3.51333L14.86 4.47333C14.9223 4.53512 14.9962 4.584 15.0774 4.61718C15.1586 4.65036 15.2456 4.66717 15.3333 4.66667C15.5101 4.66667 15.6797 4.59643 15.8047 4.4714C15.9298 4.34638 16 4.17681 16 4V0.666667Z"
      fill="black"
    />
    <path
      d="M0 4C0.000656988 4.13162 0.0402586 4.26009 0.113814 4.36924C0.18737 4.47839 0.291587 4.56332 0.413333 4.61333C0.493482 4.64749 0.579549 4.66561 0.666667 4.66667C0.754404 4.66717 0.84138 4.65036 0.922605 4.61718C1.00383 4.584 1.07771 4.53512 1.14 4.47333L2.21333 3.39333L4.74667 5.92C4.82305 5.99821 4.91429 6.06036 5.01504 6.1028C5.11579 6.14523 5.22401 6.16709 5.33333 6.16709C5.44266 6.16709 5.55087 6.14523 5.65162 6.1028C5.75237 6.06036 5.84362 5.99821 5.92 5.92C5.9974 5.84316 6.05883 5.75176 6.10075 5.65108C6.14268 5.55039 6.16426 5.4424 6.16426 5.33333C6.16426 5.22427 6.14268 5.11628 6.10075 5.01559C6.05883 4.9149 5.9974 4.82351 5.92 4.74667L3.51333 2.33333C3.49739 2.31837 3.48468 2.3003 3.476 2.28023C3.46731 2.26017 3.46283 2.23853 3.46283 2.21667C3.46283 2.1948 3.46731 2.17317 3.476 2.1531C3.48468 2.13304 3.49739 2.11496 3.51333 2.1L4.47333 1.14C4.56584 1.04625 4.6285 0.927202 4.65342 0.797876C4.67833 0.668549 4.66439 0.534741 4.61333 0.413333C4.56332 0.291587 4.47839 0.18737 4.36924 0.113814C4.2601 0.0402586 4.13162 0.000656988 4 0H0.666667C0.489856 0 0.320286 0.0702379 0.195262 0.195262C0.0702379 0.320286 0 0.489856 0 0.666667V4Z"
      fill="black"
    />
    <path
      d="M16 12C15.9993 11.8684 15.9597 11.7399 15.8862 11.6308C15.8126 11.5216 15.7084 11.4367 15.5867 11.3867C15.4653 11.3356 15.3315 11.3217 15.2021 11.3466C15.0728 11.3715 14.9537 11.4342 14.86 11.5267L13.9 12.4867C13.885 12.5026 13.867 12.5153 13.8469 12.524C13.8268 12.5327 13.8052 12.5372 13.7833 12.5372C13.7615 12.5372 13.7398 12.5327 13.7198 12.524C13.6997 12.5153 13.6816 12.5026 13.6667 12.4867L11.2533 10.08C11.0977 9.92441 10.8867 9.837 10.6667 9.837C10.4466 9.837 10.2356 9.92441 10.08 10.08C9.92441 10.2356 9.837 10.4466 9.837 10.6667C9.837 10.8867 9.92441 11.0977 10.08 11.2533L12.4867 13.6667C12.5026 13.6816 12.5153 13.6997 12.524 13.7198C12.5327 13.7398 12.5372 13.7615 12.5372 13.7833C12.5372 13.8052 12.5327 13.8268 12.524 13.8469C12.5153 13.867 12.5026 13.885 12.4867 13.9L11.5267 14.86C11.4342 14.9537 11.3715 15.0728 11.3466 15.2021C11.3217 15.3315 11.3356 15.4653 11.3867 15.5867C11.4367 15.7084 11.5216 15.8126 11.6308 15.8862C11.7399 15.9597 11.8684 15.9993 12 16H15.3333C15.5101 16 15.6797 15.9298 15.8047 15.8047C15.9298 15.6797 16 15.5101 16 15.3333V12Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgExpandArrows);
export default ForwardRef;
