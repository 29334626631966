import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPostEditorLink = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.75204 9.24468C5.61748 9.2575 5.48172 9.24287 5.35299 9.20167C5.22426 9.16048 5.10523 9.09358 5.00311 9.00502C4.90175 8.91151 4.82086 8.79803 4.76553 8.67171C4.7102 8.54539 4.68164 8.40898 4.68164 8.27108C4.68164 8.13317 4.7102 7.99676 4.76553 7.87045C4.82086 7.74413 4.90175 7.63064 5.00311 7.53713L7.53947 5.01076C7.73015 4.81743 7.98982 4.70777 8.26136 4.7059C8.5329 4.70402 8.79405 4.8101 8.98738 5.00078C9.18072 5.19146 9.29038 5.45113 9.29225 5.72267C9.29412 5.99421 9.18805 6.25537 8.99737 6.4487L6.46102 9.00502C6.26469 9.17379 6.01049 9.25972 5.75204 9.24468V9.24468Z"
      fill="#0D0D0D"
    />
    <path
      d="M10.9945 9.00658C10.8631 9.00734 10.7328 8.98215 10.6111 8.93246C10.4895 8.88277 10.3788 8.80955 10.2855 8.717C10.1919 8.62417 10.1176 8.51373 10.0669 8.39204C10.0162 8.27036 9.99014 8.13984 9.99014 8.00802C9.99014 7.8762 10.0162 7.74568 10.0669 7.62399C10.1176 7.50231 10.1919 7.39187 10.2855 7.29904L11.7035 5.88108C11.7971 5.78825 11.8714 5.6778 11.9221 5.55612C11.9728 5.43443 11.9989 5.30392 11.9989 5.17209C11.9989 5.04027 11.9728 4.90975 11.9221 4.78807C11.8714 4.66639 11.7971 4.55594 11.7035 4.46311L9.53659 2.28624C9.44376 2.19265 9.33332 2.11836 9.21164 2.06766C9.08995 2.01697 8.95943 1.99087 8.82761 1.99087C8.69579 1.99087 8.56527 2.01697 8.44359 2.06766C8.3219 2.11836 8.21146 2.19265 8.11863 2.28624L6.71066 3.7042C6.61755 3.79731 6.50702 3.87116 6.38537 3.92155C6.26372 3.97194 6.13334 3.99787 6.00167 3.99787C5.87 3.99787 5.73962 3.97194 5.61798 3.92155C5.49633 3.87116 5.3858 3.79731 5.29269 3.7042C5.10466 3.51617 4.99902 3.26114 4.99902 2.99522C4.99902 2.86355 5.02496 2.73317 5.07535 2.61153C5.12573 2.48988 5.19959 2.37935 5.29269 2.28624L6.71066 0.86828C7.28456 0.311325 8.05284 -0.000183105 8.85258 -0.000183105C9.65231 -0.000183105 10.4206 0.311325 10.9945 0.86828L13.1115 3.01519C13.3907 3.2935 13.6123 3.62421 13.7635 3.98834C13.9147 4.35247 13.9926 4.74287 13.9926 5.13714C13.9926 5.53142 13.9147 5.92182 13.7635 6.28595C13.6123 6.65008 13.3907 6.98078 13.1115 7.25909L11.7035 8.70701C11.611 8.80137 11.5007 8.87643 11.379 8.92785C11.2573 8.97927 11.1266 9.00603 10.9945 9.00658V9.00658Z"
      fill="#0D0D0D"
    />
    <path
      d="M5.17275 14.0005C4.37851 13.9998 3.61706 13.6837 3.0558 13.1217L0.888912 10.9449C0.609633 10.6666 0.388041 10.3359 0.236842 9.97173C0.0856435 9.6076 0.0078125 9.2172 0.0078125 8.82293C0.0078125 8.42865 0.0856435 8.03826 0.236842 7.67412C0.388041 7.30999 0.609633 6.97929 0.888912 6.70098L2.29689 5.293C2.4836 5.10497 2.73736 4.99881 3.00234 4.99787C3.26732 4.99693 3.52182 5.1013 3.70986 5.28801C3.89789 5.47472 4.00405 5.72848 4.00499 5.99346C4.00593 6.25844 3.90156 6.51294 3.71485 6.70098L2.29689 8.11894C2.20329 8.21177 2.12901 8.32221 2.07831 8.4439C2.02761 8.56558 2.00151 8.6961 2.00151 8.82792C2.00151 8.95974 2.02761 9.09026 2.07831 9.21195C2.12901 9.33363 2.20329 9.44407 2.29689 9.5369L4.46377 11.7038C4.5566 11.7974 4.66705 11.8717 4.78873 11.9224C4.91041 11.9731 5.04093 11.9992 5.17275 11.9992C5.30458 11.9992 5.43509 11.9731 5.55678 11.9224C5.67846 11.8717 5.78891 11.7974 5.88174 11.7038L7.28971 10.2958C7.47642 10.1078 7.73018 10.0016 7.99516 10.0007C8.26015 9.99974 8.51465 10.1041 8.70268 10.2908C8.89071 10.4775 8.99688 10.7313 8.99781 10.9963C8.99875 11.2613 8.89438 11.5158 8.70767 11.7038L7.28971 13.1217C6.72845 13.6837 5.96699 13.9998 5.17275 14.0005V14.0005Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPostEditorLink);
export default ForwardRef;
