import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgTrash = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_432_17511)">
      <path
        d="M13.0003 5H3.00033C2.91192 5 2.82714 5.03512 2.76462 5.09763C2.70211 5.16014 2.66699 5.24493 2.66699 5.33333V14.6667C2.66699 15.0203 2.80747 15.3594 3.05752 15.6095C3.30756 15.8595 3.6467 16 4.00033 16H12.0003C12.3539 16 12.6931 15.8595 12.9431 15.6095C13.1932 15.3594 13.3337 15.0203 13.3337 14.6667V5.33333C13.3337 5.24493 13.2985 5.16014 13.236 5.09763C13.1735 5.03512 13.0887 5 13.0003 5ZM6.83366 13.6667C6.83366 13.7993 6.78098 13.9265 6.68721 14.0202C6.59344 14.114 6.46627 14.1667 6.33366 14.1667C6.20105 14.1667 6.07387 14.114 5.98011 14.0202C5.88634 13.9265 5.83366 13.7993 5.83366 13.6667V7.66667C5.83366 7.53406 5.88634 7.40688 5.98011 7.31311C6.07387 7.21935 6.20105 7.16667 6.33366 7.16667C6.46627 7.16667 6.59344 7.21935 6.68721 7.31311C6.78098 7.40688 6.83366 7.53406 6.83366 7.66667V13.6667ZM10.167 13.6667C10.167 13.7993 10.1143 13.9265 10.0205 14.0202C9.92678 14.114 9.7996 14.1667 9.66699 14.1667C9.53438 14.1667 9.40721 14.114 9.31344 14.0202C9.21967 13.9265 9.16699 13.7993 9.16699 13.6667V7.66667C9.16699 7.53406 9.21967 7.40688 9.31344 7.31311C9.40721 7.21935 9.53438 7.16667 9.66699 7.16667C9.7996 7.16667 9.92678 7.21935 10.0205 7.31311C10.1143 7.40688 10.167 7.53406 10.167 7.66667V13.6667Z"
        fill="#CC0F0F"
      />
      <path
        d="M14.667 2.66667H11.5003C11.4561 2.66667 11.4137 2.64911 11.3825 2.61785C11.3512 2.5866 11.3337 2.5442 11.3337 2.5V1.66667C11.3337 1.22464 11.1581 0.800716 10.8455 0.488155C10.5329 0.175595 10.109 0 9.66699 0L6.33366 0C5.89163 0 5.46771 0.175595 5.15515 0.488155C4.84259 0.800716 4.66699 1.22464 4.66699 1.66667V2.5C4.66699 2.5442 4.64943 2.5866 4.61818 2.61785C4.58692 2.64911 4.54453 2.66667 4.50033 2.66667H1.33366C1.15685 2.66667 0.987279 2.7369 0.862254 2.86193C0.73723 2.98695 0.666992 3.15652 0.666992 3.33333C0.666992 3.51014 0.73723 3.67971 0.862254 3.80474C0.987279 3.92976 1.15685 4 1.33366 4H14.667C14.8438 4 15.0134 3.92976 15.1384 3.80474C15.2634 3.67971 15.3337 3.51014 15.3337 3.33333C15.3337 3.15652 15.2634 2.98695 15.1384 2.86193C15.0134 2.7369 14.8438 2.66667 14.667 2.66667ZM6.00033 2.5V1.66667C6.00033 1.57826 6.03544 1.49348 6.09796 1.43096C6.16047 1.36845 6.24525 1.33333 6.33366 1.33333H9.66699C9.7554 1.33333 9.84018 1.36845 9.90269 1.43096C9.96521 1.49348 10.0003 1.57826 10.0003 1.66667V2.5C10.0003 2.5442 9.98277 2.5866 9.95151 2.61785C9.92025 2.64911 9.87786 2.66667 9.83366 2.66667H6.16699C6.12279 2.66667 6.0804 2.64911 6.04914 2.61785C6.01788 2.5866 6.00033 2.5442 6.00033 2.5Z"
        fill="#CC0F0F"
      />
    </g>
    <defs>
      <clipPath id="clip0_432_17511">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgTrash);
export default ForwardRef;
