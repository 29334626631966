import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgError = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 42 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M39.5432 33.8L23.8289 2.37146C23.4721 1.66203 22.9251 1.06572 22.249 0.649041C21.573 0.232365 20.7945 0.0117188 20.0004 0.0117188C19.2062 0.0117188 18.4277 0.232365 17.7517 0.649041C17.0756 1.06572 16.5287 1.66203 16.1718 2.37146L0.457505 33.8C0.12541 34.4515 -0.033271 35.1776 -0.00324503 35.9082C0.026781 36.6388 0.244502 37.3494 0.628933 37.9715C1.01851 38.5901 1.55794 39.1003 2.19724 39.4549C2.83654 39.8096 3.55502 39.997 4.28608 40H35.7146C36.4457 39.997 37.1642 39.8096 37.8035 39.4549C38.4428 39.1003 38.9822 38.5901 39.3718 37.9715C39.7562 37.3494 39.9739 36.6388 40.004 35.9082C40.034 35.1776 39.8753 34.4515 39.5432 33.8ZM17.8575 14.2857C17.8575 13.7174 18.0833 13.1724 18.4851 12.7705C18.887 12.3686 19.432 12.1429 20.0004 12.1429C20.5687 12.1429 21.1137 12.3686 21.5156 12.7705C21.9175 13.1724 22.1432 13.7174 22.1432 14.2857V22.8572C22.1432 23.4255 21.9175 23.9705 21.5156 24.3724C21.1137 24.7743 20.5687 25 20.0004 25C19.432 25 18.887 24.7743 18.4851 24.3724C18.0833 23.9705 17.8575 23.4255 17.8575 22.8572V14.2857ZM20.0004 34.2857C19.4353 34.2857 18.8829 34.1182 18.413 33.8042C17.9432 33.4903 17.577 33.0441 17.3607 32.522C17.1445 31.9999 17.0879 31.4254 17.1981 30.8712C17.3084 30.317 17.5805 29.8079 17.9801 29.4083C18.3796 29.0087 18.8887 28.7366 19.443 28.6264C19.9972 28.5161 20.5717 28.5727 21.0937 28.7889C21.6158 29.0052 22.062 29.3714 22.376 29.8413C22.6899 30.3111 22.8575 30.8635 22.8575 31.4286C22.8575 32.1864 22.5565 32.9131 22.0207 33.4489C21.4848 33.9847 20.7581 34.2857 20.0004 34.2857Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgError);
export default ForwardRef;
