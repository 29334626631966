import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPolygonMaticLogo = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={32}
    height={28}
    viewBox="0 0 32 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M24.1667 8.5C23.5833 8.16667 22.8333 8.16667 22.1667 8.5L17.5 11.25L14.3333 13L9.75 15.75C9.16667 16.0833 8.41667 16.0833 7.75 15.75L4.16667 13.5833C3.58333 13.25 3.16667 12.5833 3.16667 11.8333V7.66666C3.16667 7 3.5 6.33333 4.16667 5.91666L7.75 3.83333C8.33333 3.5 9.08333 3.5 9.75 3.83333L13.3333 6C13.9167 6.33333 14.3333 7 14.3333 7.75V10.5L17.5 8.66666V5.83333C17.5 5.16666 17.1667 4.5 16.5 4.08333L9.83333 0.166664C9.25 -0.166669 8.5 -0.166669 7.83333 0.166664L1 4.16666C0.333333 4.5 0 5.16666 0 5.83333V13.6667C0 14.3333 0.333333 15 1 15.4167L7.75 19.3333C8.33333 19.6667 9.08333 19.6667 9.75 19.3333L14.3333 16.6667L17.5 14.8333L22.0833 12.1667C22.6667 11.8333 23.4167 11.8333 24.0833 12.1667L27.6667 14.25C28.25 14.5833 28.6667 15.25 28.6667 16V20.1667C28.6667 20.8333 28.3333 21.5 27.6667 21.9167L24.1667 24C23.5833 24.3333 22.8333 24.3333 22.1667 24L18.5833 21.9167C18 21.5833 17.5833 20.9167 17.5833 20.1667V17.5L14.4167 19.3333V22.0833C14.4167 22.75 14.75 23.4167 15.4167 23.8333L22.1667 27.75C22.75 28.0833 23.5 28.0833 24.1667 27.75L30.9167 23.8333C31.5 23.5 31.9167 22.8333 31.9167 22.0833V14.1667C31.9167 13.5 31.5833 12.8333 30.9167 12.4167L24.1667 8.5Z"
      fill="#8247E5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPolygonMaticLogo);
export default ForwardRef;
