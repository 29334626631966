import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgSearch = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M19.4833 18.5167L14.0416 13.075C15.0697 11.7398 15.6264 10.1017 15.625 8.41667C15.6287 6.67361 15.0428 4.98053 13.9625 3.61258C12.8823 2.24464 11.3711 1.28224 9.67467 0.881806C7.97823 0.481369 6.19623 0.666432 4.61827 1.40692C3.04032 2.1474 1.75917 3.39978 0.983045 4.96051C0.206919 6.52125 -0.0185613 8.29859 0.343249 10.0037C0.70506 11.7088 1.63289 13.2414 2.97596 14.3525C4.31902 15.4635 5.99836 16.0877 7.74105 16.1236C9.48374 16.1594 11.1873 15.6049 12.575 14.55L18.0166 19.9917C18.2128 20.1831 18.4759 20.2902 18.75 20.2902C19.024 20.2902 19.2872 20.1831 19.4833 19.9917C19.5803 19.8949 19.6573 19.78 19.7098 19.6534C19.7623 19.5269 19.7893 19.3912 19.7893 19.2542C19.7893 19.1172 19.7623 18.9815 19.7098 18.8549C19.6573 18.7284 19.5803 18.6134 19.4833 18.5167ZM2.29165 8.41667C2.29165 7.30415 2.62155 6.21661 3.23963 5.29159C3.85771 4.36656 4.73622 3.64559 5.76405 3.21985C6.79189 2.7941 7.92288 2.68271 9.01403 2.89975C10.1052 3.11679 11.1075 3.65252 11.8941 4.43919C12.6808 5.22586 13.2165 6.22814 13.4336 7.31929C13.6506 8.41043 13.5392 9.54143 13.1135 10.5693C12.6877 11.5971 11.9668 12.4756 11.0417 13.0937C10.1167 13.7118 9.02917 14.0417 7.91665 14.0417C6.42548 14.0395 4.99602 13.4461 3.94161 12.3917C2.88719 11.3373 2.29385 9.90784 2.29165 8.41667Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgSearch);
export default ForwardRef;
