import { useCallback, useRef, useState } from "react";
import * as React from "react";

import {
  Expand as ExpandIcon,
  Pause as PauseIcon,
  Play as PlayIcon,
  Screen as FullScreenIcon,
} from "@hl/base-components/lib/assets/icons.generated";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { Video } from "@hl/base-components/lib/video";
import { PageSupportedMediaType } from "@hl/shared-features/lib/apollo/graphql.generated";
import { GenArtUrlIFrame } from "@hl/shared-features/lib/features/gen-art/GenArtIFrame";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import {
  ActionIcon,
  Box,
  Center,
  Grid,
  Group,
  Stack,
  Tooltip,
} from "@mantine/core";
import { useDisclosure, useFullscreen } from "@mantine/hooks";
import WaveSurfer from "wavesurfer.js";

import { ImageFullscreenModal } from "~components/ImageFullscreenModal";
import { useToolbarStyles } from "~features/MintPage/apollo/GenArtMintedApollo";
import TokenDetailsCardSection from "~features/token-details/TokenDetailsCardSection";
import useMintState from "~hooks/useMintState";
import useMintTokensState from "~hooks/useMintTokensState";

import { _CollectionType } from "../../../apollo/graphql.generated";
import { useMediaStyles } from "../styles";

export const EditionHeaderApollo = () => {
  const {
    collection,
    tokens,
    animationUrl,
    contentTypeInfo,
    collectionType,
    isSingleContractEdition,
  } = useMintState();
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
  const [isExpanded, { open: expandImage, close: closeImageExpanded }] =
    useDisclosure(false);
  const { tokens: mintTokens, refetch } = useMintTokensState();
  const token = mintTokens?.[0];
  const isOneOfOne = collectionType === _CollectionType.OneOfOne;
  const hasMarketplaceButtons = token && isOneOfOne;

  const { classes } = useToolbarStyles({
    isExpanded,
    isHover: false,
    showToolbarOnHover: false,
    responsive: false,
    hasMarketplaceButtons,
    isMultiContractEdition: !isSingleContractEdition,
  });
  const { classes: classesMedia } = useMediaStyles({ hasControls: false });

  const { isMedium } = useMantineMediaQueries();
  // When the video player loads, it resizes to the src video size. If it then errors
  // it jumps back to the size set in the CSS. This causes a flicker. To prevent
  // this, we keep the video player hidden (with "max-height" and "overflow" -
  // since "display" causes a remount which causes an infinite loop of events)
  // until it can play.
  // At 3 errors we stop trying to load the video, by removing it's source.
  const [videoPlayerErrorCount, setVideoPlayerErrorCount] = useState(0);
  const [isVideoPlayerLoading, setIsVideoPlayerLoading] = useState(true);
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const waveformRef = useRef<any>(null);
  const iconSize = { width: 16, height: 16 };
  const { ref: resourceRef, toggle: toggleFullscreen } = useFullscreen();
  const imageUrl = tokens?.[0]?.image;
  const upscaleImage = collection?.flagVariations.upscaleMintPageImage;
  const onAudioButtonClick = () => {
    if (audioPlaying) {
      waveformRef.current.pause();
    } else {
      waveformRef.current.play();
    }

    setAudioPlaying(!audioPlaying);
  };

  const setWaveformRef = useCallback(() => {
    if (
      animationUrl &&
      contentTypeInfo?.type === PageSupportedMediaType.AUDIO &&
      !waveformRef.current
    ) {
      waveformRef.current = WaveSurfer.create({
        container: "#waveform",
        height: 280,
        fillParent: true,
        barWidth: 3,
        hideScrollbar: true,
      });

      waveformRef.current.load(animationUrl);
    }
  }, [animationUrl, contentTypeInfo]);

  if (isExpanded) {
    return (
      <ImageFullscreenModal
        imageUrl={tokens[0].image ?? ""}
        close={closeImageExpanded}
        shloms404UI={collection?.flagVariations.shloms404UI}
      />
    );
  }

  const hasToolbar =
    (animationUrl || imageUrl) &&
    contentTypeInfo?.type !== PageSupportedMediaType.AUDIO;

  return (
    <Center h="100%">
      <Stack align="center" mah="100%" h="100%" justify="center">
        {animationUrl ? (
          <>
            {contentTypeInfo?.type === PageSupportedMediaType.VIDEO && (
              <>
                {isVideoPlayerLoading && (
                  <Box className={classesMedia.videoLoading} />
                )}
                <Video
                  className={classesMedia.video}
                  autoPlay
                  loop
                  muted
                  playsInline
                  onCanPlay={() => {
                    setIsVideoPlayerLoading(false);
                  }}
                  onError={() => {
                    setVideoPlayerErrorCount((count) => count + 1);
                  }}
                  containerStyles={{
                    maxHeight: isVideoPlayerLoading ? 0 : "auto",
                    overflow: "hidden",
                  }}
                >
                  {videoPlayerErrorCount < 3 && (
                    <source
                      ref={resourceRef}
                      src={animationUrl}
                      type={`${contentTypeInfo.type.toLowerCase()}/${contentTypeInfo.mime?.toLowerCase()}`}
                    />
                  )}
                </Video>
              </>
            )}
            {contentTypeInfo?.type === PageSupportedMediaType.AUDIO && (
              <div className={classesMedia.audio}>
                <Grid>
                  <Grid.Col span={3} style={{ position: "relative" }}>
                    <ResizedImage
                      src={tokens[0].image ?? ""}
                      fit="scale-down"
                      height={280}
                      width={280}
                      imageRef={resourceRef}
                      style={{
                        position: "absolute",
                        filter: "blur(0)",
                        zIndex: 1,
                        marginRight: 100,
                      }}
                    />
                    <button
                      onClick={onAudioButtonClick}
                      color="white"
                      style={{
                        zIndex: 10,
                        borderRadius: "100%",
                        border: "none",
                        width: 70,
                        height: 70,
                        position: "absolute",
                        top: 105,
                        left: 105,
                      }}
                    >
                      {audioPlaying ? (
                        <PauseIcon className={classesMedia.buttonIcon} />
                      ) : (
                        <PlayIcon className={classesMedia.buttonIcon} />
                      )}
                    </button>
                  </Grid.Col>
                  <Grid.Col span={8} offset={1}>
                    <div id="waveform" ref={setWaveformRef}></div>
                  </Grid.Col>
                </Grid>
              </div>
            )}
            {contentTypeInfo?.isHtml && (
              <div className={classesMedia.video}>
                <GenArtUrlIFrame url={animationUrl} iframeRef={resourceRef} />
              </div>
            )}
          </>
        ) : isMedium ? (
          <ResizedImage
            src={tokens[0].image ?? ""}
            fit="contain"
            classNames={{
              root: hasToolbar
                ? classes.fullHeightWithoutToolbar
                : classes.fullHeight,
              imageWrapper: classes.fullHeight,
              image: classes.fullHeight,
              figure: classes.fullHeight,
            }}
            styles={{
              image: {
                maxWidth: "100%",
              },
            }}
            imageRef={resourceRef}
            onClick={expandImage}
            height={upscaleImage ? 1000 : 500}
            width="auto"
            style={{ filter: "blur(0)", zIndex: 1, cursor: "pointer" }}
          />
        ) : (
          <ResizedImage
            src={tokens[0].image ?? ""}
            fit="scale-down"
            imageRef={resourceRef}
            onClick={expandImage}
            fitSize={upscaleImage ? 1000 : 500}
            classNames={{
              root: hasToolbar
                ? classes.fullHeightWithoutToolbar
                : classes.fullHeight,
              imageWrapper: classes.fullHeight,
              image: classes.fullHeight,
              figure: classes.fullHeight,
            }}
            style={{ filter: "blur(0)", zIndex: 1, cursor: "pointer" }}
          />
        )}
        {hasToolbar && (
          <Box p={4} className={classes.toolbar} w="auto" mt={16}>
            <Group spacing={4}>
              {!isExpanded && (
                <Tooltip label="Expand">
                  <ActionIcon onClick={expandImage}>
                    <ExpandIcon {...iconSize} />
                  </ActionIcon>
                </Tooltip>
              )}
              {contentTypeInfo?.type !== PageSupportedMediaType.VIDEO && (
                <Tooltip
                  label="Fullscreen"
                  className={classes.fullscreenButton}
                >
                  <ActionIcon onClick={toggleFullscreen}>
                    <FullScreenIcon {...iconSize} />
                  </ActionIcon>
                </Tooltip>
              )}
            </Group>
          </Box>
        )}
        {hasMarketplaceButtons && !isSingleContractEdition && (
          <Center>
            <TokenDetailsCardSection
              token={token}
              refetchToken={() => refetch?.()}
            />
          </Center>
        )}
      </Stack>
    </Center>
  );
};
