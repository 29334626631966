import { memo, useEffect, useMemo, useState } from "react";

import { LoadingPage } from "@hl/base-components/lib/LoadingPage";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import {
  AppSEO,
  NotFound,
  ServerError,
} from "@hl/shared-features/lib/features/layout";
import { AppShell, Box, createStyles } from "@mantine/core";
import { useIntersection, useScrollIntoView } from "@mantine/hooks";

import { HOME_PAGE_URL } from "config";
import FooterApollo from "~features/MintPage/apollo/FooterApollo";
import MintMarketplace from "~features/MintPage/apollo/MintMarketplace";
import MintView from "~features/MintPage/apollo/MintView";
import RelatedProjects from "~features/MintPage/apollo/RelatedProjects";
import MintPageApolloSkeleton from "~features/MintPage/apollo/skeleton/MintPageApolloSkeleton";
import { useMintPage } from "~hooks/useMintPage";
import { useMintPageTokens } from "~hooks/useMintPageTokens";
import { MintContext } from "~hooks/useMintState";
import { MintTokensContext } from "~hooks/useMintTokensState";

import {
  _CollectionType,
  CollectionContractType,
  PriceType,
} from "../../apollo/graphql.generated";
import { AppFooter } from "../layout/Footer";
import { AppHeader } from "../layout/Header";

import { MintPageSEO } from "./MintPageSEO";
import {
  GetSalePageAuctionsQuery,
  GetSalePageCollectionQuery,
  GetSalePageMintVectorsQuery,
} from "./queries.graphql.generated";

export type Collection =
  | GetSalePageCollectionQuery["getPublicCollection"]
  | undefined;

export type MintVector =
  | GetSalePageMintVectorsQuery["getMintVectorsByCollection"][0]
  | undefined;

export type Auction =
  | GetSalePageAuctionsQuery["getAuctionsByCollection"][0]
  | undefined;

type MintPageProps = {
  collectionId?: string;
  embedCollectionId?: string;
  isEmbed?: boolean;
  referrer?: string;
};

export const usMintPageApolloStyles = createStyles((theme) => {
  return {
    container: {
      [theme.fn.largerThan("md")]: {
        height: "calc(100vh - var(--mantine-header-height, 0px))",
        maxHeight: "calc(100vh - var(--mantine-header-height, 0px))",
      },
    },
    header: {
      backgroundColor: theme.colors.baseBackground[0],
      borderBottom: `1px solid ${theme.colors.divider[0]}`,
    },
    mediaSectionContainer: {
      width: "100%",
      order: 1,
      [theme.fn.largerThan("md")]: {
        flex: 2,
        order: 2,
        borderRight: `1px solid ${theme.colors.divider[0]}`,
        height: "100%",
      },
    },
    mediaSection: {
      justifyContent: "center",
      [theme.fn.largerThan("md")]: {
        height: "100%",
        padding: 40,
      },
    },
    seriesMedia: {
      alignSelf: "center",
      maxWidth: "75vh",
      [theme.fn.largerThan("md")]: {
        height: "100%",
        padding: 40,
      },
    },
    genArtToolbar: {
      marginTop: 24,
      [theme.fn.largerThan("md")]: {
        marginTop: 32,
      },
    },
    fullHeight: {
      [theme.fn.largerThan("md")]: {
        maxHeight: "100%",
        height: "100%",
      },
    },
    video: {
      display: "block",
      objectFit: "contain",
      width: "100%",
    },
    mobileFooter: {
      padding: 20,
    },
  };
});

export function useQuery() {
  // Embeds doesn't support useLocation()
  const search = window.location.search;

  return useMemo(() => new URLSearchParams(search), [search]);
}

const MintPageApollo = ({
  embedCollectionId = "",
  isEmbed,
  collectionId,
  referrer,
}: MintPageProps) => {
  const { ref: buttonRef, entry } = useIntersection({
    threshold: 0,
  });
  // const { ref: marketplaceRef, entry: entryMp } = useIntersection({
  //   threshold: 0,
  // });

  collectionId = collectionId ?? embedCollectionId;
  const { classes } = usMintPageApolloStyles();
  const { isMedium } = useMantineMediaQueries();
  const mintPageHook = useMintPage(collectionId, {
    referrer: referrer,
    isEmbed,
  });
  const {
    collection,
    auction,
    mintVector,
    mintVectorsLoading,
    auctionsLoading,
    collectionLoading,
    handleMint,
    tokens,
    isSeriesMint,
    isCodeGenMint,
    collectionType,
    isAnySeriesMint,
    isCollectorChoiceMint,
    seriesTokenSelected,
    isNotFound,
    isImported,
    marketplaceId,
    setMintKey,
    isOptimizedLoading,
    appHeaderRef,
    isMarketplaceEnabledForCollectionChain,
    nonTransferable,
  } = mintPageHook;

  const isOneOfOne = collectionType === _CollectionType.OneOfOne;
  const isMarketplaceShown = !nonTransferable && !isOneOfOne;

  const mintPageTokensHook = useMintPageTokens(
    collectionId,
    marketplaceId,
    collection?.entityId ?? collection?.editionId,
    collection?.type === CollectionContractType.Imported,
    isMarketplaceEnabledForCollectionChain,
    collection?.standard
  );

  // Shows the mint card footer only if the main mint button was scrolled out of the viewport (scroll Y greater than mint button position).
  // If the user scrolls back up the footer won't show up (scroll Y lower than mint button).
  // If collections' mint is completed, always show the footer

  const showMintCardFooter = entry?.boundingClientRect
    ? !entry.isIntersecting && window.scrollY > entry.boundingClientRect.bottom
    : undefined;

  const query = useQuery();

  const [isMarketplaceTokensMounted, setIsMarketplaceTokensMounted] =
    useState(false);
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 40,
  });

  useEffect(() => {
    const isMarketplace = query.get("marketplace");
    if (isMarketplace === "true" && isMarketplaceTokensMounted) {
      scrollIntoView({ alignment: "start" });
    }
  }, [query, isMarketplaceTokensMounted]);

  const footerImageUrl = useMemo(() => {
    if (isImported) {
      return collection?.importData?.logoUrl ?? "";
    }
    if (isSeriesMint) {
      return isCollectorChoiceMint
        ? seriesTokenSelected?.imageUrl ?? ""
        : collection?.seriesImages?.coverImageUrl;
    }
    if (isCodeGenMint) {
      return collection?.generativeDetails?.logoUrl ?? "";
    }
    return tokens[0].image ?? "";
  }, [
    isSeriesMint,
    isCollectorChoiceMint,
    seriesTokenSelected,
    collection,
    tokens,
    isCodeGenMint,
  ]);

  const description = isAnySeriesMint
    ? collection?.description
    : tokens[0].description;

  useEffect(() => {
    const gmk = query.get("gmk");
    if (gmk) {
      setMintKey(gmk);
    }
  }, [query]);

  if (isOptimizedLoading && (collectionLoading || auctionsLoading)) {
    return <MintPageApolloSkeleton />;
  }

  if (
    isOptimizedLoading == null ||
    collectionLoading ||
    (mintVectorsLoading && !isOptimizedLoading) ||
    auctionsLoading
  ) {
    return <LoadingPage />;
  }
  if (isNotFound) {
    return (
      <NotFound
        redirectUrl={HOME_PAGE_URL.base}
        buttonLabel="Return to Homepage"
        isEmbed={isEmbed}
      />
    );
  }

  const noSaleForOptimizedLoading =
    !mintVector && !mintVectorsLoading && !auction && !isImported;
  const noSaleForUnoptimizedLoading = !mintVector && !auction && !isImported;

  if (
    !collection ||
    (!isAnySeriesMint &&
      ((isOptimizedLoading && noSaleForOptimizedLoading) ||
        (!isOptimizedLoading && noSaleForUnoptimizedLoading)))
  ) {
    return (
      <ServerError
        redirectUrl={HOME_PAGE_URL.base}
        buttonLabel="Return to Homepage"
        isEmbed={isEmbed}
      />
    );
  }

  const onMintClick = isCollectorChoiceMint
    ? (chainId: number) =>
        handleMint(
          chainId,
          seriesTokenSelected?.id,
          seriesTokenSelected?.imageUrl || ""
        )
    : handleMint;

  const hideFooter = mintVector?.priceType === PriceType.RankedAuction;

  return (
    <MintContext.Provider value={mintPageHook}>
      <MintTokensContext.Provider value={mintPageTokensHook}>
        <AppShell
          styles={{
            main: {
              flexDirection: "column",
              display: "flex",
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 56,
              paddingBottom: 0,
            },
          }}
          header={
            <>
              {!isEmbed && (
                <>
                  <AppSEO
                    title={`${collection.name} - Token Collection`}
                    description={description}
                  />
                  <MintPageSEO collection={collection} />
                </>
              )}
              <Box ref={appHeaderRef}>
                <AppHeader className={classes.header} />
              </Box>
            </>
          }
          footer={
            !mintVectorsLoading && !hideFooter ? (
              <AppFooter showFooter={showMintCardFooter}>
                <FooterApollo
                  imageUrl={footerImageUrl}
                  hasBid={true}
                  onMintClick={onMintClick}
                  seriesTokeMinted={
                    isCollectorChoiceMint &&
                    (!seriesTokenSelected?.imageUrl ||
                      seriesTokenSelected?.minted ||
                      false)
                  }
                />
              </AppFooter>
            ) : undefined
          }
        >
          <MintView ref={buttonRef} />
          {!isEmbed && (
            <RelatedProjects
              collectionId={collection.id}
              collectionName={collection.name}
            />
          )}
          {isMarketplaceShown && !isEmbed && (
            // <Box ref={marketplaceRef}>
            <Box
              pb={isMedium ? 120 : 160}
              ref={targetRef}
              id="marketplace-section"
            >
              <MintMarketplace
                onTokensComponentMount={() =>
                  setIsMarketplaceTokensMounted(true)
                }
              />
            </Box>
            // </Box>
          )}
        </AppShell>
      </MintTokensContext.Provider>
    </MintContext.Provider>
  );
};

export default memo(MintPageApollo);
