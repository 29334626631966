import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { useBalance } from "wagmi";

import { SalePageAuctionQueryResult } from "../Auction/types";

const useCardAuctionData = ({
  auction,
}: {
  auction: SalePageAuctionQueryResult;
}) => {
  const { walletAddress } = useAuth();
  const { data, isLoading, error } = useBalance({
    address: walletAddress ? (walletAddress as `0x${string}`) : undefined,
    chainId: auction.chainId ?? 0,
    cacheTime: 60_000,
    enabled: !!walletAddress,
  });

  const price = !!auction.stats?.currentBid
    ? 1.05 * auction.stats?.currentBid
    : parseFloat(auction.price);

  const hasEnoughMoney =
    isLoading ||
    !!error ||
    (!!data?.formatted && parseFloat(data?.formatted) >= price);

  return {
    data,
    error,
    isLoading,
    hasEnoughMoney,
    nextPrice: price,
  };
};

export default useCardAuctionData;
