import { _CollectionType } from "../../apollo/graphql.generated";
export const collectionTypeDisplayName = (type) => {
    switch (type) {
        case _CollectionType.LimitedEdition:
            return "Limited edition";
        case _CollectionType.OneOfOne:
            return "One of one";
        case _CollectionType.OpenEdition:
            return "Open edition";
        case _CollectionType.Series:
            return "Series";
        case _CollectionType.GenerativeSeries:
            return "Generative series";
        default:
            return "Collection";
    }
};
