import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgReport = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.5866 1.44666C15.4295 1.3412 15.247 1.27998 15.0581 1.26942C14.8693 1.25886 14.6811 1.29936 14.5132 1.38666C13.6377 1.74822 12.7062 1.95572 11.7599 1.99999C10.6821 1.9161 9.61978 1.69198 8.59991 1.33332C6.35991 0.666656 5.58658 0.513323 3.87324 0.733323C3.63226 0.765522 3.41108 0.883845 3.25055 1.06643C3.09002 1.24902 3.00099 1.48354 2.99991 1.72666V11.7267C2.99739 11.8699 3.02587 12.012 3.08341 12.1431C3.14094 12.2743 3.22617 12.3915 3.33324 12.4867C3.43897 12.5797 3.56294 12.6497 3.69725 12.6922C3.83155 12.7347 3.97323 12.7487 4.11324 12.7333C5.61991 12.56 6.31991 12.7333 8.40658 13.32C9.48348 13.6881 10.6043 13.9122 11.7399 13.9867C12.9067 13.9441 14.0552 13.6841 15.1266 13.22C15.5732 13.0467 15.9799 12.82 15.9799 12.2V2.26666C15.9838 2.10861 15.9502 1.95189 15.8819 1.80935C15.8135 1.66681 15.7123 1.54252 15.5866 1.44666ZM9.16658 4.29332C9.16658 4.16072 9.21926 4.03354 9.31302 3.93977C9.40679 3.846 9.53397 3.79332 9.66658 3.79332C9.79918 3.79332 9.92636 3.846 10.0201 3.93977C10.1139 4.03354 10.1666 4.16072 10.1666 4.29332V7.29332C10.1666 7.42593 10.1139 7.55311 10.0201 7.64688C9.92636 7.74064 9.79918 7.79332 9.66658 7.79332C9.53397 7.79332 9.40679 7.74064 9.31302 7.64688C9.21926 7.55311 9.16658 7.42593 9.16658 7.29332V4.29332ZM9.66658 10.46C9.50176 10.46 9.34064 10.4111 9.2036 10.3195C9.06656 10.228 8.95975 10.0978 8.89668 9.94556C8.8336 9.79329 8.8171 9.62573 8.84926 9.46408C8.88141 9.30243 8.96078 9.15394 9.07732 9.0374C9.19386 8.92086 9.34235 8.84149 9.504 8.80934C9.66565 8.77718 9.83321 8.79368 9.98548 8.85676C10.1378 8.91983 10.2679 9.02664 10.3595 9.16368C10.451 9.30072 10.4999 9.46184 10.4999 9.62666C10.4999 9.84767 10.4121 10.0596 10.2558 10.2159C10.0996 10.3722 9.88759 10.46 9.66658 10.46Z"
      fill="#0D0D0D"
    />
    <path
      d="M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16C1.26522 16 1.51957 15.8946 1.70711 15.7071C1.89464 15.5196 2 15.2652 2 15V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0V0Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgReport);
export default ForwardRef;
