import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const CodeGenImagePreviewDocument = gql `
  mutation CodeGenImagePreview(
    $codeURI: String!
    $settings: GenerativeCaptureSettingsInput!
    $params: GenerativeTokenParams!
    $skipImageCapture: Boolean
    $isGPURendering: Boolean
  ) {
    genSeriesCapturePreview(
      codeURI: $codeURI
      settings: $settings
      params: $params
      skipImageCapture: $skipImageCapture
      isGPURendering: $isGPURendering
    ) {
      taskId
      statusUrl
    }
  }
`;
/**
 * __useCodeGenImagePreviewMutation__
 *
 * To run a mutation, you first call `useCodeGenImagePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCodeGenImagePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [codeGenImagePreviewMutation, { data, loading, error }] = useCodeGenImagePreviewMutation({
 *   variables: {
 *      codeURI: // value for 'codeURI'
 *      settings: // value for 'settings'
 *      params: // value for 'params'
 *      skipImageCapture: // value for 'skipImageCapture'
 *      isGPURendering: // value for 'isGPURendering'
 *   },
 * });
 */
export function useCodeGenImagePreviewMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CodeGenImagePreviewDocument, options);
}
