import { ReactNode, useEffect, useRef, useState } from "react";

import { PoweredByHighlight } from "@hl/shared-features/lib/features/layout/PoweredByHighlight";
import { Divider, Footer } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";

export const AppFooter = ({
  children,
  showFooter,
}: {
  children: ReactNode;
  showFooter?: boolean;
}) => {
  const [scroll] = useWindowScroll();
  const [showBrand, setShowBrand] = useState<boolean>(false);
  const footerRef = useRef<HTMLElement>(null);
  const brandRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const footerHeight = footerRef?.current?.clientHeight ?? 0;
    const brandHeight = brandRef?.current?.clientHeight ?? 0;
    const isBottom =
      scroll.y + window.innerHeight - footerHeight + brandHeight >=
      document.body.scrollHeight;
    setShowBrand(isBottom);
  }, [
    scroll.y,
    window.innerHeight,
    document.body.scrollHeight,
    footerRef,
    brandRef,
  ]);

  const transition = "all 0.4s cubic-bezier(0.22, 1, 0.36, 1)";

  return (
    <Footer
      ref={footerRef}
      height="auto"
      withBorder={false}
      bg="primaryBackground.0"
      style={{
        boxShadow:
          "0px -1px 10px -1px rgba(0, 0, 0, 0.08), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        transform: (showFooter !== undefined ? showFooter : scroll.y !== 0)
          ? "translate(0, 0)"
          : "translate(0, 100%)",
        transition,
      }}
    >
      {children}
      <div
        ref={brandRef}
        style={{
          height: showBrand ? "30px" : "0px",
          transform: showBrand ? "translate(0, 0)" : "translate(0, 30px)",
          transition,
        }}
      >
        <Divider mb="sm" />
        <PoweredByHighlight />
      </div>
    </Footer>
  );
};
