import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgTokens = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0.966398 6.16664L8.99973 9.6583C9.31515 9.79596 9.65559 9.86702 9.99973 9.86702C10.3439 9.86702 10.6843 9.79596 10.9997 9.6583L19.0414 6.16664C19.2663 6.06617 19.4571 5.90229 19.5903 5.69508C19.7235 5.48786 19.7934 5.2463 19.7914 4.99997C19.7921 4.75601 19.7204 4.51732 19.5854 4.3141C19.4504 4.11088 19.2582 3.95226 19.0331 3.8583L10.9997 0.374972C10.6843 0.237314 10.3439 0.16626 9.99973 0.16626C9.65559 0.16626 9.31515 0.237314 8.99973 0.374972L0.966398 3.87497C0.743859 3.96783 0.553422 4.12388 0.418641 4.32383C0.28386 4.52378 0.21065 4.75885 0.208064 4.99997C0.205721 5.24746 0.276415 5.49015 0.4113 5.69766C0.546184 5.90518 0.73927 6.0683 0.966398 6.16664Z"
      fill="black"
    />
    <path
      d="M19.0331 8.87492L17.9998 8.42492C17.8947 8.37903 17.7812 8.35535 17.6665 8.35535C17.5517 8.35535 17.4383 8.37903 17.3331 8.42492L9.99979 11.6082L2.66646 8.42492C2.56132 8.37903 2.44784 8.35535 2.33313 8.35535C2.21841 8.35535 2.10493 8.37903 1.99979 8.42492L0.966459 8.87492C0.740374 8.9693 0.547262 9.12845 0.411432 9.33234C0.275602 9.53624 0.203125 9.77576 0.203125 10.0207C0.203125 10.2657 0.275602 10.5053 0.411432 10.7092C0.547262 10.913 0.740374 11.0722 0.966459 11.1666L8.99979 14.6582C9.31521 14.7959 9.65565 14.867 9.99979 14.867C10.3439 14.867 10.6844 14.7959 10.9998 14.6582L19.0331 11.1666C19.2603 11.0682 19.4533 10.9051 19.5882 10.6976C19.7231 10.4901 19.7938 10.2474 19.7915 9.99992C19.7889 9.7588 19.7157 9.52372 19.5809 9.32377C19.4461 9.12383 19.2557 8.96777 19.0331 8.87492Z"
      fill="black"
    />
    <path
      d="M19.0331 13.8333L17.9997 13.3833C17.8946 13.3374 17.7811 13.3137 17.6664 13.3137C17.5517 13.3137 17.4382 13.3374 17.3331 13.3833L9.99973 16.575L2.6664 13.3833C2.56126 13.3374 2.44778 13.3137 2.33306 13.3137C2.21835 13.3137 2.10487 13.3374 1.99973 13.3833L0.966398 13.8333C0.73927 13.9316 0.546184 14.0948 0.4113 14.3023C0.276415 14.5098 0.205721 14.7525 0.208064 15C0.207378 15.2439 0.27906 15.4826 0.414045 15.6858C0.54903 15.889 0.741252 16.0477 0.966398 16.1416L8.99973 19.6416C9.31515 19.7793 9.65559 19.8503 9.99973 19.8503C10.3439 19.8503 10.6843 19.7793 10.9997 19.6416L19.0331 16.1416C19.2582 16.0477 19.4504 15.889 19.5854 15.6858C19.7204 15.4826 19.7921 15.2439 19.7914 15C19.7937 14.7525 19.723 14.5098 19.5882 14.3023C19.4533 14.0948 19.2602 13.9316 19.0331 13.8333Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgTokens);
export default ForwardRef;
