import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgAddReaction = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M16.8542 0.870052C16.8542 0.389536 16.4646 0 15.9841 0C15.5036 0 15.1141 0.389536 15.1141 0.870052V3.14556L12.8387 3.14556C12.3582 3.14556 11.9686 3.5351 11.9686 4.01562C11.9686 4.49613 12.3582 4.88567 12.8387 4.88567H15.1141V7.1612C15.1141 7.64172 15.5036 8.03125 15.9841 8.03125C16.4646 8.03125 16.8542 7.64172 16.8542 7.1612V4.88567L19.1298 4.88567C19.6103 4.88567 19.9999 4.49613 19.9999 4.01562C19.9999 3.5351 19.6103 3.14557 19.1298 3.14557L16.8542 3.14556V0.870052Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0378 2.80436C10.2983 2.60402 9.52952 2.5 8.75 2.5C7.01942 2.5 5.32769 3.01318 3.88876 3.97464C2.44983 4.9361 1.32832 6.30267 0.666058 7.90152C0.00379125 9.50037 -0.169488 11.2597 0.168133 12.957C0.505753 14.6544 1.33911 16.2135 2.56282 17.4372C3.78653 18.6609 5.34563 19.4943 7.04296 19.8319C8.7403 20.1695 10.4996 19.9962 12.0985 19.3339C13.6973 18.6717 15.0639 17.5502 16.0254 16.1112C16.9868 14.6723 17.5 12.9806 17.5 11.25C17.5 10.4704 17.396 9.70149 17.1956 8.96188C16.8496 9.19514 16.4327 9.3313 15.984 9.3313C15.916 9.3313 15.8486 9.32817 15.7822 9.32204C15.9535 9.94688 16.0417 10.5951 16.0417 11.25C16.0417 13.1839 15.2734 15.0385 13.906 16.406C12.5385 17.7734 10.6839 18.5417 8.75 18.5417C7.30785 18.5417 5.89808 18.114 4.69897 17.3128C3.49986 16.5116 2.56527 15.3728 2.01338 14.0404C1.46149 12.708 1.31709 11.2419 1.59844 9.82747C1.8798 8.41302 2.57426 7.11377 3.59402 6.09401C4.61378 5.07426 5.91303 4.37979 7.32747 4.09844C8.44242 3.87666 9.58948 3.91943 10.6778 4.21779C10.6717 4.15124 10.6685 4.08382 10.6685 4.01567C10.6685 3.5671 10.8046 3.15031 11.0378 2.80436ZM12.3686 3.28332C12.128 3.43809 11.9685 3.70826 11.9685 4.01567C11.9685 4.49618 12.3581 4.88572 12.8386 4.88572H14.7549C14.0475 4.21823 13.2399 3.67907 12.3686 3.28332ZM15.114 5.2448C15.7815 5.95218 16.3207 6.7597 16.7165 7.63098C16.5618 7.87178 16.2915 8.0313 15.984 8.0313C15.5035 8.0313 15.114 7.64177 15.114 7.16125V5.2448Z"
      fill="currentColor"
    />
    <path
      d="M12.024 12.8031C11.8557 12.7087 11.6568 12.6848 11.4709 12.7367C11.285 12.7887 11.1273 12.9122 11.0323 13.0802C10.8154 13.5039 10.4897 13.8622 10.0887 14.1185C9.68761 14.3747 9.2256 14.5197 8.75003 14.5386C8.27446 14.5197 7.81245 14.3747 7.41139 14.1185C7.01033 13.8622 6.68465 13.5039 6.46774 13.0802C6.37105 12.912 6.21148 12.789 6.02414 12.7384C5.83679 12.6878 5.63703 12.7137 5.46878 12.8104C5.30053 12.9071 5.17759 13.0667 5.12699 13.254C5.0764 13.4414 5.1023 13.6411 5.19899 13.8094C5.54088 14.4585 6.05077 15.0039 6.6754 15.3887C7.30003 15.7735 8.01651 15.9835 8.75003 15.9969C9.48355 15.9835 10.2 15.7735 10.8247 15.3887C11.4493 15.0039 11.9592 14.4585 12.3011 13.8094C12.3501 13.7254 12.3819 13.6325 12.3945 13.5361C12.4072 13.4396 12.4004 13.3417 12.3745 13.2479C12.3487 13.1541 12.3044 13.0665 12.2442 12.9901C12.184 12.9137 12.1091 12.8502 12.024 12.8031Z"
      fill="currentColor"
    />
    <path
      d="M5.83333 10.5208C6.63875 10.5208 7.29167 9.86788 7.29167 9.06246C7.29167 8.25704 6.63875 7.60413 5.83333 7.60413C5.02792 7.60413 4.375 8.25704 4.375 9.06246C4.375 9.86788 5.02792 10.5208 5.83333 10.5208Z"
      fill="currentColor"
    />
    <path
      d="M11.6667 10.5208C12.4721 10.5208 13.125 9.86788 13.125 9.06246C13.125 8.25704 12.4721 7.60413 11.6667 7.60413C10.8613 7.60413 10.2084 8.25704 10.2084 9.06246C10.2084 9.86788 10.8613 10.5208 11.6667 10.5208Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgAddReaction);
export default ForwardRef;
