import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgLoadingIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M30.25 16C30.25 18.8184 29.4143 21.5735 27.8484 23.9169C26.2826 26.2603 24.0571 28.0867 21.4532 29.1653C18.8494 30.2438 15.9842 30.526 13.22 29.9762C10.4557 29.4264 7.91663 28.0692 5.92373 26.0763C3.93083 24.0834 2.57365 21.5443 2.02381 18.78C1.47397 16.0158 1.75617 13.1506 2.83472 10.5468C3.91327 7.94291 5.73972 5.71737 8.08312 4.15156C10.4265 2.58575 13.1816 1.75 16 1.75"
      stroke="#0D0D0D"
      strokeWidth={3.5}
      strokeLinecap="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgLoadingIcon);
export default ForwardRef;
