import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgAccountWallet = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0.966642 6.16664L8.99998 9.65831C9.31539 9.79596 9.65583 9.86702 9.99998 9.86702C10.3441 9.86702 10.6846 9.79596 11 9.65831L19.0416 6.16664C19.2666 6.06617 19.4573 5.90229 19.5905 5.69508C19.7237 5.48786 19.7936 5.2463 19.7916 4.99997C19.7923 4.75601 19.7206 4.51732 19.5857 4.3141C19.4507 4.11088 19.2585 3.95226 19.0333 3.85831L11 0.374972C10.6846 0.237314 10.3441 0.16626 9.99998 0.16626C9.65583 0.16626 9.31539 0.237314 8.99998 0.374972L0.966642 3.87497C0.744103 3.96783 0.553666 4.12388 0.418885 4.32383C0.284105 4.52378 0.210894 4.75885 0.208308 4.99997C0.205965 5.24746 0.276659 5.49015 0.411544 5.69766C0.546429 5.90518 0.739514 6.06831 0.966642 6.16664Z"
      fill="black"
    />
    <path
      d="M19.0334 8.87498L18 8.42498C17.8949 8.37909 17.7814 8.35541 17.6667 8.35541C17.552 8.35541 17.4385 8.37909 17.3334 8.42498L10 11.6083L2.6667 8.42498C2.56156 8.37909 2.44808 8.35541 2.33337 8.35541C2.21865 8.35541 2.10517 8.37909 2.00004 8.42498L0.966703 8.87498C0.740618 8.96936 0.547506 9.12851 0.411676 9.33241C0.275846 9.5363 0.203369 9.77582 0.203369 10.0208C0.203369 10.2658 0.275846 10.5053 0.411676 10.7092C0.547506 10.9131 0.740618 11.0723 0.966703 11.1666L9.00004 14.6583C9.31545 14.796 9.65589 14.867 10 14.867C10.3442 14.867 10.6846 14.796 11 14.6583L19.0334 11.1666C19.2605 11.0683 19.4536 10.9052 19.5885 10.6977C19.7234 10.4902 19.794 10.2475 19.7917 9.99998C19.7891 9.75886 19.7159 9.52379 19.5811 9.32384C19.4463 9.12389 19.2559 8.96783 19.0334 8.87498Z"
      fill="black"
    />
    <path
      d="M19.0333 13.8333L18 13.3833C17.8948 13.3374 17.7814 13.3137 17.6666 13.3137C17.5519 13.3137 17.4384 13.3374 17.3333 13.3833L9.99998 16.575L2.66664 13.3833C2.5615 13.3374 2.44802 13.3137 2.33331 13.3137C2.21859 13.3137 2.10511 13.3374 1.99998 13.3833L0.966642 13.8333C0.739514 13.9316 0.546429 14.0948 0.411544 14.3023C0.276659 14.5098 0.205965 14.7525 0.208308 15C0.207622 15.2439 0.279305 15.4826 0.414289 15.6858C0.549274 15.889 0.741496 16.0477 0.966642 16.1416L8.99998 19.6416C9.31539 19.7793 9.65583 19.8503 9.99998 19.8503C10.3441 19.8503 10.6846 19.7793 11 19.6416L19.0333 16.1416C19.2585 16.0477 19.4507 15.889 19.5857 15.6858C19.7206 15.4826 19.7923 15.2439 19.7916 15C19.794 14.7525 19.7233 14.5098 19.5884 14.3023C19.4535 14.0948 19.2604 13.9316 19.0333 13.8333Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgAccountWallet);
export default ForwardRef;
