import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { PencilFill } from "@hl/base-components/lib/assets/icons.generated";
import { OUTLINE_COLOR, PRIMARY_COLOR, } from "@hl/base-components/lib/theme/button";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { Button, Text } from "@mantine/core";
import { _CollectionType, TransactionType, } from "../../apollo/graphql.generated";
import { logError } from "../../services/logger";
import { useAuth } from "../auth/AuthContext";
import { TransactionStateType, useTransactionsDispatch, useTransactionState, } from "../evm-tx/TransactionContext";
import { useFulfillAuctionMutation } from "./queries/auction.graphql.generated";
export const FulfillAuction = ({ collectionId, auctionId, chainId, refetchData, fulfillerIsOwner, ...props }) => {
    const { walletAddress } = useAuth();
    const getNewTxnId = () => `txn-${Date.now()}`;
    const [error, setError] = useState("");
    const [txnId, setTxnId] = useState(getNewTxnId());
    const transactionState = useTransactionState(txnId);
    const transactionDispatch = useTransactionsDispatch();
    const [fulfillAuction, { data, loading }] = useFulfillAuctionMutation();
    useEffect(() => {
        if (data && auctionId && walletAddress && data.fulfillAuction) {
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_ARGS",
                payload: {
                    id: txnId,
                    args: data.fulfillAuction,
                    metadata: {
                        auctionId,
                        collectionId,
                        nftContractAddress: walletAddress,
                    },
                },
            });
        }
    }, [data, auctionId]);
    useEffect(() => {
        if (data && !data.fulfillAuction) {
            refetchData();
        }
    }, [data]);
    const onSubmit = async () => {
        setError("");
        if (!auctionId) {
            throw new Error("Auction without id");
        }
        try {
            await fulfillAuction({
                variables: { auctionId, chainId },
            });
            const txnId = getNewTxnId();
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "START_TRANSACTION",
                payload: {
                    id: txnId,
                    entityId: auctionId,
                    transactionType: TransactionType.EVM_721_AUCTION,
                    collectionType: _CollectionType.OneOfOne,
                },
            });
            setTxnId(txnId);
        }
        catch (e) {
            setError(fulfillerIsOwner
                ? "Failed to fulfill auction."
                : "Failed to claim token.");
            logError({
                e,
                message: "[FulfillAuction] Failed to fulfill auction",
            });
        }
    };
    let buttonLabel = fulfillerIsOwner ? "Fulfill auction" : "Claim token";
    let buttonDisabled = false;
    if (transactionState && !transactionState.error && !!transactionState.args) {
        if (transactionState.type === TransactionStateType.WaitingSignedTx) {
            buttonLabel = "Waiting for user signature...";
            buttonDisabled = false;
        }
        else if (transactionState.type !== TransactionStateType.Done) {
            buttonLabel = fulfillerIsOwner
                ? "Fulfilling your auction..."
                : "Claiming your token...";
            buttonDisabled = true;
        }
    }
    useEffect(() => {
        if ((transactionState === null || transactionState === void 0 ? void 0 : transactionState.type) === TransactionStateType.Done) {
            refetchData();
        }
    }, [transactionState]);
    return (_jsxs(_Fragment, { children: [fulfillerIsOwner ? (_jsxs(_Fragment, { children: [_jsx(Button, { disabled: buttonDisabled, loading: loading, color: OUTLINE_COLOR, leftIcon: _jsx(PencilFill, {}), onClick: onSubmit, ...props, children: buttonLabel }), error] })) : (_jsx(_Fragment, { children: _jsx(Button, { disabled: buttonDisabled, loading: loading, color: PRIMARY_COLOR, onClick: onSubmit, ...props, children: buttonLabel }) })), error && (_jsx(Text, { size: "xs", mt: 5, color: STATUS_COLOR.ERROR, children: error }))] }));
};
