import * as React from "react";

import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import {
  WEIGHT_BOLD,
  WEIGHT_NORMAL,
} from "@hl/base-components/lib/theme/typography";
import { collectionTypeDisplayName } from "@hl/shared-features/lib/features/collections/display-utils";
import { Title, Text, Box } from "@mantine/core";

import CollectionCreatedBy from "~features/MintPage/CollectionCreatedBy";
import useMintState from "~hooks/useMintState";

const CollectionDetailsTitleSection = () => {
  const { collection, nonTransferable } = useMintState();
  if (!collection) return null;

  return (
    <Box mb={4}>
      <Text size="xs" color={TEXT_COLOR.TERTIARY} tt="uppercase">
        {collectionTypeDisplayName(collection?.collectionType)}
      </Text>
      <Title
        order={4}
        fw={WEIGHT_NORMAL}
        mt={8}
        sx={{ wordBreak: "break-word" }}
      >
        {collection.name}
      </Title>
      {nonTransferable && (
        <Text size="xs" weight={WEIGHT_BOLD} color={TEXT_COLOR.SECONDARY}>
          Non-transferable
        </Text>
      )}
      <CollectionCreatedBy mt={10} />
    </Box>
  );
};

export default CollectionDetailsTitleSection;
