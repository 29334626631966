import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgBenefitAccess = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.4063 3.99999L14.5663 3.12666L15.153 2.53999C15.2715 2.42205 15.3655 2.28179 15.4296 2.12732C15.4937 1.97285 15.5266 1.80722 15.5263 1.63999C15.5283 1.38499 15.454 1.13521 15.3131 0.922686C15.1722 0.710158 14.971 0.544569 14.7353 0.447151C14.4997 0.349733 14.2403 0.324927 13.9904 0.375914C13.7405 0.426901 13.5116 0.551357 13.333 0.733323L5.99963 8.06666C5.14971 7.64522 4.17768 7.53958 3.25708 7.76858C2.33648 7.99758 1.52723 8.54632 0.973814 9.31684C0.420402 10.0873 0.158853 11.0295 0.235864 11.975C0.312874 12.9205 0.723431 13.8079 1.39423 14.4787C2.06504 15.1495 2.95242 15.5601 3.89795 15.6371C4.84348 15.7141 5.7856 15.4525 6.55611 14.8991C7.32662 14.3457 7.87537 13.5365 8.10437 12.6159C8.33337 11.6953 8.22773 10.7232 7.80629 9.87332L10.2996 7.37999L10.8063 7.89332C11.0255 8.11169 11.3225 8.23401 11.632 8.23338C11.9414 8.23276 12.2379 8.10924 12.4563 7.88999C12.6747 7.67074 12.797 7.37373 12.7964 7.0643C12.7957 6.75486 12.6722 6.45835 12.453 6.23999L11.9996 5.71999L12.9463 4.77332L13.7863 5.61999C14.0082 5.83152 14.303 5.94951 14.6096 5.94951C14.9162 5.94951 15.211 5.83152 15.433 5.61999C15.6377 5.39833 15.7491 5.10633 15.7441 4.80465C15.7392 4.50297 15.6182 4.2148 15.4063 3.99999ZM4.25296 9.63999C4.64852 9.63999 5.0352 9.75729 5.3641 9.97705C5.693 10.1968 5.94934 10.5092 6.10072 10.8746C6.25209 11.2401 6.2917 11.6422 6.21453 12.0302C6.13736 12.4181 5.94688 12.7745 5.66717 13.0542C5.38747 13.3339 5.0311 13.5244 4.64314 13.6016C4.25518 13.6787 3.85304 13.6391 3.48759 13.4877C3.12214 13.3364 2.80978 13.08 2.59002 12.7511C2.37026 12.4222 2.25296 12.0356 2.25296 11.64C2.25296 11.1096 2.46367 10.6008 2.83875 10.2258C3.21382 9.8507 3.72253 9.63999 4.25296 9.63999Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgBenefitAccess);
export default ForwardRef;
