import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgPencil = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_2_684)">
      <path
        opacity={0.12}
        d="M1.67785 10.5674C1.70465 10.3262 1.71805 10.2056 1.75455 10.0929C1.78692 9.99289 1.83267 9.89771 1.89053 9.80996C1.95576 9.71104 2.04156 9.62524 2.21317 9.45363L9.91685 1.74997C10.5612 1.10564 11.6058 1.10564 12.2502 1.74997C12.8945 2.39431 12.8945 3.43898 12.2502 4.08331L4.5465 11.787C4.3749 11.9586 4.28909 12.0444 4.19018 12.1096C4.10242 12.1675 4.00725 12.2132 3.90724 12.2456C3.79451 12.2821 3.67391 12.2955 3.43271 12.3223L1.4585 12.5416L1.67785 10.5674Z"
        fill="black"
      />
      <path
        d="M1.67785 10.5674C1.70465 10.3262 1.71805 10.2056 1.75455 10.0929C1.78692 9.99289 1.83267 9.89771 1.89053 9.80996C1.95576 9.71104 2.04156 9.62524 2.21317 9.45363L9.91685 1.74997C10.5612 1.10564 11.6058 1.10564 12.2502 1.74997C12.8945 2.39431 12.8945 3.43898 12.2502 4.08331L4.5465 11.787C4.3749 11.9586 4.28909 12.0444 4.19018 12.1096C4.10242 12.1675 4.00725 12.2132 3.90724 12.2456C3.79451 12.2821 3.67391 12.2955 3.43271 12.3223L1.4585 12.5416L1.67785 10.5674Z"
        stroke="black"
        strokeWidth={1.16667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2_684">
        <rect width={14} height={14} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgPencil);
export default ForwardRef;
