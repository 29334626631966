import { useState } from "react";

import MultilineText from "@hl/base-components/lib/MultilineText";
import { CoinsHand } from "@hl/base-components/lib/assets/icons.generated";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { ArrowDownBold } from "@hl/communities-app/src/assets/icons.generated";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import BidModalHighlight from "@hl/shared-features/lib/features/marketplace/modals/BidModal";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import {
  Text,
  Group,
  Stack,
  useMantineTheme,
  Button,
  Flex,
  ScrollArea,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useCollections } from "@reservoir0x/reservoir-kit-ui";

import { AuctionCardDate } from "~features/MintPage/Auction/AuctionCardDate";
import { AuctionCardSmall } from "~features/MintPage/Auction/AuctionCardSmall";
import CollectionCreatedBy from "~features/MintPage/CollectionCreatedBy";
import MintCardDate from "~features/MintPage/MintVector/MintCardDate";
import MintCardSmall from "~features/MintPage/MintVector/MintCardSmall";
import MarketplaceStatsFooter from "~features/MintPage/apollo/MarketplaceStatsFooter";
import { isActiveSale } from "~features/MintPage/utils/statuses";
import { SaleStatus } from "~features/MintPage/utils/types";
import useMintState from "~hooks/useMintState";

type CardFooterProps = {
  imageUrl?: string | null;
  onMintClick: (chainId: number) => void;
  hasBid?: boolean;
  seriesTokeMinted: boolean;
  showGateRequirementsModal?: boolean;
  showWarningModals?: boolean;
};

const FooterApollo = ({
  imageUrl,
  onMintClick,
  hasBid = false,
  seriesTokeMinted = false,
  showGateRequirementsModal,
  showWarningModals,
}: CardFooterProps) => {
  const {
    collection,
    chainId,
    reservoirCollectionId,
    isMarketplaceNoticeShown,
    auction,
    mintVector,
    saleStatus,
    isCollectorChoiceMint,
    isImported,
  } = useMintState();
  const { pushModal } = useModalStack();
  const title = collection?.name || "";
  const theme = useMantineTheme();
  const isMedium = useMediaQuery(`(max-width: ${theme.breakpoints.md - 1}px)`);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs - 1}px)`);
  const saleActive = isActiveSale(saleStatus as SaleStatus);
  // Show the mint card if the mint button was clicked while on mobile
  const [mintView, setMintView] = useState(false);

  const { data } = useCollections(
    isMarketplaceNoticeShown
      ? {
          id: reservoirCollectionId,
        }
      : false,
    undefined,
    chainId
  );
  const showStats = isMarketplaceNoticeShown || isImported;

  const marketplaceCollection = data[0];

  return (
    <Stack spacing="xs">
      {showStats && isMedium && (
        <ScrollArea>
          <Flex wrap="nowrap" pt={16} pb={10}>
            <MarketplaceStatsFooter isScrollable />
          </Flex>
        </ScrollArea>
      )}
      {mintView && isMedium && (
        <Flex
          onClick={() => setMintView(false)}
          w="100%"
          p="xs"
          mb="-xs"
          sx={{
            justifyContent: "center",
            borderBottom: `1px solid ${theme.colors.divider[0]}`,
            cursor: "pointer",
          }}
        >
          <ArrowDownBold />
        </Flex>
      )}
      <Flex
        p="md"
        justify={isMedium && mintView ? "center" : "space-between"}
        align="center"
        gap={16}
        direction={isMedium && mintView ? "column" : "row"}
        sx={{ flexGrow: isMedium && mintView ? 1 : "unset" }}
      >
        {/* Show image, title and creator address/name if on desktop or mobile while mint button wasn't clicked */}
        {((isMedium && !mintView) || !isMedium) && (
          <Group spacing="xs" noWrap maw={isMedium ? "100%" : "45%"} miw={0}>
            <ResizedImage
              src={imageUrl}
              fit="contain"
              radius="md"
              height={isMedium ? 48 : 64}
              width={isMedium ? 48 : 64}
            />
            <Stack
              spacing={0}
              sx={{
                flexShrink: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {!isMedium ? (
                <>
                  {mintVector && <MintCardDate smallSize />}
                  {auction && <AuctionCardDate smallSize />}
                  <MultilineText
                    numLines="2"
                    weight={500}
                    size={18}
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "220px",
                      flexShrink: 1,
                    }}
                  >
                    {title}
                  </MultilineText>
                  <Text size={12}>
                    <CollectionCreatedBy />
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    weight={500}
                    size="lg"
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    {title}
                  </Text>
                  <Text size="xs">
                    <CollectionCreatedBy small />
                  </Text>
                </>
              )}
            </Stack>
          </Group>
        )}
        {showStats ? (
          <>
            {!isMedium && (
              <ScrollArea>
                <Flex wrap="nowrap" py={10}>
                  <MarketplaceStatsFooter isScrollable />
                </Flex>
              </ScrollArea>
            )}
            <Button
              px={isMobile ? 8 : 12}
              size={isMobile ? "xs" : "lg"}
              sx={{ flexShrink: 0 }}
              onClick={() =>
                collection
                  ? pushModal(
                      <BidModalHighlight
                        src={
                          collection?.collectionImage ||
                          marketplaceCollection?.image ||
                          ""
                        }
                        collectionData={collection}
                      />,
                      {
                        size: "lg",
                        title: (
                          <Text size="md" fw={WEIGHT_BOLD}>
                            Make an offer
                          </Text>
                        ),
                      }
                    )
                  : null
              }
            >
              <Group spacing={8}>
                {!isMobile && <CoinsHand />}
                Make collection offer
              </Group>
            </Button>
          </>
        ) : (
          <>
            {/* Show the mint card with the real mint button if there is a mint vector, and the mint button was clicked while on mobile. */}
            {mintVector &&
              ((isMedium && mintView) || !isMedium || !saleActive) && (
                <MintCardSmall
                  onMintClick={onMintClick}
                  seriesTokeMinted={seriesTokeMinted}
                  showGateRequirementsModal={showGateRequirementsModal}
                  showWarningModals={showWarningModals}
                  isApollo
                  showCreditCardButton
                  showReferButton
                />
              )}
            {/* Initial view for mobile. Clicking this button will make the mint card appear. */}
            {mintVector && isMedium && !mintView && saleActive && (
              <Button px="sm" mt={16} onClick={() => setMintView(true)}>
                {isCollectorChoiceMint
                  ? "Select token to mint"
                  : mintVector.price !== "0"
                  ? `${mintVector.price} ${getCurrencySymbol(
                      mintVector.chainId || ""
                    )}`
                  : "Mint"}
              </Button>
            )}
            {auction && hasBid && <AuctionCardSmall />}
          </>
        )}
      </Flex>
    </Stack>
  );
};

export default FooterApollo;
