import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgVideo = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_432_17238)">
      <path
        d="M16 5.50008C16 5.41168 15.9649 5.32689 15.9024 5.26438C15.8399 5.20187 15.7551 5.16675 15.6667 5.16675H0.333333C0.244928 5.16675 0.160143 5.20187 0.0976311 5.26438C0.035119 5.32689 0 5.41168 0 5.50008L0 13.5001C0 13.9421 0.175595 14.366 0.488155 14.6786C0.800716 14.9912 1.22464 15.1667 1.66667 15.1667H14.3333C14.5522 15.1667 14.7689 15.1236 14.9711 15.0399C15.1733 14.9561 15.3571 14.8334 15.5118 14.6786C15.6666 14.5238 15.7894 14.3401 15.8731 14.1379C15.9569 13.9357 16 13.719 16 13.5001V5.50008ZM10.8267 9.41342C10.9765 9.47438 11.1047 9.57861 11.1949 9.7128C11.2852 9.84698 11.3334 10.005 11.3334 10.1667C11.3334 10.3285 11.2852 10.4865 11.1949 10.6207C11.1047 10.7549 10.9765 10.8591 10.8267 10.9201L6.40667 12.7734C6.29275 12.8233 6.1684 12.8447 6.04436 12.8358C5.92032 12.8269 5.8003 12.788 5.69466 12.7224C5.58902 12.6568 5.50092 12.5664 5.43797 12.4592C5.37503 12.3519 5.33911 12.231 5.33333 12.1067V8.25342C5.33911 8.12919 5.37503 8.00823 5.43797 7.90098C5.50092 7.79373 5.58902 7.7034 5.69466 7.63778C5.8003 7.57217 5.92032 7.53323 6.04436 7.52434C6.1684 7.51545 6.29275 7.53687 6.40667 7.58675L10.8267 9.41342Z"
        fill="#0D0D0D"
      />
      <path
        d="M13.0398 1.12007C13.064 1.09629 13.0807 1.06592 13.0878 1.03274C13.0949 0.999555 13.0921 0.965015 13.0798 0.933401C13.0668 0.903588 13.0453 0.878234 13.0181 0.860465C12.9908 0.842696 12.959 0.833288 12.9264 0.833401H10.3998C10.3846 0.828319 10.3683 0.828319 10.3531 0.833401L7.33311 3.88007C7.3089 3.90385 7.29221 3.93422 7.2851 3.9674C7.27799 4.00058 7.28077 4.03512 7.29311 4.06673C7.30612 4.09655 7.32757 4.1219 7.35482 4.13967C7.38207 4.15744 7.41392 4.16685 7.44644 4.16673H9.99978C10.0149 4.17182 10.0313 4.17182 10.0464 4.16673L13.0398 1.12007Z"
        fill="#0D0D0D"
      />
      <path
        d="M14.72 0.880127L11.6934 3.88013C11.6705 3.90476 11.6547 3.93511 11.6477 3.96796C11.6407 4.00081 11.6426 4.03496 11.6534 4.06679C11.6664 4.09661 11.6878 4.12196 11.7151 4.13973C11.7423 4.1575 11.7742 4.16691 11.8067 4.16679H15.6667C15.7551 4.16679 15.8399 4.13167 15.9024 4.06916C15.9649 4.00665 16 3.92187 16 3.83346V2.50013C15.9998 2.12554 15.8733 1.76197 15.6411 1.46806C15.4089 1.17414 15.0844 0.967031 14.72 0.880127Z"
        fill="#0D0D0D"
      />
      <path
        d="M8.6666 1.12007C8.69081 1.09629 8.7075 1.06592 8.71461 1.03274C8.72173 0.999555 8.71894 0.965015 8.7066 0.933401C8.69359 0.903588 8.67214 0.878234 8.64489 0.860465C8.61765 0.842696 8.5858 0.833288 8.55327 0.833401H6.0666C6.05146 0.828319 6.03507 0.828319 6.01993 0.833401L2.99327 3.88007C2.96906 3.90385 2.95236 3.93422 2.94525 3.9674C2.93814 4.00058 2.94093 4.03512 2.95327 4.06673C2.96628 4.09655 2.98773 4.1219 3.01498 4.13967C3.04222 4.15744 3.07407 4.16685 3.1066 4.16673H5.59993C5.61507 4.17182 5.63146 4.17182 5.6466 4.16673L8.6666 1.12007Z"
        fill="#0D0D0D"
      />
      <path
        d="M4.34 1.12016C4.36421 1.09638 4.3809 1.06602 4.38801 1.03283C4.39512 0.999651 4.39234 0.965111 4.38 0.933497C4.36699 0.903684 4.34554 0.87833 4.31829 0.860561C4.29105 0.842792 4.25919 0.833384 4.22667 0.833497H1.66667C1.22464 0.833497 0.800716 1.00909 0.488155 1.32165C0.175595 1.63421 0 2.05814 0 2.50016L0 3.8335C0 3.9219 0.0351189 4.00669 0.0976311 4.0692C0.160143 4.13171 0.244928 4.16683 0.333333 4.16683H1.26667C1.28181 4.17191 1.29819 4.17191 1.31333 4.16683L4.34 1.12016Z"
        fill="#0D0D0D"
      />
    </g>
    <defs>
      <clipPath id="clip0_432_17238">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgVideo);
export default ForwardRef;
