import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgCloseXBenefit = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={10}
    height={10}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.24268 9.24262C9.40343 9.08187 9.49373 8.86386 9.49373 8.63653C9.49373 8.4092 9.40343 8.19118 9.24268 8.03044L6.36375 5.1515C6.32356 5.11132 6.30098 5.05681 6.30098 4.99998C6.30098 4.94315 6.32356 4.88864 6.36375 4.84846L9.24268 1.96952C9.40343 1.80878 9.49373 1.59076 9.49373 1.36343C9.49373 1.1361 9.40343 0.918083 9.24268 0.757338C9.08193 0.596592 8.86392 0.506287 8.63659 0.506287C8.40926 0.506287 8.19124 0.596592 8.0305 0.757338L5.15156 3.63627C5.11138 3.67646 5.05687 3.69904 5.00004 3.69904C4.94321 3.69904 4.8887 3.67646 4.84852 3.63627L1.96958 0.757338C1.80884 0.596592 1.59082 0.506287 1.36349 0.506287C1.13616 0.506287 0.918144 0.596592 0.757399 0.757338C0.596653 0.918083 0.506348 1.1361 0.506348 1.36343C0.506348 1.59076 0.596653 1.80878 0.757399 1.96952L3.63633 4.84846C3.67652 4.88864 3.6991 4.94315 3.6991 4.99998C3.6991 5.05681 3.67652 5.11132 3.63633 5.1515L0.757399 8.03044C0.596653 8.19118 0.506348 8.4092 0.506348 8.63653C0.506348 8.86386 0.596653 9.08187 0.757399 9.24262C0.918144 9.40337 1.13616 9.49367 1.36349 9.49367C1.59082 9.49367 1.80884 9.40337 1.96958 9.24262L4.84852 6.36368C4.8887 6.3235 4.94321 6.30092 5.00004 6.30092C5.05687 6.30092 5.11138 6.3235 5.15156 6.36368L8.0305 9.24262C8.19124 9.40337 8.40926 9.49367 8.63659 9.49367C8.86392 9.49367 9.08193 9.40337 9.24268 9.24262Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCloseXBenefit);
export default ForwardRef;
