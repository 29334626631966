import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgMembers = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.058 9.8916C11.0198 9.87462 10.9867 9.84804 10.9619 9.81445C10.9371 9.78085 10.9214 9.7414 10.9163 9.69993C10.9163 9.65716 10.929 9.61534 10.9527 9.57975C10.9765 9.54416 11.0102 9.51639 11.0497 9.49993C11.6166 9.25797 12.0826 8.82763 12.3688 8.28168C12.6551 7.73574 12.7439 7.10769 12.6205 6.50376C12.497 5.89983 12.1687 5.35708 11.6912 4.9673C11.2136 4.57752 10.6161 4.36462 9.99967 4.36462C9.38325 4.36462 8.78574 4.57752 8.3082 4.9673C7.83065 5.35708 7.50238 5.89983 7.3789 6.50376C7.25541 7.10769 7.3443 7.73574 7.63052 8.28168C7.91673 8.82763 8.38272 9.25797 8.94967 9.49993C8.98916 9.51639 9.02289 9.54416 9.04662 9.57975C9.07035 9.61534 9.08301 9.65716 9.08301 9.69993C9.07797 9.7414 9.06227 9.78085 9.03745 9.81445C9.01262 9.84804 8.97951 9.87462 8.94134 9.8916C8.29354 10.118 7.73141 10.5389 7.3317 11.0967C6.93199 11.6545 6.71418 12.3221 6.70801 13.0083V15.5083C6.70801 15.6166 6.75103 15.7204 6.82761 15.797C6.90418 15.8736 7.00804 15.9166 7.11634 15.9166H7.85801C7.90904 15.9185 7.95761 15.939 7.99448 15.9744C8.03135 16.0097 8.05396 16.0574 8.05801 16.1083L8.33301 19.6249C8.3414 19.7274 8.38809 19.8228 8.46378 19.8924C8.53947 19.9619 8.63858 20.0003 8.74134 19.9999H11.2413C11.3441 20.0003 11.4432 19.9619 11.5189 19.8924C11.5946 19.8228 11.6413 19.7274 11.6497 19.6249L11.9497 16.0749C11.9537 16.024 11.9763 15.9764 12.0132 15.941C12.0501 15.9057 12.0986 15.8851 12.1497 15.8833H12.8913C12.9996 15.8833 13.1035 15.8402 13.1801 15.7637C13.2567 15.6871 13.2997 15.5832 13.2997 15.4749V12.9749C13.2861 12.2934 13.0639 11.6325 12.6631 11.0812C12.2623 10.5299 11.7021 10.1147 11.058 9.8916Z"
      fill="black"
    />
    <path
      d="M3.85834 4.69999C3.81915 4.68452 3.78509 4.65835 3.76004 4.62446C3.735 4.59057 3.71997 4.55034 3.71667 4.50833C3.71787 4.46689 3.73007 4.42652 3.75204 4.39137C3.77401 4.35622 3.80495 4.32756 3.84167 4.30833C4.30918 4.09022 4.6883 3.71915 4.9164 3.25644C5.1445 2.79373 5.20794 2.26705 5.09623 1.7634C4.98452 1.25976 4.70436 0.809291 4.30201 0.486409C3.89967 0.163526 3.39922 -0.0124512 2.88334 -0.0124512C2.36746 -0.0124512 1.86701 0.163526 1.46467 0.486409C1.06232 0.809291 0.782157 1.25976 0.670451 1.7634C0.558745 2.26705 0.622182 2.79373 0.850283 3.25644C1.07838 3.71915 1.4575 4.09022 1.92501 4.30833C1.96075 4.32796 1.99049 4.35694 2.01104 4.39217C2.03159 4.4274 2.04218 4.46755 2.04167 4.50833C2.03838 4.55034 2.02335 4.59057 1.9983 4.62446C1.97326 4.65835 1.9392 4.68452 1.90001 4.69999C1.34295 4.90081 0.86149 5.26873 0.521416 5.75349C0.181341 6.23824 -0.000755941 6.81618 7.5279e-06 7.40833V8.64166C-0.000645587 8.75038 0.041222 8.85505 0.116674 8.93333C0.195882 9.00733 0.299949 9.04895 0.408341 9.04999H1.09167C1.14236 9.05134 1.19098 9.07043 1.22904 9.10393C1.26711 9.13743 1.29222 9.18322 1.30001 9.23333L1.66667 12.3917C1.67878 12.4919 1.72685 12.5843 1.80195 12.6517C1.87705 12.7192 1.97408 12.757 2.07501 12.7583H3.74167C3.84404 12.7591 3.94309 12.7221 4.01994 12.6545C4.09679 12.5868 4.14606 12.4933 4.15834 12.3917L4.50834 9.23333C4.51428 9.18392 4.53772 9.13827 4.57441 9.10464C4.61109 9.07101 4.6586 9.05163 4.70834 9.04999H5.39167C5.49997 9.04999 5.60383 9.00697 5.68041 8.9304C5.75699 8.85382 5.80001 8.74996 5.80001 8.64166V7.40833C5.79773 6.81115 5.60955 6.22949 5.26159 5.74415C4.91364 5.2588 4.42318 4.89386 3.85834 4.69999Z"
      fill="black"
    />
    <path
      d="M18.0996 4.69963C18.0604 4.68415 18.0264 4.65798 18.0013 4.6241C17.9763 4.59021 17.9613 4.54997 17.958 4.50796C17.9573 4.46612 17.9687 4.42496 17.9909 4.38947C18.0131 4.35397 18.045 4.32567 18.083 4.30796C18.5497 4.08907 18.9278 3.71764 19.155 3.25493C19.3823 2.79223 19.445 2.26589 19.3329 1.76275C19.2207 1.2596 18.9404 0.809708 18.5382 0.487276C18.136 0.164844 17.636 -0.0108643 17.1205 -0.0108643C16.605 -0.0108643 16.1049 0.164844 15.7027 0.487276C15.3005 0.809708 15.0202 1.2596 14.9081 1.76275C14.7959 2.26589 14.8587 2.79223 15.0859 3.25493C15.3131 3.71764 15.6913 4.08907 16.158 4.30796C16.1947 4.32719 16.2256 4.35586 16.2476 4.39101C16.2696 4.42616 16.2818 4.46653 16.283 4.50796C16.2797 4.54997 16.2646 4.59021 16.2396 4.6241C16.2145 4.65798 16.1805 4.68415 16.1413 4.69963C15.5842 4.90045 15.1028 5.26837 14.7627 5.75312C14.4226 6.23788 14.2405 6.81582 14.2413 7.40796V8.6413C14.2402 8.69523 14.2499 8.74884 14.2701 8.79889C14.2902 8.84895 14.3202 8.89442 14.3584 8.93256C14.3965 8.97071 14.442 9.00074 14.492 9.02086C14.5421 9.04098 14.5957 9.05077 14.6496 9.04963H15.333C15.3832 9.04946 15.4317 9.06822 15.4688 9.1022C15.5059 9.13617 15.5288 9.18286 15.533 9.23296L15.883 12.3913C15.8952 12.4929 15.9445 12.5865 16.0214 12.6541C16.0982 12.7217 16.1973 12.7587 16.2996 12.758H17.9663C18.0672 12.7567 18.1643 12.7188 18.2394 12.6514C18.3145 12.5839 18.3625 12.4915 18.3746 12.3913L18.7246 9.23296C18.7324 9.18286 18.7575 9.13707 18.7956 9.10357C18.8337 9.07007 18.8823 9.05098 18.933 9.04963H19.6163C19.7246 9.04963 19.8285 9.00661 19.905 8.93003C19.9816 8.85346 20.0246 8.74959 20.0246 8.6413V7.40796C20.0236 6.81281 19.8378 6.23265 19.493 5.74754C19.1482 5.26244 18.6614 4.89629 18.0996 4.69963Z"
      fill="black"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgMembers);
export default ForwardRef;
