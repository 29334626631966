import React, { useState } from "react";

import { InfoCircleFill } from "@hl/base-components/lib/assets/icons.generated";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import EditionSize from "@hl/shared-features/lib/features/chain/EditionSize";
import OpenseaRegistryInfoModal from "@hl/shared-features/lib/features/openseaBlocklist/OpenseaBlocklistInfoModal";
import CopyButton from "@hl/shared-features/lib/features/widgets/CopyButton";
import CopyText from "@hl/shared-features/lib/features/widgets/CopyText";
import { networkLookup } from "@hl/shared-features/lib/utils/blockExplorer";
import { maskAddress } from "@hl/shared-features/lib/utils/content";
import { getMintVectorId } from "@hl/shared-features/lib/utils/getMintVectorId";
import { isUnlimitedSize } from "@hl/shared-features/lib/utils/unlimitedSize";
import { Group, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";

import {
  DetailSectionColumn,
  DetailSectionColumnProps,
} from "~features/MintPage/components/DetailSection/DetailSectionColumn";
import { DetailSectionLine } from "~features/MintPage/components/DetailSection/DetailSectionLine";
import useMintState from "~hooks/useMintState";

import {
  CollectionContractType,
  PriceType,
} from "../../../apollo/graphql.generated";
import { BaseToken } from "../index";

type DetailSectionProps = {
  token?: BaseToken;
};

export const DetailSectionApollo = ({ token }: DetailSectionProps) => {
  const [openseaBlocklistModalOpen, setOpenseaBlocklistModalOpen] =
    useState<boolean>(false);
  const {
    mintVector,
    collectionLoading,
    collection,
    address,
    chainId,
    // isSeriesMint,
    isAnySeriesMint,
    mintVectorsLoading,
    totalSize,
    isImported,
    importedPlatformInfo,
  } = useMintState();
  const mintVectorIdProps = {
    isDirectMint: mintVector?.isDirectMint ?? false,
    vectorId: mintVector?.id ?? undefined,
    encodedOnchainId: mintVector?.onchainMintVectorId ?? undefined,
  };
  const theme = useMantineTheme();
  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);

  const blockchain = networkLookup(chainId);

  const maskedAddress = maskAddress(address, 4, 4);

  const mintVectorId =
    getMintVectorId(
      mintVectorIdProps.isDirectMint,
      mintVectorIdProps.vectorId,
      mintVectorIdProps.encodedOnchainId
    ) ?? "";
  const maskedMintVectorId = maskAddress(mintVectorId, 6, 4);

  return (
    <>
      <DetailSectionLine
        leftContent="Created"
        rightContent={dayjs(mintVector?.start).format("YYYY")}
      />
      <DetailSectionLine
        leftContent="Blockchain"
        rightContent={blockchain.displayName}
      />
      {parseFloat(collection?.royalty?.royaltyPercentageString ?? "0") > 0 && (
        <DetailSectionLine
          leftContent="Creator royalty"
          rightContent={`${
            collection?.royalty?.royaltyPercentageString ?? "0"
          }%`}
        />
      )}
      <DetailSectionLine leftContent="Token standard" rightContent="ERC-721" />
      <DetailSectionLine
        leftContent="Contract address"
        rightContent={
          <Group spacing={4} noWrap>
            {isSmall ? (
              <CopyText
                displayText={maskedAddress}
                copyValue={address}
              ></CopyText>
            ) : (
              maskedAddress
            )}
            <CopyButton copyValue={address} />
          </Group>
        }
      />
      {isAnySeriesMint ? (
        <DetailSectionLine
          leftContent="Series Size"
          rightContent={
            <EditionSize
              isSizeLoading={false}
              editionSize={totalSize === 0 ? null : totalSize}
              useInfinitySymbol={false}
            />
          }
        />
      ) : (
        <DetailSectionLine
          leftContent="Edition size"
          rightContent={
            <>
              {collection?.type === CollectionContractType.General ? (
                <EditionSize
                  isSizeLoading={false}
                  editionSize={1}
                  useInfinitySymbol={false}
                />
              ) : collectionLoading ? (
                <EditionSize
                  isSizeLoading={true}
                  editionSize={null}
                  useInfinitySymbol={false}
                />
              ) : (
                <EditionSize
                  isSizeLoading={false}
                  editionSize={
                    isUnlimitedSize(token?.size ?? 0) ? null : token?.size
                  }
                  useInfinitySymbol={false}
                />
              )}
            </>
          }
        />
      )}
      {collection?.usingOpenseaBlocklist && (
        <DetailSectionLine
          leftContent={
            <Group spacing={7}>
              <div>Using CORI Operator Filter Registry</div>
              <InfoCircleFill
                width={17}
                height={17}
                cursor={"pointer"}
                onClick={() => setOpenseaBlocklistModalOpen(true)}
              />
            </Group>
          }
          rightContent="Yes"
        />
      )}
      {mintVector && (
        <DetailSectionLine
          leftContent="Mint vector ID"
          rightContent={
            mintVectorsLoading ? (
              "Loading ..."
            ) : mintVectorIdProps.isDirectMint &&
              mintVector.priceType != PriceType.DutchAuction ? (
              mintVectorId
            ) : (
              <Group spacing={4}>
                {isSmall ? (
                  <CopyText
                    displayText={maskedMintVectorId}
                    copyValue={mintVectorId}
                  ></CopyText>
                ) : (
                  maskedMintVectorId
                )}
                <CopyButton copyValue={mintVectorId} />
              </Group>
            )
          }
        />
      )}
      {isImported && importedPlatformInfo && (
        <DetailSectionLine
          leftContent="Imported from"
          rightContent={
            <Group spacing={8}>
              <importedPlatformInfo.icon width={16} />
              {importedPlatformInfo.name}
            </Group>
          }
        />
      )}
      <OpenseaRegistryInfoModal
        isOpen={openseaBlocklistModalOpen}
        onClose={() => setOpenseaBlocklistModalOpen(false)}
        onCreateCollectionPage={false}
      />
    </>
  );
};

export const DetailSectionItem = ({
  title,
  content,
  link,
}: DetailSectionColumnProps) => (
  <DetailSectionColumn
    title={
      <Text size="xs" color={TEXT_COLOR.SECONDARY}>
        {title}
      </Text>
    }
    content={<Text size="sm">{content}</Text>}
    link={link}
  />
);
