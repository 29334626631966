import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgCommunityBenefit = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M19.2588 5.00003L18.2088 3.90836L18.9422 3.17502C19.0904 3.0276 19.2079 2.85228 19.288 2.65919C19.3681 2.4661 19.4092 2.25907 19.4088 2.05002C19.4113 1.73128 19.3185 1.41905 19.1424 1.15339C18.9662 0.887736 18.7147 0.680749 18.4201 0.558977C18.1255 0.437205 17.8013 0.406197 17.489 0.469931C17.1767 0.533665 16.8905 0.689234 16.6672 0.916691L7.50051 10.0834C6.43812 9.55657 5.22308 9.42451 4.07233 9.71076C2.92157 9.99701 1.91001 10.6829 1.21824 11.6461C0.526479 12.6092 0.199543 13.7869 0.295806 14.9688C0.39207 16.1507 0.905266 17.2599 1.74377 18.0984C2.58227 18.9369 3.6915 19.4501 4.87341 19.5464C6.05532 19.6427 7.23298 19.3157 8.19612 18.624C9.15926 17.9322 9.84518 16.9206 10.1314 15.7699C10.4177 14.6191 10.2856 13.4041 9.75884 12.3417L12.8755 9.22503L13.5088 9.86669C13.7829 10.1396 14.1542 10.2925 14.541 10.2918C14.9277 10.291 15.2984 10.1366 15.5713 9.86253C15.8443 9.58847 15.9972 9.21721 15.9964 8.83041C15.9956 8.44362 15.8412 8.07298 15.5672 7.80003L15.0005 7.15003L16.1838 5.96669L17.2338 7.02503C17.5112 7.28943 17.8798 7.43693 18.263 7.43693C18.6462 7.43693 19.0148 7.28943 19.2922 7.02503C19.548 6.74795 19.6873 6.38295 19.6811 6.00585C19.6749 5.62875 19.5237 5.26853 19.2588 5.00003ZM5.31717 12.05C5.81163 12.05 6.29498 12.1966 6.7061 12.4714C7.11722 12.7461 7.43765 13.1365 7.62687 13.5933C7.81609 14.0501 7.8656 14.5528 7.76914 15.0378C7.67267 15.5227 7.43457 15.9682 7.08494 16.3178C6.73531 16.6674 6.28985 16.9055 5.8049 17.002C5.31995 17.0985 4.81728 17.0489 4.36047 16.8597C3.90365 16.6705 3.5132 16.3501 3.2385 15.939C2.9638 15.5278 2.81717 15.0445 2.81717 14.55C2.81717 13.887 3.08057 13.2511 3.54941 12.7823C4.01825 12.3134 4.65413 12.05 5.31717 12.05Z"
      fill="#0D0D0D"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCommunityBenefit);
export default ForwardRef;
