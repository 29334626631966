import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgDiscordLogoBlack = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={25}
    height={20}
    viewBox="0 0 25 20"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path d="M20.9012 2.48152C19.356 1.77251 17.699 1.25014 15.9665 0.950962C15.935 0.945188 15.9034 0.959618 15.8872 0.988478C15.6741 1.3675 15.438 1.86197 15.2727 2.25062C13.4093 1.97165 11.5555 1.97165 9.73025 2.25062C9.56491 1.85333 9.32029 1.3675 9.10623 0.988478C9.08998 0.960581 9.05846 0.946151 9.02691 0.950962C7.29536 1.24919 5.63836 1.77155 4.0922 2.48152C4.07881 2.48729 4.06734 2.49692 4.05972 2.50941C0.916737 7.20497 0.0557412 11.7851 0.478117 16.3085C0.480028 16.3306 0.492451 16.3518 0.509652 16.3652C2.5833 17.8881 4.59199 18.8126 6.56338 19.4254C6.59493 19.435 6.62836 19.4234 6.64843 19.3975C7.11477 18.7606 7.53046 18.0891 7.88688 17.383C7.90791 17.3417 7.88783 17.2926 7.84484 17.2762C7.18548 17.0261 6.55764 16.7212 5.9537 16.3748C5.90593 16.347 5.90211 16.2786 5.94605 16.2459C6.07314 16.1507 6.20027 16.0516 6.32162 15.9515C6.34358 15.9333 6.37417 15.9294 6.39999 15.941C10.3676 17.7524 14.663 17.7524 18.5839 15.941C18.6097 15.9285 18.6403 15.9323 18.6632 15.9506C18.7846 16.0506 18.9117 16.1507 19.0397 16.2459C19.0837 16.2786 19.0808 16.347 19.033 16.3748C18.4291 16.7279 17.8012 17.0261 17.1409 17.2753C17.0979 17.2916 17.0788 17.3417 17.0998 17.383C17.4639 18.0882 17.8796 18.7596 18.3373 19.3965C18.3565 19.4234 18.3908 19.435 18.4224 19.4254C20.4033 18.8126 22.412 17.8881 24.4857 16.3652C24.5038 16.3518 24.5153 16.3316 24.5172 16.3094C25.0227 11.0799 23.6705 6.53735 20.9327 2.51037C20.926 2.49692 20.9146 2.48729 20.9012 2.48152ZM8.47938 13.5542C7.28485 13.5542 6.30059 12.4575 6.30059 11.1107C6.30059 9.76391 7.26576 8.66724 8.47938 8.66724C9.70252 8.66724 10.6773 9.77354 10.6581 11.1107C10.6581 12.4575 9.69296 13.5542 8.47938 13.5542ZM16.5351 13.5542C15.3406 13.5542 14.3563 12.4575 14.3563 11.1107C14.3563 9.76391 15.3215 8.66724 16.5351 8.66724C17.7582 8.66724 18.7329 9.77354 18.7139 11.1107C18.7139 12.4575 17.7582 13.5542 16.5351 13.5542Z" />
  </svg>
);

const ForwardRef = forwardRef(SvgDiscordLogoBlack);
export default ForwardRef;
