import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Switch, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
const SwitchMarketplace = (props) => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs - 1}px)`);
    return (_jsx(Switch, { styles: {
            root: {
                alignItems: "center",
                display: "flex",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                borderRadius: "6px",
                width: isMobile ? "100%" : undefined,
                height: 40,
                padding: "0 12px",
            },
            label: { fontWeight: WEIGHT_BOLD },
        }, ...props }));
};
export default SwitchMarketplace;
