import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgCodeBrowser = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      opacity={0.12}
      d="M18.3333 7.5V6.5C18.3333 5.09987 18.3333 4.3998 18.0608 3.86502C17.8212 3.39462 17.4387 3.01217 16.9683 2.77248C16.4335 2.5 15.7335 2.5 14.3333 2.5L5.66666 2.5C4.26653 2.5 3.56646 2.5 3.03168 2.77248C2.56128 3.01217 2.17882 3.39462 1.93914 3.86502C1.66666 4.3998 1.66666 5.09987 1.66666 6.5L1.66666 7.5L18.3333 7.5Z"
      fill="currentColor"
    />
    <path
      d="M18.3333 7.5H1.66666M11.6667 14.5833L13.75 12.5L11.6667 10.4167M8.33332 10.4167L6.24999 12.5L8.33332 14.5833M1.66666 6.5L1.66666 13.5C1.66666 14.9001 1.66666 15.6002 1.93914 16.135C2.17882 16.6054 2.56127 16.9878 3.03168 17.2275C3.56646 17.5 4.26652 17.5 5.66665 17.5H14.3333C15.7335 17.5 16.4335 17.5 16.9683 17.2275C17.4387 16.9878 17.8212 16.6054 18.0608 16.135C18.3333 15.6002 18.3333 14.9001 18.3333 13.5V6.5C18.3333 5.09987 18.3333 4.3998 18.0608 3.86502C17.8212 3.39462 17.4387 3.01217 16.9683 2.77248C16.4335 2.5 15.7335 2.5 14.3333 2.5L5.66666 2.5C4.26653 2.5 3.56646 2.5 3.03168 2.77248C2.56128 3.01217 2.17882 3.39462 1.93914 3.86502C1.66666 4.3998 1.66666 5.09987 1.66666 6.5Z"
      stroke="currentColor"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCodeBrowser);
export default ForwardRef;
